import React from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../../service/ApiRest";
import {UI} from "@newageerp/nae-react-ui"

interface Props {
    id: number
}

export default function InvoiceLineDeleteButton(props: Props) {
    const history = useHistory();
    const {id} = props;

    const doDelete = () => {
        ApiRest.deleteElement('invoice-line', id).then(res => {
            history.goBack();
        });
    }

    return (
        <UI.PopoverConfirm.PopoverConfirm onClick={doDelete}>
            <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.red} className={'w-full'}>Ištrinti</UI.Buttons.Button>
        </UI.PopoverConfirm.PopoverConfirm>
    )
}
