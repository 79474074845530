import React, { Fragment } from 'react'
import { MenuFolder, MenuSpacer } from '@newageerp/ui.menu.base.menu'
import MenuItemUpload from "../items/MenuItemUpload" 
import MenuItemUploadReport from "../items/MenuItemUploadReport"

export default function MenuFolderImportavimasc97() {
    return (
        <Fragment>
            <MenuFolder
                item={{
                    children: 'Importavimas',
                    icon: 'arrow-up-from-bracket'
                }}
                menuFolderId='MenuFolderImportavimasc97'
            >

                <MenuItemUpload forceSkipIcon={true}/>
                <MenuItemUploadReport forceSkipIcon={true}/>
            </MenuFolder>
        </Fragment>
    )
}
