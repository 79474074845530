import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import InvoicesListCard from "./InvoicesListCard";


export default function InvoicesListPage() {

    return (
        <Container>
            <Row>
                <Col>
                    <InvoicesListCard options={{onlyPurchases: true}}/>
                </Col>
            </Row>
        </Container>
    );
}
