import React, { Fragment, useState } from 'react'
import { useTripHookNae } from '../../../../../Components/Hooks/useTripHookNae'
import { PropsId } from '../../../../../UserComponents/ModelsCacheData/types'
import { Table, Th, Td } from '@newageerp/ui.table.base.table'
import { TwBackgroundColors, TwTextAlignment, TwTextColors } from '@newageerp/ui.styles.tailwind';
import classNames from 'classnames';
import { SquareButton } from '@newageerp/ui.buttons.base.square-button'

interface Opt {
    title: string,
    key: string,
    fullTitle: string,
}

export default function TripKmTable(props: PropsId) {
    const [showWithoutDiff, setShowWithoutDiff] = useState(false);
    const toggleShowWithoutDiff = () => setShowWithoutDiff(!showWithoutDiff);

    const element: any = useTripHookNae(props.id);
    const optionsWithDiff: Opt[] = [
        {
            title: '~L',
            key: 'loctrackerDrivenKmWithAllowedDiff',
            fullTitle: 'Loctracker KM su Nefiksuojama paklaida',
        },
        {
            title: '~A',
            key: 'autoRouteKmWithAllowedDiff',
            fullTitle: 'KM pagal autoroute su Nefiksuojama paklaida',
        },
        
        {
            title: 'S',
            key: 'invoiceDrivenKm',
            fullTitle: 'Sąskaita, KM'
        }
    ];

    const optionsWithoutDiff: Opt[] = [
        {
            title: 'L',
            key: 'loctrackerDrivenKm',
            fullTitle: 'Loctracker KM',
        },
        {
            title: 'A',
            key: 'autoRouteKm',
            fullTitle: 'KM pagal autoroute'
        },
        
        {
            title: 'S',
            key: 'invoiceDrivenKm',
            fullTitle: 'Sąskaita, KM'
        }
    ];

    const options = showWithoutDiff ? optionsWithoutDiff : optionsWithDiff;

    if (!element) {
        return <Fragment />
    }

    const calcDiffPercent = (_v1: number, _v2: number) => {
        const v1 = Math.round(_v1);
        const v2 = Math.round(_v2);

        return Math.round((v1 > 0 && v2 > 0 ? (v1 - v2) / v1 : -1) * 100 * 100) / 100;
    }
    const calcDiffValue = (v1: number, v2: number) => {
        return Math.round(v1 - v2).toFixed(0);
    }

    return (
        <Fragment>
            <div>
                <SquareButton onClick={toggleShowWithoutDiff} size={"sm"} bgColor={showWithoutDiff ? TwBackgroundColors.green : TwBackgroundColors.slate400}>Rodyti be paklaidų</SquareButton>
            </div>
            <Table
                thead={
                    <thead>
                        <tr>
                            <Th></Th>
                            <Th></Th>
                            {options.map((i, index) => {
                                return (
                                    <Th key={`th-${index}`} title={i.fullTitle} className={classNames(TwTextAlignment.right, {'hidden': index === 2})}>
                                        {i.title}
                                    </Th>
                                )
                            })}
                        </tr>
                        <tr>
                            <Th></Th>
                            <Th></Th>
                            {options.map((i, index) => {
                                return (
                                    <Th key={`th-${index}`} title={i.fullTitle} className={classNames(TwTextAlignment.right, {'hidden': index === 2})}>
                                        {element[i.key]}
                                    </Th>
                                )
                            })}
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>

                        {options.map((rowI, rowIndex) => {

                            return (
                                <tr key={`tr-${rowIndex}`} className={rowIndex === 0?'hidden':''}>
                                    <Th title={rowI.fullTitle}>
                                        {rowI.title}
                                    </Th>
                                    <Th title={rowI.fullTitle}>
                                        {element[rowI.key]}
                                    </Th>
                                    {options.map((i, index) => {
                                        const v = calcDiffValue(element[rowI.key], element[i.key]);
                                        const vInt = parseInt(v, 10);

                                        const className = vInt === 0 ? TwTextColors.slate400 : (vInt > 0 ? TwTextColors.green : TwTextColors.red);

                                        return (
                                            <Td key={`th-${index}-${rowIndex}`} title={`${rowI.fullTitle} - ${i.fullTitle}`} className={classNames(className, TwTextAlignment.right, {'hidden': index === 2})}>
                                                {index >= rowIndex ? '' : <Fragment>{calcDiffValue(element[rowI.key], element[i.key])} KM</Fragment>}
                                            </Td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                }
            />
            <Table
                thead={
                    <thead>
                        <tr>
                            <Th></Th>
                            <Th></Th>
                            {options.map((i, index) => {
                                return (
                                    <Th key={`th-${index}`} title={i.fullTitle} className={classNames(TwTextAlignment.right, {'hidden': index === 2})}>
                                        {i.title}
                                    </Th>
                                )
                            })}
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>

                        {options.map((rowI, rowIndex) => {
                            
                            return (
                                <tr key={`tr-${rowIndex}`} className={rowIndex === 0?'hidden':''}>
                                    <Th title={rowI.fullTitle}>
                                        {rowI.title}
                                    </Th>
                                    <Th title={rowI.fullTitle}>
                                        {element[rowI.key]}
                                    </Th>
                                    {options.map((i, index) => {
                                        const v = calcDiffPercent(element[rowI.key], element[i.key]);

                                        const className = v === 0 ? TwTextColors.slate400 : (v > 0 ? TwTextColors.green : TwTextColors.red);
                                        return (
                                            <Td key={`th-${index}-${rowIndex}`} title={`${rowI.fullTitle} - ${i.fullTitle}`} className={classNames(className, TwTextAlignment.right, {'hidden': index === 2})}>
                                                {index >= rowIndex ? '' : <Fragment>{v} %</Fragment>}
                                            </Td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                }
            />
        </Fragment>
    )
}
