import React, {Fragment, useEffect, useState} from "react";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {Invoice, InvoiceLine} from "../InvoiceTypes";
import ApiRest from "../../../../service/ApiRest";
import {NaeTable} from 'nae-react-core-styles'

interface Props {
    element: Invoice,
}

const onlyUnique = (value: string, index: number, self: any) => {
    return self.indexOf(value) === index;
}

export default function InvoiceLines(props: Props) {
    const [filterFvsCode, setFilterFvsCode] = useState('');
    const [filterFvsVatCode, setFilterFvsVatCode] = useState('');

    const [data, setData] = useState<InvoiceLine[]>([]);
    const [dataToRender, setDataToRender] = useState<InvoiceLine[]>([]);
    const [search, setSearch] = useState('');

    const {element} = props;
    const getData = () => {
        if (element.id > 0) {
            ApiRest.getElementRel('invoice', element.id, 'lines').then(res => {
                if (res) {
                    setData(res);
                }
            });
        }
    }

    useEffect(getData, [element]);

    const filterData = () => {
        let _data = data;
        if (search) {
            _data = _data.filter((item: InvoiceLine) => {
                return item.truckName.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.fuelCardNumber.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.fvsCode.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.fvsVatCode.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.serviceName.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.country.toLowerCase().indexOf(search.toLowerCase()) > -1
            });
        }
        if (!!filterFvsCode) {
            _data = _data.filter((item: InvoiceLine) => item.fvsCode === filterFvsCode);
        }
        if (!!filterFvsVatCode) {
            _data = _data.filter((item: InvoiceLine) => item.fvsVatCode === filterFvsVatCode);
        }

        setDataToRender(_data);
    }

    useEffect(filterData, [search, data, filterFvsCode, filterFvsVatCode]);

    const fvsCodes = data.map(i => i.fvsCode).filter(onlyUnique);
    const fvsVatCodes = data.map(i => i.fvsVatCode).filter(onlyUnique);

    const goTo = (id: number) => {
        window.open('/invoice-line/' + id);
    }

    return (
        <Card className={"card-table"}>
            <Card.Header>
                Eilutės
            </Card.Header>
            <Card.Header className={"search"}>
                <Row>
                    <Col className={"v-center"}>
                        <input placeholder={"Paieška"} className={"search-input"} value={search}
                               onChange={(e) => setSearch(e.target.value)}/>
                    </Col>
                    <Col sm={2}>
                        <select onChange={e => setFilterFvsCode(e.target.value)} value={filterFvsCode}>
                            <option value={""}>FVS kodas</option>
                            {fvsCodes.map(i => <option key={"opt-" + i} value={i}>FVS {i}</option>)}
                        </select>
                    </Col>
                    <Col sm={2}>
                        <select onChange={e => setFilterFvsVatCode(e.target.value)} value={filterFvsVatCode}>
                            <option value={""}>PVM kodas</option>
                            {fvsVatCodes.map(i => <option key={"opt-" + i} value={i}>FVS {i}</option>)}
                        </select>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                {data.length > 0 ?
                    <Table striped={true} bordered={true}>
                        <thead>
                        <tr>
                            <NaeTable.th id={true}>#</NaeTable.th>
                            <NaeTable.th small={true}>Transportas /<br/>Kortelė</NaeTable.th>
                            {/*<th>Apt. stotis</th>*/}
                            <NaeTable.th>Šalis</NaeTable.th>
                            <NaeTable.th>Paslauga</NaeTable.th>
                            <NaeTable.th small={true}>FVS kodas /<br/>PVM kodas</NaeTable.th>
                            <NaeTable.th number={true}>Kiekis</NaeTable.th>
                            {element.currency !== 'EUR' &&
                                <Fragment>
                                    <NaeTable.th number={true}>Suma be PVM, {element.currency}</NaeTable.th>
                                    <NaeTable.th number={true}>Suma su PVM, {element.currency}</NaeTable.th>
                                </Fragment>
                            }
                            {/*<th>Valiuta</th>*/}

                            <NaeTable.th number={true}>Suma be PVM, EUR</NaeTable.th>
                            <NaeTable.th number={true}>Suma su PVM, EUR</NaeTable.th>
                            <th scope={"col"}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataToRender.map((item: InvoiceLine, index: number) => {
                            return (
                                <tr key={"line-" + item.id} className={`${item.hasReports ? ' bg-green-100' : ''}`}>
                                    <NaeTable.td link={true} onClick={() => goTo(item.id)}>
                                        {index + 1}
                                    </NaeTable.td>
                                    <NaeTable.td>
                                        {item.truckName}<br/>
                                        {item.fuelCardNumber} {item.tripReference}
                                    </NaeTable.td>
                                    {/*<td>Apt. stotis</td>*/}
                                    <NaeTable.td>{item.country}</NaeTable.td>
                                    <NaeTable.td>{item.serviceName}</NaeTable.td>
                                    <NaeTable.td>{item.fvsCode}<br/>{item.fvsVatCode}</NaeTable.td>
                                    <NaeTable.td number={true}>{item.quantity.toFixed(2)}</NaeTable.td>
                                    {element.currency !== 'EUR' &&
                                        <Fragment>
                                            <NaeTable.td number={true}>{item.total.toFixed(2)}</NaeTable.td>
                                            <NaeTable.td number={true}>{item.totalWithVat.toFixed(2)}</NaeTable.td>
                                        </Fragment>
                                    }
                                    {/*<td>Valiuta</td>*/}
                                    <NaeTable.td number={true}>{item.totalEur.toFixed(2)}</NaeTable.td>
                                    <NaeTable.td number={true}>{item.totalWithVatEur.toFixed(2)}</NaeTable.td>
                                    <td></td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </Table>
                    :
                    <Spinner animation="border" variant="info"/>
                }
            </Card.Body>
        </Card>
    )
}
