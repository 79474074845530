import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import ApiRest from "../../service/ApiRest";
import {Service, ServiceGroup} from "../Pages/Services/ServiceTypes";
import {useHistory} from 'react-router-dom'
import BackBtn from "../BackBtn";

interface Props {
    element: Service,
    saveData: (name: string,
               fvsCode: string,
               reverseVat: boolean,
               fvsSeparateLine: boolean,
               skipReports: boolean,
               serviceGroup: number,
    ) => void,
}

export default function ServiceForm(props: Props) {
    const history = useHistory();

    const {element, saveData} = props;
    const [name, setName] = useState(element.name);
    const [fvsCode, setFvsCode] = useState(element.fvsCode);
    const [reverseVat, setReverseVat] = useState(element.reverseVat);
    const [fvsSeparateLine, setFvsSeparateLine] = useState(element.fvsSeparateLine);
    const [skipReports, setSkipReports] = useState(element.skipReports);
    const [serviceGroup, setServiceGroup] = useState(element.serviceGroup.toString());


    const [groups, setGroups] = useState([]);

    const getListData = () => {
        ApiRest.getElements('service-group').then(res => {
            if (res) {
                setGroups(res);
            }
        });
    }

    useEffect(getListData, []);

    const goBack = () => {
        history.goBack();
    }

    return (
        <Card>
            <Card.Header>
                <BackBtn/>
                Paslauga
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Pavadinimas</Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Finvaldos kodas</Form.Label>
                        <Form.Control value={fvsCode} onChange={e => setFvsCode(e.target.value)}/>
                    </Form.Group>

                    <Form.Group onClick={() => setReverseVat(!reverseVat)}>
                        <Form.Check type="checkbox" label="Atvirkštinis PVM" checked={reverseVat}/>
                    </Form.Group>
                    <Form.Group onClick={() => setFvsSeparateLine(!fvsSeparateLine)}>
                        <Form.Check type="checkbox" label="FVS kaip atskira eilutė" checked={fvsSeparateLine}/>
                    </Form.Group>
                    <Form.Group onClick={() => setSkipReports(!skipReports)}>
                        <Form.Check type="checkbox" label="Nerodyti ataskaitoje" checked={skipReports}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Grupė</Form.Label>
                        <Form.Control
                            as="select"
                            className="mr-sm-2"
                            value={serviceGroup}
                            onChange={(e) => setServiceGroup(e.target.value)}
                        >
                            <option value="0">Pasirinkite...</option>
                            {groups.map((group: ServiceGroup) => <option key={'opt-g' + group.id}
                                                                         value={group.id}>{group.name}</option>)}

                        </Form.Control>
                    </Form.Group>

                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(
                                    name,
                                    fvsCode,
                                    reverseVat,
                                    fvsSeparateLine,
                                    skipReports,
                                    parseInt(serviceGroup, 10)
                                )}>Išsaugoti</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
