import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {Card, Col, Row, Button} from "react-bootstrap";
import {NaeTable} from "nae-react-core-styles";
import TableWithPaging from "../../TableWithPaging";
import {SystemUser} from "./UserTypes";

const moduleName = 'user';
const pageSize = 20;

interface Props {
    groupId?: number
}

export default function UsersListCard(props: Props) {
    const history = useHistory();

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [filter, setFilter] = useState('');

    const [services, setServices] = useState<SystemUser[]>([]);
    const [dataToRender, setDataToRender] = useState<SystemUser[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setServices(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
        let _data = services;
        if (search) {
            _data = _data.filter((item: SystemUser) => {
                return item.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.email.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.position.toLowerCase().indexOf(search.toLowerCase()) > -1
            });
        }

        // if (props.groupId) {
        //     _data = _data.filter((item: Service) => {
        //         return item.serviceGroup === props.groupId
        //     });
        // }
        //
        // if (!!filter) {
        //     if (filter === 'without_fvs') {
        //         _data = _data.filter((item: Service) => {
        //             return !item.fvsCode
        //         });
        //     } else if (filter === 'without_group') {
        //         _data = _data.filter((item: Service) => {
        //             return !item.serviceGroupName
        //         });
        //     }
        // }

        setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, services, filter, props.groupId, readyToLoad]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: SystemUser) => {
        history.replace('/users', {search, activePage, filter});
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (

        <Card className={"card-table"}>
            <Card.Header>
                <Row>
                    <Col className={"v-center"}>
                        Vartotojai
                    </Col>
                    <Col className={"text-right"}>
                        <Button variant={"success"} onClick={goToNew}>Naujas vartotajas</Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Header className={"search"}>
                <Row>
                    <Col className={"v-center"}>
                        <input placeholder={"Paieška"} className={"search-input"} value={search}
                               onChange={(e) => setSearch(e.target.value)}/>
                    </Col>
                    <Col sm={2}>
                        {/*<select value={filter} onChange={(e) => setFilter(e.target.value)}>*/}
                        {/*    <option value={""}>Filtras</option>*/}
                        {/*    <option value={"without_fvs"}>Be FVS kodo</option>*/}
                        {/*    <option value={"without_group"}>Be grupės</option>*/}
                        {/*</select>*/}
                    </Col>
                </Row>
            </Card.Header>
            <TableWithPaging
                activePage={activePage}
                setActivePage={setActivePage}
                dataToRender={dataToRender}
                head={
                    <tr>
                        <NaeTable.th id={true}>ID</NaeTable.th>
                        <NaeTable.th>Vardas, Pavardė</NaeTable.th>
                        <NaeTable.th>El. paštas</NaeTable.th>
                        <NaeTable.th>Pareigos</NaeTable.th>
                    </tr>
                }
                renderItem={(item: SystemUser) => {
                    return (
                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                            <NaeTable.td id={true}>{item.id}</NaeTable.td>
                            <NaeTable.td link={true}>{item.fullName}</NaeTable.td>
                            <NaeTable.td link={true}>{item.email}</NaeTable.td>
                            <NaeTable.td>{item.position}</NaeTable.td>
                        </tr>
                    )
                }}
            />
        </Card>

    );
}
