import React from "react";
import DriversListCard from "./DriversListCard";

export default function DriversListPage() {

    return (
        <div className={"container"}>
            <DriversListCard/>
        </div>
    );
}
