import React, { useState } from 'react'
import { SquareButton } from '@newageerp/ui.buttons.base.square-button'
import { useTranslation } from 'react-i18next'
import { IconList } from '@newageerp/ui.icons.base.icon-list';
import { Icon, IconType } from '@newageerp/ui.icons.base.icon';
import { TwBackgroundColors } from '@newageerp/ui.styles.tailwind';
import { Popup } from '@newageerp/ui.popups.base.popup'
import { Label, SelectAdvId, Wide, WideRow, WideToolbar } from '@newageerp/ui.form.base.form-pack';
import { DropZone } from '@newageerp/ui.files.files.drop-zone'

const options = [
  {
    value: "",
    label: ""
  },
  {
    value: "SN",
    label: "DKV"
  },
  {
    value: "AS24",
    label: "AS24"
  },
  {
    value: "Eurowag",
    label: "Eurowag"
  },
  {
    value: "Neste",
    label: "Neste"
  },
  {
    value: "E100",
    label: "E100"
  },
  {
    value: "WEX",
    label: "WEX EUROPE"
  },
  {
    value: "TISPL_PAS",
    label: "DKV TISPL PAS (FR)"
  },
  {
    value: "DKV_PAS",
    label: "DKV PAS (IT)"
  },
  {
    value: "FVS_PURCHASE",
    label: "FVS pirkimai"
  },
  {
    value: "CRT",
    label: "CRT"
  },
  {
    value: "CEPSA",
    label: "CEPSA"
  },
  {
    value: "DSV_SALES",
    label: "DSV pardavimai"
  },
  {
    value: "TRANSIMEKSA_SALES",
    label: "TRANSIMEKSA pardavimai"
  },
  {
    value: "ALPI_SALES",
    label: "ALPI pardavimai"
  },
  {
    value: "WALTER_SALES",
    label: "LKW Walter pardavimai"
  },
  {
    value: "WITT_SALES",
    label: "WITT Trucking pardavimai"
  },
  {
    value: "NTG_SALES",
    label: "NTG LT pardavimai"
  },
  {
    value: "NTG_DK_SALES",
    label: "NTG DK pardavimai"
  },
  {
    value: "HELLMAN",
    label: "HELLMAN pardavimai"
  },
  {
    value: "AXIS_SALES",
    label: "Axis pardavimai"
  },
  {
    value: "CONTINEXUS_SALES",
    label: "Continexus pardavimai"
  },
]

interface Props {
  onAddExtraFilter: (key: string, data: any) => void,
  loadData: () => void,
}

export default function ImportedFileMainTableDataSourceToolbarStartContent(props: Props) {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [files, setFiles] = useState<any>([]);

  return (
    <div>
      <SquareButton
        icon={{ icon: IconList.upload, type: IconType.SolidButton }}
        bgColor={TwBackgroundColors.sky}
        onClick={() => setShowPopup(true)}
      >
        {t('Įkelti')}
      </SquareButton>
      {showPopup && <Popup onClose={() => setShowPopup(false)} header={t('Failų importavimas')}>
        <Wide>
          <WideRow
            label={<Label>{t('Tipas')}</Label>}
            control={<SelectAdvId
              onSelectId={setSelectedType}
              selectedId={selectedType}
              options={options}
            />
            }
          />
          <WideRow
            label={<Label>{t('Failas')}</Label>}
            control={<DropZone onChangeFile={(e: any) => setFiles([...files, ...e])} />}
          />
          <WideToolbar
            onCancel={() => { }}
            onSave={() => { }}
          />
        </Wide>
      </Popup>}
    </div>
  )
}
