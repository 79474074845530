import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import TableWithPaging from "../../TableWithPaging";
import {NaeTable} from "nae-react-core-styles";
import {Trip} from "./SalesTypes";
import {UI} from "@newageerp/nae-react-ui";
import {Col, Form, Row} from "react-bootstrap";
import {Driver, Truck} from "../Trucks/TruckTypes";
import DateField from "../../Forms/fields/DateField";
import moment from "moment";
// @ts-ignore
import {Multiselect} from 'multiselect-react-dropdown';
import {MultiSelectOption} from "../Reports/ReportsPage";

const moduleName = 'trip';

const uri = 'data:application/vnd.ms-excel;base64,';

const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
    'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
    'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
    'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
    '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
    'xml><![endif]--></head><body>{table}</body></html>';


const calcDiffPercent = (_v1: number, _v2: number) => {
    const v1 = Math.round(_v1);
    const v2 = Math.round(_v2);

    return Math.round((v1 > 0 && v2 > 0 ? (v1 - v2) / v1 : -1) * 100 * 100) / 100;
}
const calcDiffValue = (v1: number, v2: number) => {
    return Math.round(v1 - v2).toFixed(0);
}

export default function TripRoutesFrigoReports() {
    const history = useHistory();

    const base64 = (s: string) => {
        return window.btoa(unescape(encodeURIComponent(s)));
    }

    const doFormat = (s: string) => {
        const context = {
            worksheet: 'Worksheet',
            // @ts-ignore
            table: window.document.getElementById("table-to-export").outerHTML,
        };

        // @ts-ignore
        return s.replace(/{(\w+)}/g, (_m, p) => context[p]);
    }

    const doDownload = () => {
        const element = window.document.createElement('a');
        element.href = uri + base64(doFormat(template));
        element.download = 'ataskaita.xls';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const [searchElement, setSearchElement] = useState<any>({});
    const appendSearch = (key: string, val: string) => {
        setSearchElement({...searchElement, [key]: val})
    }
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedTrucks, setSelectedTrucks] = useState<MultiSelectOption[]>([]);

    const getListData = () => {
        ApiRest.getElements('driver').then(res => {
            if (res) {
                setDrivers(res);
            }
        });
        ApiRest.getElements('truck').then(res => {
            if (res) {
                setTrucks(res.filter((t: Truck) => !!t.locktrackerNumber));
            }
        });
    }
    useEffect(getListData, []);

    const truckOptions = useMemo(() => {
        return [
            {id: -1, name: 'Nepriskirtas'},
            ...trucks.map((item) => {
                return {id: item.id, name: item.name};
            })
        ]
    }, [trucks]);

    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = () => setShowFilter(!showFilter);

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [trips, setTrips] = useState<Trip[]>([])
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [dataToRender, setDataToRender] = useState<Trip[]>([]);

    const [filter, setFilter] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setTrips(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = trips;

            _data = _data.filter((item: Trip) => {
               return item.otherCostFrigo > 0;
            });

            if (search) {
                _data = _data.filter((item: Trip) => {
                    return item.number.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.reference.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.truckName.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.trailer.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.comment.toLowerCase().indexOf(search.toLowerCase()) > -1
                });
            }

            if (selectedTrucks && selectedTrucks.length > 0) {
                const selectedTrucksIds = selectedTrucks.map(i => parseInt(i.id, 10));
                _data = _data.filter((item: Trip) => {
                    return selectedTrucksIds.indexOf(item.truck) >= 0;
                });
            }

            if (searchElement) {
                if (searchElement.number) {
                    _data = _data.filter((item: Trip) => {
                        return item.number.toLowerCase().indexOf(searchElement.number.toLowerCase()) > -1
                    });
                }
                if (searchElement.reference) {
                    _data = _data.filter((item: Trip) => {
                        return item.reference.toLowerCase().indexOf(searchElement.reference.toLowerCase()) > -1
                    });
                }
                if (searchElement.trailer) {
                    _data = _data.filter((item: Trip) => {
                        return item.trailer.toLowerCase().indexOf(searchElement.trailer.toLowerCase()) > -1
                    });
                }
                if (searchElement.driver && searchElement.driver > 0) {
                    _data = _data.filter((item: Trip) => {
                        return item.truckDriver == searchElement.driver || item.truckDriver2 == searchElement.driver;
                    });
                }

                if (searchElement.dateFromFrom) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateFrom) >= moment(searchElement.dateFromFrom);
                    });
                }
                if (searchElement.dateFromTo) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) <= moment(searchElement.dateFromTo);
                    });
                }


                if (searchElement.dateToFrom) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) >= moment(searchElement.dateToFrom);
                    });
                }
                if (searchElement.dateToTo) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) <= moment(searchElement.dateToTo);
                    });
                }

            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, trips, filter, readyToLoad, searchElement]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter', 'searchElement']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                } else if (p === 'searchElement') {
                    setSearchElement(v ? v : {});
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: Trip) => {
        history.replace('/trips-reports-frigo', {search, activePage, filter, searchElement});
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    let frigoDaysTotal = 0;
    let frigoTotal = 0;


    dataToRender.forEach((item: Trip) => {
        if (item.otherCostFrigo % 42 === 0) {
            frigoDaysTotal += item.otherCostFrigo / 42;
        } else {
            frigoDaysTotal += item.otherCostFrigo / 50;    
        }
        // frigoDaysTotal += item.otherCostFrigo / 42;
        frigoTotal += item.otherCostFrigo;
    })

    return (
        <div>

            <UI.Card.WhiteCard>
                <div className={"flex items-center"}>
                    <UI.Typography.H5 className={'flex-grow'}>Kuro šaldytuvui ataskaita</UI.Typography.H5>
                </div>

                <div className={"flex gap-2"}>
                    <UI.Form.Input placeholder={"Paieška"} className={"w-full"} value={search}
                                   onChange={(e) => setSearch(e.target.value)}/>

                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.nsecondary}
                                       onClick={toggleFilter}>Filtras</UI.Buttons.Button>

                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.green} brightness={700}
                                       onClick={doDownload}>Xlsx</UI.Buttons.Button>
                </div>

                {showFilter && <div className={'bg-blue-50 rounded-md px-4'}>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Numeris</Form.Label>
                                <Form.Control value={searchElement.number ? searchElement.number : ''}
                                              onChange={e => appendSearch('number', e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Reference</Form.Label>
                                <Form.Control value={searchElement.reference ? searchElement.reference : ''}
                                              onChange={e => appendSearch('reference', e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Vilkikas</Form.Label>
                                <Multiselect options={truckOptions}
                                             displayValue="name"
                                             placeholder={"Pasirinkti..."}
                                             id={"trucks"}
                                             onSelect={(selectedList: any) => setSelectedTrucks(selectedList)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Priekaba</Form.Label>
                                <Form.Control value={searchElement.trailer ? searchElement.trailer : ''}
                                              onChange={e => appendSearch('trailer', e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Vairuotojas</Form.Label>
                                <Form.Control
                                    as="select"
                                    className="mr-sm-2"
                                    value={searchElement.driver ? searchElement.driver : ''}
                                    onChange={(e) => appendSearch('driver', e.target.value)}
                                >
                                    <option value="0">Pasirinkite...</option>
                                    {drivers.map((driver: Driver) => <option key={'opt-g' + driver.id}
                                                                             value={driver.id}>{driver.fullName}</option>)}

                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pradžios data nuo</Form.Label>
                                <DateField value={searchElement.dateFromFrom ? searchElement.dateFromFrom : ''}
                                           setValue={(val) => appendSearch('dateFromFrom', val)}/>

                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pradžios data iki</Form.Label>
                                <DateField value={searchElement.dateFromTo ? searchElement.dateFromTo : ''}
                                           setValue={(val) => appendSearch('dateFromTo', val)}/>
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pabaigos data nuo</Form.Label>
                                <DateField value={searchElement.dateToFrom ? searchElement.dateToFrom : ''}
                                           setValue={(val) => appendSearch('dateToFrom', val)}/>

                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pabaigos data iki</Form.Label>
                                <DateField value={searchElement.dateToTo ? searchElement.dateToTo : ''}
                                           setValue={(val) => appendSearch('dateToTo', val)}/>
                            </Form.Group>
                        </Col>
                    </Row>

                </div>}

                <TableWithPaging
                    pageSize={500}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    dataToRender={dataToRender}
                    head={
                        <tr>
                            <NaeTable.th id={true}>ID</NaeTable.th>
                            <NaeTable.th>Numeris</NaeTable.th>
                            <NaeTable.th>Vilkikas</NaeTable.th>
                            <NaeTable.th>Vairuotojas</NaeTable.th>

                            <NaeTable.th> </NaeTable.th>

                            <NaeTable.th number={true}>Frigo dienos</NaeTable.th>
                            <NaeTable.th number={true}>Frigo suma</NaeTable.th>


                        </tr>}
                    renderItem={
                        (item: Trip) => {

                            let frigoDays = 0;

                            if (item.otherCostFrigo % 42 === 0) {
                                frigoDays = item.otherCostFrigo / 42;
                            } else {
                                frigoDays = item.otherCostFrigo / 50;    
                            }

                            return (
                                <tr key={"list-row-" + item.id}>
                                    <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                    <NaeTable.td onClick={() => goTo(item)} link={true}>{item.number}</NaeTable.td>
                                    <NaeTable.td>{item.truckName}</NaeTable.td>
                                    <NaeTable.td>
                                        {!!item.truckDriver && item.truckDriverName}
                                        {!!item.truckDriver2 && <Fragment> / {item.truckDriver2Name}</Fragment>}
                                    </NaeTable.td>

                                    <NaeTable.td number={true}>
                                        {"        "}
                                    </NaeTable.td>

                                    <NaeTable.td number={true}>
                                        {frigoDays.toFixed(0)}
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        {item.otherCostFrigo.toFixed(0)}
                                    </NaeTable.td>


                                </tr>
                            )
                        }
                    }
                    afterItems={
                        <tr className={'bg-blue-100 font-semibold'}>
                            <NaeTable.td id={true}>#</NaeTable.td>
                            <NaeTable.td>Iš viso</NaeTable.td>
                            <NaeTable.td>

                            </NaeTable.td>
                            <NaeTable.td>

                            </NaeTable.td>

                            <NaeTable.td number={true}>
                                {"        "}
                            </NaeTable.td>

                            <NaeTable.td number={true}>
                                {frigoDaysTotal.toFixed(0)}
                            </NaeTable.td>
                            <NaeTable.td number={true}>
                                {frigoTotal.toFixed(0)}
                            </NaeTable.td>

                        </tr>
                    }
                />



            </UI.Card.WhiteCard>

        </div>
    );
}
