import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {Card, Col, Row} from "react-bootstrap";
import {Service} from "./ServiceTypes";
import {NaeTable} from "nae-react-core-styles";
import TableWithPaging from "../../TableWithPaging";

const moduleName = 'service';
const pageSize = 20;

interface Props {
    groupId?: number
}

export default function ServicesListCard(props: Props) {
    const history = useHistory();

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [filter, setFilter] = useState('');

    const [services, setServices] = useState<Service[]>([]);
    const [dataToRender, setDataToRender] = useState<Service[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setServices(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
        let _data = services;
        if (search) {
            _data = _data.filter((item: Service) => {
                return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.serviceGroupName.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.fvsCode.toLowerCase().indexOf(search.toLowerCase()) > -1
            });
        }

        if (props.groupId) {
            _data = _data.filter((item: Service) => {
                return item.serviceGroup === props.groupId
            });
        }

        if (!!filter) {
            if (filter === 'without_fvs') {
                _data = _data.filter((item: Service) => {
                    return !item.fvsCode
                });
            } else if (filter === 'without_group') {
                _data = _data.filter((item: Service) => {
                    return !item.serviceGroupName
                });
            }
        }

        setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, services, filter, props.groupId, readyToLoad]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: Service) => {
        history.replace('/services', {search, activePage, filter});
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (

        <Card className={"card-table"}>
            <Card.Header>
                <Row>
                    <Col className={"v-center"}>
                        Paslaugos
                    </Col>
                    <Col className={"text-right"}>
                        {/*<Button variant={"success"} onClick={goToNew}>Nauja</Button>*/}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Header className={"search"}>
                <Row>
                    <Col className={"v-center"}>
                        <input placeholder={"Paieška"} className={"search-input"} value={search}
                               onChange={(e) => setSearch(e.target.value)}/>
                    </Col>
                    <Col sm={2}>
                        <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                            <option value={""}>Filtras</option>
                            <option value={"without_fvs"}>Be FVS kodo</option>
                            <option value={"without_group"}>Be grupės</option>
                        </select>
                    </Col>
                </Row>
            </Card.Header>
            <TableWithPaging
                activePage={activePage}
                setActivePage={setActivePage}
                dataToRender={dataToRender}
                head={
                    <tr>
                        <NaeTable.th id={true}>ID</NaeTable.th>
                        <NaeTable.th>Pavadinimas</NaeTable.th>
                        <NaeTable.th>FVS kodas</NaeTable.th>
                        <NaeTable.th>Grupė</NaeTable.th>
                    </tr>
                }
                renderItem={(item: Service) => {
                    return (
                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                            <NaeTable.td id={true}>{item.id}</NaeTable.td>
                            <NaeTable.td link={true}>{item.name}</NaeTable.td>
                            <NaeTable.td>{item.fvsCode}</NaeTable.td>
                            <NaeTable.td>{item.serviceGroupName}</NaeTable.td>
                        </tr>
                    )
                }}
            />
        </Card>

    );
}
