import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Invoice} from "../Pages/Invoices/InvoiceTypes";


interface Props {
    element: Invoice,
    saveData: (date: string, number: string, currencyRate: number) => void,
}

export default function InvoiceUpdateForm(props: Props) {
    const {element, saveData} = props;

    const [date, setDate] = useState(element.date);
    const [number, setNumber] = useState(element.number);
    const [currencyRate, setCurrencyRate] = useState((element.total / element.totalEur).toFixed(6));


    return (
        <Card>
            <Card.Header>Sąskaita</Card.Header>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Data</Form.Label>
                            <Form.Control value={date} onChange={e => setDate(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Numeris</Form.Label>
                            <Form.Control value={number} onChange={e => setNumber(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Kursas</Form.Label>
                            <Form.Control value={currencyRate} onChange={e => setCurrencyRate(e.target.value)}/>
                        </Form.Group>
                    </Form.Row>

                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(
                                    date, number, parseFloat(currencyRate)
                                )}>Išsaugoti</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}