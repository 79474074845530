import { UI } from '@newageerp/nae-react-ui';
import { INaeWidget } from '@newageerp/nae-react-ui/dist/interfaces';
import { TruckRelTableDataSourceByTruckFuelTankWidget } from '../../../_generated/tabs/tables-rel-data-source/TruckRelTableDataSourceByTruckFuelTank';
import { NaeSSchemaMap } from '../../NaeSSchema';

export const TruckRelWidgets: INaeWidget[] = [
    {
        schema: NaeSSchemaMap.TruckFuelTank.schema,
        type: UI.Widget.WidgetType.viewBottom,
        sort: 40,
        options: { hideSearchBar: true },
        comp: TruckRelTableDataSourceByTruckFuelTankWidget,
    }
];
