import { useEffect } from "react";
import { NaeSSchemaMap } from "../../config/NaeSSchema";
import { useDataCache } from "./DataCacheProvider";
import { useSelector } from "react-redux";
import { SelectorUserNae } from "../Models/ormSelectors";

interface ChildId {
  id: number
}

export interface IUserModelNae {
    id: number,
scopes: any,
badges: any,
disabled: boolean,
fullName: string,
position: string,

}

export const IUserFieldsNae = [
    "id",
    "scopes",
    "badges",
    "disabled",
    "fullName",
    "position"
];

export function useUserHookNae(id: number) : IUserModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorUserNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.User.schema,
        fields: IUserFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
