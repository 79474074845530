import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Invoice} from "./InvoiceTypes";
import ApiRest from "../../../service/ApiRest";
import {Button, Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {NaePaging, NaeTable} from 'nae-react-core-styles'
import ApiInvoice from "../../../service/ApiInvoice";
import DateField from "../../Forms/fields/DateField";

const moduleName = 'invoice';

interface Props {
    options?: any
    replaceModule?: string,
}

export default function InvoicesListCard(props: Props) {
    const history = useHistory();

    // console.log('history', history.location.state)
    const [readyToLoad, setReadyToLoad] = useState(false);

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const [state, setState] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(0);

    const [totalEur, setTotalEur] = useState(0);
    const [totalVatEur, setTotalVatEur] = useState(0);
    const [totalWithVatEur, setTotalWithVatEur] = useState(0);


    const [invoices, setInvoices] = useState<Invoice[]>([])
    const [search, setSearch] = useState('');


    const getData = () => {
        if (readyToLoad) {
            setState('loading');
            ApiRest.getElements(moduleName, {
                page: activePage,
                options: {search, dateFrom, dateTo, ...props.options}
            }).then(res => {
                setInvoices(res.data);
                setCount(res.count);


                setTotalEur(res.totalEur);
                setTotalVatEur(res.totalVatEur);
                setTotalWithVatEur(res.totalWithVatEur);

                setState('');
            })
        }
    }

    useEffect(getData, [activePage, search, dateFrom, dateTo, readyToLoad]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'dateFrom', 'dateTo']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'dateFrom') {
                    setDateFrom(v);
                } else if (p === 'dateTo') {
                    setDateTo(v);
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300);
    }
    useEffect(restoreHistoryState, []);


    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, dateFrom, dateTo]);


    const goTo = (item: Invoice) => {
        history.replace('/' + (props.replaceModule ? props.replaceModule : 'invoices'), {
            search,
            activePage,
            dateFrom,
            dateTo
        });
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    const exportInvoices = () => {
        ApiInvoice.exportMultiple({search, dateFrom, dateTo, ...props.options});
    }

    const pages = Math.ceil(count / 20);

    return (

        <Card className={"card-table"}>
            <Card.Header>
                <Row>
                    <Col className={"v-center"}>
                        Sąskaitos
                    </Col>
                    <Col className={"text-right"}>
                        <Button variant={"info"} onClick={exportInvoices}>Eksportuoti</Button>
                    </Col>
                </Row>
            </Card.Header>

            <Card.Header className={"search"}>
                <Row>
                    <Col className={"v-center"}>
                        <input placeholder={"Paieška"} className={"search-input"} value={search}
                               onChange={(e) => setSearch(e.target.value)}/>
                    </Col>
                    <Col sm={"auto"}>
                        <Row>
                            <Col className={"v-center"}>Nuo</Col>
                            <Col sm={"8"}><DateField value={dateFrom}
                                                     setValue={(val) => setDateFrom(val)}/></Col>
                        </Row>


                    </Col>
                    <Col sm={"auto"}>
                        <Row>
                            <Col className={"v-center"}>Iki:</Col>
                            <Col sm={"8"}><DateField value={dateTo}
                                                     setValue={(val) => setDateTo(val)}/></Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                {state === 'loading' ?
                    <Spinner animation="border" variant="info"/>
                    :
                    <Table striped hover className={"mt-2"}>
                        <thead>
                        <tr>
                            <NaeTable.th link={true}>ID</NaeTable.th>
                            <NaeTable.th>Data</NaeTable.th>
                            <NaeTable.th>Numeris</NaeTable.th>
                            <NaeTable.th>Klientas</NaeTable.th>
                            <NaeTable.th number={true}>Eilučių kiekis</NaeTable.th>
                            <NaeTable.th number={true}>Iš viso</NaeTable.th>
                            <NaeTable.th number={true}>Iš viso su PVM</NaeTable.th>
                            <NaeTable.th>Valiuta</NaeTable.th>
                            <NaeTable.th number={true}>Iš viso, EUR</NaeTable.th>
                            <NaeTable.th number={true}>Iš viso su PVM, EUR</NaeTable.th>
                            <NaeTable.th number={true}> </NaeTable.th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoices.map((item: Invoice) => {
                            return (
                                <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                    <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                    <NaeTable.td nw={true} link={true}>{item.date}</NaeTable.td>
                                    <NaeTable.td>{item.number}</NaeTable.td>
                                    <NaeTable.td small={true}>{item.client}</NaeTable.td>
                                    <NaeTable.td small={true} number={true}>{item.linesCount}</NaeTable.td>
                                    <NaeTable.td small={true}
                                                 number={true}>{item.total.toFixed(2)}</NaeTable.td>
                                    <NaeTable.td small={true}
                                                 number={true}>{item.totalWithVat.toFixed(2)}</NaeTable.td>
                                    <NaeTable.td small={true}>{item.currency}</NaeTable.td>
                                    <NaeTable.td number={true}>{item.totalEur.toFixed(2)}</NaeTable.td>
                                    <NaeTable.td
                                        number={true}>{item.totalWithVatEur.toFixed(2)}</NaeTable.td>
                                    <NaeTable.Td>
                                        {item.type === 'S' && item.references}
                                    </NaeTable.Td>
                                </tr>
                            )
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={10} className={"text-right"}>Iš viso, EUR:</td>
                            <NaeTable.td
                                number={true}>{totalEur.toFixed(2)}</NaeTable.td>
                        </tr>
                        <tr>
                            <td colSpan={10} className={"text-right"}>PVM, EUR:</td>
                            <NaeTable.td
                                number={true}>{totalVatEur.toFixed(2)}</NaeTable.td>
                        </tr>
                        <tr>
                            <td colSpan={10} className={"text-right"}>Iš viso su PVM, EUR:</td>
                            <NaeTable.td
                                number={true}>{totalWithVatEur.toFixed(2)}</NaeTable.td>
                        </tr>
                        </tfoot>
                    </Table>

                }
            </Card.Body>

            <Card.Footer>
                <div className={"paging"}>
                    <NaePaging.Prev onClick={() => {
                        setActivePage(Math.max(activePage - 1, 1));
                    }}/>
                    <NaePaging.Group pages={pages} activePage={activePage} onClick={(p) => {
                        setActivePage(p);
                    }}/>
                    <NaePaging.Next onClick={() => {
                        setActivePage(Math.min(activePage + 1, pages));
                    }}/>
                </div>
            </Card.Footer>
        </Card>

    );
}
