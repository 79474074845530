import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {Card} from "react-bootstrap";
import ApiRest from "../../../service/ApiRest";
import TruckForm from "../../Forms/TruckForm";
import {toast} from "react-toastify";
import InvoiceLinesListCard from "../Invoices/InvoiceLinesListCard";
import {Truck} from "./TruckTypes";
import {UI} from "@newageerp/nae-react-ui"
import useApiRest from "../../../Hooks/useApiRest";
import useApiRestPost from "../../../Hooks/useApiRestPost";

const moduleName = 'truck';

interface ParamTypes {
    id: string
}

const defElement: Truck = {
    id: 0,
    name: '',
    leasingFrom: 0,
    leasingTo: 0,
    manufactureYear: 0,
    mileage: 0,
    truckTrailerGroup: 0,
    truckGroup: 0,
    truckGroupName: '',
    truckTrailerGroupName: '',
    locktrackerNumber: '',
}

export default function TrucksPage() {
    const history = useHistory();

    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (name: string,
                      leasingFrom: number,
                      leasingTo: number,
                      manufactureYear: number,
                      mileage: number,
                      truckTrailerGroup: number,
                      truckGroup: number
    ) => {
        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    name,
                    leasingFrom,
                    leasingTo,
                    manufactureYear,
                    mileage,
                    truckTrailerGroup,
                    truckGroup
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
                history.push('/' + moduleName + '/' + res.id);
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    name,
                    leasingFrom,
                    leasingTo,
                    manufactureYear,
                    mileage,
                    truckTrailerGroup,
                    truckGroup
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const [doSyncTasks, doSyncTasksData] = useApiRestPost('loctracker/syncActiveTasksForTruck')
    const doSync = () => {
        doSyncTasks({truckId: element.id})
    }

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <TruckForm element={element} saveData={saveData}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <UI.Card.WhiteCard>
            <UI.Buttons.Button className={"w-full"} icon={"fal fa-fw fa-sync"} iconLoading={doSyncTasksData.loading} onClick={doSync}>
                LocTracker užduotys
            </UI.Buttons.Button>
        </UI.Card.WhiteCard>
    );

    return (<Fragment>

            <div className={"mb-2"}>
                <div className={"flex gap-2"}>
                    <div className={"flex-grow"}>
                        {form}
                    </div>
                    <div className={"w-96"}>
                        {rightComponent}
                    </div>
                </div>
            </div>

            {element.id > 0 &&
                <InvoiceLinesListCard title={"Pirkimai"} options={{truckId: element.id, type: "P"}}/>
            }

            {element.id > 0 &&
                <InvoiceLinesListCard title={"Pardavimai"} options={{truckId: element.id, type: "S"}}/>
            }
        </Fragment>
    )
}
