import React, {Fragment} from 'react';
import { Wide, WideToolbar, WideRow, Compact, CompactToolbar, CompactRow, Label } from '@newageerp/ui.form.base.form-pack';
import { useTranslation } from 'react-i18next'
import { InputFloat } from "@newageerp/ui.form.base.form-pack";

interface Props {
    element: any;
    onChange: (key: string, value: any) => void,
    compact?: boolean,

    onSave: () => void,
    onCancel: () => void,
}

export default function TripOtherCostsForm(props: Props) {
    const { element, onChange } = props;
    const { t } = useTranslation();

    if (props.compact) {
        return (
            <Compact>
                <CompactRow label={<Label>{t('Extra driver')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('otherCostDriver', e)} value={element.otherCostDriver} className={"field-float"}/>}/>
<CompactRow label={<Label>{t('Adr')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('otherCostAdr', e)} value={element.otherCostAdr} className={"field-float"}/>}/>
<CompactRow label={<Label>{t('Load / Unload')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('otherCostLoad', e)} value={element.otherCostLoad} className={"field-float"}/>}/>
<CompactRow label={<Label>{t('Kita')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('otherCostDowntime', e)} value={element.otherCostDowntime} className={"field-float"}/>}/>
                <CompactToolbar onSave={props.onSave} onCancel={props.onCancel} />
            </Compact>
        )
    }

    return (
        <Wide>
            <WideRow label={<Label>{t('Extra driver')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('otherCostDriver', e)} value={element.otherCostDriver} className={"field-float"}/>}/>
<WideRow label={<Label>{t('Adr')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('otherCostAdr', e)} value={element.otherCostAdr} className={"field-float"}/>}/>
<WideRow label={<Label>{t('Load / Unload')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('otherCostLoad', e)} value={element.otherCostLoad} className={"field-float"}/>}/>
<WideRow label={<Label>{t('Kita')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('otherCostDowntime', e)} value={element.otherCostDowntime} className={"field-float"}/>}/>
            <WideToolbar onSave={props.onSave} onCancel={props.onCancel} />
        </Wide>
    )
}