import React from 'react'
import InvoiceToRemoveTableDataSource from './tables-data-source/InvoiceToRemoveTableDataSource';
import CountryMainTableDataSource from './tables-data-source/CountryMainTableDataSource';
import ImportedFileMainTableDataSource from './tables-data-source/ImportedFileMainTableDataSource';
import InvoiceMainTableDataSource from './tables-data-source/InvoiceMainTableDataSource';
import TruckMainTableDataSource from './tables-data-source/TruckMainTableDataSource';
import FuelCardMainTableDataSource from './tables-data-source/FuelCardMainTableDataSource';
import TruckGroupMainTableDataSource from './tables-data-source/TruckGroupMainTableDataSource';
import TruckTrailerGroupMainTableDataSource from './tables-data-source/TruckTrailerGroupMainTableDataSource';
import ServiceMainTableDataSource from './tables-data-source/ServiceMainTableDataSource';
import ServiceGroupMainTableDataSource from './tables-data-source/ServiceGroupMainTableDataSource';
import StationMainTableDataSource from './tables-data-source/StationMainTableDataSource';
import DriverMainTableDataSource from './tables-data-source/DriverMainTableDataSource';
import TripMainTableDataSource from './tables-data-source/TripMainTableDataSource';
import UserMainTableDataSource from './tables-data-source/UserMainTableDataSource';
import TruckFuelTankMainTableDataSource from './tables-data-source/TruckFuelTankMainTableDataSource';
import DriverTripMainTableDataSource from './tables-data-source/DriverTripMainTableDataSource';
import ClientMainTableDataSource from './tables-data-source/ClientMainTableDataSource';

export const TablesDataSource = [
        {
        schema: 'invoice',
        type: 'to-remove',
        comp: InvoiceToRemoveTableDataSource
    },
        {
        schema: 'country',
        type: 'main',
        comp: CountryMainTableDataSource
    },
        {
        schema: 'imported-file',
        type: 'main',
        comp: ImportedFileMainTableDataSource
    },
        {
        schema: 'invoice',
        type: 'main',
        comp: InvoiceMainTableDataSource
    },
        {
        schema: 'truck',
        type: 'main',
        comp: TruckMainTableDataSource
    },
        {
        schema: 'fuel-card',
        type: 'main',
        comp: FuelCardMainTableDataSource
    },
        {
        schema: 'truck-group',
        type: 'main',
        comp: TruckGroupMainTableDataSource
    },
        {
        schema: 'truck-trailer-group',
        type: 'main',
        comp: TruckTrailerGroupMainTableDataSource
    },
        {
        schema: 'service',
        type: 'main',
        comp: ServiceMainTableDataSource
    },
        {
        schema: 'service-group',
        type: 'main',
        comp: ServiceGroupMainTableDataSource
    },
        {
        schema: 'station',
        type: 'main',
        comp: StationMainTableDataSource
    },
        {
        schema: 'driver',
        type: 'main',
        comp: DriverMainTableDataSource
    },
        {
        schema: 'trip',
        type: 'main',
        comp: TripMainTableDataSource
    },
        {
        schema: 'user',
        type: 'main',
        comp: UserMainTableDataSource
    },
        {
        schema: 'truck-fuel-tank',
        type: 'main',
        comp: TruckFuelTankMainTableDataSource
    },
        {
        schema: 'driver-trip',
        type: 'main',
        comp: DriverTripMainTableDataSource
    },
        {
        schema: 'client',
        type: 'main',
        comp: ClientMainTableDataSource
    },
    ];

export const tablesDataSourceBySchemaAndType = (schema: string, type: string) => {
    const f = TablesDataSource.filter(i => i.schema === schema && i.type === type);
    if (f.length > 0) {
        return f[0].comp;
    }
    return undefined;
}