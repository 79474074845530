import { WhiteCard } from '@newageerp/ui.cards.base.white-card';
import { CardWidgetTitle } from '@newageerp/ui.cards.base.card-widget-title'
import React, { Fragment } from 'react'
import { useTripHookNae } from '../../../Components/Hooks/useTripHookNae'
import { PropsId } from '../../../UserComponents/ModelsCacheData/types'
import { SquareButton } from '@newageerp/ui.buttons.base.square-button'
import { IconType } from '@newageerp/ui.icons.base.icon';
import { IconList } from '@newageerp/ui.icons.base.icon-list';
import { TwTextColors } from '@newageerp/ui.styles.tailwind';
import TripRoadsViewForm from '../forms/TripRoadsViewForm';
import { UI } from '@newageerp/nae-react-ui';

interface Props extends PropsId {
    title?: string,
    onEdit?: () => void,
    contentBeforeForm?: any,
    contentAfterForm?: any,
}

export default function TripRoadsViewFormWidget(props: Props) {
    const element = useTripHookNae(props.id);

    const { showEditPopup } = UI.Window.useNaeWindow();
    const openEditWindow = () => showEditPopup({
        schema: 'trip',
        type: 'roads',
        id: props.id,
    });
    const onEdit = props.onEdit ? props.onEdit : openEditWindow;

    if (!element) {
        return <Fragment />
    }

    const title = props.title?props.title:"Keliai";

    return (
        <WhiteCard>
            {(!!title || onEdit) &&
                <div className='flex gap-2 items-center'>
                    {!!title &&
                        <CardWidgetTitle className='flex-grow'>{title}</CardWidgetTitle>
                    }
                    {!!onEdit &&
                        <SquareButton
                            bgColor={"transparent"}
                            onClick={onEdit}
                            icon={
                            {
                                type: IconType.SolidButton,
                                icon: IconList.edit,
                                className: TwTextColors.slate400,
                            }
                            }

                        />
                    }
                </div>
            }

            {props.contentBeforeForm}

            <TripRoadsViewForm element={element} />

            {props.contentAfterForm}
        </WhiteCard>
    )
}
