import React from "react";
import { UI } from '@newageerp/nae-react-ui';

export const FuelCardEnumsColors: any = {
    "status": {
        "0": "redSolid",
        "10": "greenSolid"
    }
};

export const FuelCardEnumsOptions: any = {
    "status": [
        {
            "value": "0",
            "label": "Neaktyvi"
        },
        {
            "value": "10",
            "label": "Aktyvi"
        }
    ]
};

export const FuelCardEnums: any = {
    "status": {
        "0": "Neaktyvi",
        "10": "Aktyvi"
    }
};

export const getFuelCardEnums = (type: string, value: any) => {
    return !!FuelCardEnums[type] && !!FuelCardEnums[type][value]?FuelCardEnums[type][value]:"";
}

export const getFuelCardEnumsColors = (type: string, value: any) => {
    return !!FuelCardEnumsColors[type] && !!FuelCardEnumsColors[type][value]?FuelCardEnumsColors[type][value]:"";
}