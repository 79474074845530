import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import TrucksListCard from "./TrucksListCard";

export default function TrucksListPage() {

    return (
        <div className={"container"}>
            <TrucksListCard/>
        </div>
    );
}
