import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Truck, TruckGroup, TruckTrailerGroup} from "../Pages/Trucks/TruckTypes";
import ApiRest from "../../service/ApiRest";
import BackBtn from "../BackBtn";
import {UI} from "@newageerp/nae-react-ui"
import {useHistory} from "react-router-dom";

interface Props {
    element: Truck,
    saveData: (name: string,
               leasingFrom: number,
               leasingTo: number,
               manufactureYear: number,
               mileage: number,
               truckTrailerGroup: number,
               truckGroup: number
    ) => void,
}

export default function TruckForm(props: Props) {
    const {element, saveData} = props;
    const [name, setName] = useState(element.name);
    const [leasingFrom, setLeasingFrom] = useState(element.leasingFrom.toString());
    const [leasingTo, setLeasingTo] = useState(element.leasingTo.toString());
    const [manufactureYear, setManufactureYear] = useState(element.manufactureYear.toString());
    const [mileage, setMileage] = useState(element.mileage.toString());
    const [truckGroup, setTruckGroup] = useState(element.truckGroup.toString());
    const [truckTrailerGroup, setTruckTrailerGroup] = useState(element.truckTrailerGroup.toString());

    const [groups, setGroups] = useState([]);
    const [trailers, setTrailers] = useState([]);

    const getListData = () => {
        ApiRest.getElements('truck-group').then(res => {
            if (res) {
                setGroups(res);
            }
        });
        ApiRest.getElements('truck-trailer').then(res => {
            if (res) {
                setTrailers(res);
            }
        });
    }

    const history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    useEffect(getListData, []);

    return (
        <UI.Card.WhiteCard>
            <UI.MVC.MvcViewTitle
                onBack={goBack}
                title={"Vilkikas"}

            />

            <div>
                <Form>
                    <Form.Group>
                        <Form.Label>Valstybinis numeris</Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)}/>
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} sm={3}>
                            <Form.Label>Pagaminimo metai</Form.Label>
                            <Form.Control type={"number"} value={manufactureYear}
                                          onChange={e => setManufactureYear(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col} sm={4}>
                            <Form.Label>Rida</Form.Label>
                            <Form.Control type={"number"} value={mileage} onChange={e => setMileage(e.target.value)}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Grupė</Form.Label>
                            <Form.Control
                                as="select"
                                className="mr-sm-2"
                                value={truckGroup}
                                onChange={(e) => setTruckGroup(e.target.value)}
                            >
                                <option value="0">Pasirinkite...</option>
                                {groups.map((group: TruckGroup) => <option key={'opt-g' + group.id}
                                                                           value={group.id}>{group.name}</option>)}

                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Priekabos tipas</Form.Label>
                            <Form.Control
                                as="select"
                                className="mr-sm-2"
                                value={truckTrailerGroup}
                                onChange={(e) => setTruckTrailerGroup(e.target.value)}
                            >
                                <option value="0">Pasirinkite...</option>
                                {trailers.map((trailer: TruckTrailerGroup) => <option key={'opt-t' + trailer.id}
                                                                                      value={trailer.id}>{trailer.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={3}>
                            <Form.Label>Lizingas nuo</Form.Label>
                            <Form.Control type={"number"} value={leasingFrom}
                                          onChange={e => setLeasingFrom(e.target.value)}/>
                        </Form.Group>

                        <Form.Group as={Col} sm={3}>
                            <Form.Label>iki</Form.Label>
                            <Form.Control type={"number"} value={leasingTo}
                                          onChange={e => setLeasingTo(e.target.value)}/>
                        </Form.Group>
                    </Form.Row>

                    <div className={"flex items-center gap-2"}>
                        <UI.Form.Label text={"LocTracker numeris:"}/>
                        {element.locktrackerNumber}
                    </div>

                </Form>
            </div>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(
                                    name,
                                    parseInt(leasingFrom, 10),
                                    parseInt(leasingTo, 10),
                                    parseInt(manufactureYear, 10),
                                    parseInt(mileage, 10),
                                    parseInt(truckTrailerGroup, 10),
                                    parseInt(truckGroup, 10)
                                )}>Išsaugoti</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </UI.Card.WhiteCard>)
}
