import {Card, Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import TableWithPaging from "../../TableWithPaging";
import {NaeTable} from "nae-react-core-styles";
import ApiRest from "../../../service/ApiRest";
import {UI} from "@newageerp/nae-react-ui"

const pageSize = 20;

export default function TrucksLocationsPage() {
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [dataToRender, setDataToRender] = useState([]);
    const [loading, setLoading] = useState(false);

    const getData = () => {
        setLoading(true);
        ApiRest.getTrucksLocations().then(res => {
            setData(res.data);
            setLoading(false);
        })
    }
    useEffect(getData, []);

    const filterData = () => {
        let _data = data;
        if (search) {
            _data = _data.filter((item: any) => {
                return item.registrationNumber.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    item.number.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    item.location.toLowerCase().indexOf(search.toLowerCase()) > -1
            });
        }
        setDataToRender(_data);
    }
    useEffect(filterData, [search, activePage, data]);

    const pages = Math.ceil(data.length / pageSize);
    const offsetStart = (activePage - 1) * pageSize;
    const offsetEnd = offsetStart + pageSize;

    return (
        <Container>
            <Row>
                <Col>
                    <UI.Card.WhiteCard>
                        <div className={"flex gap-2"}>
                            <UI.Typography.H5>Lokacijos</UI.Typography.H5>
                            {loading && <UI.Loader.Logo size={30}/>}
                        </div>

                        <div>
                            <UI.Form.Input placeholder={"Paieška"} className={"w-full"} value={search}
                                           onChange={(e) => setSearch(e.target.value)}/>
                        </div>

                        <UI.Table.Table
                            thead={<UI.Table.Thead columns={[
                                {content: 'Valstybinis nr.', props: {'className': 'text-left'}},
                                {content: 'Numeris', props: {'className': 'text-left'}},
                                {content: 'Lokacija', props: {'className': 'text-left'}},
                                {content: 'Odometras', props: {'className': 'text-right'}},
                            ]}/>}
                            tbody={<UI.Table.Tbody
                                data={dataToRender.slice(offsetStart, offsetEnd)}
                                callback={(item: any, index: number) => {
                                    return (
                                        {
                                            columns: [
                                                {content: item.registrationNumber, props: {'className': 'text-left'}},
                                                {content: item.number, props: {'className': 'text-left'}},
                                                {content: item.location, props: {'className': 'text-left'}},
                                                {content: item.position.odometerValue, props: {'className': 'text-right'}},
                                            ],
                                            className: UI.Table.defaultStrippedRowClassName(index)
                                        }
                                    )
                                }
                            }
                            />}
                        />
                        <UI.Table.Paging pages={pages} activePage={activePage} setActivePage={setActivePage}/>

                    </UI.Card.WhiteCard>

                </Col>
            </Row>
        </Container>
    );
}
