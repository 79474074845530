import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DefaultEditRouteWrapper from "./wrappers/DefaultEditRouteWrapper";
    import InvoiceToRemoveTableDataSource from "../tabs/tables-data-source/InvoiceToRemoveTableDataSource"
    import CountryMainTableDataSource from "../tabs/tables-data-source/CountryMainTableDataSource"
    import ImportedFileMainTableDataSource from "../tabs/tables-data-source/ImportedFileMainTableDataSource"
    import InvoiceMainTableDataSource from "../tabs/tables-data-source/InvoiceMainTableDataSource"
    import TruckMainTableDataSource from "../tabs/tables-data-source/TruckMainTableDataSource"
    import FuelCardMainTableDataSource from "../tabs/tables-data-source/FuelCardMainTableDataSource"
    import TruckGroupMainTableDataSource from "../tabs/tables-data-source/TruckGroupMainTableDataSource"
    import TruckTrailerGroupMainTableDataSource from "../tabs/tables-data-source/TruckTrailerGroupMainTableDataSource"
    import ServiceMainTableDataSource from "../tabs/tables-data-source/ServiceMainTableDataSource"
    import ServiceGroupMainTableDataSource from "../tabs/tables-data-source/ServiceGroupMainTableDataSource"
    import StationMainTableDataSource from "../tabs/tables-data-source/StationMainTableDataSource"
    import DriverMainTableDataSource from "../tabs/tables-data-source/DriverMainTableDataSource"
    import TripMainTableDataSource from "../tabs/tables-data-source/TripMainTableDataSource"
    import UserMainTableDataSource from "../tabs/tables-data-source/UserMainTableDataSource"
    import TruckFuelTankMainTableDataSource from "../tabs/tables-data-source/TruckFuelTankMainTableDataSource"
    import DriverTripMainTableDataSource from "../tabs/tables-data-source/DriverTripMainTableDataSource"
    import ClientMainTableDataSource from "../tabs/tables-data-source/ClientMainTableDataSource"
    import ServiceMainFormDataSource from "../editforms/forms-data-source/ServiceMainFormDataSource"
    import CountryMainFormDataSource from "../editforms/forms-data-source/CountryMainFormDataSource"
    import InvoiceMainFormDataSource from "../editforms/forms-data-source/InvoiceMainFormDataSource"
    import FuelCardMainFormDataSource from "../editforms/forms-data-source/FuelCardMainFormDataSource"
    import TruckMainFormDataSource from "../editforms/forms-data-source/TruckMainFormDataSource"
    import TripMainFormDataSource from "../editforms/forms-data-source/TripMainFormDataSource"
    import TripFrigoSettingsFormDataSource from "../editforms/forms-data-source/TripFrigoSettingsFormDataSource"
    import TripKmsFormDataSource from "../editforms/forms-data-source/TripKmsFormDataSource"
    import TripRoadsFormDataSource from "../editforms/forms-data-source/TripRoadsFormDataSource"
    import TripOtherCostsFormDataSource from "../editforms/forms-data-source/TripOtherCostsFormDataSource"
    import TaskMainFormDataSource from "../editforms/forms-data-source/TaskMainFormDataSource"
    import TruckFuelTankMainFormDataSource from "../editforms/forms-data-source/TruckFuelTankMainFormDataSource"
    import DriverTripMainFormDataSource from "../editforms/forms-data-source/DriverTripMainFormDataSource"
    import ClientMainFormDataSource from "../editforms/forms-data-source/ClientMainFormDataSource"
    import BookmarksPage from "../apps/bookmarks/BookmarksPage";
    import TasksPage from "../apps/tasks/TasksPage";
    import NotesPage from "../apps/notes/NotesPage";
    import FollowUpPage from "../apps/follow-up/FollowUpPage";
import { UI } from "@newageerp/nae-react-ui";


export function AppsRoutes() {
    return (
        <Fragment>
                            <Route path={"/c/apps/bookmarks"}>
                    <BookmarksPage />
                </Route>
                            <Route path={"/c/apps/tasks"}>
                    <TasksPage />
                </Route>
                            <Route path={"/c/apps/notes"}>
                    <NotesPage />
                </Route>
                            <Route path={"/c/apps/follow-up"}>
                    <FollowUpPage />
                </Route>
                    </Fragment>
    )
}

export function ListRoutes() {
    return (
        <Fragment>
                            <Route path={"/u/invoice/to-remove/list/"}>
                    <InvoiceToRemoveTableDataSource />
                </Route>
                            <Route path={"/u/country/main/list/"}>
                    <CountryMainTableDataSource />
                </Route>
                            <Route path={"/u/imported-file/main/list/"}>
                    <ImportedFileMainTableDataSource />
                </Route>
                            <Route path={"/u/invoice/main/list/"}>
                    <InvoiceMainTableDataSource />
                </Route>
                            <Route path={"/u/truck/main/list/"}>
                    <TruckMainTableDataSource />
                </Route>
                            <Route path={"/u/fuel-card/main/list/"}>
                    <FuelCardMainTableDataSource />
                </Route>
                            <Route path={"/u/truck-group/main/list/"}>
                    <TruckGroupMainTableDataSource />
                </Route>
                            <Route path={"/u/truck-trailer-group/main/list/"}>
                    <TruckTrailerGroupMainTableDataSource />
                </Route>
                            <Route path={"/u/service/main/list/"}>
                    <ServiceMainTableDataSource />
                </Route>
                            <Route path={"/u/service-group/main/list/"}>
                    <ServiceGroupMainTableDataSource />
                </Route>
                            <Route path={"/u/station/main/list/"}>
                    <StationMainTableDataSource />
                </Route>
                            <Route path={"/u/driver/main/list/"}>
                    <DriverMainTableDataSource />
                </Route>
                            <Route path={"/u/trip/main/list/"}>
                    <TripMainTableDataSource />
                </Route>
                            <Route path={"/u/user/main/list/"}>
                    <UserMainTableDataSource />
                </Route>
                            <Route path={"/u/truck-fuel-tank/main/list/"}>
                    <TruckFuelTankMainTableDataSource />
                </Route>
                            <Route path={"/u/driver-trip/main/list/"}>
                    <DriverTripMainTableDataSource />
                </Route>
                            <Route path={"/u/client/main/list/"}>
                    <ClientMainTableDataSource />
                </Route>
                    </Fragment>
    )
}

export function EditRoutes() {
    return (
        <Fragment>
                            <Route path={"/u/service/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <ServiceMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/country/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <CountryMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/invoice/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <InvoiceMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/fuel-card/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <FuelCardMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/truck/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <TruckMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/trip/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <TripMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/trip/frigo-settings/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <TripFrigoSettingsFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/trip/kms/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <TripKmsFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/trip/roads/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <TripRoadsFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/trip/other-costs/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <TripOtherCostsFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/task/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <TaskMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/truck-fuel-tank/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <TruckFuelTankMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/driver-trip/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <DriverTripMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                            <Route path={"/u/client/main/edit/:id"}>
                    <DefaultEditRouteWrapper
                            render={
                            (
                            id: string,
                            onSave: (element: any, isNew: boolean, schema: string) => void,
                        onCancel: () => void,
                        ) => <ClientMainFormDataSource id={id} onSave={onSave} onCancel={onCancel} />
                        }
                        />
                </Route>
                    </Fragment>
    )
}

export function ViewRoutes() {
    return (
        <Fragment>
                            <Route path={"/u/trip/main/view/:id"}>
                    <UI.RoutePoints.ViewEntry key={"trip-main"} schema="trip" type="main"/>
                </Route>
                            <Route path={"/u/truck/main/view/:id"}>
                    <UI.RoutePoints.ViewEntry key={"truck-main"} schema="truck" type="main"/>
                </Route>
                            <Route path={"/u/truck-fuel-tank/main/view/:id"}>
                    <UI.RoutePoints.ViewEntry key={"truck-fuel-tank-main"} schema="truck-fuel-tank" type="main"/>
                </Route>
                            <Route path={"/u/driver-trip/main/view/:id"}>
                    <UI.RoutePoints.ViewEntry key={"driver-trip-main"} schema="driver-trip" type="main"/>
                </Route>
                            <Route path={"/u/client/main/view/:id"}>
                    <UI.RoutePoints.ViewEntry key={"client-main"} schema="client" type="main"/>
                </Route>
                    </Fragment>
    )
}

export default function AppRoutes() {
    return (
        <Fragment>
            <ListRoutes/>
            <EditRoutes/>
            <ViewRoutes/>
            <AppsRoutes/>
        </Fragment>
    )
}