import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Location } from 'history';
import ApiRest from "../../../service/ApiRest";
import { Driver } from "./TruckTypes";
import { NaeTable } from "nae-react-core-styles";
import TableWithPaging from "../../TableWithPaging";
import { UI } from "@newageerp/nae-react-ui"

const moduleName = 'employee';

interface Props {
}

type EmploymentStatus = 'all' | 'employed' | 'terminated';

interface DriverListState {
    search: string;
    activePage: number;
    selectedCountry: string;
    selectedPosition: string;
    employmentStatus: EmploymentStatus;
}

interface LocationState extends Location {
    state: DriverListState;
}

export default function DriversListCard(props: Props) {
    const history = useHistory<DriverListState>();

    const [readyToLoad, setReadyToLoad] = useState(false);
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [dataToRender, setDataToRender] = useState<Driver[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedPosition, setSelectedPosition] = useState<string>('');
    const [employmentStatus, setEmploymentStatus] = useState<EmploymentStatus>('all');

    const uniqueCountries = React.useMemo(() => {
        const countries = drivers
            .map(driver => driver.country)
            .filter((country): country is string => !!country);
        return Array.from(new Set(countries)).sort();
    }, [drivers]);

    const uniquePositions = React.useMemo(() => {
        const positions = drivers
            .map(driver => driver.position)
            .filter((position): position is string => !!position);
        return Array.from(new Set(positions)).sort();
    }, [drivers]);

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setDrivers(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = drivers;

            if (search) {
                _data = _data.filter((item: Driver) => {
                    return item.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                        (item.personalCode && item.personalCode.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
                        (item.email && item.email.toLowerCase().indexOf(search.toLowerCase()) > -1);
                });
            }

            if (selectedCountry) {
                _data = _data.filter((item: Driver) => item.country === selectedCountry);
            }

            if (selectedPosition) {
                _data = _data.filter((item: Driver) => item.position === selectedPosition);
            }

            if (employmentStatus !== 'all') {
                const currentDate = new Date().toISOString().split('T')[0];
                _data = _data.filter((item: Driver) => {
                    if (employmentStatus === 'employed') {
                        return !item.terminationDate || item.terminationDate > currentDate;
                    } else {
                        return item.terminationDate && item.terminationDate <= currentDate;
                    }
                });
            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, drivers, selectedCountry, selectedPosition, employmentStatus, readyToLoad]);

    const restoreHistoryState = () => {
        const historyState = history.location.state as DriverListState;
        
        if (historyState) {
            setSearch(historyState.search || '');
            setActivePage(historyState.activePage || 1);
            setSelectedCountry(historyState.selectedCountry || '');
            setSelectedPosition(historyState.selectedPosition || '');
            setEmploymentStatus(historyState.employmentStatus || 'all');
        }

        setTimeout(() => {
            setReadyToLoad(true);
        }, 300);
    }

    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, selectedCountry, selectedPosition, employmentStatus]);

    const goTo = (item: Driver) => {
        const state: DriverListState = {
            search,
            activePage,
            selectedCountry,
            selectedPosition,
            employmentStatus
        };
        
        history.replace('/employees', state);
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (
        <UI.Card.WhiteCard>
            <div className="flex items-center gap-2">
                <UI.Typography.H3 className="flex-grow">
                    Darbuotojai
                </UI.Typography.H3>
                <UI.Buttons.Button icon="fal fa-fw fa-plus" onClick={goToNew}>
                    Naujas
                </UI.Buttons.Button>
            </div>

            <div className="flex items-center gap-2 mb-4">
                <UI.Form.Input 
                    placeholder="Paieška"
                    className="flex-grow" 
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} 
                />
                
                <UI.Form.Select 
                    className="w-48"
                    value={selectedCountry}
                    onChange={e => setSelectedCountry(e)}
                    options={[
                        { value: "", label: "Visos pilietybės" },
                        ...uniqueCountries.map(country => ({
                            value: country,
                            label: country
                        }))
                    ]}
                />

                <UI.Form.Select 
                    className="w-48"
                    value={selectedPosition}
                    onChange={e => setSelectedPosition(e)}
                    options={[
                        { value: "", label: "Visos pareigos" },
                        ...uniquePositions.map(position => ({
                            value: position,
                            label: position
                        }))
                    ]}
                />

                <UI.Form.Select
                    className="w-48"
                    value={employmentStatus}
                    onChange={e => setEmploymentStatus(e as EmploymentStatus)}
                    options={[
                        { value: "all", label: "Visi darbuotojai" },
                        { value: "employed", label: "Dirbantys" },
                        { value: "terminated", label: "Nebedirbantys" }
                    ]}
                />
            </div>

            <TableWithPaging
                activePage={activePage}
                setActivePage={setActivePage}
                dataToRender={dataToRender}
                head={
                    <tr>
                        <NaeTable.th>Vardas, pavardė</NaeTable.th>
                        <NaeTable.th>Gimimo data</NaeTable.th>
                        <NaeTable.th>Priėmimo data</NaeTable.th>
                        <NaeTable.th>Atleidimo data</NaeTable.th>
                        <NaeTable.th>Pilietybė</NaeTable.th>
                        <NaeTable.th>Pareigos</NaeTable.th>
                        <NaeTable.th>Komentaras</NaeTable.th>
                    </tr>
                }
                renderItem={(item: Driver) => {
                    return (
                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                            <NaeTable.td link={true}>{item.fullName}</NaeTable.td>
                            <NaeTable.td>{item.birthDate}</NaeTable.td>
                            <NaeTable.td>{item.hireDate}</NaeTable.td>
                            <NaeTable.td>{item.terminationDate}</NaeTable.td>
                            <NaeTable.td>{item.country}</NaeTable.td>
                            <NaeTable.td>{item.position}</NaeTable.td>
                            <NaeTable.td>{item.comment}</NaeTable.td>
                        </tr>
                    )
                }}
            />
        </UI.Card.WhiteCard>
    );
}