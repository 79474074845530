import React, {useEffect, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";

import ApiRest from "../../../service/ApiRest";
import {Card, Col, Container, Row, Spinner, Table} from "react-bootstrap";
import {ImportedFile} from "./UploadTypes";
import {NaePaging, NaeTable} from 'nae-react-core-styles';

const moduleName = 'imported-file';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function UploadsListPage() {
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const [state, setState] = useState('');
    const [activePage, setActivePage] = useState(parseInt(query.get("page") || "1"));
    const [count, setCount] = useState(0);

    const [invoiceLines, setInvoiceLines] = useState<ImportedFile[]>([])

    const getData = () => {
        setState('loading');
        ApiRest.getElements(moduleName, {page: activePage}).then(res => {
            setInvoiceLines(res.data);
            setCount(res.count);
            setState('');
        })
    }

    useEffect(getData, [activePage]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set("page", activePage.toString());
        history.replace({ pathname: location.pathname, search: params.toString() });
    }, [activePage, history, location.search, location.pathname]);

    const goTo = (item: ImportedFile) => {
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    const pages = Math.ceil(count / 20);

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"card-table"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Importavimas
                                </Col>
                                <Col className={"text-right"}>
                                    {/*<Button variant={"success"} onClick={goToNew}>Nauja</Button>*/}
                                </Col>
                            </Row>
                        </Card.Header>

                        <Card.Body>
                            {state === 'loading' ?
                                <Spinner animation="border" variant="info"/>
                                :
                                <Table striped hover className={"mt-2"}>
                                    <thead>
                                    <tr>
                                        <NaeTable.th id={true}>ID</NaeTable.th>
                                        <NaeTable.th>Data</NaeTable.th>
                                        <NaeTable.th>Tipas</NaeTable.th>
                                        <NaeTable.th>Statusas</NaeTable.th>
                                        <NaeTable.th number={true}>Dydis</NaeTable.th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {invoiceLines.map((item: ImportedFile) => {
                                        return (
                                            <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                                <NaeTable.td id={true} link={true}>{item.id}</NaeTable.td>
                                                <NaeTable.td link={true}>{item.createdAt}</NaeTable.td>
                                                <NaeTable.td>{item.type}</NaeTable.td>
                                                <NaeTable.td>{item.status}</NaeTable.td>
                                                <NaeTable.td number={true}>{item.size}</NaeTable.td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            }

                        </Card.Body>
                        <Card.Footer>
                            <div className={"paging"}>
                                <NaePaging.Prev onClick={() => {
                                    setActivePage(Math.max(activePage - 1, 1));
                                }}/>
                                <NaePaging.Group pages={pages} activePage={activePage} onClick={(p) => {
                                    setActivePage(p);
                                }}/>
                                <NaePaging.Next onClick={() => {
                                    setActivePage(Math.min(activePage + 1, pages));
                                }}/>
                            </div>
                        </Card.Footer>
                    </Card>


                </Col>
            </Row>
        </Container>
    );
}
