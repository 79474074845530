import React, { Fragment } from "react";
import { INaeWidget } from "@newageerp/nae-react-ui/dist/interfaces";
import { BaseEntityWidgets } from "./widgets/base-entity.widgets";
import { GeneratedLayoutWidgets } from "../../_generated/layout/GeneratedLayoutWidgets";
import { GeneratedPdfWidgets } from "../../_generated/pdfs/GeneratedPdfWidgets";
import { TripWidgets } from "./widgets/trip.widgets";
import { TruckRelWidgets } from "./widgets/truck-rel.widgets";

export const NaeWidgets: INaeWidget[] = [
  ...TripWidgets,
  ...TruckRelWidgets,

  ...BaseEntityWidgets,
  ...GeneratedLayoutWidgets,
  ...GeneratedPdfWidgets,
];
