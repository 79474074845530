import { useEffect } from "react";
import { NaeSSchemaMap } from "../../config/NaeSSchema";
import { useDataCache } from "./DataCacheProvider";
import { useSelector } from "react-redux";
import { SelectorTripNae } from "../Models/ormSelectors";

interface ChildId {
  id: number
}

export interface ITripModelNae {
    id: number,
scopes: any,
badges: any,
otherCostFrigo: number,
otherCostFrigoCost: number,
otherCostFrigoDays: number,
autoRouteKm: number,
autoRouteKmAllowedDiff: number,
trip: any,
loctrackerDrivenKm: number,
invoiceDrivenKm: number,
roadCost: number,
invoiceRoadCosts: number,
otherCostDriver: number,
otherCostAdr: number,
otherCostLoad: number,
otherCostDowntime: number,
invoiceOtherCosts: number,
otherCost: number,
loctrackerDrivenKmWithAllowedDiff: number,
autoRouteKmWithAllowedDiff: number,

}

export const ITripFieldsNae = [
    "id",
    "scopes",
    "badges",
    "otherCostFrigo",
    "otherCostFrigoCost",
    "otherCostFrigoDays",
    "autoRouteKm",
    "autoRouteKmAllowedDiff",
    "trip",
    "loctrackerDrivenKm",
    "invoiceDrivenKm",
    "roadCost",
    "invoiceRoadCosts",
    "otherCostDriver",
    "otherCostAdr",
    "otherCostLoad",
    "otherCostDowntime",
    "invoiceOtherCosts",
    "otherCost",
    "loctrackerDrivenKmWithAllowedDiff",
    "autoRouteKmWithAllowedDiff"
];

export function useTripHookNae(id: number) : ITripModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorTripNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.Trip.schema,
        fields: ITripFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
