import React, { Fragment } from 'react'
import { MenuItem } from '@newageerp/ui.menu.base.menu'
import { useHistory } from 'react-router';

interface Props {
    forceSkipIcon?: boolean;
}

export default function MenuItemTripsReports(props: Props) {
    const history = useHistory();

    const path = '/trips-reports';

    const badgeKey = '';

    const goTo = () => {
        history.push(path);
    }

    return (
        <Fragment>
            <MenuItem onClick={goTo} icon={props.forceSkipIcon?'':''} badgeKey={badgeKey?badgeKey:undefined}>{'Kelionių išklotinė'}</MenuItem>
        </Fragment>
    )
}
