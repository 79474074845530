import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {toast} from "react-toastify";
import {Trip, TripLine} from "./SalesTypes";
import TripForm from "../../Forms/TripForm";

interface ParamTypes {
    id: string
}

const defElement: Trip = {
    id: 0,
    number: '',

    truck: 0,
    truckName: '',

    truckDriver: 0,
    truckDriverName: '',

    truckDriver2: 0,
    truckDriver2Name: '',

    dateFrom: '',
    dateTo: '',
    status: 0,
    trip: [],

    comment: '',
    reference: '',

    autoRouteKm: 0,
    autoRouteKm2: 0,
    
    roadCost: 0,
    otherCost: 0,

    statusComment: '',

    trailer: '',
    driver: '',

    otherCostDriver: 0,
    otherCostAdr: 0,
    otherCostFrigo: 0,
    otherCostLoad: 0,
    otherCostDowntime: 0,

    invoiceDrivenKm: 0,
    invoiceOtherCosts: 0,
    invoiceRoadCosts: 0,
    locktrackerKm: 0,
    locktrackerKm2: 0,

    userId: 0,
    creator: '',
    client: null,
}


const moduleName = 'trip';

export default function TripPage() {
    const history = useHistory();

    const [isSaving, setIsSaving] = useState(false);

    const [key, setKey] = useState<string | null>('list');


    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (number: string,
                      truck: number,
                      truckDriver: number,
                      truckDriver2: number,
                      dateFrom: string,
                      dateTo: string,
                      trip: TripLine[],
                      status: number,
                      comment: string,
                      reference: string,
                      autoRouteKm: number,
                      roadCost: number,
                      otherCost: number,
                      statusComment: string,
                      trailer: string,
                      otherCostFrigo: number,
                      otherCostAdr: number,
                      otherCostLoad: number,
                      otherCostDriver: number,
                      otherCostDowntime: number,
                      driver: string,
                      userId: number,
                      clientId: number,
    ) => {
        if (isSaving) return;

        setIsSaving(true);

        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    number,
                    truck,
                    truckDriver,
                    truckDriver2,
                    dateFrom,
                    dateTo,
                    trip,
                    status,
                    comment,
                    reference,
                    autoRouteKm,
                    roadCost,
                    otherCost,
                    statusComment,
                    trailer,
                    otherCostFrigo,
                    otherCostAdr,
                    otherCostLoad,
                    otherCostDriver,
                    otherCostDowntime,
                    driver,
                    userId,
                    clientId,
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
                history.replace('/' + moduleName + '/' + res.id);
                setIsSaving(false);
            }).catch((e) => {
                toast.error(e);
                setIsSaving(false);

            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    number,
                    truck,
                    truckDriver,
                    truckDriver2,
                    dateFrom,
                    dateTo,
                    trip,
                    status,
                    comment,
                    reference,
                    autoRouteKm,
                    roadCost,
                    otherCost,
                    statusComment,
                    trailer,
                    otherCostFrigo,
                    otherCostAdr,
                    otherCostLoad,
                    otherCostDriver,
                    otherCostDowntime,
                    driver,
                    userId,
                    clientId,
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
                setIsSaving(false);
            }).catch(() => {
                setIsSaving(false);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10), {addHistory: true}).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <TripForm element={element} saveData={saveData} getData={getData} isSaving={isSaving}/>
        </Fragment>
        : <Fragment/>
    ;

    return (<div className="page-bg">
            {form}
        </div>
    )
}
