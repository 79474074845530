import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Address } from "../Pages/Trucks/TruckTypes";
import { COUNTRIES } from '../Utils/countries';

interface Props {
  driverId: number;
  address?: Address;
  onClose: () => void;
  onSave: (address: Address) => void;
}

const addressTypeOptions = [
  { id: 'Deklaruota gyv. vieta', name: 'Deklaruota gyv. vieta' },
  { id: 'Namų adresas', name: 'Namų adresas' },
  { id: 'Kita', name: 'Kita' },
] as const;

export default function AddressPopup({ driverId, address, onClose, onSave }: Props) {
  const [type, setType] = useState(address?.type || addressTypeOptions[0].id);
  const [city, setCity] = useState(address?.city || '');
  const [addressText, setAddressText] = useState(address?.address || '');
  const [country, setCountry] = useState(address?.country || COUNTRIES[0].name);
  const [comment, setComment] = useState(address?.comment || '');
  const isEditMode = !!address;

  const handleSave = () => {
    if (!type || !country || !city || !addressText) {
      return;
    }

    const addressData: Address = {
      id: address?.id || Date.now(),
      type,
      country,
      city,
      address: addressText,
      comment,
    };

    onSave(addressData);
    onClose();
  };

  return (
    <div className="px-4 pb-2">

      <Form.Group className="mb-3">
        <Form.Label>Šalis</Form.Label>
        <Form.Control
          as="select"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          {COUNTRIES.map((prefix) => (
            <option key={prefix.country} value={prefix.name}>
              {prefix.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Miestas</Form.Label>
        <Form.Control
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Gatvė, namo numeris</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={addressText}
          onChange={(e) => setAddressText(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Tipas</Form.Label>
        <Form.Control
          as="select"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          {addressTypeOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Komentaras</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Form.Group>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!type || !city || !addressText}
        >
          {isEditMode ? 'Atnaujinti' : 'Pridėti'}
        </Button>
        <Button
          variant="secondary"
          onClick={onClose}
        >
          Atšaukti
        </Button>
      </div>
    </div>
  );
}