import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { Driver, Truck } from "../Pages/Trucks/TruckTypes";
import ApiRest from "../../service/ApiRest";
import { Trip, Client, TripLine } from "../Pages/Sales/SalesTypes";
import { Hooks, UI } from "@newageerp/nae-react-ui";
import { useHistory } from "react-router-dom";
import TripLocatorTasks from "../Pages/Sales/TripLocatorTasks";
import useApiRestPost from "../../Hooks/useApiRestPost";
import { InvoiceLine } from "../Pages/Invoices/InvoiceTypes";
import { toast } from "react-toastify";
import DateField from "./fields/DateField";
import TripDeleteButton from "../Pages/Sales/Components/TripDeleteButton";
import { Prompt } from 'react-router';
import TripFormTripLine from "./TripFormTripLine";
import moment from "moment";
import { SystemUser } from "../Pages/Common/UserTypes";
import { useUser } from "../User/UserProvider";
import TripFormSplit from "./TripFormSplit";
import TripFormEdit from "./TripFormEdit";
import { useTripHookNae } from "../Hooks/useTripHookNae";
import { WhiteCard } from "@newageerp/ui.cards.base.white-card";
import { CardWidgetTitle } from "@newageerp/ui.cards.base.card-widget-title";
import { IconType } from "@newageerp/ui.icons.base.icon";
import { IconList } from "@newageerp/ui.icons.base.icon-list";
import {
    TwBackgroundColors,
    TwTextColors,
} from "@newageerp/ui.styles.tailwind";
import { SquareButton } from "@newageerp/ui.buttons.base.square-button";
import { useTranslation } from "react-i18next";
import { Popup, PopupSize } from "@newageerp/ui.popups.base.popup";
import { useRecoilValue } from "recoil";
import { OpenApi } from "@newageerp/nae-react-auth-wrapper";
import { usePermissions } from "../../Components/User/PermissionProvider";

export interface LocatorTask {
    id: number,
    location: string,
    date: string,
    completed: string,
    comment: string,
    tag: string,
    odometerStart: number,
    odometerEnd: number,
    status: string,
    taskId: number,
    prevTaskId: number
}

interface Props {
    isSaving: boolean,
    element: Trip,
    saveData: (
        number: string,
        truck: number,
        truckDriver: number,
        truckDriver2: number,
        dateFrom: string,
        dateTo: string,
        trip: TripLine[],
        status: number,
        comment: string,
        reference: string,
        autoRouteKm: number,
        roadCost: number,
        otherCost: number,
        statusComment: string,
        trailer: string,
        otherCostFrigo: number,
        otherCostAdr: number,
        otherCostLoad: number,
        otherCostDriver: number,
        otherCostDowntime: number,
        driver: string,
        userId: number,
        clientId: number,
    ) => void,
    getData: () => void,
}

const allowedUnlock: number[] = [6, 11];

export default function TripForm(props: Props) {
    const { user } = useUser();
    const userState: any = useRecoilValue(OpenApi.naeUserState);
    const { hasPermission } = usePermissions();

    const { t } = useTranslation();

    const [splitId, setSplitId] = useState(0);
    const [editId, setEditId] = useState(0);

    const isReadonly = props.element.status > 5;
    const isMount = Hooks.useDidMount();

    const history = useHistory();
    const goBack = () => {
        history.goBack();
    }

    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

    const { element } = props;

    const elementTrip = useTripHookNae(element.id);
    const [showPopup, setShowPopup] = useState(false);

    const [number, setNumber] = useState(element.number);
    const [reference, setReference] = useState(element.reference);
    const [truck, setTruck] = useState(element.truck.toString());
    const [truckDriver, setTruckDriver] = useState(element.truckDriver.toString());
    const [truckDriver2, setTruckDriver2] = useState(element.truckDriver2.toString());

    const [userId, setUserId] = useState(props.element.id === 0 ? userState.id.toString() : element.userId.toString());

    const [dateFrom, setDateFrom] = useState(element.dateFrom);
    const [dateTo, setDateTo] = useState(element.dateTo);

    const [tripLines, setTripLines] = useState(element.trip)

    const [status, setStatus] = useState(element.status);

    const [autoRouteKm, setAutoRouteKm] = useState(element.autoRouteKm.toString());
    const [roadCost, setRoadCost] = useState(element.roadCost.toString());
    const [otherCost, setOtherCost] = useState(element.otherCost.toString());
    const [comment, setComment] = useState(element.comment);
    const [statusComment, setStatusComment] = useState(element.statusComment);

    const [trailer, setTrailer] = useState(element.trailer);
    const [driver, setDriver] = useState(element.driver);

    const [otherCostFrigo, setOtherCostFrigo] = useState(element.otherCostFrigo.toString());
    const [otherCostAdr, setOtherCostAdr] = useState(element.otherCostAdr.toString());
    const [otherCostLoad, setOtherCostLoad] = useState(element.otherCostLoad.toString());
    const [otherCostDriver, setOtherCostDriver] = useState(element.otherCostDriver.toString());
    const [otherCostDowntime, setOtherCostDowntime] = useState(element.otherCostDowntime.toString());


    const [clients, setClients] = useState<Client[]>([]);
    const [clientId, setClientId] = useState(element.client ? element.client.id.toString() : "0");

    const unlockTrip = () => {
        ApiRest.updateElement(
            'trip',
            element.id,
            {
                status: 0
            }
        ).then(res => {
            toast.success('Įrašas išsaugotas');
            props.getData();
        }).catch(() => {
        });
    }

    useEffect(() => {
        if (!isMount) {
            setShouldBlockNavigation(true);
        }
    }, [
        number,
        reference,
        truck,
        dateFrom,
        dateTo,
        tripLines,
        status,
        autoRouteKm,
        roadCost,
        otherCost,
        comment,
        statusComment,
        trailer,
        otherCostFrigo,
        otherCostAdr,
        otherCostLoad,
        otherCostDriver,
        otherCostDowntime,
        driver,
        truckDriver,
        truckDriver2,
        userId,
        clientId,
    ]);


    const [trucks, setTrucks] = useState([]);
    const [users, setUsers] = useState([]);
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        const sum = parseFloat(otherCostAdr) + parseFloat(otherCostLoad) + parseFloat(otherCostFrigo) + parseFloat(otherCostDriver) + parseFloat(otherCostDowntime);
        setOtherCost(sum.toString());
    }, [otherCostAdr, otherCostLoad, otherCostFrigo, otherCostDriver, otherCostDowntime]);


    const getListData = () => {
        ApiRest.getElements('truck').then(res => {
            if (res) {
                setTrucks(res);
            }
        });
        ApiRest.getElements('driver').then(res => {
            if (res) {
                setDrivers(res);
            }
        });
        ApiRest.getElements('user').then(res => {
            if (res) {
                setUsers(res);
            }
        });
        ApiRest.getElements('client').then(res => {
            if (res) {
            setClients(res);
            }
        });
    }
    
    const addTripLine = (obj?: TripLine) => {
        const lastOdo = tripLines.length > 0 ? tripLines[tripLines.length - 1].odoFinish : '';
        setTripLines([...tripLines, obj ? obj : {
            city: '',
            dateStart: '',
            dateFinish: '',
            odoStart: '',
            odoFinish: lastOdo,
            comment: '',
        }]);
    }
    const removeTripLine = (index: number) => {
        setTripLines([...tripLines.filter((_l, i) => index !== i)]);
    }

    const upTripLine = (index: number) => {
        if (index > 0) {
            const indexZero = tripLines[index - 1];
            const indexEq = tripLines[index];
            const _tripLines = [...tripLines];
            _tripLines[index - 1] = indexEq;
            _tripLines[index] = indexZero;
            setTripLines(_tripLines);
        }
    }
    const downTripLine = (index: number) => {
        if ((index + 1) < tripLines.length) {
            const indexZero = tripLines[index + 1];
            const indexEq = tripLines[index];
            const _tripLines = [...tripLines];
            _tripLines[index + 1] = indexEq;
            _tripLines[index] = indexZero;
            setTripLines(_tripLines);
        }
    }

    const setDataToTripLine = (index: number, key: string, value: string) => {
        setTripLines([...tripLines.map((_l, i) => {
            if (index === i) {
                const _el = { ..._l, [key]: value }
                return _el;
            }
            return _l;
        })]);
    }

    useEffect(getListData, []);

    let odoDiff = 0;
    let minOdo = 0;
    let maxOdo = 0;

    tripLines.forEach((_l: TripLine, tripIndex: number) => {
        if (parseFloat(_l.odoStart) > 0 && minOdo === 0 && tripIndex > 0) {
            minOdo = parseFloat(_l.odoStart);
        }
        if (parseFloat(_l.odoFinish) > 0 && minOdo === 0) {
            minOdo = parseFloat(_l.odoFinish);
        }

        if (parseFloat(_l.odoStart) < minOdo && parseFloat(_l.odoStart) > 0 && tripIndex > 0) {
            minOdo = parseFloat(_l.odoStart);
        }
        if (parseFloat(_l.odoFinish) < minOdo && parseFloat(_l.odoFinish)) {
            minOdo = parseFloat(_l.odoFinish);
        }

        if (parseFloat(_l.odoStart) > maxOdo) {
            maxOdo = parseFloat(_l.odoStart);
        }
        if (parseFloat(_l.odoFinish) > maxOdo) {
            maxOdo = parseFloat(_l.odoFinish);
        }
    })
    odoDiff = Math.round(maxOdo - minOdo);

    const [loadInvoiceLines, loadInvoiceLinesData] = useApiRestPost('loctracker/loadInvoiceLines');
    const syncInvoiceLines = () => {
        loadInvoiceLines({
            tripId: props.element.id
        });
    }
    useEffect(() => {
        syncInvoiceLines()
    }, []);


    let drivenKm = 0;
    let invRoadCosts = 0;
    let invOtherCosts = 0;
    loadInvoiceLinesData.data.data.forEach((i: InvoiceLine) => {
        if (i.serviceGroupName === 'Pardavimai (KM)') {
            drivenKm += i.quantity;
        } else if (i.serviceGroupName === 'Pardavimai (Keliai)') {
            invRoadCosts += i.totalEur;
        } else {
            invOtherCosts += i.totalEur;
        }
    })
    drivenKm = Math.round(drivenKm)

    const autoRouteKmFl = parseFloat(autoRouteKm);
    const autoRouteDrivenDiff = Math.round((drivenKm > 0 && autoRouteKmFl > 0 ? (drivenKm - autoRouteKmFl) / drivenKm : -1) * 100 * 100) / 100;
    const autoRouteLockTrackerDiff = Math.round((drivenKm > 0 && odoDiff > 0 ? (drivenKm - odoDiff) / drivenKm : 1) * 100 * 100) / 100;

    const needStatusChangeComment = autoRouteDrivenDiff > 1.5 || autoRouteLockTrackerDiff > 3;

    const saveData = (number: string,
        truck: number,
        truckDriver: number,
        truckDriver2: number,
        dateFrom: string,
        dateTo: string,
        trip: TripLine[],
        status: number,
        comment: string,
        reference: string,
        autoRouteKm: number,
        roadCost: number,
        otherCost: number,
        statusComment: string,
        trailer: string,
        otherCostFrigo: number,
        otherCostAdr: number,
        otherCostLoad: number,
        otherCostDriver: number,
        otherCostDowntime: number,
        driver: string,
        userId: number,
        clientId: number,
    ) => {

        if (status > 0) {
            if (!statusComment && needStatusChangeComment) {
                toast.error('Įveskite KM SKIRTUMO PAAIŠKINIMUS')
                return;
            }
        }

        props.saveData(
            number,
            truck,
            truckDriver,
            truckDriver2,
            dateFrom,
            dateTo,
            trip,
            status,
            comment,
            reference,
            autoRouteKm,
            roadCost,
            otherCost,
            statusComment,
            trailer,
            otherCostFrigo,
            otherCostAdr,
            otherCostLoad,
            otherCostDriver,
            otherCostDowntime,
            driver,
            userId,
            clientId,
        )
        setShouldBlockNavigation(false);
    }

    const goToInvoiceLine = (item: InvoiceLine) => {
        history.push('/invoice-line/' + item.id);
    }
    const goToService = (item: InvoiceLine) => {
        history.push('/service/' + item.serviceId);
    }

    const calcExtraDriver = (e: any) => {
        e.preventDefault();
        setOtherCostDriver((parseFloat(autoRouteKm) * 0.03).toFixed(2))
    }
    
    const otherCostFrigoCost = 42;
    const calcExtraFrigo = (e: any) => {
        e.preventDefault();
        const days = moment(dateTo).diff(moment(dateFrom), 'days')
        setOtherCostFrigo((days * otherCostFrigoCost).toFixed(2))
    }

    const doInvoiceLineSplit = (invoiceLineId: number) => {
        ApiRest.makeRequestPost(
            'invoice-line/splitLine',
            { id: invoiceLineId }
        ).then(res => {
            syncInvoiceLines();
            toast.success("Padalinta");
        });
    }

    return (
        <Fragment>
            {shouldBlockNavigation &&
                <UI.Alerts.Alert bgColor={UI.Alerts.AlertBgColor.blue} brightness={100} size={UI.Alerts.AlertSize.sm}
                    className={"mb-2"}>Yra neišsaugotų pakeitimų.</UI.Alerts.Alert>}
            <div className={"flex gap-2"}>
                <div className={"w-3/4 space-y-4"}>
                    <div className={'flex gap-1'}>
                        <div className={'w-4/12'}>
                            <Fragment>
                                <UI.Card.WhiteCard>
                                    <div className={"flex items-center"}>
                                        <div className={"flex-grow"}>
                                            <UI.MVC.MvcViewTitle
                                                onBack={goBack}
                                                title={"Kelionės lapas"}
                                            />
                                        </div>

                                        {element.id > 0 && isReadonly && allowedUnlock.indexOf(userState.id) >= 0 &&
                                            <UI.PopoverConfirm.PopoverConfirm onClick={unlockTrip}>
                                                <UI.Buttons.Button size={"sm"}
                                                    bgColor={"yellow"}>Atrakinti</UI.Buttons.Button>
                                            </UI.PopoverConfirm.PopoverConfirm>
                                        }

                                        {element.id > 0 && !isReadonly && <TripDeleteButton id={element.id} />}
                                    </div>


                                    <div>

                                        <Form.Group>
                                            <Form.Label>Numeris</Form.Label>
                                            <Form.Control value={number} onChange={e => setNumber(e.target.value)}
                                                disabled={isReadonly} />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Reference</Form.Label>
                                            <Form.Control value={reference}
                                                onChange={e => setReference(e.target.value)}
                                                disabled={isReadonly} />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Klientas</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={clientId}
                                                onChange={(e) => setClientId(e.target.value)}
                                                disabled={isReadonly}
                                            >
                                                <option value="0">Pasirinkite...</option>
                                                {clients.map((client) => 
                                                    <option key={'opt-c' + client.id} value={client.id}>{client.name}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={6}>
                                                <Form.Label>Vilkikas</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="mr-sm-2"
                                                    value={truck}
                                                    onChange={(e) => setTruck(e.target.value)}
                                                    disabled={isReadonly}
                                                >
                                                    <option value="0">Pasirinkite...</option>
                                                    {trucks.filter((truck: Truck) => !!truck.locktrackerNumber).map((truck: Truck) =>
                                                        <option key={'opt-g' + truck.id}
                                                            value={truck.id}>{truck.name}</option>)}

                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group as={Col} sm={6}>
                                                <Form.Label>Priekaba</Form.Label>
                                                <Form.Control value={trailer}
                                                    onChange={e => setTrailer(e.target.value)}
                                                    disabled={isReadonly} />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>

                                            {/*<Form.Group as={Col} sm={6}>*/}
                                            {/*    <Form.Label>Vairuotojas (nenaudoti)</Form.Label>*/}
                                            {/*    <Form.Control value={driver}*/}
                                            {/*                  onChange={e => setDriver(e.target.value)}*/}
                                            {/*                  disabled={isReadonly}/>*/}
                                            {/*</Form.Group>*/}
                                            {/*<Form.Group as={Col} sm={6}></Form.Group>*/}

                                            <Form.Group as={Col} sm={6}>
                                                <Form.Label>Vairuotojas</Form.Label>

                                                <UI.Form.Select value={truckDriver} onChange={(e) => {
                                                    if (!isReadonly) {
                                                        setTruckDriver(e)
                                                    }
                                                }} options={[
                                                    { value: '0', label: "Pasirinkite..." },
                                                    ...drivers.map((driver: Driver) => {
                                                        return (
                                                            { value: driver.id.toString(), label: driver.fullName }
                                                        )
                                                    })
                                                ]}

                                                />

                                            </Form.Group>

                                            <Form.Group as={Col} sm={6}>
                                                <Form.Label>Vairuotojas 2</Form.Label>

                                                <UI.Form.Select value={truckDriver2} onChange={(e) => {
                                                    if (!isReadonly) {
                                                        setTruckDriver2(e)
                                                    }
                                                }} options={[
                                                    { value: '0', label: "Pasirinkite..." },
                                                    ...drivers.map((driver: Driver) => {
                                                        return (
                                                            { value: driver.id.toString(), label: driver.fullName }
                                                        )
                                                    })
                                                ]} />

                                            </Form.Group>

                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>Data nuo</Form.Label>
                                                <DateField value={dateFrom} setValue={e => setDateFrom(e)}
                                                    disabled={isReadonly} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>Data iki</Form.Label>
                                                <DateField value={dateTo} setValue={e => setDateTo(e)}
                                                    disabled={isReadonly} />
                                            </Form.Group>

                                            <Form.Group as={Col} sm={4}>

                                            </Form.Group>
                                        </Form.Row>


                                        <Form.Group>
                                            <Form.Label>Komentaras</Form.Label>
                                            <Form.Control as={"textarea"} value={comment}
                                                onChange={e => setComment(e.target.value)}
                                                disabled={isReadonly} />
                                        </Form.Group>

                                        <Form.Row>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>KM pagal autoRoute</Form.Label>
                                                <Form.Control value={autoRouteKm}
                                                    onChange={e => setAutoRouteKm((e.target.value.replaceAll(' ', '').replaceAll(',', '.')))}
                                                    disabled={isReadonly} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>Keliai, EUR</Form.Label>
                                                <Form.Control value={roadCost}
                                                    onChange={e => setRoadCost((e.target.value.replaceAll(' ', '').replaceAll(',', '.')))}
                                                    disabled={isReadonly} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>Kitos išlaidos, EUR</Form.Label>
                                                <Form.Control value={otherCost}
                                                    onChange={e => setOtherCost((e.target.value).replaceAll(' ', '').replaceAll(',', '.'))}
                                                    disabled={true} />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col} sm={4}>
                                                <div className={"flex items-center gap-2"}>
                                                    <Form.Label>FRIGO</Form.Label>
                                                    <button onClick={calcExtraFrigo}>
                                                        <UI.Icons.FA type={"fad"} icon={"calculator"}
                                                            className={"text-blue-500"} />
                                                    </button>
                                                </div>

                                                <Form.Control value={otherCostFrigo}
                                                    onChange={e => setOtherCostFrigo((e.target.value).replaceAll(' ', '').replaceAll(',', '.'))}
                                                    disabled={isReadonly} />
                                            </Form.Group>

                                            <Form.Group as={Col} sm={4}>
                                                <div className={"flex items-center gap-2"}>
                                                    <Form.Label>Extra Driver</Form.Label>
                                                    <button onClick={calcExtraDriver}>
                                                        <UI.Icons.FA type={"fad"} icon={"calculator"}
                                                            className={"text-blue-500"} />
                                                    </button>
                                                </div>
                                                <Form.Control value={otherCostDriver}
                                                    onChange={e => setOtherCostDriver((e.target.value).replaceAll(' ', '').replaceAll(',', '.'))}
                                                    disabled={isReadonly} />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>ADR</Form.Label>
                                                <Form.Control value={otherCostAdr}
                                                    onChange={e => setOtherCostAdr((e.target.value).replaceAll(' ', '').replaceAll(',', '.'))}
                                                    disabled={isReadonly} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>LOAD/UNLOAD</Form.Label>
                                                <Form.Control value={otherCostLoad}
                                                    onChange={e => setOtherCostLoad((e.target.value).replaceAll(' ', '').replaceAll(',', '.'))}
                                                    disabled={isReadonly} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>Kita</Form.Label>
                                                <Form.Control value={otherCostDowntime}
                                                    onChange={e => setOtherCostDowntime((e.target.value).replaceAll(' ', '').replaceAll(',', '.'))}
                                                    disabled={isReadonly} />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Table>
                                                <tbody>
                                                    <tr className={"bg-indigo-100 bg-opacity-50"}>
                                                        <td colSpan={3}>
                                                            <UI.Typography.H5>Atstumai</UI.Typography.H5>
                                                        </td>
                                                        <td className={"text-right"}>Skirtumas</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className={"font-medium"}>AutoRoute</div>
                                                        </td>
                                                        <td className={"text-right font-semibold"}>{autoRouteKm}</td>
                                                        <td colSpan={2}></td>
                                                    </tr>
                                                    <tr className={"bg-blue-100 bg-opacity-20"}>
                                                        <td>
                                                            <div className={"font-medium"}>LocTracker</div>
                                                            <div>{minOdo.toFixed(0)} - {maxOdo.toFixed(0)}</div>
                                                        </td>
                                                        <td className={"text-right font-semibold"}>{odoDiff.toFixed(0)}</td>
                                                        <td colSpan={2} className={'text-right'}>
                                                            {(drivenKm - odoDiff).toFixed(0)} <span
                                                                className={`${autoRouteLockTrackerDiff < 0 ? 'text-red-500' : 'text-green-500'}`}>({autoRouteLockTrackerDiff}%)</span>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <div className={"font-medium"}>Sąskaita</div>
                                                        </td>
                                                        <td className={"text-right font-semibold"}>{drivenKm.toFixed(0)}</td>
                                                        <td colSpan={2} className={'text-right'}>
                                                            {(drivenKm - autoRouteKmFl).toFixed(0)} <span
                                                                className={`${autoRouteDrivenDiff < 0 ? 'text-red-500' : 'text-green-500'}`}>({autoRouteDrivenDiff}%)</span>
                                                        </td>
                                                    </tr>

                                                    <tr className={"bg-indigo-100 bg-opacity-50"}>
                                                        <td colSpan={3}><UI.Typography.H5>Išlaidos</UI.Typography.H5>
                                                        </td>
                                                        <td className={"text-right"}>Skirtumas</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <div className={"font-medium"}>Keliai (Sąskaita)</div>
                                                        </td>
                                                        <td className={"text-right font-semibold"}>{invRoadCosts.toFixed(2)}</td>
                                                        <td colSpan={2} className={'text-right'}>
                                                            {(invRoadCosts - parseFloat(roadCost)).toFixed(2)}
                                                        </td>
                                                    </tr>

                                                    <tr className={"bg-blue-100 bg-opacity-20"}>
                                                        <td>
                                                            <div className={"font-medium"}>Kitos išlaidos (Sąskaita)
                                                            </div>
                                                        </td>
                                                        <td className={"text-right font-semibold"}>{invOtherCosts.toFixed(2)}</td>
                                                        <td colSpan={2} className={'text-right'}>
                                                            {(invOtherCosts - parseFloat(otherCost)).toFixed(2)}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>

                                        </Form.Row>

                                        <Form.Row>

                                            <Form.Group as={Col} sm={4}>
                                                <Form.Label>Statusas</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="mr-sm-2"
                                                    value={status}
                                                    onChange={(e) => setStatus(parseInt(e.target.value, 10))}
                                                    disabled={isReadonly}
                                                >
                                                    <option value="0">Aktyvus</option>
                                                    <option value="3">Grąžintas tikrinimui</option>
                                                    <option value="5">Užbaigtas vadybininko</option>
                                                    {hasPermission('CAN_APPROVE_TRIP') && (
                                                        <option value="10">Užbaigtas</option>
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={8}>
                                                <Fragment>
                                                    <Form.Label>KM skirtumo paaiškinimas</Form.Label>
                                                    <Form.Control as={"textarea"} value={statusComment}
                                                        disabled={isReadonly}
                                                        onChange={e => setStatusComment(e.target.value)} />
                                                </Fragment>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                            </Form.Group>

                                            <Form.Group as={Col} sm={12}>
                                                <Form.Label>Vadybininkas</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="mr-sm-2"
                                                    value={userId}
                                                    onChange={(e) => setUserId(e.target.value)}
                                                    disabled={isReadonly}
                                                >
                                                    <option value="0">Pasirinkite...</option>
                                                    {users.map((user: SystemUser) =>
                                                        <option key={'opt-g' + user.id}
                                                            value={user.id}>{user.fullName} ({user.email})</option>)}

                                                </Form.Control>
                                            </Form.Group>
                                        </Form.Row>

                                    </div>
                                    {!isReadonly &&
                                        <Card.Footer>
                                            <Row>
                                                <Col>

                                                </Col>
                                                <Col className={"text-right"}>
                                                    <UI.Buttons.Button
                                                        icon={"fad fa-fw fa-save"}
                                                        iconLoading={props.isSaving}
                                                        onClick={() => saveData(
                                                            number,
                                                            parseInt(truck, 10),
                                                            parseInt(truckDriver, 10),
                                                            parseInt(truckDriver2, 10),
                                                            dateFrom,
                                                            dateTo,
                                                            tripLines,
                                                            status,
                                                            comment,
                                                            reference,
                                                            parseFloat(autoRouteKm),
                                                            parseFloat(roadCost),
                                                            parseFloat(otherCost),
                                                            statusComment,

                                                            trailer,
                                                            parseFloat(otherCostFrigo),
                                                            parseFloat(otherCostAdr),
                                                            parseFloat(otherCostLoad),
                                                            parseFloat(otherCostDriver),
                                                            parseFloat(otherCostDowntime),

                                                            driver,
                                                            parseInt(userId, 10),
                                                            parseInt(clientId, 10),
                                                        )}>Išsaugoti</UI.Buttons.Button>
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    }
                                </UI.Card.WhiteCard>
                            </Fragment>
                        </div>
                        <div className={'w-8/12 space-y-4'}>
                            <UI.Card.WhiteCard>

                                <div className="flex items-center gap-2">
                                    <CardWidgetTitle className="flex-grow">
                                        {t("Maršrutas")}
                                    </CardWidgetTitle>
                                    {!isReadonly &&
                                        <Fragment>
                                            <SquareButton
                                                bgColor={TwBackgroundColors.slate50}
                                                icon={{
                                                    type: IconType.SolidButton,
                                                    icon: IconList.plus,
                                                    className: TwTextColors.slate400,
                                                }}
                                                onClick={() => addTripLine()}
                                            />
                                            <SquareButton
                                                bgColor={TwBackgroundColors.slate50}
                                                icon={{
                                                    type: IconType.SolidButton,
                                                    icon: IconList.locationPin,
                                                    className: TwTextColors.slate400,
                                                }}
                                                onClick={() => setShowPopup(true)}
                                            />
                                        </Fragment>
                                    }
                                </div>


                                <Table className={"small"}>
                                    <thead>
                                        <tr>
                                            <th>Miestas</th>
                                            <th>Data</th>
                                            <th>Odo</th>
                                            <th style={{ width: 50 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tripLines.map((_l: TripLine, i: number) => {
                                            return <TripFormTripLine
                                                downTripLine={downTripLine}
                                                setDataToTripLine={setDataToTripLine}
                                                line={_l}
                                                index={i}
                                                removeTripLine={removeTripLine}
                                                upTripLine={upTripLine}
                                                isReadonly={isReadonly}
                                                tripLinesCount={tripLines.length}

                                                key={"trip-line-" + i} />
                                        })}


                                    </tbody>
                                </Table>

                            </UI.Card.WhiteCard>

                            {showPopup && !!elementTrip && (
                                <Popup onClose={() => setShowPopup(false)} header={t("Užduotys")}>
                                    <TripLocatorTasks
                                        addTripLine={addTripLine}
                                        tripLines={tripLines}
                                        element={elementTrip}
                                        getData={() => { }}
                                    />
                                </Popup>
                            )}


                            <UI.Card.WhiteCard>
                                <div className={"flex items-center gap-2"}>
                                    <div className={'flex-grow'}>
                                        <UI.MVC.MvcViewTitle
                                            title={"Sąskaita"}
                                        />
                                    </div>
                                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.nsecondary}
                                        size={UI.Buttons.ButtonSize.sm}
                                        onClick={() => syncInvoiceLines()}>Atnaujinti</UI.Buttons.Button>
                                </div>
                                <UI.Table.Table
                                    thead={<UI.Table.Thead
                                        columns={[
                                            {
                                                content: "Sąskaita", props: { className: 'text-left' }
                                            },
                                            {
                                                content: "Paslauga", props: { className: 'text-left' }
                                            },
                                            {
                                                content: "Grupė", props: { className: 'text-left' }
                                            },
                                            {
                                                content: "Kiekis", props: { className: 'text-right' }
                                            },
                                            {
                                                content: "Kaina, EUR", props: { className: 'text-right' }
                                            },
                                            {
                                                content: "Iš viso, EUR", props: { className: 'text-right' }
                                            },
                                            {
                                                content: '', props: { className: '' },
                                            }
                                        ]
                                        }
                                    />
                                    }
                                    tbody={
                                        <UI.Table.Tbody
                                            data={loadInvoiceLinesData.data.data}
                                            callback={(item: InvoiceLine, index: number) => {
                                                return (
                                                    {
                                                        columns: [
                                                            {
                                                                content: <button className={"text-blue-500"}
                                                                    onClick={() => goToInvoiceLine(item)}>{item.invoice}</button>,
                                                                props: { className: 'text-left' }
                                                            },
                                                            {
                                                                content: <button
                                                                    className={"text-blue-500"}
                                                                    onClick={() => goToService(item)}>{item.serviceName}</button>,
                                                                props: { className: 'text-left' }
                                                            },
                                                            {
                                                                content: item.serviceGroupName,
                                                                props: { className: 'text-left' }
                                                            },
                                                            {
                                                                content: item.quantity.toFixed(2),
                                                                props: { className: 'text-right' }
                                                            },
                                                            {
                                                                content: item.priceEur.toFixed(2),
                                                                props: { className: 'text-right' }
                                                            },
                                                            {
                                                                content: item.totalEur.toFixed(2),
                                                                props: { className: 'text-right' }
                                                            },
                                                            {
                                                                content: <Fragment>

                                                                    <UI.Buttons.Button
                                                                        onClick={() => setEditId(item.id)}
                                                                        size={UI.Buttons.ButtonSize.xs}
                                                                        bgColor={UI.Buttons.ButtonBgColor.white}
                                                                        brightness={200}
                                                                        icon="fa-pen-to-square fa-solid fa-fw"
                                                                        title="keisti"
                                                                    />

                                                                    <UI.Buttons.Button
                                                                        onClick={() => setSplitId(item.id)}
                                                                        size={UI.Buttons.ButtonSize.xs}
                                                                        bgColor={UI.Buttons.ButtonBgColor.white}
                                                                        brightness={200}
                                                                        icon="fa-split fa-solid fa-fw"
                                                                        title="dalinti"
                                                                    />

                                                                </Fragment>,
                                                                props: { className: 'text-right space-x-2' }
                                                            }
                                                        ],
                                                        className: UI.Table.defaultStrippedRowClassName(index)
                                                    }
                                                )
                                            }
                                            }
                                        />
                                    }
                                />
                            </UI.Card.WhiteCard>

                            {splitId > 0 && <TripFormSplit id={splitId} onClose={() => setSplitId(0)} />}
                            {editId > 0 && <TripFormEdit id={editId} onClose={() => setEditId(0)} />}
                        </div>
                    </div>

                </div>
                <div className="w-1/4 space-y-2">
                    {!!elementTrip &&
                        <UI.Widget.Widget
                            type={UI.Widget.WidgetType.viewRightTop}
                            schema={'trip'}
                            element={{ id: elementTrip.id }}
                        />
                    }
                </div>
            </div>
            <Prompt
                when={shouldBlockNavigation}
                message='Yra neišsaugotų pakeitimų. Ar tikrai norite išeiti?'
            />
        </Fragment>)
}
