import React, { Fragment } from 'react'
import { MenuFolder, MenuSpacer } from '@newageerp/ui.menu.base.menu'
import MenuItemUsers from "../items/MenuItemUsers" 


export default function MenuFolderNustatymaifad() {
    return (
        <Fragment>
            <MenuFolder
                item={{
                    children: 'Nustatymai',
                    icon: 'cogs'
                }}
                menuFolderId='MenuFolderNustatymaifad'
            >

                <MenuItemUsers forceSkipIcon={true}/>
            </MenuFolder>
        </Fragment>
    )
}
