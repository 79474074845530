import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {Card, Col, Row} from "react-bootstrap";
import {Truck} from "./TruckTypes";
import {NaeTable} from "nae-react-core-styles";
import TableWithPaging from "../../TableWithPaging";
import {UI} from "@newageerp/nae-react-ui"
import useApiRest from "../../../Hooks/useApiRest";
import { OpenApi } from "@newageerp/nae-react-auth-wrapper";

const moduleName = 'truck';

interface Props {
    groupId?: number
    trailerGroupId?: number
}

export default function TrucksListCard(props: Props) {
    const history = useHistory();

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [trucks, setTrucks] = useState<Truck[]>([])
    const [dataToRender, setDataToRender] = useState<Truck[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const [filter, setFilter] = useState('');

    const [getTrucks, trucksData] = OpenApi.useUList('truck', ['id']);

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setTrucks(res);
        })
        getTrucks(
            [],
            1,
            100,
            [
                {
                    key: 'i.id',
                    value: 'ASC',
                }
            ]
        )
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = trucks;
            if (search) {
                _data = _data.filter((item: Truck) => {
                    return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
                });
            }

            if (props.groupId) {
                _data = _data.filter((item: Truck) => {
                    return item.truckGroup === props.groupId
                });
            }
            if (props.trailerGroupId) {
                _data = _data.filter((item: Truck) => {
                    return item.truckTrailerGroup === props.trailerGroupId
                });
            }

            if (filter) {
                if (filter === 'no_group') {
                    _data = _data.filter((item: Truck) => {
                        return item.truckGroupName === ''
                    });
                }
                if (filter === 'no_trailer') {
                    _data = _data.filter((item: Truck) => {
                        return item.truckTrailerGroupName === ''
                    });
                }
                if (filter === 'has_loctracker') {
                    _data = _data.filter((item: Truck) => {
                        return item.locktrackerNumber !== ''
                    });
                }
                if (filter === 'no_loctracker') {
                    _data = _data.filter((item: Truck) => {
                        return item.locktrackerNumber === ''
                    });
                }
            } else {
                _data = _data.filter((item: Truck) => {
                    return item.locktrackerNumber !== '' || item.name.indexOf('MAGNUM') >= 0
                });
            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, trucks, filter, props.groupId, props.trailerGroupId, readyToLoad]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: Truck) => {
        history.replace('/trucks', {search, activePage, filter});
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    const [doSync, doSyncParams] = useApiRest('loctracker/syncDevices');
    const runSync = () => {
        doSync().then(() => getData())
    }

    return (

        <UI.Card.WhiteCard>
            <div className={"flex items-center gap-2"}>
                <UI.Typography.H3 className={"flex-grow"}>
                    Vilkikai
                </UI.Typography.H3>
                <UI.Buttons.Button icon={"fal fa-fw fa-sync"} iconLoading={doSyncParams.loading} onClick={runSync}>
                    Susieti su LocTracker
                </UI.Buttons.Button>
            </div>

            <div className={"flex items-center gap-2"}>
                <UI.Form.Input placeholder={"Paieška"} className={"flex-grow"} value={search}
                               onChange={(e) => setSearch(e.target.value)}/>

                <UI.Form.Select className={"w-72"} value={filter} onChange={e => setFilter(e)} options={[
                    {value: "", label: "Filtras"},
                    {value: "no_group", label: "Nepriskirtas parkas"},
                    {value: "no_trailer", label: "Nepriskirtas priekabos tipas"},
                    {value: "has_loctracker", label: "Priskirtas LocTracker numeris"},
                    {value: "no_loctracker", label: "Nepriskirtas LocTracker numeris"},
                ]}/>
            </div>
            <TableWithPaging
                activePage={activePage}
                setActivePage={setActivePage}
                dataToRender={dataToRender} head={
                <tr>
                    <NaeTable.th id={true}>ID</NaeTable.th>
                    <NaeTable.th>Valstybinis nr.</NaeTable.th>
                    <NaeTable.th>Parkas</NaeTable.th>
                    <NaeTable.th>Priekabos tipas</NaeTable.th>
                    <NaeTable.th number={true}>Pagaminimo metai</NaeTable.th>
                    <NaeTable.th number={true}>Rida</NaeTable.th>

                </tr>
            } renderItem={(item: Truck) => {
                return (
                    <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                        <NaeTable.td id={true}>{item.id}</NaeTable.td>
                        <NaeTable.td link={true}>{item.name}</NaeTable.td>
                        <NaeTable.td>{item.truckGroupName}</NaeTable.td>
                        <NaeTable.td>{item.truckTrailerGroupName}</NaeTable.td>
                        <NaeTable.td number={true}>{item.manufactureYear}</NaeTable.td>
                        <NaeTable.td number={true}>{item.mileage}</NaeTable.td>
                    </tr>
                )
            }}
            />

        </UI.Card.WhiteCard>

    );
}
