import React, { Fragment, useEffect, useState } from "react";
import { WhiteCard } from "@newageerp/ui.cards.base.white-card";
import { CardWidgetTitle } from "@newageerp/ui.cards.base.card-widget-title";
import { useTranslation } from "react-i18next";
import {
  TwBackgroundColors,
  TwTextColors,
} from "@newageerp/ui.styles.tailwind";
import { IconType } from "@newageerp/ui.icons.base.icon";
import { IconList } from "@newageerp/ui.icons.base.icon-list";
import useApiRestPost from "../../Hooks/useApiRestPost";
import { PropsId } from "../ModelsCacheData/types";
import { SquareButton } from "@newageerp/ui.buttons.base.square-button";
import { Table, Th, Td } from "@newageerp/ui.table.base.table";
import { InvoiceLine } from "../../Components/Pages/Invoices/InvoiceTypes";
import { UI } from "@newageerp/nae-react-ui";
import TripFormSplit from "../../Components/Forms/TripFormSplit";
import TripFormEdit from "../../Components/Forms/TripFormEdit";
import { useHistory } from "react-router-dom";
import { Popup } from "@newageerp/ui.popups.base.popup";

export default function TripsInvoiceLines(props: PropsId) {
  const { t } = useTranslation();

  const history = useHistory();

  const [splitId, setSplitId] = useState(0);
  const [editId, setEditId] = useState(0);

  const [loadInvoiceLines, loadInvoiceLinesData] = useApiRestPost(
    "loctracker/loadInvoiceLines"
  );
  const syncInvoiceLines = () => {
    loadInvoiceLines({
      tripId: props.id,
    });
  };
  useEffect(() => {
    syncInvoiceLines();
  }, []);

  const goToInvoiceLine = (item: InvoiceLine) => {
    history.push("/invoice-line/" + item.id);
  };
  const goToService = (item: InvoiceLine) => {
    history.push("/service/" + item.serviceId);
  };

  return (
    <Fragment>
      <WhiteCard>
        <div className="flex items-center gap-2">
          <CardWidgetTitle className="flex-grow">
            {t("Sąskaita")}
          </CardWidgetTitle>
          <SquareButton
            bgColor={TwBackgroundColors.slate50}
            icon={{
              type: IconType.SolidButton,
              icon: IconList.reload,
              className: TwTextColors.slate400,
            }}
            onClick={() => syncInvoiceLines()}
          />
        </div>

        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Sąskaita")}</Th>
                <Th>{t("Paslauga")}</Th>
                <Th>{t("Grupė")}</Th>
                <Th>{t("Kiekis")}</Th>
                <Th>{t("Kaina, EUR")}</Th>
                <Th>{t("Iš viso, EUR")}</Th>
                <Th style={{ width: 100 }}>{t("")}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {loadInvoiceLinesData.data.data.map(
                (item: InvoiceLine, index: number) => {
                  return (
                    <tr key={`invoice-line-${item.id}`}>
                      <Td>
                        <button
                          className={"text-blue-500 text-left"}
                          onClick={() => goToInvoiceLine(item)}
                        >
                          {item.invoice}
                        </button>
                      </Td>
                      <Td>
                        <button
                          className={"text-blue-500 text-left"}
                          onClick={() => goToService(item)}
                        >
                          {item.serviceName}
                        </button>
                      </Td>
                      <Td>{item.serviceGroupName}</Td>
                      <Td>{item.quantity.toFixed(2)}</Td>
                      <Td>{item.priceEur.toFixed(2)}</Td>
                      <Td>{item.totalEur.toFixed(2)}</Td>
                      <Td className="space-x-2">
                        <Fragment>
                          <UI.Buttons.Button
                            onClick={() => setEditId(item.id)}
                            size={UI.Buttons.ButtonSize.xs}
                            bgColor={UI.Buttons.ButtonBgColor.white}
                            brightness={200}
                            icon="fa-pen-to-square fa-solid fa-fw"
                            title="keisti"
                          />

                          <UI.Buttons.Button
                            onClick={() => setSplitId(item.id)}
                            size={UI.Buttons.ButtonSize.xs}
                            bgColor={UI.Buttons.ButtonBgColor.white}
                            brightness={200}
                            icon="fa-split fa-solid fa-fw"
                            title="dalinti"
                          />
                        </Fragment>
                      </Td>
                    </tr>
                  );
                }
              )}
            </tbody>
          }
        />
      </WhiteCard>
      {splitId > 0 && (
        <Popup onClose={() => setSplitId(0)} header={t("Dalinimas")}>
          <TripFormSplit id={splitId} onClose={() => setSplitId(0)} />
        </Popup>
      )}
      {editId > 0 && (
        <Popup onClose={() => setEditId(0)} header={t("Redagavimas")}>
          <TripFormEdit id={editId} onClose={() => setEditId(0)} />
        </Popup>
      )}
    </Fragment>
  );
}
