import React, { Fragment } from 'react'
import { MenuFolder, MenuSpacer } from '@newageerp/ui.menu.base.menu'
import MenuItemDriverTripMain from "../items/MenuItemDriverTripMain" 
import MenuItemClientMain from "../items/MenuItemClientMain" 


export default function MenuFolderKomandiruots6c8() {
    return (
        <Fragment>
            <MenuFolder
                item={{
                    children: 'Komandiruotės',
                    icon: 'person-circle-exclamation'
                }}
                menuFolderId='MenuFolderKomandiruots6c8'
            >

                <MenuItemDriverTripMain forceSkipIcon={true}/>
                <MenuSpacer />
                <MenuItemClientMain forceSkipIcon={true}/>
            </MenuFolder>
        </Fragment>
    )
}
