import React, { useEffect, useState, useRef } from "react";
import { Truck } from "../../Pages/Trucks/TruckTypes";
import { TripLine } from "../../Pages/Sales/SalesTypes";
import { UI } from "@newageerp/nae-react-ui";
import useApiRestPost from "../../../Hooks/useApiRestPost";
import { LocatorTask } from "../../Forms/TripForm";
import { TabContainer } from "@newageerp/ui.tabs.tab-container";
import { useTranslation } from "react-i18next";
import { Table, Td, Th } from "@newageerp/ui.table.base.table";
import { ActionTagIcon } from "@newageerp/sfs.locktracker.action-tag-icon";
import ApiRest from "../../../service/ApiRest";
import { toast } from "react-toastify";
import { usePermissions } from "../../User/PermissionProvider";

const TasksListPage: React.FC = () => {
    const { t } = useTranslation();
    const { hasPermission } = usePermissions();

    const [truck, setTruck] = useState<Truck | null>(null);
    const [tripLines, setTripLines] = useState<TripLine[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);

    const [getData, getDataParams] = useApiRestPost("loctracker/getActiveTasksForTruck");
    const [deleteTasks] = useApiRestPost("loctracker/deleteTasks");

    const loadTrucks = () => {
        ApiRest.getElements("truck")
            .then((res: Truck[]) => {
                const trucksWithLoctracker = res.filter(truck => truck.locktrackerNumber);
                setTrucks(trucksWithLoctracker);
            })
            .catch(() => {
                toast.error("Failed to load trucks");
            });
    };

    const loadTasksForTruck = (truckId: number) => {
        setIsLoading(true);
        getData({ truckId })
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                toast.error("Failed to load tasks");
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadTrucks();
    }, []);

    useEffect(() => {
        if (truck) {
            console.log("Truck selected: ", truck);
            loadTasksForTruck(truck.id);
        }
    }, [truck]);

    const handleTruckChange = (selectedTruckId: string) => {
        console.log("Selected Truck ID: ", selectedTruckId);
        const selectedTruck = trucks.find(truck => truck.id === parseInt(selectedTruckId)) || null;
        console.log("Selected Truck: ", selectedTruck);
        setTruck(selectedTruck);
    };

    const handleTaskSelect = (taskId: number) => {
        setSelectedTasks(prevSelectedTasks =>
            prevSelectedTasks.includes(taskId)
                ? prevSelectedTasks.filter(id => id !== taskId)
                : [...prevSelectedTasks, taskId]
        );
    };

    const handleSelectAllTasks = () => {
        if (selectedTasks.length === activeData.length) {
            setSelectedTasks([]);
        } else {
            setSelectedTasks(activeData.map(task => task.id));
        }
    };

    const handleDeleteSelectedTasks = () => {
        if (selectedTasks.length > 0) {
            console.log("Task IDs to delete: ", selectedTasks);
            deleteTasks({ taskIds: selectedTasks })
                .then(() => {
                    toast.success("Selected tasks deleted successfully");
                    loadTasksForTruck(truck?.id || 0);
                    setSelectedTasks([]);
                })
                .catch(() => {
                    toast.error("Failed to delete selected tasks");
                });
        }
    };

    const data: LocatorTask[] = getDataParams.data?.data ?? [];
    const activeData = data.filter(el => el.status === "PENDING" || el.status === "IN_PROGRESS_NOW");

    const items = [
        {
            content: <ParseTable data={activeData} onTaskSelect={handleTaskSelect} selectedTasks={selectedTasks} onSelectAll={handleSelectAllTasks} />,
            tab: { children: t("Aktyvios") },
        },
    ];

    return (
        <UI.Card.WhiteCard className={"overflow-y-auto"} style={{ minHeight: '420px', maxHeight: "calc(100vh - 105px)" }}>
            <div className={"flex items-center gap-2"}>
                <UI.Typography.H5>{t("Užduotys (Loctracker)")}</UI.Typography.H5>
                {isLoading && <UI.Loader.Logo size={20} />}
            </div>
            <div className={"flex flex-wrap gap-2"}>
                <p className="flex items-center font-medium">Vilkikai: </p>
                <UI.Form.Select
                    className='w-1/4'
                    value={truck?.id.toString() || ""}
                    onChange={handleTruckChange}
                    options={[
                        { value: "", label: "Pasirinkite vilkiką" },
                        ...trucks.map((truck) => ({
                            value: truck.id.toString(),
                            label: `${truck.name}`,
                        })),
                    ]}
                />
                {hasPermission('CAN_DELETE_TASK') && (
                    <UI.Buttons.Button onClick={handleDeleteSelectedTasks}>
                        {t("Ištrinti pasirinktas užduotis")}
                    </UI.Buttons.Button>
                )}
            </div>
            <TabContainer items={items} />
        </UI.Card.WhiteCard>
    );
};

interface ParseTableProps {
    data: LocatorTask[];
    onTaskSelect: (taskId: number) => void;
    selectedTasks: number[];
    onSelectAll: () => void;
}

const ParseTable: React.FC<ParseTableProps> = ({ data, onTaskSelect, selectedTasks, onSelectAll }) => {
    const { t } = useTranslation();
    const allSelected = selectedTasks.length === data.length;
    const someSelected = selectedTasks.length > 0 && !allSelected;

    const selectAllRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (selectAllRef.current) {
            selectAllRef.current.indeterminate = someSelected;
        }
    }, [someSelected]);

    return (
        <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
            <Table
                thead={
                    <thead>
                        <tr>
                            <Th>
                                <input
                                    type="checkbox"
                                    ref={selectAllRef}
                                    checked={allSelected}
                                    onChange={onSelectAll}
                                />
                            </Th>
                            <Th>{t("Lokacija")}</Th>
                            <Th>{t("Data")}</Th>
                            <Th>{t("Užbaigta")}</Th>
                            <Th>{t("Tipas")}</Th>
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {data.map((item: LocatorTask) => (
                            <tr key={`task-${item.id}`} onClick={() => onTaskSelect(item.id)} className={selectedTasks.includes(item.id) ? "selected-row" : ""}>
                                <Td>
                                    <input
                                        type="checkbox"
                                        checked={selectedTasks.includes(item.id)}
                                        onChange={(e) => e.stopPropagation()}
                                    />
                                </Td>
                                <Td>{item.location}</Td>
                                <Td>{item.date}</Td>
                                <Td>{item.completed}</Td>
                                <Td>
                                    <ActionTagIcon type={item.tag ?? ""} className="text-lg" />
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                }
            />
        </div>
    );
};


export default TasksListPage;
