class ApiProfileService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/profile';
    }

    authRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    };

    authUploadRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Authorization': token
            },
        }
    };
    getProfile = () => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(this.baseUrl + '/get', requestOptions).then(res => res.json());
    }

    saveProfile = (email: string, password: string, passwordRepeat: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({email, password, passwordRepeat})
        };

        return fetch(this.baseUrl + '/save', requestOptions).then(res => res.json());
    }
}

const ApiProfile = new ApiProfileService();
export default ApiProfile;
