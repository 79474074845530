import { Compact, CompactRow, Wide, WideRow, Label } from '@newageerp/ui.form.base.form-pack'
import { useTranslation } from 'react-i18next'
import React, {Fragment} from 'react'
    import { Float } from "@newageerp/data.table.float";

interface Props {
    element: any
}

export default function TripKmsViewForm(props: Props) {
    const { t } = useTranslation();
    const { element } = props;

    return (
        <Wide>
                            <WideRow label={<Label>{t('KM pagal autoroute')}</Label>} control={<Float value={element.autoRouteKm}/>}/>
                            <WideRow label={<Label>{t('Nefiksuojama paklaida, KM')}</Label>} control={<Float value={element.autoRouteKmAllowedDiff}/>}/>
                            <WideRow label={<Label>{t('Loctracker, KM')}</Label>} control={<Float value={element.loctrackerDrivenKm}/>}/>
                            <WideRow label={<Label>{t('Sąskaita, KM')}</Label>} control={<Float value={element.invoiceDrivenKm}/>}/>
                    </Wide>
    )
}
