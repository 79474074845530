import React, {Fragment} from 'react';
import { Wide, WideToolbar, WideRow, Compact, CompactToolbar, CompactRow, Label } from '@newageerp/ui.form.base.form-pack';
import { useTranslation } from 'react-i18next'
import { Input } from "@newageerp/ui.form.base.form-pack";
import { SelectAdvData } from "@newageerp/ui.form.base.form-pack";
import { Datepicker } from "@newageerp/ui.form.base.form-pack";
import { FuelCardEnumsOptions } from "../../enums/view/FuelCardEnums";
import { SelectAdvId } from "@newageerp/ui.form.base.form-pack";

interface Props {
    element: any;
    onChange: (key: string, value: any) => void,
    compact?: boolean,

    onSave: () => void,
    onCancel: () => void,
}

export default function FuelCardMainForm(props: Props) {
    const { element, onChange } = props;
    const { t } = useTranslation();

    if (props.compact) {
        return (
            <Compact>
                <CompactRow label={<Label>{t('Tiekėjas')}</Label>} control={<Input onChange={(e: any) => onChange('supplier', e.target.value)} value={element.supplier}/>}/>
<CompactRow label={<Label>{t('Kortelės nr.')}</Label>} control={<Input onChange={(e: any) => onChange('number', e.target.value)} value={element.number}/>}/>
<CompactRow label={<Label>{t('Vilkikas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck"
                        field="name"
                        selectedId={element.truck?.id}
                        onSelectId={(e: any) => onChange('truck', {id: e})}
                        parentElement={element}
                        parentSchema={"fuel-card"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<CompactRow label={<Label>{t('Pin')}</Label>} control={<Input onChange={(e: any) => onChange('pin', e.target.value)} value={element.pin}/>}/>
<CompactRow label={<Label>{t('Galiojimo data')}</Label>} control={<Datepicker value={element.date} onChange={(e: any) => onChange('date', e)}  className={"field-date"}/>}/>
<CompactRow label={<Label>{t('Grupė')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck-group"
                        field="name"
                        selectedId={element.truckGroup?.id}
                        onSelectId={(e: any) => onChange('truckGroup', {id: e})}
                        parentElement={element}
                        parentSchema={"fuel-card"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<CompactRow label={<Label>{t('Statusas')}</Label>} control={<SelectAdvId withIcon={true} options={FuelCardEnumsOptions['status']} selectedId={element.status} onSelectId={(e: any) => onChange('status', e)} />}/>
<CompactRow label={<Label>{t('Komentaras')}</Label>} control={<Input onChange={(e: any) => onChange('comment', e.target.value)} value={element.comment}/>}/>
                <CompactToolbar onSave={props.onSave} onCancel={props.onCancel} />
            </Compact>
        )
    }

    return (
        <Wide>
            <WideRow label={<Label>{t('Tiekėjas')}</Label>} control={<Input onChange={(e: any) => onChange('supplier', e.target.value)} value={element.supplier}/>}/>
<WideRow label={<Label>{t('Kortelės nr.')}</Label>} control={<Input onChange={(e: any) => onChange('number', e.target.value)} value={element.number}/>}/>
<WideRow label={<Label>{t('Vilkikas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck"
                        field="name"
                        selectedId={element.truck?.id}
                        onSelectId={(e: any) => onChange('truck', {id: e})}
                        parentElement={element}
                        parentSchema={"fuel-card"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<WideRow label={<Label>{t('Pin')}</Label>} control={<Input onChange={(e: any) => onChange('pin', e.target.value)} value={element.pin}/>}/>
<WideRow label={<Label>{t('Galiojimo data')}</Label>} control={<Datepicker value={element.date} onChange={(e: any) => onChange('date', e)}  className={"field-date"}/>}/>
<WideRow label={<Label>{t('Grupė')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck-group"
                        field="name"
                        selectedId={element.truckGroup?.id}
                        onSelectId={(e: any) => onChange('truckGroup', {id: e})}
                        parentElement={element}
                        parentSchema={"fuel-card"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<WideRow label={<Label>{t('Statusas')}</Label>} control={<SelectAdvId withIcon={true} options={FuelCardEnumsOptions['status']} selectedId={element.status} onSelectId={(e: any) => onChange('status', e)} />}/>
<WideRow label={<Label>{t('Komentaras')}</Label>} control={<Input onChange={(e: any) => onChange('comment', e.target.value)} value={element.comment}/>}/>
            <WideToolbar onSave={props.onSave} onCancel={props.onCancel} />
        </Wide>
    )
}