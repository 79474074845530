import React from "react";
import UsersListCard from "./UsersListCard";

export default function UsersListPage() {

    return (
        <div>
            <UsersListCard/>
        </div>
    );
}
