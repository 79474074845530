import React, { Fragment, useEffect, useState } from "react";
import { Trip, TripLine } from "./SalesTypes";
import { UI } from "@newageerp/nae-react-ui"
import useApiRestPost from "../../../Hooks/useApiRestPost";
import { LocatorTask } from "../../Forms/TripForm";
import { ITripModelNae } from "../../Hooks/useTripHookNae";
import { TabContainer } from "@newageerp/ui.tabs.tab-container";
import { useTranslation } from "react-i18next";
import { Table, Td, Th } from '@newageerp/ui.table.base.table'
import { ActionTagIcon } from "@newageerp/sfs.locktracker.action-tag-icon";

interface Props {
    element: ITripModelNae,
    addTripLine: (TripLine?: any) => void,
    getData: () => void,
    tripLines: TripLine[],
}

// interface LocatorTask {
//     status: string,
//     locationName: string,
//     lat: number,
//     lng: number,
//     logistComment: string,
//     taskId: number
// }

interface LocatorData {
    odometerValue: number,
    fuelLevel: number,
    speed: number,
}


export default function TripLocatorTasks(props: Props) {
    const { t } = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);

    const [loadDataLoading, setLoadDataLoading] = useState(false);
    const [runSyncLoading, setRunSyncLoading] = useState(false);

    const [getData, getDataParams] = useApiRestPost('loctracker/getDbTasks');
    const loadData = () => {
        setLoadDataLoading(true)
        getData({
            tripId: props.element.id
        }).then(() => {
            setLoadDataLoading(false)
        }).catch(e => {
            setLoadDataLoading(false)
        })
    }

    const [doSync, doSyncData] = useApiRestPost('loctracker/syncTasksForTrip');
    const runSync = () => {
        if (!runSyncLoading) {
            setRunSyncLoading(true);
            doSync({
                tripId: props.element.id
            }).then(() => {
                props.getData();
                loadData();
                setRunSyncLoading(false);
            }).catch(e => {
                setRunSyncLoading(false);
            })
        }
    }

    const data: LocatorTask[] = getDataParams.data.data;
    const activeData = data.filter((el) => el.status !== 'COMPLETED');
    const completedData = data.filter((el) => el.status === 'COMPLETED');

    useEffect(() => {
        setIsLoaded(true)
    }, []);
    useEffect(() => {
        if (isLoaded) {
            runSync();
        }
    }, [isLoaded]);

    const items = [
        {
            content: <ParseTable tripLines={props.tripLines} data={activeData} addTripLine={props.addTripLine}
                element={props.element} />,
            tab: {
                children: t("Aktyvios"),
            },
        },
        {
            content: <ParseTable tripLines={props.tripLines} data={completedData} addTripLine={props.addTripLine}
                element={props.element} />,
            tab: {
                children: t("Užbaigtos"),
            },
        }
    ]

    return (
        <TabContainer
            items={items}
        />
    )

    return (
        <UI.Card.WhiteCard className={"overflow-y-auto"} style={{ maxHeight: 'calc(100vh - 105px)' }}>
            <div className={"flex items-center gap-2"}>
                <UI.Typography.H5>Locktracker</UI.Typography.H5>
                {(runSyncLoading || loadDataLoading) &&
                    <UI.Loader.Logo size={20} />
                }
            </div>


            <div className={"flex flex-wrap gap-2"}>
                <UI.Buttons.Button
                    icon={"fal fa-fw fa-sync"}
                    iconLoading={doSyncData.loading}
                    onClick={runSync}
                >
                    Iš Locktracker
                </UI.Buttons.Button>

                <UI.Buttons.Button
                    icon={"fal fa-fw fa-sync"}
                    iconLoading={getDataParams.loading}
                    onClick={loadData}
                >
                    Užkrauti taškus
                </UI.Buttons.Button>
            </div>

            {activeData.length > 0 && <Fragment>
                <UI.Typography.H6>Aktyvios</UI.Typography.H6>
                <ParseTable tripLines={props.tripLines} data={activeData} addTripLine={props.addTripLine}
                    element={props.element} />
            </Fragment>}

            {completedData.length > 0 &&
                <Fragment>
                    <UI.Typography.H6>Užbaigtos</UI.Typography.H6>
                    <ParseTable tripLines={props.tripLines} data={completedData} addTripLine={props.addTripLine}
                        element={props.element} />
                </Fragment>
            }
        </UI.Card.WhiteCard>
    )
}

interface ParseTableProps {
    data: LocatorTask[],
    addTripLine: (TripLine?: any) => void,
    element: ITripModelNae,
    tripLines: TripLine[],
}

const ParseTable = (props: ParseTableProps) => {
    const { t } = useTranslation();

    return (
        <div className="overflow-y-auto" style={{ maxHeight: '60vh' }}>
            <Table
                thead={
                    <thead>
                        <tr>
                            <Th>{t('Lokacija')}</Th>
                            <Th>{t('Data')}</Th>
                            <Th>{t('Užbaigta')}</Th>
                            <Th>{t('Tipas')}</Th>
                            <Th></Th>
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {props.data.map((item: LocatorTask, index: number) => {
                            const isActive = props.element.trip.filter((i: any) => i.taskId == item.taskId).length > 0;
                            const isActiveTmp = props.tripLines.filter((i: any) => i.taskId == item.taskId).length > 0;
                            const className = isActive ? "bg-green-100" : (isActiveTmp ? "bg-indigo-100" : '')
                            return (
                                <tr key={`task-${item.taskId}`} className={className}>
                                    <Td>{item.location}</Td>
                                    <Td>{item.date}</Td>
                                    <Td>{item.completed}</Td>
                                    <Td>
                                        <ActionTagIcon type={item.tag ? item.tag : ""} className="text-lg" />
                                    </Td>
                                    <Td>
                                        <button onClick={() => props.addTripLine({
                                            city: item.location,
                                            dateStart: item.date,
                                            dateFinish: item.completed,
                                            odoStart: item.odometerStart,
                                            odoFinish: item.odometerEnd,

                                            taskId: item.taskId,
                                            prevTaskId: item.prevTaskId,

                                            tagName: item.tag,
                                        })}>
                                            <i className={"fad fa-plus text-primary"} />
                                        </button>
                                    </Td>
                                </tr>
                            )
                        })}

                    </tbody>
                }
            />
        </div>
    )
}
