import React, { Fragment, useEffect, useState } from "react";
import { Components, NaeAuthWrapper, OpenApi } from '@newageerp/nae-react-auth-wrapper';
import { NaeApiFunctions } from "../../config/NaePaths";
import AppRouter from "../routes/wrappers/AppRouter";
import { Provider } from 'react-redux';
import { store } from '../../Components/Models/ormstore';
import '@newageerp/ui.styles.tailwind/dist/all.css'
import { RoutesWrapper } from "../routes/wrappers/RoutesWrapper";
import AuthLogin from "../auth/AuthLogin";
import UserSpaceWrapper from "../routes/wrappers/UserSpaceWrapper";
import AppRoutes from "../routes/AppRoutes";
import { UI, Hooks as UIHooks, UIConfig } from "@newageerp/nae-react-ui";
import InitComponent from "./InitComponent";
import { DataCacheProvider } from "../../Components/Hooks/DataCacheProvider";
import { Redirect, Switch } from "react-router-dom";
import CustomUserWrapperRoutes from "../_custom/routes/CustomUserWrapperRoutes";
import { PermissionProvider } from "../../Components/User/PermissionProvider";

function App() {
    const [isLoaded, setIsLoaded] = useState(false);

    OpenApi.naePaths = NaeApiFunctions;
    const isMount = UIHooks.useDidMount();

    useEffect(() => {
        if (isMount) {
            setIsLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (isLoaded) {
            UI.Socket.Service.connect();
        }
    }, [isLoaded]);

    return (
        <Provider store={store}>
            <PermissionProvider>
                <RoutesWrapper>
                    <AppRouter
                        authRoutes={
                            <Fragment>
                                <UI.UIBuilder.UIBuilderProvider>
                                    <InitComponent>
                                        <DataCacheProvider>
                                            <UI.Window.NaeWindowProvider>
                                                <UserSpaceWrapper>
                                                    <Switch>
                                                        <AppRoutes />
                                                    </Switch>
                                                    <Switch>
                                                        <CustomUserWrapperRoutes />
                                                    </Switch>
                                                </UserSpaceWrapper>
                                            </UI.Window.NaeWindowProvider>
                                        </DataCacheProvider>
                                    </InitComponent>
                                </UI.UIBuilder.UIBuilderProvider>
                            </Fragment>
                        }
                        noAuthRoutes={(isLoggedIn) => {
                            return (
                                <Fragment>
                                    <AuthLogin />
                                    {!isLoggedIn && <Redirect to='/login' />}
                                </Fragment>
                            )
                        }}
                    />
                </RoutesWrapper>
            </PermissionProvider>
        </Provider>
    );
}

export default App;
