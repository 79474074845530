import { useEffect } from "react";
import { NaeSSchemaMap } from "../../config/NaeSSchema";
import { useDataCache } from "./DataCacheProvider";
import { useSelector } from "react-redux";
import { SelectorImportedFileNae } from "../Models/ormSelectors";

interface ChildId {
  id: number
}

export interface IImportedFileModelNae {
    id: number,
scopes: any,
badges: any,

}

export const IImportedFileFieldsNae = [
    "id",
    "scopes",
    "badges"
];

export function useImportedFileHookNae(id: number) : IImportedFileModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorImportedFileNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.ImportedFile.schema,
        fields: IImportedFileFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
