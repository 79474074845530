import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import TruckGroupForm from "../../Forms/TruckGroupForm";
import { Card } from "react-bootstrap";
import { NaeLayout } from "nae-react-core-styles";
import ServiceGroupForm from "../../Forms/ServiceGroupForm";
import { toast } from "react-toastify";
import ServicesListCard from "./ServicesListCard";
import CommonRemoveBtn from "../../CommonRemoveBtn";
import ServiceMainWideFormDataSource from "../../../_generated/editforms/forms-data-source/ServiceMainFormDataSource";
import CountryMainFormDataSource from "../../../_generated/editforms/forms-data-source/CountryMainFormDataSource";
import InvoiceMainFormDataSource from "../../../_generated/editforms/forms-data-source/InvoiceMainFormDataSource";
import FuelCardForm from "../../Forms/FuelCardForm";
import FuelCardMainFormDataSource from "../../../_generated/editforms/forms-data-source/FuelCardMainFormDataSource";

const moduleName = "service-group";

interface ParamTypes {
  id: string;
}

const defElement = {
  id: 0,
  name: "",
};

export default function ServicesGroupPage() {
  const history = useHistory();

  const [element, setElement] = useState(defElement);
  const { id } = useParams<ParamTypes>();
  const saveData = (name: string) => {
    if (id === "new") {
      ApiRest.addElement(moduleName, { name }).then((res) => {
        toast.success("Įrašas išsaugotas");
        setElement(res);
        history.push("/" + moduleName + "/" + res.id);
      });
    } else {
      ApiRest.updateElement(moduleName, element.id, { name }).then((res) => {
        toast.success("Įrašas išsaugotas");
        setElement(res);
      });
    }
  };

  const getData = () => {
    if (id !== "new") {
      ApiRest.getElement(moduleName, parseInt(id, 10))
        .then((res) => {
          setElement(res);
        })
        .catch((e) => {});
    }
  };

  useEffect(getData, [id]);

  const form =
    element.id > 0 || id === "new" ? (
      <Fragment>
        <ServiceGroupForm element={element} saveData={saveData}/>
        {/* <CountryMainFormDataSource id={id} onSave={() => {}} onCancel={() => {}}/> */}
        {/* <InvoiceMainFormDataSource id={id} onSave={() => {}} onCancel={() => {}}/> */}
        {/* <FuelCardMainFormDataSource id={id} onSave={() => {}} onCancel={() => {}} /> */}
      </Fragment>
    ) : (
      <Fragment />
    );
  const rightComponent =
    element.id > 0 ? (
      <Card>
        <Card.Body>
          <CommonRemoveBtn
            id={element.id}
            module={moduleName}
            path={"/service-groups"}
          />
        </Card.Body>
      </Card>
    ) : (
      <Fragment />
    );

  return (
    <Fragment>
      <NaeLayout.NaePageViewLayout
        leftComponent={form}
        rightComponent={rightComponent}
      />

      {element.id > 0 && <ServicesListCard groupId={element.id} />}
    </Fragment>
  );
}
