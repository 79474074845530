import { INaeProperty, INaePropertyEnum } from "@newageerp/nae-react-ui/dist/interfaces";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "bookmark",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "bookmark",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "bookmark",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "bookmark",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "bookmark",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "bookmark",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "bookmark",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "bookmark",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "client",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "client",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "client",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "country",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "country",
        "key": "fvsCode",
        "type": "string",
        "format": "",
        "title": "FVS kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "country",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "country",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "country",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "driver",
        "key": "birthDate",
        "type": "string",
        "format": "date-time",
        "title": "Gimimo data",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "driver",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "Vardas",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "driver",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Vardas, pavard\u0117",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "driver",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "driver",
        "key": "inDate",
        "type": "string",
        "format": "date-time",
        "title": "Pri\u0117mimo data",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "driver",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Pavard\u0117",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "driver",
        "key": "outDate",
        "type": "string",
        "format": "date-time",
        "title": "Atleidimo data",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "driver-trip",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "driver-trip",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Klientas",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "driver-trip",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "driver-trip",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "driver-trip",
        "key": "dateFinish",
        "type": "string",
        "format": "date",
        "title": "Pabaigos data",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "driver-trip",
        "key": "dateStart",
        "type": "string",
        "format": "date",
        "title": "Prad\u017eios data",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "driver-trip",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Atsakingas",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "driver-trip",
        "key": "driver",
        "type": "rel",
        "format": "driver",
        "title": "Vairuotojas",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "driver-trip",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "driver-trip",
        "key": "refFinish",
        "type": "number",
        "format": "float",
        "title": "REF kelion\u0117s gale (l)",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "driver-trip",
        "key": "refStart",
        "type": "number",
        "format": "float",
        "title": "REF kelion\u0117s prad\u017eioje (l)",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "driver-trip",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "driver-trip",
        "key": "tankLeftCmFinish",
        "type": "number",
        "format": "float",
        "title": "K bakas pabaigoje (cm)",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "driver-trip",
        "key": "tankLeftCmStart",
        "type": "number",
        "format": "float",
        "title": "K bakas prad\u017eioje (cm)",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "driver-trip",
        "key": "tankRightCmFinish",
        "type": "number",
        "format": "float",
        "title": "D bakas pabaigoje (cm)",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "driver-trip",
        "key": "tankRightCmStart",
        "type": "number",
        "format": "float",
        "title": "D bakas prad\u017eioje (cm)",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "driver-trip",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Vilkikas",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "driver-trip",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "DriverTrip",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "follow-up",
        "key": "comment",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "follow-up",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "follow-up",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "follow-up",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "follow-up",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "follow-up",
        "key": "onDate",
        "type": "string",
        "format": "date",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "follow-up",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "follow-up",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "follow-up",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "follow-up",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "fuel-card",
        "key": "comment",
        "type": "string",
        "format": "",
        "title": "Komentaras",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "fuel-card",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Galiojimo data",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "fuel-card",
        "key": "history",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "fuel-card",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "fuel-card",
        "key": "number",
        "type": "string",
        "format": "",
        "title": "Kortel\u0117s nr.",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "fuel-card",
        "key": "pin",
        "type": "string",
        "format": "",
        "title": "Pin",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "fuel-card",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Statusas",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Aktyvi",
                "value": 10
            },
            {
                "label": "Neaktyvi",
                "value": 0
            }
        ]
    },
    {
        "schema": "fuel-card",
        "key": "supplier",
        "type": "string",
        "format": "",
        "title": "Tiek\u0117jas",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "fuel-card",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Vilkikas",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "fuel-card",
        "key": "truckGroup",
        "type": "rel",
        "format": "truck-group",
        "title": "Grup\u0117",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "fuel-card",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCard",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "fuel-card-history",
        "key": "change",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCardHistory",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "fuel-card-history",
        "key": "date",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCardHistory",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "fuel-card-history",
        "key": "fuelCard",
        "type": "rel",
        "format": "fuel-card",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCardHistory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "fuel-card-history",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCardHistory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "fuel-card-history",
        "key": "truckAfter",
        "type": "rel",
        "format": "truck",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCardHistory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "fuel-card-history",
        "key": "truckBefore",
        "type": "rel",
        "format": "truck",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FuelCardHistory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "imported-file",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Data",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "imported-file",
        "key": "filePath",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "imported-file",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "imported-file",
        "key": "invoiceLines",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "imported-file",
        "key": "invoices",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "imported-file",
        "key": "linesWithoutFvsCode",
        "type": "array",
        "format": "InvoiceLine",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "imported-file",
        "key": "size",
        "type": "integer",
        "format": "",
        "title": "Dydis",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "imported-file",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "Statusas",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "imported-file",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Tipas",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "imported-file",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ImportedFile",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice",
        "key": "clientCode",
        "type": "string",
        "format": "",
        "title": "Klientas",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "invoice",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "Valiuta",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "invoice",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Kursas",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Data",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "invoice",
        "key": "fvsType",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "invoice",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice",
        "key": "importedFile",
        "type": "rel",
        "format": "imported-file",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice",
        "key": "invoiceLines",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "invoice",
        "key": "linesCount",
        "type": "integer",
        "format": "",
        "title": "Eilu\u010di\u0173 kiekis",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice",
        "key": "serialNo",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "invoice",
        "key": "serialNoFormatted",
        "type": "string",
        "format": "",
        "title": "Numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "invoice",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "I\u0161 viso",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "I\u0161 viso, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "PVM",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice",
        "key": "totalVatEur",
        "type": "number",
        "format": "float",
        "title": "PVM, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "I\u0161 viso su PVM",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice",
        "key": "totalWithVatEur",
        "type": "number",
        "format": "float",
        "title": "I\u0161 viso su PVM, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Tipas",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "invoice",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice-line",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "invoice-line",
        "key": "date",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "invoice-line",
        "key": "dateTime",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "invoice-line",
        "key": "fuelCard",
        "type": "rel",
        "format": "fuel-card",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice-line",
        "key": "fvsVatCode",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "invoice-line",
        "key": "hasReports",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "invoice-line",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice-line",
        "key": "importedFile",
        "type": "rel",
        "format": "imported-file",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice-line",
        "key": "invoice",
        "type": "rel",
        "format": "invoice",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice-line",
        "key": "invoiceDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "invoice-line",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "priceEur",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "service",
        "type": "rel",
        "format": "service",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice-line",
        "key": "station",
        "type": "rel",
        "format": "station",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice-line",
        "key": "time",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "invoice-line",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "totalDiscount",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "totalDiscountEur",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "totalFee",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "totalFeeEur",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "totalVatEur",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "invoice-line",
        "key": "tripReference",
        "type": "string",
        "format": "",
        "title": "Get the value of tripReference",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "invoice-line",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "invoice-line",
        "key": "vatPercent",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "canAverageFuelUsage",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "canDistance",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "canFuelUsed",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "canOdometerValueEnd",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "canOdometerValueStart",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "date",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "driveTime",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "loc-tracker-task",
        "key": "gpsAverageFuelUsage",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "gpsDistance",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "loc-tracker-task",
        "key": "lat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "lng",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "loc-tracker-task",
        "key": "locationAddress",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "locationName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "logistComment",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "pathKey",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "prevTaskId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "loc-tracker-task",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "stopTime",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "loc-tracker-task",
        "key": "tagName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "taskId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "loc-tracker-task",
        "key": "timeComment",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "loc-tracker-task",
        "key": "timeCompleted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "bigint"
    },
    {
        "schema": "loc-tracker-task",
        "key": "timeStarted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "bigint"
    },
    {
        "schema": "loc-tracker-task",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LocTrackerTask",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "note",
        "key": "content",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "note",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "note",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "note",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "note",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "note",
        "key": "notify",
        "type": "array",
        "format": "number|string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json"
    },
    {
        "schema": "note",
        "key": "notifyAccept",
        "type": "array",
        "format": "number|string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json"
    },
    {
        "schema": "note",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "note",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "note",
        "key": "replyTo",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "note",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "note",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "service",
        "key": "fvsCode",
        "type": "string",
        "format": "",
        "title": "Finvaldos kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "service",
        "key": "fvsSeparateLine",
        "type": "boolean",
        "format": "",
        "title": "FVS kaip atskira eilut\u0117",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "service",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "service",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "service",
        "key": "reverseVat",
        "type": "boolean",
        "format": "",
        "title": "Atvirk\u0161tinis PVM",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "service",
        "key": "serviceGroup",
        "type": "rel",
        "format": "service-group",
        "title": "Grup\u0117",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "service",
        "key": "skipReports",
        "type": "boolean",
        "format": "",
        "title": "Nerodyti ataskaitoje",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "service",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "service-group",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ServiceGroup",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "service-group",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "ServiceGroup",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "service-group",
        "key": "services",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ServiceGroup",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "service-group",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ServiceGroup",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "station",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Station",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "station",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "\u0160alis",
        "additionalProperties": [],
        "description": "",
        "className": "Station",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "station",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Station",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "station",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Station",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "station",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Station",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "task",
        "key": "completed",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "task",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "task",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "U\u017eduotis",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "task",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Atsakingas",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "task",
        "key": "dueDate",
        "type": "string",
        "format": "datetime",
        "title": "Atlikti iki",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "task",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Failai",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple"
    },
    {
        "schema": "task",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "task",
        "key": "longTerm",
        "type": "integer",
        "format": "",
        "title": "Tipas",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "enum": [
            {
                "label": "Paprasta",
                "value": 0
            },
            {
                "label": "Ilgalaik\u0117",
                "value": 10
            }
        ]
    },
    {
        "schema": "task",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "task",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "task",
        "key": "relatedTask",
        "type": "rel",
        "format": "task",
        "title": "U\u017eduotis",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "task",
        "key": "repeatPeriod",
        "type": "integer",
        "format": "",
        "title": "Kartoti",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Nekartoti",
                "value": 0
            },
            {
                "label": "Kiekvien\u0105 dien\u0105",
                "value": 1
            }
        ]
    },
    {
        "schema": "task",
        "key": "taskCategory",
        "type": "rel",
        "format": "task-category",
        "title": "Kategorija",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "task-category",
        "key": "color",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [
            {
                "as": "color"
            }
        ],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "varchar",
        "as": "color"
    },
    {
        "schema": "task-category",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "task-category",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "trip",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "trip",
        "key": "autoRouteKm",
        "type": "number",
        "format": "float",
        "title": "KM pagal autoroute",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "autoRouteKmAllowedDiff",
        "type": "number",
        "format": "float",
        "title": "Nefiksuojama paklaida, KM",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "autoRouteKmWithAllowedDiff",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "trip",
        "key": "comment",
        "type": "string",
        "format": "",
        "title": "Komentaras",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "trip",
        "key": "dateFrom",
        "type": "string",
        "format": "date",
        "title": "Data nuo",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "trip",
        "key": "dateTo",
        "type": "string",
        "format": "date",
        "title": "Data iki",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "trip",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "trip",
        "key": "driver",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "trip",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "trip",
        "key": "invoiceDrivenKm",
        "type": "number",
        "format": "float",
        "title": "S\u0105skaita, KM",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "invoiceOtherCosts",
        "type": "number",
        "format": "float",
        "title": "S\u0105skaita, kitos i\u0161laidos",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "invoiceRoadCosts",
        "type": "number",
        "format": "float",
        "title": "S\u0105skaita, keliai, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "loctrackerDrivenKm",
        "type": "number",
        "format": "float",
        "title": "Loctracker, KM",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "loctrackerDrivenKmWithAllowedDiff",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "trip",
        "key": "number",
        "type": "string",
        "format": "",
        "title": "Numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "trip",
        "key": "otherCost",
        "type": "number",
        "format": "float",
        "title": "Kitos i\u0161laidos",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "otherCostAdr",
        "type": "number",
        "format": "float",
        "title": "Adr",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "otherCostDowntime",
        "type": "number",
        "format": "float",
        "title": "Kita",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "otherCostDriver",
        "type": "number",
        "format": "float",
        "title": "Extra driver",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "otherCostFrigo",
        "type": "number",
        "format": "float",
        "title": "Frigo",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "otherCostFrigoCost",
        "type": "number",
        "format": "float",
        "title": "FRIGO kaina",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "otherCostFrigoDays",
        "type": "number",
        "format": "float",
        "title": "FRIGO dienos",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "otherCostLoad",
        "type": "number",
        "format": "float",
        "title": "Load \/ Unload",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "reference",
        "type": "string",
        "format": "",
        "title": "Reference",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "trip",
        "key": "roadCost",
        "type": "number",
        "format": "float",
        "title": "Keliai",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "trip",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Statusas",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "int",
        "as": "status"
    },
    {
        "schema": "trip",
        "key": "statusComment",
        "type": "string",
        "format": "",
        "title": "KM skirtumo paai\u0161kinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "trip",
        "key": "trailer",
        "type": "string",
        "format": "",
        "title": "Priekaba",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "trip",
        "key": "trip",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "json"
    },
    {
        "schema": "trip",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Vilkikas",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "trip",
        "key": "truckDriver",
        "type": "rel",
        "format": "driver",
        "title": "Vairuotojas",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "trip",
        "key": "truckDriver2",
        "type": "rel",
        "format": "driver",
        "title": "Vairuotojas 2",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "trip",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "Vadybininkas",
        "additionalProperties": [],
        "description": "",
        "className": "Trip",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "truck",
        "key": "fuelTank",
        "type": "rel",
        "format": "truck-fuel-tank",
        "title": "Kuro bakas",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "leasingFrom",
        "type": "integer",
        "format": "",
        "title": "Lizingas nuo",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "leasingTo",
        "type": "integer",
        "format": "",
        "title": "Lizingas iki",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "leasingToDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "truck",
        "key": "locktrackerNumber",
        "type": "string",
        "format": "",
        "title": "LocTracker numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "truck",
        "key": "manufactureYear",
        "type": "integer",
        "format": "",
        "title": "Pagaminimo metai",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Rida",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Valstybinis numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "truck",
        "key": "purchaseDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "truck",
        "key": "truckGroup",
        "type": "rel",
        "format": "truck-group",
        "title": "Grup\u0117",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "truckTrailerGroup",
        "type": "rel",
        "format": "truck-trailer-group",
        "title": "Priekabos tipas",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck-fuel-tank",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckFuelTank",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "truck-fuel-tank",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckFuelTank",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck-fuel-tank",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "TruckFuelTank",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "truck-fuel-tank",
        "key": "tankLeftCapacity",
        "type": "number",
        "format": "float",
        "title": "K bakas litrai",
        "additionalProperties": [],
        "description": "",
        "className": "TruckFuelTank",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "truck-fuel-tank",
        "key": "tankLeftCml",
        "type": "number",
        "format": "float",
        "title": "K bakas 1 cm \/ l",
        "additionalProperties": [],
        "description": "",
        "className": "TruckFuelTank",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "truck-fuel-tank",
        "key": "tankRightCapacity",
        "type": "number",
        "format": "float",
        "title": "R bakas litrai",
        "additionalProperties": [],
        "description": "",
        "className": "TruckFuelTank",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "truck-fuel-tank",
        "key": "tankRightCml",
        "type": "number",
        "format": "float",
        "title": "R bakas l \/ cm",
        "additionalProperties": [],
        "description": "",
        "className": "TruckFuelTank",
        "isDb": true,
        "dbType": "double"
    },
    {
        "schema": "truck-fuel-tank",
        "key": "trucks",
        "type": "array",
        "format": "truck",
        "title": "Get the value of trucks",
        "additionalProperties": [
            {
                "mapped": "fuelTank"
            }
        ],
        "description": "",
        "className": "TruckFuelTank",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "truck-group",
        "key": "fuelCards",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckGroup",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "truck-group",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckGroup",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck-group",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "TruckGroup",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "truck-group",
        "key": "trucks",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckGroup",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "truck-group",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckGroup",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck-trailer-group",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckTrailerGroup",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "truck-trailer-group",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "TruckTrailerGroup",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "truck-trailer-group",
        "key": "trucks",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckTrailerGroup",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "truck-trailer-group",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckTrailerGroup",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "user",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "El. pa\u0161tas",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "Vardas",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Vardas, pavard\u0117",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Pavard\u0117",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "Prisijungimo vardas",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "Telefonas",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Pareigos",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "salt",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint"
    }
]