import React, {Fragment} from 'react';
import { Wide, WideToolbar, WideRow, Compact, CompactToolbar, CompactRow, Label } from '@newageerp/ui.form.base.form-pack';
import { useTranslation } from 'react-i18next'
import { Textarea } from "@newageerp/ui.form.base.form-pack";
import { TaskEnumsOptions } from "../../enums/view/TaskEnums";
import { SelectAdvId } from "@newageerp/ui.form.base.form-pack";
import { SelectAdvData } from "@newageerp/ui.form.base.form-pack";

import { FilePickerMultiple } from "@newageerp/ui.form.base.form-pack";

interface Props {
    element: any;
    onChange: (key: string, value: any) => void,
    compact?: boolean,

    onSave: () => void,
    onCancel: () => void,
}

export default function TaskMainForm(props: Props) {
    const { element, onChange } = props;
    const { t } = useTranslation();

    if (props.compact) {
        return (
            <Compact>
                <CompactRow label={<Label>{t('Užduotis')}</Label>} control={<Textarea onChange={(e: any) => onChange('description', e.target.value)} value={element.description}/>}/>
<CompactRow label={<Label>{t('Tipas')}</Label>} control={<SelectAdvId withIcon={true} options={TaskEnumsOptions['longTerm']} selectedId={element.longTerm} onSelectId={(e: any) => onChange('longTerm', e)} />}/>
<CompactRow label={<Label>{t('Kategorija')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="task-category"
                        field="name"
                        selectedId={element.taskCategory?.id}
                        onSelectId={(e: any) => onChange('taskCategory', {id: e})}
                        parentElement={element}
                        parentSchema={"task"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<CompactRow label={<Label>{t('Atlikti iki')}</Label>} control={<Fragment/>}/>
<CompactRow label={<Label>{t('Atsakingas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="user"
                        field="fullName"
                        selectedId={element.doer?.id}
                        onSelectId={(e: any) => onChange('doer', {id: e})}
                        parentElement={element}
                        parentSchema={"task"}
                        sort={[
    {
        "key": "i.fullName",
        "value": "ASC"
    }
]}
                        />}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Failai')}</Label>} control={<FilePickerMultiple width="tw3-w-full" val={element.files} onChange={(e: any) => onChange('files', e)}  folder={"task/files"}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Kartoti')}</Label>} control={<SelectAdvId withIcon={true} options={TaskEnumsOptions['repeatPeriod']} selectedId={element.repeatPeriod} onSelectId={(e: any) => onChange('repeatPeriod', e)} />}/>
                <CompactToolbar onSave={props.onSave} onCancel={props.onCancel} />
            </Compact>
        )
    }

    return (
        <Wide>
            <WideRow label={<Label>{t('Užduotis')}</Label>} control={<Textarea onChange={(e: any) => onChange('description', e.target.value)} value={element.description}/>}/>
<WideRow label={<Label>{t('Tipas')}</Label>} control={<SelectAdvId withIcon={true} options={TaskEnumsOptions['longTerm']} selectedId={element.longTerm} onSelectId={(e: any) => onChange('longTerm', e)} />}/>
<WideRow label={<Label>{t('Kategorija')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="task-category"
                        field="name"
                        selectedId={element.taskCategory?.id}
                        onSelectId={(e: any) => onChange('taskCategory', {id: e})}
                        parentElement={element}
                        parentSchema={"task"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<WideRow label={<Label>{t('Atlikti iki')}</Label>} control={<Fragment/>}/>
<WideRow label={<Label>{t('Atsakingas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="user"
                        field="fullName"
                        selectedId={element.doer?.id}
                        onSelectId={(e: any) => onChange('doer', {id: e})}
                        parentElement={element}
                        parentSchema={"task"}
                        sort={[
    {
        "key": "i.fullName",
        "value": "ASC"
    }
]}
                        />}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Failai')}</Label>} control={<FilePickerMultiple width="tw3-w-full" val={element.files} onChange={(e: any) => onChange('files', e)}  folder={"task/files"}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Kartoti')}</Label>} control={<SelectAdvId withIcon={true} options={TaskEnumsOptions['repeatPeriod']} selectedId={element.repeatPeriod} onSelectId={(e: any) => onChange('repeatPeriod', e)} />}/>
            <WideToolbar onSave={props.onSave} onCancel={props.onCancel} />
        </Wide>
    )
}