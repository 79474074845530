class ApiRestService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/';
    }

    makeRequest = (action: string) => {
        let url = this.baseUrl + action;

        const requestOptions = {
            ...this.authRequestOptions('GET'),
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    makeRequestPost = (action: string, data: any) => {
        let url = this.baseUrl + action;

        const requestOptions = {
            ...this.authRequestOptions('POST'),
            body: JSON.stringify(data)
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    authRequestOptions = (method: string = 'POST') => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    };

    addElement = (model: string, data: any) => {
        const url = this.baseUrl + model + '/';

        const requestOptions = {
            ...this.authRequestOptions('POST'),
            body: JSON.stringify(data),
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    getTrucksLocations = () => {
        let url = this.baseUrl + 'locator-public/getCarsData';


        const requestOptions = {
            ...this.authRequestOptions('GET'),
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    getElements = (model: string, extraData?: any) => {
        let url = this.baseUrl + model + '/';
        if (extraData) {
            url += '?extraData=' + JSON.stringify(extraData);
        }

        const requestOptions = {
            ...this.authRequestOptions('GET'),
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    getReport = (model: string, options: any) => {
        let url = this.baseUrl + model + '/report';

        const requestOptions = {
            ...this.authRequestOptions('POST'),
            body: JSON.stringify(options)
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    getTruckReports = (trucks: any, dateFrom: string, dateTo: string,) => {
        let url = this.baseUrl + 'loctracker/reportsForTrucks';

        const requestOptions = {
            ...this.authRequestOptions('POST'),
            body: JSON.stringify({trucks, dateFrom, dateTo})
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    exportReport = (model: string, options: any) => {
        let url = this.baseUrl + model + '/export';

        const requestOptions = {
            ...this.authRequestOptions('POST'),
            body: JSON.stringify(options)
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res)).then(res => {
            if (res.url) {
                window.open(this.baseUrl + res.url);
            }
        });
    }

    getElement = (model: string, id: number, extraData?: any) => {
        let url = this.baseUrl + model + '/' + id;
        if (extraData) {
            url += '?extraData=' + JSON.stringify(extraData);
        }

        const requestOptions = {
            ...this.authRequestOptions('GET'),
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    getElementRel = (model: string, id: number, relModel: string) => {
        const url = this.baseUrl + model + '/' + id + '/' + relModel;

        const requestOptions = {
            ...this.authRequestOptions('GET'),
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }


    updateElement = (model: string, id: number, data: any) => {
        const url = this.baseUrl + model + '/' + id;

        const requestOptions = {
            ...this.authRequestOptions('PUT'),
            body: JSON.stringify(data),
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    deleteElement = (model: string, id: number) => {
        const url = this.baseUrl + model + '/' + id;

        const requestOptions = {
            ...this.authRequestOptions('DELETE'),
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }

    _checkResponse = (res: any) => {
        return new Promise<any>((success, reject) => {
            if (!!res && res.success !== 0) {
                success(res);
            } else {
                reject(res.errors);
            }
        });
    }
}

const ApiRest = new ApiRestService();
export default ApiRest;
