import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import InvoiceLinesListCard from "./InvoiceLinesListCard";


export default function InvoiceLinesListPage() {

    return (
        <Container>
            <Row>
                <Col>
                    <InvoiceLinesListCard/>
                </Col>
            </Row>
        </Container>
    );
}