import React, { createContext, useContext, useEffect, useState } from 'react';
import ApiRest from "../../service/ApiRest";

interface PermissionContextType {
    permissions: string[];
    fetchPermissions: (id: number) => void;
    hasPermission: (permission: string) => boolean;
}

const PermissionContext = createContext<PermissionContextType | undefined>(undefined);

export const PermissionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [permissions, setPermissions] = useState<string[]>([]);
    const [userId, setUserId] = useState<number | null>(null);

    const fetchUserId = async () => {
        try {
            const response = await ApiRest.makeRequest('profile/get');
            setUserId(response.id);
            return response.id;
        } catch (error) {
            console.error("Failed to fetch user ID", error);
        }
    };

    const fetchPermissions = async (id: number) => {
        try {
            const response = await ApiRest.getElements(`permissions/user/${id}`);
            setPermissions(response.map((p: any) => p.permissionName));
        } catch (error) {
            console.error("Failed to fetch permissions", error);
        }
    };

    useEffect(() => {
        const initializePermissions = async () => {
            const id = await fetchUserId();
            if (id) {
                await fetchPermissions(id);
            }
        };
        initializePermissions();
    }, []);

    const hasPermission = (permission: string) => {
        return permissions.includes(permission);
    };

    return (
        <PermissionContext.Provider value={{ permissions, fetchPermissions, hasPermission }}>
            {children}
        </PermissionContext.Provider>
    );
};

export const usePermissions = () => {
    const context = useContext(PermissionContext);
    if (!context) {
        throw new Error('usePermissions must be used within a PermissionProvider');
    }
    return context;
};
