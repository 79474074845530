import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {Truck, Tablet} from "./TruckTypes";  // Replace types
import ApiRest from "../../../service/ApiRest";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import TableWithPaging from "../../TableWithPaging";
import { NaeTable } from "nae-react-core-styles";
import moment from "moment";
import DateField from "../../Forms/fields/DateField";
import { UI } from "@newageerp/nae-react-ui";
const moduleName = 'tablet';

export default function TabletsListPage() {
    const history = useHistory();

    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = () => setShowFilter(!showFilter);

    const [trucks, setTrucks] = useState([]);

    const [searchElement, setSearchElement] = useState<any>({});

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [tablets, setTablets] = useState<Tablet[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [dataToRender, setDataToRender] = useState<Tablet[]>([]); 

    const [filter, setFilter] = useState('');

    // Data fetching for tablets
    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setTablets(res);
        })
    }
    useEffect(getData, []);

    // Here you might have fetching logic for other lists you want to display or filter by.
    // ... (keep it the same as getListData, just replace the data specifics)
    const getListData = () => {
        ApiRest.getElements('truck').then(res => {
            if (res) {
                setTrucks(res.filter((t: Truck) => !!t.locktrackerNumber || t.name.indexOf('MAGNUM') >= 0));
            }
        });
    }
    useEffect(getListData, []);

    // Filtering logic

    const filterData = () => {
      
        if (readyToLoad) {
          let _data = tablets;
      
          // Global Search
          if (search) {
            const searchStr = search.toLowerCase();
          
            _data = _data.filter((item: Tablet) => {
              return (
                String(item.number).toLowerCase().indexOf(searchStr) > -1 ||
                String(item.model).toLowerCase().indexOf(searchStr) > -1 ||
                String(item.truckName).toLowerCase().indexOf(searchStr) > -1 ||
                String(item.date).toLowerCase().indexOf(searchStr) > -1 ||
                String(item.comment).toLowerCase().indexOf(searchStr) > -1 ||
                String(item.historyText ? item.historyText : "").toLowerCase().indexOf(searchStr) > -1 // Handle possible null or undefined
              );
            });
          }
          
      
      
          // Advanced Search Element Filtering
          if (searchElement) {

            // Number filter
            if (searchElement.number) {
              _data = _data.filter((item: Tablet) =>
                item.number.toLowerCase().includes(searchElement.number.toLowerCase())
              );
            }

            // Model filter
            if (searchElement.model) {
                _data = _data.filter((item: Tablet) =>
                  item.model.toLowerCase().includes(searchElement.model.toLowerCase())
                );
              }
      
            // Truck filter
            if (searchElement.truck && searchElement.truck > 0) {
                _data = _data.filter((item: Tablet) => {
                    return item.truck == searchElement.truck;
                });
            }
      
            // Date filters
            if (searchElement.dateFrom || searchElement.dateTo) {
              _data = _data.filter((item: Tablet) =>
                (!searchElement.dateFrom || moment(item.date).isSameOrAfter(moment(searchElement.dateFrom))) &&
                (!searchElement.dateTo || moment(item.date).isSameOrBefore(moment(searchElement.dateTo)))
              );
            }
      
            // Status filter
            let _status = searchElement.status || filter;
            if (_status) {
                if (_status == '5' || _status == 'no_active') {
                    _data = _data.filter((item: Tablet) => item.status === 0);
                }
                if (_status == '10' || _status == 'active') {
                    _data = _data.filter((item: Tablet) => item.status === 10);
                }
            }
            // Comment filter
            if (searchElement.comment) {
                _data = _data.filter((item: Tablet) => {
                    return item.comment.toLowerCase().indexOf(searchElement.comment.toLowerCase()) > -1 ||
                    (item.historyText ? item.historyText : "").toLowerCase().indexOf(searchElement.comment.toLowerCase()) > -1
                });
            }
          }
      
          setDataToRender(_data);
        }
      };
      


    useEffect(filterData, [search, activePage, tablets, filter, readyToLoad, searchElement]);

    // ... (The rest of the useEffects remain largely the same, just replace Tablets with tablets where needed)
    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter', 'searchElement']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                } else if (p === 'searchElement') {
                    setSearchElement(v ? v : {});
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);
    // Your navigation logic
    const goTo = (item: Tablet) => {
        history.replace('/tablets', { search, activePage, filter, searchElement });
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    // Append to search state
    const appendSearch = (key: string, val: string) => {
        setSearchElement({ ...searchElement, [key]: val });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"card-table"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Planšetės
                                </Col>
                                <Col className={"text-right"}>
                                    <UI.Buttons.Button icon={"fal fa-fw fa-plus"} onClick={goToNew}>
                                        Nauja
                                    </UI.Buttons.Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Header className={"search"}>
                            <Row>
                                <Col className={"v-center"}>
                                    <input placeholder={"Paieška"} className={"search-input"} value={search}
                                        onChange={(e) => setSearch(e.target.value)} />
                                </Col>
                                <Col sm={"auto"}>
                                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.nsecondary} onClick={toggleFilter}>Filtras</UI.Buttons.Button>
                                </Col>
                            </Row>
                            {showFilter && <Fragment>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Numeris</Form.Label>
                                            <Form.Control value={searchElement.number ? searchElement.number : ''}
                                                onChange={e => appendSearch('number', e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                    <Form.Group>
                                            <Form.Label>Modelis</Form.Label>
                                            <Form.Control value={searchElement.model ? searchElement.model: ''}
                                                onChange={e => appendSearch('model', e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Vilkikas</Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mr-sm-2"
                                                value={searchElement.truck ? searchElement.truck : ''}
                                                onChange={(e) => appendSearch('truck', e.target.value)}
                                            >
                                                <option value="0">Pasirinkite...</option>
                                                {trucks.map((truck: Truck) => <option key={'opt-g' + truck.id}
                                                    value={truck.id}>{truck.name}</option>)}

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>pirkimo data nuo</Form.Label>
                                            <DateField value={searchElement.dateFrom ? searchElement.dateFrom : ''}
                                                setValue={(val) => appendSearch('dateFrom', val)} />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>pirkimo data iki</Form.Label>
                                            <DateField value={searchElement.dateTo ? searchElement.dateTo : ''}
                                                setValue={(val) => appendSearch('dateTo', val)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Statusas</Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mr-sm-2"
                                                value={searchElement.status ? searchElement.status : ''}
                                                onChange={(e) => appendSearch('status', e.target.value)}
                                            >
                                                <option value="">Visos</option>
                                                <option value="5">Neaktyvios</option>
                                                <option value="10">Aktyvios</option>

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Komentaras</Form.Label>
                                            <Form.Control
                                                value={searchElement.comment ? searchElement.comment : ''}
                                                onChange={e => appendSearch('comment', e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Fragment>}

                        </Card.Header>
                        <TableWithPaging
                            activePage={activePage}
                            setActivePage={setActivePage}
                            dataToRender={dataToRender}
                            head={
                                <tr>
                                    <NaeTable.th id={true}>ID</NaeTable.th>
                                    <NaeTable.th>Numeris</NaeTable.th>
                                    <NaeTable.th>Modelis</NaeTable.th>
                                    <NaeTable.th>Vilkikas</NaeTable.th>
                                    <NaeTable.th>Pirkimo Data</NaeTable.th>
                                    <NaeTable.th>Statusas</NaeTable.th>
                                </tr>}
                                
                            renderItem={
                                (item: Tablet) => {
                                    return (
                                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                            <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                            <NaeTable.td link={true}>{item.number}</NaeTable.td>
                                            <NaeTable.td>{item.model}</NaeTable.td>
                                            <NaeTable.td>{item.truckName}</NaeTable.td>
                                            <NaeTable.td>{item.date}</NaeTable.td>
                                            <NaeTable.td>{item.status === 10 ? "Aktyvios" : "Neaktyvios"}</NaeTable.td>
                                        </tr>
                                    )
                                }
                            }
                        />

                    </Card>


                </Col>
            </Row>
        </Container>
    );
}
