import React, {Fragment} from 'react';
import { Wide, WideToolbar, WideRow, Compact, CompactToolbar, CompactRow, Label } from '@newageerp/ui.form.base.form-pack';
import { useTranslation } from 'react-i18next'
import { Input } from "@newageerp/ui.form.base.form-pack";

interface Props {
    element: any;
    onChange: (key: string, value: any) => void,
    compact?: boolean,

    onSave: () => void,
    onCancel: () => void,
}

export default function CountryMainForm(props: Props) {
    const { element, onChange } = props;
    const { t } = useTranslation();

    if (props.compact) {
        return (
            <Compact>
                <CompactRow label={<Label>{t('Pavadinimas')}</Label>} control={<Input onChange={(e: any) => onChange('name', e.target.value)} value={element.name}/>}/>
<CompactRow label={<Label>{t('Kodas')}</Label>} control={<Input onChange={(e: any) => onChange('code', e.target.value)} value={element.code}/>}/>
<CompactRow label={<Label>{t('FVS kodas')}</Label>} control={<Input onChange={(e: any) => onChange('fvsCode', e.target.value)} value={element.fvsCode}/>}/>
                <CompactToolbar onSave={props.onSave} onCancel={props.onCancel} />
            </Compact>
        )
    }

    return (
        <Wide>
            <WideRow label={<Label>{t('Pavadinimas')}</Label>} control={<Input onChange={(e: any) => onChange('name', e.target.value)} value={element.name}/>}/>
<WideRow label={<Label>{t('Kodas')}</Label>} control={<Input onChange={(e: any) => onChange('code', e.target.value)} value={element.code}/>}/>
<WideRow label={<Label>{t('FVS kodas')}</Label>} control={<Input onChange={(e: any) => onChange('fvsCode', e.target.value)} value={element.fvsCode}/>}/>
            <WideToolbar onSave={props.onSave} onCancel={props.onCancel} />
        </Wide>
    )
}