import React, { useEffect, useState, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ServiceGroupMainTable from "../tables/ServiceGroupMainTable"
import { OpenApi } from '@newageerp/nae-react-auth-wrapper';
import { functions, UI } from '@newageerp/nae-react-ui';
import { PageContainer, PageContainerMarginTop } from '@newageerp/ui.paging.base.page-container'
import { SortingItem } from '@newageerp/ui.components.list.sort-controller';
import { useHistory } from 'react-router-dom'
import DefaultSearchToolbar from "../DefaultSearchToolbar";
import {TypeItemFilters} from '@newageerp/ui.components.list.filter-container'
import { LayoutToolbar } from "@newageerp/ui.layout.base.layout-toolbar";
import { Toolbar } from "@newageerp/ui.layout.base.toolbar";
import { ToolbarTitle } from "@newageerp/ui.texts.base.toolbar-title";
import { useRecoilState } from "recoil";
import { UserSpaceWrapperToolbarState } from "../../layout/toolbar/UserSpaceWrapperToolbar";


interface Props {
    navigate?: (schema: string, id: number | string, el: any) => void,
    dataProcessing?: (data: any[]) => any[],
    reloadState?: any,
    extraFilter?: any,
    disableScrollToHeader?: boolean,
    disableCreate?: boolean,
    disableSetTitle?: boolean,
}

export default function ServiceGroupMainTableDataSource(props: Props) {
    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)

    const history = useHistory()

    const onAddExtraFilter = (key: string, data: any) => {
        const _extraFilter = JSON.parse(JSON.stringify(dataState.extraFilter));
        _extraFilter[key] = data;

        if (_extraFilter !== dataState.extraFilter) {
            const _state = JSON.parse(JSON.stringify(dataState));
            _state.page = 1;
            _state.extraFilter = _extraFilter;
            setDateState(_state);
        }
    }

    const otherTabs = null;

    const exports = null;

    const headerRef = useRef(null)
    const scrollToHeader = () => {
        if (headerRef && headerRef.current && !props.disableScrollToHeader) {
            // @ts-ignore
            headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    const pageSize = 20;
    const sort = [{"key":"i.id","value":"DESC"}];

    const defaultState : any = {
        page: 1,
        sort: sort,
        quickSearch: '',
        detailedSearch: [],
        extraFilter: {},
    }

    const isCreatable = true && !props.disableCreate;

    const quickSearchFields : any = [];
    const predefinedFilter : any = null;

    const [dataState, setDateState] = useState(defaultState);

    const setActivePage = (page: number) => {
        if (page !== dataState.page) {
            const _state = JSON.parse(JSON.stringify(dataState));
            _state.page = page;
            setDateState(_state);
        }
    }

    const setQuickSearch = (s: string) => {
        if (s !== dataState.quickSearch) {
            const _state = JSON.parse(JSON.stringify(dataState));
            _state.page = 1;
            _state.quickSearch = s;
            setDateState(_state);
        }
    }

    const setSort = (sort: SortingItem[]) => {
        if (sort !== dataState.sort) {
            const _state = JSON.parse(JSON.stringify(dataState));
            _state.page = 1;
            _state.sort = sort;
            setDateState(_state);
        }
    }

    const setDetailedSearch = (detailedSearch: TypeItemFilters) => {
        if (detailedSearch !== dataState.detailedSearch) {
            const _state = JSON.parse(JSON.stringify(dataState));
            _state.page = 1;
            _state.detailedSearch = detailedSearch;
            setDateState(_state);
        }
    }

    const tab = functions.tabs.getTabFromSchemaAndType('service-group', 'main');
    const fieldsToReturn = functions.tabs.getTabFieldsToReturn(tab);

    const [getData, dataResult] = OpenApi.useUList('service-group', fieldsToReturn);
    const { t } = useTranslation();

    const navigate = (schema: string, id: number | string, el: any) => {
        if (props.navigate) {
            props.navigate(schema, id, el);
        } else {
            history.push(`/u/${schema}/main/view/${id}`);
        }
    }

    const navigateToTab = (type: string) => {
        history.push(`/u/service-group/${type}/list`)
    }

    const onCreate = () => {
        history.push('/u/service-group/main/edit/new')
    }

    const loadData = () => {
        let _filter: any = [{ and: [] }]
        if (!!predefinedFilter) {
            _filter[0]['and'].push(predefinedFilter);
        }

        if (quickSearchFields && !!dataState.quickSearch) {
            let _or: any = quickSearchFields.map((s: any) => {
                if (typeof s === 'string') {
                    return [s, 'contains', dataState.quickSearch, true]
                }
                return [
                    s.key,
                    s.method ? s.method : 'contains',
                    dataState.quickSearch,
                    s.directSelect
                ]
            })
            _filter[0]['and'].push({ or: _or })
        }

        let filter = _filter[0]['and'].length > 0 ? _filter : []

        if (!!dataState.detailedSearch && dataState.detailedSearch.length > 0) {
            dataState.detailedSearch.forEach((_f: any) => {
                const orFilter : any = {"or": []}
                _f.filter.forEach((_subF: any) => {
                    const andFilter : any = {"and": []}
                    _subF.items.forEach((itemFilter: any) => {
                        andFilter.and.push(
                            [itemFilter.selectedFilter.id, itemFilter.selectedComparison.value, itemFilter.filterValue]
                        )
                    })
                    orFilter.or.push(andFilter);
                })
                filter.push(orFilter);
            })
        }

        if (!!dataState.extraFilter) {
            const keys = Object.keys(dataState.extraFilter);
            keys.forEach((k) => {
                const fV = dataState.extraFilter[k];
                if (!!fV) {
                    filter.push(fV);
                }
            })
        }

        if (!!props.extraFilter) {
            filter.push(props.extraFilter);
        }

        getData(
            filter,
            dataState.page,
            pageSize,
            dataState.sort.filter((s: SortingItem) => s.key !== ''),
        );
    }
    useEffect(loadData, [dataState, props.extraFilter]);

    useEffect(scrollToHeader, [dataResult.data.data]);

    const records = dataResult.data.records;
    const pages = Math.ceil(records / pageSize);

    const dataProcessing = (data: any[]) => {
        if (props.dataProcessing) {
            return props.dataProcessing(data);
        }
        return data;
    }

    const dataToRender = dataProcessing(dataResult.data.data);

    useEffect(() => {
        if (!props.disableSetTitle) {
            setToolbarTitle('Paslaugų grupės');
        }
    }, []);

    return (
<Fragment>
        <div ref={headerRef} className="py-8">
            <DefaultSearchToolbar
                    onCreate={isCreatable?onCreate:undefined}
                    export={!!exports?{
                        schema: 'service-group',
                        requestFunc: OpenApi.useURequest,
                        exports: exports
                        }: undefined
                    }
                    search={
                        !!quickSearchFields?
                        {
                            searchValue: dataState.quickSearch,
                            onSearch: setQuickSearch
                        }:
                        undefined
                    }
                    sort={
                        !!sort?
                        {
                            onSort: setSort,
                            sort: dataState.sort,
                            defaultSort: sort,
                            schema: 'service-group',
                        }
                        :
                        undefined
                    }
                    extendedSearch={
                        1 === 1?
                        {
                            search: dataState.detailedSearch,
                            onSearch: setDetailedSearch,
                            schema: 'service-group'
                        }:undefined
                    }
                    tabSwitch={
                        otherTabs?
                        {
                            options: otherTabs,
                            currentTab: 'main',
                            onTabSwitch: navigateToTab
                        }:
                        undefined
                    }
                                                                            />
            <ServiceGroupMainTable
                    data={dataToRender}
                    doReload={loadData}
                    className={`${dataResult.loading?'tw3-animate-pulse':''}`}
                    navigate={navigate}
            />
            <PageContainer pages={pages} activePage={dataState.page} setActivePage={setActivePage} marginTop={PageContainerMarginTop['42px']} />
        </div>
</Fragment>
    )
}