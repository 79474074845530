import React, {Fragment, useEffect, useState} from "react";
import {Card, Table, Button, Spinner} from "react-bootstrap";
import {Invoice, InvoiceLine, InvoiceLineReport} from "../InvoiceTypes";
import ApiRest from "../../../../service/ApiRest";
import ApiInvoice from "../../../../service/ApiInvoice";

interface Props {
    element: Invoice,
}

export default function InvoiceReport(props: Props) {
    const [data, setData] = useState<InvoiceLineReport[]>([]);
    const {element} = props;
    const getData = () => {
        if (element.id > 0) {
            ApiRest.getElementRel('invoice', element.id, 'report').then(res => {
                if (res) {
                    setData(res);
                }
            });
        }
    }

    useEffect(getData, [element]);

    const doExport = () => {
        ApiInvoice.exportInvoice(element.id);
    }

    return (
        <Card className={"card-table"}>
            <Card.Header>
                Eksportui
            </Card.Header>
            <Card.Body>
                {data.length > 0 ?
                    <Table striped={true} bordered={true}>
                        <thead>
                        <tr>
                            <th scope={"col"}>#</th>
                            <th scope={"col"}>Paslaugos kodas</th>
                            <th scope={"col"}>PVM kodas</th>
                            <th scope={"col"}>Objektas 1</th>
                            <th scope={"col"}>Objektas 2</th>

                            <th scope={"col"} className={"text-right"}>Kiekis</th>
                            <th scope={"col"} className={"text-right"}>Suma, EUR</th>
                            <th scope={"col"} className={"text-right"}>PVM Suma, EUR</th>
                            <th scope={"col"} className={"text-right"}>Suma su PVM, EUR</th>

                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item: InvoiceLineReport, index: number) => {
                            return (
                                <tr key={"r-line-" + index}>
                                    <td>{index + 1}</td>
                                    <td>{item.fvsCode}</td>
                                    <td>{item.vatCode}</td>
                                    <td>{item.objektas1}</td>
                                    <td>{item.objektas2}</td>

                                    <td className={"text-right"}>{item.quantity.toFixed(2)}</td>

                                    <td className={"text-right"}>{item.totalEur.toFixed(2)}</td>
                                    <td className={"text-right"}>{item.totalVat.toFixed(2)}</td>
                                    <td className={"text-right"}>{item.totalWVatEur.toFixed(2)}</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </Table>
                    :
                    <Spinner animation="border" variant="info"/>
                }
            </Card.Body>
            <Card.Footer>
                <Button variant={"primary"} onClick={doExport}>Eksportuoti</Button>
            </Card.Footer>
        </Card>
    )
}
