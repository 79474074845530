import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getSelectorForSchema } from "../../../UserComponents/ModelsCacheData/ModelFields";

interface Props {
  selected: any;
  setSelected: (val: any) => void;
}

export default function NotesContentMembers(props: Props) {
  const { selected, setSelected } = props;

  const usersSelector = getSelectorForSchema('user')
  const users : any[] = useSelector((state) => usersSelector(state))

  return (
    <Select
      placeholder={"Informuoti..."}
      options={users.filter(u => u.disabled === false).sort((object1, object2) => {
        const key = 'fullName';
        const obj1 = object1[key].toUpperCase()
        const obj2 = object2[key].toUpperCase()

        if (obj1 < obj2) {
          return -1
        }
        if (obj1 > obj2) {
          return 1
        }
        return 0
      }).map((u: any) => ({
        value: u.id,
        label: u.fullName,
      }))}
      className={["form-control", "form-select"].join(" ")}
      isMulti={true}
      value={selected}
      onChange={setSelected}
    />
  );
}
