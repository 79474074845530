import { INaeStatus } from "@newageerp/nae-react-ui/dist/interfaces";
export const NaeSStatuses: INaeStatus[] = [
    {
        "type": "status",
        "status": 0,
        "text": "Aktyvus",
        "bgColor": "blue",
        "brightness": 200,
        "schema": "trip"
    },
    {
        "type": "status",
        "status": 3,
        "text": "Gr\u0105\u017eintas tikrinimui",
        "bgColor": "red",
        "brightness": 500,
        "schema": "trip"
    },
    {
        "type": "status",
        "status": 5,
        "text": "U\u017ebaigtas vadybininko",
        "bgColor": "indigo",
        "brightness": 500,
        "schema": "trip"
    },
    {
        "type": "status",
        "status": 10,
        "text": "U\u017ebaigtas",
        "bgColor": "green",
        "brightness": 500,
        "schema": "trip"
    }
]