import React from "react";
import { UI } from '@newageerp/nae-react-ui';

export const TaskEnumsColors: any = {
    "longTerm": {
        "0": "slate",
        "10": "cyanSolid"
    },
    "repeatPeriod": [
        "slate",
        "slate"
    ]
};

export const TaskEnumsOptions: any = {
    "longTerm": [
        {
            "value": "0",
            "label": "Paprasta"
        },
        {
            "value": "10",
            "label": "Ilgalaikė"
        }
    ],
    "repeatPeriod": [
        {
            "value": "0",
            "label": "Nekartoti"
        },
        {
            "value": "1",
            "label": "Kiekvieną dieną"
        }
    ]
};

export const TaskEnums: any = {
    "longTerm": {
        "0": "Paprasta",
        "10": "Ilgalaikė"
    },
    "repeatPeriod": [
        "Nekartoti",
        "Kiekvieną dieną"
    ]
};

export const getTaskEnums = (type: string, value: any) => {
    return !!TaskEnums[type] && !!TaskEnums[type][value]?TaskEnums[type][value]:"";
}

export const getTaskEnumsColors = (type: string, value: any) => {
    return !!TaskEnumsColors[type] && !!TaskEnumsColors[type][value]?TaskEnumsColors[type][value]:"";
}