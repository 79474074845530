import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import UsersListPage from '../../../Components/Pages/Common/UsersListPage'
import UsersPage from '../../../Components/Pages/Common/UsersPage'
import InvoiceLinePage from '../../../Components/Pages/Invoices/InvoiceLinePage'
import InvoiceLinesListPage from '../../../Components/Pages/Invoices/InvoiceLinesListPage'
import InvoicePage from '../../../Components/Pages/Invoices/InvoicePage'
import InvoicesListPage from '../../../Components/Pages/Invoices/InvoicesListPage'
import InvoicesSalesListPage from '../../../Components/Pages/Invoices/InvoicesSalesListPage'
import ReportsAdbluePage from '../../../Components/Pages/Reports/ReportsAdbluePage'
import ReportsFeePage from '../../../Components/Pages/Reports/ReportsFeePage'
import ReportsFuelPage from '../../../Components/Pages/Reports/ReportsFuelPage'
import ReportsPage from '../../../Components/Pages/Reports/ReportsPage'
import TripListPage from '../../../Components/Pages/Sales/TripListPage'
import TripListPageReports from '../../../Components/Pages/Sales/TripListPageReports'
import TripPage from '../../../Components/Pages/Sales/TripPage'
import TripRoutesErrorReports from '../../../Components/Pages/Sales/TripRoutesErrorReports'
import TripRoutesFrigoReports from '../../../Components/Pages/Sales/TripRoutesFrigoReports'
import TripRoutesFuelKmReports from '../../../Components/Pages/Sales/TripRoutesFuelKmReports'
import CountriesListPage from '../../../Components/Pages/Services/CountriesListPage'
import CountriesPage from '../../../Components/Pages/Services/CountriesPage'
import ServicesGroupListPage from '../../../Components/Pages/Services/ServicesGroupListPage'
import ServicesGroupPage from '../../../Components/Pages/Services/ServicesGroupPage'
import ServicesListPage from '../../../Components/Pages/Services/ServicesListPage'
import ServicesPage from '../../../Components/Pages/Services/ServicesPage'
import StationsListPage from '../../../Components/Pages/Services/StationsListPage'
import StationsPage from '../../../Components/Pages/Services/StationsPage'
import DriversListPage from '../../../Components/Pages/Trucks/DriversListPage'
import DriversPage from '../../../Components/Pages/Trucks/DriversPage'
import FuelCardsListPage from '../../../Components/Pages/Trucks/FuelCardsListPage'
import FuelCardsPage from '../../../Components/Pages/Trucks/FuelCardsPage'
import TrucksGroupListPage from '../../../Components/Pages/Trucks/TrucksGroupListPage'
import TrucksGroupPage from '../../../Components/Pages/Trucks/TrucksGroupPage'
import TrucksListPage from '../../../Components/Pages/Trucks/TrucksListPage'
import TrucksLocationsPage from '../../../Components/Pages/Trucks/TrucksLocationsPage'
import TrucksPage from '../../../Components/Pages/Trucks/TrucksPage'
import TrucksTrailerListPage from '../../../Components/Pages/Trucks/TrucksTrailerListPage'
import TrucksTrailerPage from '../../../Components/Pages/Trucks/TrucksTrailerPage'
import UploadPage from '../../../Components/Pages/Upload/UploadPage'
import UploadsPage from '../../../Components/Pages/Upload/UploadsPage'
import InvoiceToRemoveTableDataSource from '../../tabs/tables-data-source/InvoiceToRemoveTableDataSource'
import TabletsListPage from '../../../Components/Pages/Trucks/TabletsListPage'
import TabletsPage from '../../../Components/Pages/Trucks/TabletsPage'
import "nae-react-core-styles/dist/index.css";
import UploadReportPage from '../../../Components/Pages/Upload/UploadReportPage'
import TasksListPage from '../../../Components/Pages/Services/TasksListPage'
import DriverReportsPage from '../../../Components/Pages/Trucks/DriverReportsPage'


export default function CustomUserWrapperRoutes() {
  return (
    <Fragment>
      {/* UPLOAD */}
      <Route path={"/upload"}>
        <UploadPage />
      </Route>
      <Route path={"/imported-file/:id"}>
        <UploadsPage />
      </Route>
      <Route path={"/upload-report"}>
        <UploadReportPage />
      </Route>

      {/* REPORTS */}
      <Route path={"/reports"}>
        <ReportsPage />
      </Route>
      <Route path={"/reports-fee"}>
        <ReportsFeePage />
      </Route>

      {/* INVOICES */}
      <Route path={"/invoices"}>
        <InvoicesListPage />
      </Route>

      <Route path={"/invoices-to-remove"}>
        <InvoiceToRemoveTableDataSource />
      </Route>

      <Route path={"/invoices-trips"}>
        <InvoicesSalesListPage />
      </Route>
      <Route path={"/invoice/:id"}>
        <InvoicePage />
      </Route>

      <Route path={"/invoice-lines"}>
        <InvoiceLinesListPage />
      </Route>
      <Route path={"/invoice-line/:id"}>
        <InvoiceLinePage />
      </Route>

      {/* TRUCKS */}
      <Route path={"/trucks-locations"}>
        <TrucksLocationsPage />
      </Route>

      <Route path={"/trucks"}>
        <TrucksListPage />
      </Route>
      <Route path={"/truck/:id"}>
        <TrucksPage />
      </Route>

      <Route path={"/fuel-cards"}>
        <FuelCardsListPage />
      </Route>
      <Route path={"/fuel-card/:id"}>
        <FuelCardsPage />
      </Route>

      <Route path={"/tablets"}>
        <TabletsListPage />
      </Route>
      <Route path={"/tablet/:id"}>
        <TabletsPage />
      </Route>

      <Route path={"/truck-groups"}>
        <TrucksGroupListPage />
      </Route>
      <Route path={"/truck-group/:id"}>
        <TrucksGroupPage />
      </Route>

      <Route path={"/truck-trailers"}>
        <TrucksTrailerListPage />
      </Route>
      <Route path={"/truck-trailer/:id"}>
        <TrucksTrailerPage />
      </Route>

      {/* SERVICES */}
      <Route path={"/services"}>
        <ServicesListPage />
      </Route>
      <Route path={"/service/:id"}>
        <ServicesPage />
      </Route>

      <Route path={"/service-groups"}>
        <ServicesGroupListPage />
      </Route>
      <Route path={"/service-group/:id"}>
        <ServicesGroupPage />
      </Route>

      <Route path={"/countries"}>
        <CountriesListPage />
      </Route>
      <Route path={"/country/:id"}>
        <CountriesPage />
      </Route>

      <Route path={"/stations"}>
        <StationsListPage />
      </Route>
      <Route path={"/station/:id"}>
        <StationsPage />
      </Route>
      <Route path={"/tasks-list"}>
        <TasksListPage />
      </Route>

      {/* SALES */}
      <Route path={"/trips"}>
        <TripListPage />
      </Route>
      <Route path={"/trips-reports"}>
        <TripListPageReports />
      </Route>

      <Route path={"/trip/:id"}>
        <TripPage />
      </Route>

      <Route path={"/trips-reports-km"}>
        <TripRoutesErrorReports />
      </Route>
      <Route path={"/driver-reports"}>
        <DriverReportsPage />
      </Route>
      <Route path={"/trips-reports-frigo"}>
        <TripRoutesFrigoReports />
      </Route>
      <Route path={"/reports-adblue"}>
        <ReportsAdbluePage />
      </Route>
      <Route path={"/reports-fuel"}>
        <ReportsFuelPage />
      </Route>
      <Route path={"/trips-reports-fuel-km"}>
        <TripRoutesFuelKmReports />
      </Route>

      {/* DRIVERS */}
      <Route path={"/employees"}>
        <DriversListPage />
      </Route>
      <Route path={"/employee/:id"}>
        <DriversPage />
      </Route>

      {/* DRIVERS */}
      <Route path={"/users"}>
        <UsersListPage />
      </Route>
      <Route path={"/user/:id"}>
        <UsersPage />
      </Route>
    </Fragment>
  )
}
