import { useEffect } from "react";
import { NaeSSchemaMap } from "../../config/NaeSSchema";
import { useDataCache } from "./DataCacheProvider";
import { useSelector } from "react-redux";
import { SelectorClientNae } from "../Models/ormSelectors";

interface ChildId {
  id: number
}

export interface IClientModelNae {
    id: number,
scopes: any,
badges: any,

}

export const IClientFieldsNae = [
    "id",
    "scopes",
    "badges"
];

export function useClientHookNae(id: number) : IClientModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorClientNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.Client.schema,
        fields: IClientFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
