import React, { Fragment } from 'react'
import { MenuFolder, MenuSpacer } from '@newageerp/ui.menu.base.menu'
import MenuItemTrucks from "../items/MenuItemTrucks" 
import MenuItemFuelCards from "../items/MenuItemFuelCards" 
import MenuItemTruckGroups from "../items/MenuItemTruckGroups" 
import MenuItemTruckTrailers from "../items/MenuItemTruckTrailers" 
import MenuItemServices from "../items/MenuItemServices" 
import MenuItemServiceGroups from "../items/MenuItemServiceGroups" 
import MenuItemCountries from "../items/MenuItemCountries" 
import MenuItemStations from "../items/MenuItemStations" 
import MenuItemTablets from '../items/MenuItemTablets'
import MenuItemTasks from '../items/MenuItemTasks'


export default function MenuFolderDuomenysc60() {
    return (
        <Fragment>
            <MenuFolder
                item={{
                    children: 'Duomenys',
                    icon: 'database'
                }}
                menuFolderId='MenuFolderDuomenysc60'
            >
                <MenuItemTrucks forceSkipIcon={true}/>
                <MenuItemFuelCards forceSkipIcon={true}/>
                <MenuItemTablets forceSkipIcon={true}/>
                <MenuItemTruckGroups forceSkipIcon={true}/>
                <MenuItemTruckTrailers forceSkipIcon={true}/>
                <MenuSpacer />
                <MenuItemServices forceSkipIcon={true}/>
                <MenuItemServiceGroups forceSkipIcon={true}/>
                <MenuItemTasks forceSkipIcon={true} />
                <MenuItemCountries forceSkipIcon={true}/>
                <MenuItemStations forceSkipIcon={true}/>
            </MenuFolder>
        </Fragment>
    )
}
