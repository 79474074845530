import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import {Button, ButtonGroup, Card} from "react-bootstrap";
import {NaeLayout} from "nae-react-core-styles";
import {InvoiceLine} from "./InvoiceTypes";
import InvoiceLineUpdateForm from "../../Forms/InvoiceLineUpdateForm";
import {toast} from "react-toastify";
import InvoiceLineDeleteButton from "./Components/InvoiceLineDeleteButton";

const moduleName = 'invoice-line';

interface ParamTypes {
    id: string
}

const defElement: InvoiceLine = {
    id: 0,
    date: '',
    dateTime: '',
    quantity: 0,
    price: 0,
    priceEur: 0,

    total: 0,
    totalWithVat: 0,

    totalEur: 0,
    totalVat: 0,
    totalVatEur: 0,
    totalWithVatEur: 0,

    fvsCode: '',
    fvsVatCode: '',
    fuelCardNumber: '',
    truckName: '',
    serviceName: '',
    serviceGroupName: '',
    country: '',

    invoice: '',
    invoiceDate: '',
    invoiceType: '',

    truckId: 0,
    serviceGroupId: 0,
    serviceId: 0,
    invoiceId: 0,

    hasReports: false,
    tripReference: '',
}


export default function InvoiceLinePage() {
    const history = useHistory();

    const [editMode, setEditMode] = useState(false);
    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (quantity: number,
                      price: number,
                      priceEur: number,
                      total: number,
                      totalEur: number,
                      totalVat: number,
                      totalVatEur: number,
                      serviceId: number,) => {
        if (id === 'new') {
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    quantity,
                    price,
                    priceEur,
                    total,
                    totalEur,
                    totalVat,
                    totalVatEur,
                    serviceId,
                }).then(res => {
                setElement(res);
                setEditMode(false);
                toast.success("Išsaugota");
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <InvoiceLineUpdateForm element={element} saveData={saveData}/>

        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Card>
            <Card.Body>
                <ButtonGroup vertical>

                    {/*<DeleteButton id={domain.id}/>*/}

                    {/*{!editMode &&*/}
                    {/*<Button variant={"outline-success"} onClick={() => setEditMode(true)}>Redaguoti</Button>*/}
                    {/*}*/}
                    {/*{editMode &&*/}
                    {/*<Button variant={"outline-light"} onClick={() => setEditMode(false)}>Atšaukti</Button>*/}
                    {/*}*/}

                    <InvoiceLineDeleteButton id={element.id}/>
                </ButtonGroup>
            </Card.Body>
        </Card>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>


        </Fragment>
    )
}
