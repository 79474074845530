import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import TableWithPaging from "../../TableWithPaging";
import { NaeTable } from "nae-react-core-styles";
import { Trip, Client } from "./SalesTypes";
import { UI } from "@newageerp/nae-react-ui";
import { Col, Form, Row } from "react-bootstrap";
import { Driver, Truck } from "../Trucks/TruckTypes";
import DateField from "../../Forms/fields/DateField";
import moment from "moment";
// @ts-ignore
import { Multiselect } from 'multiselect-react-dropdown';
import { MultiSelectOption } from "../Reports/ReportsPage";
import { useRecoilState } from "recoil";
import { UserSpaceWrapperToolbarState } from "../../../_generated/layout/toolbar/UserSpaceWrapperToolbar";
import { WhiteCard } from "@newageerp/ui.cards.base.white-card";
import { Table, Th, Td } from '@newageerp/ui.table.base.table'
import TableWithPagingV2 from "../../TableWithPagingV2";
import { TwTextAlignment } from "@newageerp/ui.styles.tailwind";
import { SystemUser } from "../Common/UserTypes";

const moduleName = 'trip';

const uri = 'data:application/vnd.ms-excel;base64,';

const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
    'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
    'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
    'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
    '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
    'xml><![endif]--></head><body>{table}</body></html>';


const calcDiffPercent = (_v1: number, _v2: number) => {
    const v1 = Math.round(_v1);
    const v2 = Math.round(_v2);

    return Math.round((v1 > 0 && v2 > 0 ? (v1 - v2) / v1 : -1) * 100 * 100) / 100;
}
const calcDiffValue = (v1: number, v2: number) => {
    return Math.round(v1 - v2).toFixed(0);
}

export default function TripRoutesErrorReports() {
    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)

    const history = useHistory();

    const base64 = (s: string) => {
        return window.btoa(unescape(encodeURIComponent(s)));
    }

    const doFormat = (s: string) => {
        const context = {
            worksheet: 'Worksheet',
            // @ts-ignore
            table: window.document.getElementById("table-to-export").outerHTML,
        };

        // @ts-ignore
        return s.replace(/{(\w+)}/g, (_m, p) => context[p]);
    }

    const doDownload = () => {
        const element = window.document.createElement('a');
        element.href = uri + base64(doFormat(template));
        element.download = 'ataskaita.xls';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const [searchElement, setSearchElement] = useState<any>({});
    const appendSearch = (key: string, val: string) => {
        setSearchElement({ ...searchElement, [key]: val })
    }
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedTrucks, setSelectedTrucks] = useState<MultiSelectOption[]>([]);
    const [selectedClients, setSelectedClients] = useState<MultiSelectOption[]>([]);
    const [users, setUsers] = useState<SystemUser[]>([]);

    const getListData = () => {
        ApiRest.getElements('driver').then(res => {
            if (res) {
                setDrivers(res);
            }
        });
        ApiRest.getElements('truck').then(res => {
            if (res) {
                setTrucks(res.filter((t: Truck) => !!t.locktrackerNumber));
            }
        });
        ApiRest.getElements('client').then(res => {
            if (res) {
                setClients(res);
            }
        });
        ApiRest.getElements('user').then(res => {
            if (res) {
                setUsers(res);
            }
        });
    }
    useEffect(getListData, []);

    const truckOptions = useMemo(() => {
        return [
            { id: -1, name: 'Nepriskirtas' },
            ...trucks.map((item) => {
                return { id: item.id, name: item.name };
            })
        ]
    }, [trucks]);

    const clientOptions = useMemo(() => {
        return [
            { id: "null", name: 'Nepriskirtas' },
            ...clients.map((item) => {
                return { id: item.id.toString(), name: item.name };
            })
        ];
    }, [clients]);

    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = () => setShowFilter(!showFilter);

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [trips, setTrips] = useState<Trip[]>([])
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [dataToRender, setDataToRender] = useState<Trip[]>([]);

    const [filter, setFilter] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setTrips(res);
        })
    }

    const [percentageFilter, setPercentageFilter] = useState("all");

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = trips;

            if (search) {
                _data = _data.filter(item =>
                    item.number.toLowerCase().includes(search.toLowerCase()) ||
                    item.reference.toLowerCase().includes(search.toLowerCase()) ||
                    item.truckName.toLowerCase().includes(search.toLowerCase()) ||
                    item.trailer.toLowerCase().includes(search.toLowerCase()) ||
                    item.comment.toLowerCase().includes(search.toLowerCase())
                );
            }

            if (selectedTrucks.length > 0) {
                const selectedTrucksIds = selectedTrucks.map(i => parseInt(i.id, 10));
                _data = _data.filter(item => selectedTrucksIds.includes(item.truck));
            }

            if (selectedClients.length > 0) {
                const selectedClientIds = selectedClients.map(i => i.id);
                _data = _data.filter(item =>
                    item.client === null ? selectedClientIds.includes("null") : selectedClientIds.includes(item.client.id.toString())
                );
            }

            if (searchElement.userId && searchElement.userId !== "0") {
                const selectedUserId = parseInt(searchElement.userId, 10);
                _data = _data.filter(item => item.userId === selectedUserId);
            }

            if (searchElement) {
                if (searchElement.number) {
                    _data = _data.filter((item: Trip) => {
                        return item.number.toLowerCase().indexOf(searchElement.number.toLowerCase()) > -1
                    });
                }
                if (searchElement.reference) {
                    _data = _data.filter((item: Trip) => {
                        return item.reference.toLowerCase().indexOf(searchElement.reference.toLowerCase()) > -1
                    });
                }
                if (searchElement.trailer) {
                    _data = _data.filter((item: Trip) => {
                        return item.trailer.toLowerCase().indexOf(searchElement.trailer.toLowerCase()) > -1
                    });
                }
                if (searchElement.driver && searchElement.driver > 0) {
                    _data = _data.filter((item: Trip) => {
                        return item.truckDriver == searchElement.driver || item.truckDriver2 == searchElement.driver;
                    });
                }

                if (searchElement.dateFromFrom) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateFrom) >= moment(searchElement.dateFromFrom);
                    });
                }
                if (searchElement.dateFromTo) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) <= moment(searchElement.dateFromTo);
                    });
                }


                if (searchElement.dateToFrom) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) >= moment(searchElement.dateToFrom);
                    });
                }
                if (searchElement.dateToTo) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) <= moment(searchElement.dateToTo);
                    });
                }   

                _data = _data.filter(item => {
                    const errorPercent = calcDiffPercent(item.invoiceDrivenKm, item.locktrackerKm2);
                    switch (percentageFilter) {
                        case "-1":
                            return errorPercent < 0 && errorPercent >= -1;
                        case "-3":
                            return errorPercent < -1 && errorPercent >= -3;
                        case "-100":
                            return Math.abs(errorPercent + 100) < 0.01;
                        default:
                            return true;
                    }
                });

            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, trips, filter, readyToLoad, searchElement, selectedTrucks, selectedClients, percentageFilter]);
    
    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter', 'searchElement', 'selectedTrucks', 'selectedClients', 'percentageFilter'];
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];
                switch (p) {
                    case 'search':
                        setSearch(v);
                        break;
                    case 'activePage':
                        setActivePage(v);
                        break;
                    case 'filter':
                        setFilter(v);
                        break;
                    case 'searchElement':
                        setSearchElement(v || {});
                        break;
                    case 'selectedTrucks':
                        setSelectedTrucks(v || []);
                        break;
                    case 'selectedClients':
                        setSelectedClients(v || []);
                        break;
                    case 'percentageFilter':
                        setPercentageFilter(v || "all");
                        break;
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: Trip) => {
        history.replace('/trips-reports-km', {
            search,
            activePage,
            filter,
            searchElement,
            selectedTrucks,
            selectedClients,
            percentageFilter
        });
        history.push('/' + moduleName + '/' + item.id);
    };

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    let autoRouteKmFlTotal = 0;
    let loctrackerKmFlTotal = 0;
    let invoicesKmFlTotal = 0;

    let otherCostsTotal = 0;
    let invoiceOtherCostsTotal = 0;
    let roadCostsTotal = 0;
    let invoiceRoadCostsTotal = 0;

    dataToRender.forEach((item: Trip) => {
        autoRouteKmFlTotal += item.autoRouteKm2;
        loctrackerKmFlTotal += item.locktrackerKm2;
        invoicesKmFlTotal += item.invoiceDrivenKm;

        otherCostsTotal += item.otherCost;
        invoiceOtherCostsTotal += item.invoiceOtherCosts;
        roadCostsTotal += item.roadCost;
        invoiceRoadCostsTotal += item.invoiceRoadCosts;
    })

    useEffect(() => {
        setToolbarTitle('Kilometrų paklaidos ataskaita');
    }, [])

    return (
        <div>

            <WhiteCard compact={true}>

                <div className={"flex gap-2 px-4"}>
                    <UI.Form.Input placeholder={"Paieška"} className={"w-full"} value={search}
                        onChange={(e) => setSearch(e.target.value)} />

                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.nsecondary}
                        onClick={toggleFilter}>Filtras</UI.Buttons.Button>

                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.green} brightness={700}
                        onClick={doDownload}>Xlsx</UI.Buttons.Button>
                </div>

                {showFilter && <div className={'bg-blue-50 rounded-md px-4'}>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Numeris</Form.Label>
                                <Form.Control value={searchElement.number ? searchElement.number : ''}
                                    onChange={e => appendSearch('number', e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Reference</Form.Label>
                                <Form.Control value={searchElement.reference ? searchElement.reference : ''}
                                    onChange={e => appendSearch('reference', e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Klientas</Form.Label>
                                <Multiselect
                                    options={clientOptions}
                                    displayValue="name"
                                    placeholder={"Pasirinkti..."}
                                    onSelect={(selectedList: any) => setSelectedClients(selectedList)}
                                    onRemove={(selectedList: any) => setSelectedClients(selectedList)}
                                    selectedValues={selectedClients}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Vadybininkas</Form.Label>
                                <Form.Control
                                    as="select"
                                    className="mr-sm-2"
                                    value={searchElement.userId ? searchElement.userId : "0"}
                                    onChange={(e) => appendSearch('userId', e.target.value)}
                                >
                                    <option value="0">Pasirinkite...</option>
                                    {users.map((user: SystemUser) =>
                                        <option key={'opt-u' + user.id} value={user.id}>
                                            {user.fullName} ({user.email})
                                        </option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Vilkikas</Form.Label>
                                <Multiselect
                                    options={truckOptions}
                                    displayValue="name"
                                    placeholder={"Pasirinkti..."}
                                    onSelect={(selectedList: any) => setSelectedTrucks(selectedList)}
                                    onRemove={(selectedList: any) => setSelectedTrucks(selectedList)}
                                    selectedValues={selectedTrucks}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Priekaba</Form.Label>
                                <Form.Control value={searchElement.trailer ? searchElement.trailer : ''}
                                    onChange={e => appendSearch('trailer', e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Vairuotojas</Form.Label>
                                <Form.Control
                                    as="select"
                                    className="mr-sm-2"
                                    value={searchElement.driver ? searchElement.driver : ''}
                                    onChange={(e) => appendSearch('driver', e.target.value)}
                                >
                                    <option value="0">Pasirinkite...</option>
                                    {drivers.map((driver: Driver) => <option key={'opt-g' + driver.id}
                                        value={driver.id}>{driver.fullName}</option>)}

                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Km % paklaida</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={percentageFilter}
                                    onChange={e => setPercentageFilter(e.target.value)}
                                >
                                    <option value="all">Visi</option>
                                    <option value="-1">Iki -1%</option>
                                    <option value="-3">Nuo -1% iki -3%</option>
                                    <option value="-100">-100%</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pradžios data nuo</Form.Label>
                                <DateField value={searchElement.dateFromFrom ? searchElement.dateFromFrom : ''}
                                    setValue={(val) => appendSearch('dateFromFrom', val)} />

                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pabaigos data iki</Form.Label>
                                <DateField value={searchElement.dateToTo ? searchElement.dateToTo : ''}
                                    setValue={(val) => appendSearch('dateToTo', val)} />
                            </Form.Group>
                        </Col>
                    </Row>

                </div>}

                <TableWithPagingV2
                    pageSize={500}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    dataToRender={dataToRender}
                    head={
                        <tr>
                            <Th>ID</Th>
                            <Th>Numeris</Th>
                            <Th>Vilkikas</Th>
                            <Th className="border-r border-blue-300">Vairuotojas</Th>

                            <Th textAlignment={TwTextAlignment.right}>Sąskaita</Th>
                            <Th textAlignment={TwTextAlignment.right}>AutoRoute</Th>
                            <Th> </Th>
                            <Th className="border-r border-blue-300"> </Th>

                            <Th textAlignment={TwTextAlignment.right}>Sąskaita</Th>
                            <Th textAlignment={TwTextAlignment.right}>LocTracker</Th>
                            <Th> </Th>
                            <Th className="border-r border-blue-300"> </Th>

                            <Th textAlignment={TwTextAlignment.right}>LocTracker</Th>
                            <Th textAlignment={TwTextAlignment.right}>AutoRoute</Th>
                            <Th> </Th>
                            <Th> </Th>

                        </tr>}
                    beforeItems={
                        <tr className={' font-semibold'}>
                            <Td>#</Td>
                            <Td>Iš viso</Td>
                            <Td>

                            </Td>
                            <Td className="border-r border-blue-300">

                            </Td>


                            <Td textAlignment={TwTextAlignment.right}>
                                {invoicesKmFlTotal.toFixed(0)}
                            </Td>
                            <Td textAlignment={TwTextAlignment.right}>
                                {autoRouteKmFlTotal.toFixed(0)}
                            </Td>
                            <Td textAlignment={TwTextAlignment.right} className={"whitespace-nowrap"}>
                                {calcDiffValue(invoicesKmFlTotal, autoRouteKmFlTotal)} KM
                            </Td>
                            <Td textAlignment={TwTextAlignment.right} className="border-r border-blue-300 whitespace-nowrap">
                                {calcDiffPercent(invoicesKmFlTotal, autoRouteKmFlTotal)} %
                            </Td>



                            <Td textAlignment={TwTextAlignment.right}>
                                {invoicesKmFlTotal.toFixed(0)}
                            </Td>
                            <Td textAlignment={TwTextAlignment.right}>
                                {loctrackerKmFlTotal.toFixed(0)}
                            </Td>
                            <Td textAlignment={TwTextAlignment.right} className={"whitespace-nowrap"}>
                                {calcDiffValue(invoicesKmFlTotal, loctrackerKmFlTotal)} KM
                            </Td>
                            <Td textAlignment={TwTextAlignment.right} className="border-r border-blue-300 whitespace-nowrap">
                                {calcDiffPercent(invoicesKmFlTotal, loctrackerKmFlTotal)} %
                            </Td>


                            <Td textAlignment={TwTextAlignment.right}>
                                {loctrackerKmFlTotal.toFixed(0)}
                            </Td>
                            <Td textAlignment={TwTextAlignment.right}>
                                {autoRouteKmFlTotal.toFixed(0)}
                            </Td>
                            <Td textAlignment={TwTextAlignment.right} className={"whitespace-nowrap"}>
                                {calcDiffValue(loctrackerKmFlTotal, autoRouteKmFlTotal)} KM
                            </Td>
                            <Td textAlignment={TwTextAlignment.right} className={"whitespace-nowrap"}>
                                {calcDiffPercent(loctrackerKmFlTotal, autoRouteKmFlTotal)} %
                            </Td>
                        </tr>
                    }
                    renderItem={
                        (item: Trip) => {

                            const drivenKm = item.invoiceDrivenKm;
                            const odoDiff = item.locktrackerKm2;

                            const autoRouteKmFl = item.autoRouteKm2;
                            const autoRouteDrivenDiff = Math.round((drivenKm > 0 && autoRouteKmFl > 0 ? (drivenKm - autoRouteKmFl) / drivenKm : -1) * 100 * 100) / 100;
                            const autoRouteLockTrackerDiff = Math.round((drivenKm > 0 && odoDiff > 0 ? (drivenKm - odoDiff) / drivenKm : 1) * 100 * 100) / 100;


                            const invRoadCosts = item.invoiceRoadCosts;
                            const invOtherCosts = item.invoiceOtherCosts;

                            const roadCost = item.roadCost;
                            const otherCost = item.otherCost;


                            return (
                                <tr key={"list-row-" + item.id}>
                                    <Td>{item.id}</Td>
                                    <Td><button onClick={() => goTo(item)} className={"text-left text-primary"}>{item.number}</button></Td>
                                    <Td>{item.truckName}</Td>
                                    <Td className="border-r border-blue-300">
                                        {!!item.truckDriver && item.truckDriverName}
                                        {!!item.truckDriver2 && <Fragment> / {item.truckDriver2Name}</Fragment>}
                                    </Td>

                                    <Td textAlignment={TwTextAlignment.right}>
                                        {item.invoiceDrivenKm.toFixed(0)}
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right}>
                                        {item.autoRouteKm2.toFixed(0)}
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right} className={"whitespace-nowrap"}>
                                        {calcDiffValue(item.invoiceDrivenKm, item.autoRouteKm2)} KM
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right} className="border-r border-blue-300 whitespace-nowrap">
                                        {calcDiffPercent(item.invoiceDrivenKm, item.autoRouteKm2)} %
                                    </Td>



                                    <Td textAlignment={TwTextAlignment.right}>
                                        {item.invoiceDrivenKm.toFixed(0)}
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right}>
                                        {item.locktrackerKm2.toFixed(0)}
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right} className={"whitespace-nowrap"}>
                                        {calcDiffValue(item.invoiceDrivenKm, item.locktrackerKm2)} KM
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right} className="border-r border-blue-300 whitespace-nowrap">
                                        {calcDiffPercent(item.invoiceDrivenKm, item.locktrackerKm2)} %
                                    </Td>


                                    <Td textAlignment={TwTextAlignment.right}>
                                        {item.locktrackerKm2.toFixed(0)}
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right}>
                                        {item.autoRouteKm2.toFixed(0)}
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right} className={"whitespace-nowrap"}>
                                        {calcDiffValue(item.locktrackerKm2, item.autoRouteKm2)} KM
                                    </Td>
                                    <Td textAlignment={TwTextAlignment.right} className={"whitespace-nowrap"}>
                                        {calcDiffPercent(item.locktrackerKm2, item.autoRouteKm2)} %
                                    </Td>

                                </tr>
                            )
                        }
                    }
                    afterItems={
                        <Fragment>
                            <tr className="bg-blue-50">
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th className="border-r border-blue-300"></Th>

                                <Th textAlignment={TwTextAlignment.right}>Sąskaita</Th>
                                <Th textAlignment={TwTextAlignment.right}>AutoRoute</Th>
                                <Th> </Th>
                                <Th className="border-r border-blue-300"> </Th>

                                <Th textAlignment={TwTextAlignment.right}>Sąskaita</Th>
                                <Th textAlignment={TwTextAlignment.right}>LocTracker</Th>
                                <Th> </Th>
                                <Th className="border-r border-blue-300"> </Th>

                                <Th textAlignment={TwTextAlignment.right}>LocTracker</Th>
                                <Th textAlignment={TwTextAlignment.right}>AutoRoute</Th>
                                <Th> </Th>
                                <Th> </Th>

                            </tr>
                        </Fragment>
                    }
                />



            </WhiteCard>

        </div>
    );
}
