import React, { useState, useMemo, useEffect } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { Driver, DriverHistory, PhoneNumber, Address, FamilyContact, DocumentValidity } from "../Pages/Trucks/TruckTypes";
import { Popup, PopupSize } from "@newageerp/ui.popups.base.popup";
import { UI } from "@newageerp/nae-react-ui";
import { useHistory } from "react-router-dom";
import DateField from "./fields/DateField";
import { TabContainer } from "@newageerp/ui.tabs.tab-container";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { Wide } from "@newageerp/ui.form.base.form-pack";
import PhoneNumberPopup from './PhoneNumberPopup';
import AddressPopup from './AddressPopup';
import FamilyContactPopup from './FamilyContactPopup';
import DocumentValidityPopup from './DocumentValidityPopup';
import { IconType } from "@newageerp/ui.icons.base.icon";
import { IconList } from "@newageerp/ui.icons.base.icon-list";
import {
  TwBackgroundColors,
  TwTextColors,
} from "@newageerp/ui.styles.tailwind";
import { SquareButton } from "@newageerp/ui.buttons.base.square-button";
import { validateIBAN, formatIBAN } from '../Utils/IbanValidation';
import { COUNTRIES } from '../Utils/countries';
import ApiRest from "../../service/ApiRest";
import { toast } from "react-toastify";

interface Props {
  element: Driver;
  saveData: (data: Partial<Driver>) => void;
}


const POSITIONS = [
  { id: 'Vairuotojas', name: 'Vairuotojas' }
] as const;

export default function DriverForm(props: Props) {
  const { element, saveData } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [firstName, setFirstName] = useState(element.firstName || "");
  const [lastName, setLastName] = useState(element.lastName || "");
  const [birthDate, setBirthDate] = useState(element.birthDate || "");
  const [country, setCountry] = useState(element.country || "");
  const [comment, setComment] = useState(element.comment || "");
  const [email, setEmail] = useState(element.email || "");
  const [personalCode, setPersonalCode] = useState(element.personalCode || "");
  const [personalCodeError, setPersonalCodeError] = useState<string>('');
  const [isPersonalCodeValid, setIsPersonalCodeValid] = useState(false);
  const [employmentContractNumber, setEmploymentContractNumber] = useState(element.employmentContractNumber || "");
  const [contractSigningDate, setContractSigningDate] = useState(element.contractSigningDate || "");
  const [commentHistory, setCommentHistory] = useState<DriverHistory[]>(element.history || []);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>(element.phoneNumbers || []);
  const [addresses, setAddresses] = useState<Address[]>(element.addresses || []);
  const [familyContacts, setFamilyContacts] = useState<FamilyContact[]>(element.familyContacts || []);
  const [hireDate, setHireDate] = useState(element.hireDate || "");
  const [terminationDate, setTerminationDate] = useState(element.terminationDate || "");
  const [position, setPosition] = useState(element.position || POSITIONS[0].id);
  const [positionGrantDate, setPositionGrantDate] = useState(element.positionGrantDate || "");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState(element.socialSecurityNumber || "");
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<number[]>([]);
  const [showPhoneNumberPopup, setShowPhoneNumberPopup] = useState(false);
  const [editPhoneNumber, setEditPhoneNumber] = useState<PhoneNumber | null>(null);
  const [bankAccountError, setBankAccountError] = useState<string>('');
  const [bankAccount, setBankAccount] = useState(element.bankAccount || "");
  const [bankAccountDisplay, setBankAccountDisplay] = useState(element.bankAccount ? formatIBAN(element.bankAccount) : "");
  const [bankAccount2, setBankAccount2] = useState(element.bankAccount2 || "");
  const [bankAccount2Display, setBankAccount2Display] = useState(element.bankAccount2 ? formatIBAN(element.bankAccount2) : "");
  const [bankAccount2Error, setBankAccount2Error] = useState<string>('');


  // For Addresses
  const [selectedAddresses, setSelectedAddresses] = useState<number[]>([]);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [editAddress, setEditAddress] = useState<Address | null>(null);

  // For Family Contacts
  const [selectedFamilyContacts, setSelectedFamilyContacts] = useState<number[]>([]);
  const [showFamilyContactPopup, setShowFamilyContactPopup] = useState(false);
  const [editFamilyContact, setEditFamilyContact] = useState<FamilyContact | null>(null);

  // For Documents validity
  const [documents, setDocuments] = useState<DocumentValidity[]>(element.documents || []);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [editDocument, setEditDocument] = useState<DocumentValidity | null>(null);



  const handleSave = () => {

    if (!personalCode.trim()) {
      toast.error('Asmens kodas yra privalomas laukas', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (!isPersonalCodeValid) {
      toast.error('Įveskite unikalų asmens kodą', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    let newHistory: DriverHistory[] = [];

    if (comment.trim() !== "") {
      const newHistoryItem: DriverHistory = {
        id: Date.now(),
        date: new Date().toISOString(),
        change: comment
      };
      newHistory = [newHistoryItem];
    }

    const formattedData: Partial<Driver> = {
      firstName,
      lastName,
      birthDate,
      position,
      country,
      comment,
      email,
      personalCode,
      employmentContractNumber,
      hireDate,
      contractSigningDate,
      terminationDate,
      positionGrantDate,
      socialSecurityNumber,
      bankAccount,
      bankAccount2,
      phoneNumbers,
      addresses,
      familyContacts,
      documents,
      history: newHistory
    };

    saveData(formattedData);
    setComment("");
    setCommentHistory(prevHistory => [...prevHistory, ...newHistory]);
  };



  const togglePhoneNumberSelection = (id: number) => {
    setSelectedPhoneNumbers(prev =>
      prev.includes(id) ? prev.filter(num => num !== id) : [...prev, id]
    );
  };

  const handlePhoneNumberSave = (newPhoneNumber: PhoneNumber) => {
    if (editPhoneNumber) {
      // Update existing phone number
      setPhoneNumbers(prev =>
        prev.map(phone =>
          phone.id === editPhoneNumber.id ? newPhoneNumber : phone
        )
      );
      setEditPhoneNumber(null);
    } else {
      // Add new phone number
      setPhoneNumbers(prev => [...prev, newPhoneNumber]);
      setShowPhoneNumberPopup(false);
    }
  };

  const handleAddPhoneNumber = () => {
    setShowPhoneNumberPopup(true);
  };

  const handleEditPhoneNumber = (phone: PhoneNumber) => {
    setEditPhoneNumber(phone);
  };

  const handleDeletePhoneNumbers = () => {
    setPhoneNumbers(prev =>
      prev.filter(phone => !selectedPhoneNumbers.includes(phone.id))
    );
    setSelectedPhoneNumbers([]);
  };

  const toggleAddressSelection = (id: number) => {
    setSelectedAddresses(prev =>
      prev.includes(id) ? prev.filter(addr => addr !== id) : [...prev, id]
    );
  };

  const handleAddressSave = (newAddress: Address) => {
    if (editAddress) {
      setAddresses(prev =>
        prev.map(addr =>
          addr.id === editAddress.id ? newAddress : addr
        )
      );
      setEditAddress(null);
    } else {
      setAddresses(prev => [...prev, newAddress]);
      setShowAddressPopup(false);
    }
  };

  const handleAddAddress = () => {
    setShowAddressPopup(true);
  };

  const handleEditAddress = (address: Address) => {
    setEditAddress(address);
  };

  const handleDeleteAddresses = () => {
    setAddresses(prev =>
      prev.filter(addr => !selectedAddresses.includes(addr.id))
    );
    setSelectedAddresses([]);
  };

  const toggleFamilyContactSelection = (id: number) => {
    setSelectedFamilyContacts(prev =>
      prev.includes(id) ? prev.filter(contact => contact !== id) : [...prev, id]
    );
  };

  const handleFamilyContactSave = (newContact: FamilyContact) => {
    if (editFamilyContact) {
      setFamilyContacts(prev =>
        prev.map(contact =>
          contact.id === editFamilyContact.id ? newContact : contact
        )
      );
      setEditFamilyContact(null);
    } else {
      setFamilyContacts(prev => [...prev, newContact]);
      setShowFamilyContactPopup(false);
    }
  };

  const handleAddFamilyContact = () => {
    setShowFamilyContactPopup(true);
  };

  const handleEditFamilyContact = (contact: FamilyContact) => {
    setEditFamilyContact(contact);
  };

  const handleDeleteFamilyContacts = () => {
    setFamilyContacts(prev =>
      prev.filter(contact => !selectedFamilyContacts.includes(contact.id))
    );
    setSelectedFamilyContacts([]);
  };

  const toggleDocumentSelection = (id: number) => {
    setSelectedDocuments(prev =>
      prev.includes(id) ? prev.filter(doc => doc !== id) : [...prev, id]
    );
  };

  const handleDocumentSave = (newDocument: DocumentValidity) => {
    if (editDocument) {
      setDocuments(prev =>
        prev.map(doc =>
          doc.id === editDocument.id ? newDocument : doc
        )
      );
      setEditDocument(null);
    } else {
      setDocuments(prev => [...prev, newDocument]);
      setShowDocumentPopup(false);
    }
  };

  const handleAddDocument = () => {
    setShowDocumentPopup(true);
  };

  const handleEditDocument = (document: DocumentValidity) => {
    setEditDocument(document);
  };

  const handleDeleteDocuments = () => {
    setDocuments(prev =>
      prev.filter(doc => !selectedDocuments.includes(doc.id))
    );
    setSelectedDocuments([]);
  };

  const checkPersonalCode = async (code: string) => {
    if (!code) {
      setPersonalCodeError('Asmens kodas privalomas');
      setIsPersonalCodeValid(false);
      return;
    }

    try {
      const response = await ApiRest.getElements('employee');

      const exists = response.some((emp: Driver) =>
        emp.personalCode === code && emp.id !== element.id
      );

      if (exists) {
        setPersonalCodeError('Šis asmens kodas jau egzistuoja');
        setIsPersonalCodeValid(false);
      } else {
        setPersonalCodeError('');
        setIsPersonalCodeValid(true);
      }
    } catch (error) {
      setPersonalCodeError('Klaida tikrinant asmens kodą');
      setIsPersonalCodeValid(false);
    }
  };


  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0',
    borderBottom: '2px solid #dee2e6',
    marginBottom: '1rem'
  };

  useEffect(() => {
    if (element.bankAccount) {
      if (!validateIBAN(element.bankAccount)) {
        setBankAccountError('Neteisingas banko sąskaitos numeris');
      }
    }
  }, [element.bankAccount]);

  useEffect(() => {
    if (element.personalCode) {
      checkPersonalCode(element.personalCode);
    }
  }, [element.personalCode, element.id]);

  const GeneralTab = useMemo(() => (
    <Row>
      <Col md={6}>
        <Form>
          <Form.Group>
            <Form.Label>Vardas</Form.Label>
            <Form.Control value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gimimo data</Form.Label>
            <DateField value={birthDate} setValue={setBirthDate} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Asmens kodas *</Form.Label>
            <Form.Control
              value={personalCode}
              onChange={(e) => {
                const newValue = e.target.value;
                setPersonalCode(newValue);
                checkPersonalCode(newValue);
              }}
              isInvalid={!!personalCodeError}
            />
            {personalCodeError && (
              <Form.Control.Feedback type="invalid">
                {personalCodeError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form>
      </Col>
      <Col md={6}>
        <Form>
          <Form.Group>
            <Form.Label>Pavardė</Form.Label>
            <Form.Control value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Pilietybė</Form.Label>
            <Form.Control
              as="select"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              {COUNTRIES.map((prefix) => (
                <option key={prefix.country} value={prefix.name}>
                  {prefix.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  ), [firstName, lastName, birthDate, country, personalCode, personalCodeError, element.id]);


  const ContactsTab = useMemo(() => (
    <div>
      {/* Phone Numbers Section */}
      <Row className="mt-4">
        <Col>
          <div style={headerStyle}>
            <h4 style={{ margin: 0, flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              TEL. NUMERIS
              <SquareButton
                bgColor={TwBackgroundColors.slate50}
                icon={{
                  type: IconType.SolidButton,
                  icon: IconList.plus,
                  className: TwTextColors.slate400,
                }}
                onClick={handleAddPhoneNumber}
              />
              {selectedPhoneNumbers.length > 0 && (
                <SquareButton
                  bgColor={TwBackgroundColors.slate50}
                  icon={{
                    type: IconType.SolidButton,
                    icon: IconList.trash,
                    className: TwTextColors.slate400,
                  }}
                  onClick={handleDeletePhoneNumbers}
                />
              )}
            </h4>
          </div>
        </Col>
      </Row>

      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th style={{ width: '40px' }}>
              <Form.Check
                type="checkbox"
                checked={phoneNumbers.length > 0 && selectedPhoneNumbers.length === phoneNumbers.length}
                onChange={() => {
                  if (selectedPhoneNumbers.length === phoneNumbers.length) {
                    setSelectedPhoneNumbers([]);
                  } else {
                    setSelectedPhoneNumbers(phoneNumbers.map(p => p.id));
                  }
                }}
              />
            </th>
            <th>Tel. numeris</th>
            <th>Šalis</th>
            <th>Tipas</th>
            <th>Pastaba</th>
            <th style={{ width: '100px' }}>Veiksmai</th>
          </tr>
        </thead>
        <tbody>
          {phoneNumbers.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center py-4 text-muted">
                Nėra pridėtų tel. numerių
              </td>
            </tr>
          ) : (
            phoneNumbers.map(phone => (
              <tr key={phone.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedPhoneNumbers.includes(phone.id)}
                    onChange={() => togglePhoneNumberSelection(phone.id)}
                  />
                </td>
                <td>{phone.number}</td>
                <td>{phone.country}</td>
                <td>{phone.type}</td>
                <td>{phone.comment}</td>
                <td>
                  <SquareButton
                    bgColor={TwBackgroundColors.slate50}
                    icon={{
                      type: IconType.SolidButton,
                      icon: IconList.editPencil,
                      className: TwTextColors.slate400,
                    }}
                    onClick={() => handleEditPhoneNumber(phone)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Row className="mt-4">
        <Col>
          <div style={headerStyle}>
            <h4 style={{ margin: 0, flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              EL. PAŠTAS
              {selectedAddresses.length > 0 && (
                <SquareButton
                  bgColor={TwBackgroundColors.slate50}
                  icon={{
                    type: IconType.SolidButton,
                    icon: IconList.trash,
                    className: TwTextColors.slate400,
                  }}
                  onClick={handleDeleteAddresses}
                />
              )}
            </h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>

      {/* Addresses Section */}
      <Row className="mt-4">
        <Col>
          <div style={headerStyle}>
            <h4 style={{ margin: 0, flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              ADRESAS
              <SquareButton
                bgColor={TwBackgroundColors.slate50}
                icon={{
                  type: IconType.SolidButton,
                  icon: IconList.plus,
                  className: TwTextColors.slate400,
                }}
                onClick={handleAddAddress}
              />
              {selectedAddresses.length > 0 && (
                <SquareButton
                  bgColor={TwBackgroundColors.slate50}
                  icon={{
                    type: IconType.SolidButton,
                    icon: IconList.trash,
                    className: TwTextColors.slate400,
                  }}
                  onClick={handleDeleteAddresses}
                />
              )}
            </h4>
          </div>
        </Col>
      </Row>

      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th style={{ width: '40px' }}>
              <Form.Check
                type="checkbox"
                checked={addresses.length > 0 && selectedAddresses.length === addresses.length}
                onChange={() => {
                  if (selectedAddresses.length === addresses.length) {
                    setSelectedAddresses([]);
                  } else {
                    setSelectedAddresses(addresses.map(a => a.id));
                  }
                }}
              />
            </th>
            <th>Šalis</th>
            <th>Miestas</th>
            <th>Gatvė, namo numeris</th>
            <th>Tipas</th>
            <th>Pastaba</th>
            <th style={{ width: '100px' }}>Veiksmai</th>
          </tr>
        </thead>
        <tbody>
          {addresses.length === 0 ? (
            <tr>
              <td colSpan={7} className="text-center py-4 text-muted">
                Nėra pridėtų adresų
              </td>
            </tr>
          ) : (
            addresses.map(address => (
              <tr key={address.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedAddresses.includes(address.id)}
                    onChange={() => toggleAddressSelection(address.id)}
                  />
                </td>
                <td>{address.country}</td>
                <td>{address.city}</td>
                <td>{address.address}</td>
                <td>{address.type}</td>
                <td>{address.comment}</td>
                <td>
                  <SquareButton
                    bgColor={TwBackgroundColors.slate50}
                    icon={{
                      type: IconType.SolidButton,
                      icon: IconList.editPencil,
                      className: TwTextColors.slate400,
                    }}
                    onClick={() => handleEditAddress(address)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Family Contacts Section */}
      <Row className="mt-4">
        <Col>
          <div style={headerStyle}>
            <h4 style={{ margin: 0, flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              ARTIMŲJŲ KONTAKTAI
              <SquareButton
                bgColor={TwBackgroundColors.slate50}
                icon={{
                  type: IconType.SolidButton,
                  icon: IconList.plus,
                  className: TwTextColors.slate400,
                }}
                onClick={handleAddFamilyContact}
              />
              {selectedFamilyContacts.length > 0 && (
                <SquareButton
                  bgColor={TwBackgroundColors.slate50}
                  icon={{
                    type: IconType.SolidButton,
                    icon: IconList.trash,
                    className: TwTextColors.slate400,
                  }}
                  onClick={handleDeleteFamilyContacts}
                />
              )}
            </h4>
          </div>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '40px' }}>
              <Form.Check
                type="checkbox"
                checked={familyContacts.length > 0 && selectedFamilyContacts.length === familyContacts.length}
                onChange={() => {
                  if (selectedFamilyContacts.length === familyContacts.length) {
                    setSelectedFamilyContacts([]);
                  } else {
                    setSelectedFamilyContacts(familyContacts.map(fc => fc.id));
                  }
                }}
              />
            </th>
            <th>Vardas</th>
            <th>Pavardė</th>
            <th>Tel. numeris</th>
            <th>El. paštas</th>
            <th>Pastaba</th>
            <th style={{ width: '100px' }}>Veiksmai</th>
          </tr>
        </thead>
        <tbody>
          {familyContacts.length === 0 ? (
            <tr>
              <td colSpan={9} className="text-center py-4 text-muted">
                Nėra pridėtų artimųjų kontaktų
              </td>
            </tr>
          ) : (
            familyContacts.map(contact => (
              <tr key={contact.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedFamilyContacts.includes(contact.id)}
                    onChange={() => toggleFamilyContactSelection(contact.id)}
                  />
                </td>
                <td>{contact.name}</td>
                <td>{contact.surname}</td>
                <td>{contact.telNumber1}</td>
                <td>{contact.email1}</td>
                <td>{contact.comment}</td>
                <td>
                  <SquareButton
                    bgColor={TwBackgroundColors.slate50}
                    icon={{
                      type: IconType.SolidButton,
                      icon: IconList.editPencil,
                      className: TwTextColors.slate400,
                    }}
                    onClick={() => handleEditFamilyContact(contact)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Popups */}
      {(showPhoneNumberPopup || editPhoneNumber) && (
        <Popup
          onClose={() => {
            setShowPhoneNumberPopup(false);
            setEditPhoneNumber(null);
          }}
          header={editPhoneNumber ? "Redaguoti tel. numerį" : "Pridėti tel. numerį"}
          size={PopupSize.w500}
        >
          <PhoneNumberPopup
            driverId={element.id}
            phoneNumber={editPhoneNumber || undefined}
            onClose={() => {
              setShowPhoneNumberPopup(false);
              setEditPhoneNumber(null);
            }}
            onSave={handlePhoneNumberSave}
          />
        </Popup>
      )}

      {(showAddressPopup || editAddress) && (
        <Popup
          onClose={() => {
            setShowAddressPopup(false);
            setEditAddress(null);
          }}
          header={editAddress ? "Redaguoti adresą" : "Pridėti adresą"}
          size={PopupSize.w500}
        >
          <AddressPopup
            driverId={element.id}
            address={editAddress || undefined}
            onClose={() => {
              setShowAddressPopup(false);
              setEditAddress(null);
            }}
            onSave={handleAddressSave}
          />
        </Popup>
      )}

      {(showFamilyContactPopup || editFamilyContact) && (
        <Popup
          onClose={() => {
            setShowFamilyContactPopup(false);
            setEditFamilyContact(null);
          }}
          header={editFamilyContact ? "Redaguoti artimųjų kontaktą" : "Pridėti artimųjų kontaktą"}
          size={PopupSize.w500}
        >
          <FamilyContactPopup
            driverId={element.id}
            contact={editFamilyContact || undefined}
            onClose={() => {
              setShowFamilyContactPopup(false);
              setEditFamilyContact(null);
            }}
            onSave={handleFamilyContactSave}
          />
        </Popup>
      )}
    </div>
  ), [
    phoneNumbers, selectedPhoneNumbers, showPhoneNumberPopup, editPhoneNumber,
    addresses, selectedAddresses, showAddressPopup, editAddress,
    familyContacts, selectedFamilyContacts, showFamilyContactPopup, editFamilyContact,
    email
  ]);

  const DocumentValidityTab = useMemo(() => (
    <div>
      <Row className="mt-4">
        <Col>
          <div style={headerStyle}>
            <h4 style={{ margin: 0, flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              DOKUMENTAI
              <SquareButton
                bgColor={TwBackgroundColors.slate50}
                icon={{
                  type: IconType.SolidButton,
                  icon: IconList.plus,
                  className: TwTextColors.slate400,
                }}
                onClick={handleAddDocument}
              />
              {selectedDocuments.length > 0 && (
                <SquareButton
                  bgColor={TwBackgroundColors.slate50}
                  icon={{
                    type: IconType.SolidButton,
                    icon: IconList.trash,
                    className: TwTextColors.slate400,
                  }}
                  onClick={handleDeleteDocuments}
                />
              )}
            </h4>
          </div>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '40px' }}>
              <Form.Check
                type="checkbox"
                checked={documents.length > 0 && selectedDocuments.length === documents.length}
                onChange={() => {
                  if (selectedDocuments.length === documents.length) {
                    setSelectedDocuments([]);
                  } else {
                    setSelectedDocuments(documents.map(d => d.id));
                  }
                }}
              />
            </th>
            <th>Dokumento tipas</th>
            <th>Galiojimo data</th>
            <th>Dokumento numeris</th>
            <th>Pastabos</th>
            <th style={{ width: '100px' }}>Veiksmai</th>
          </tr>
        </thead>
        <tbody>
          {documents.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center py-4 text-muted">
                Nėra pridėtų dokumentų
              </td>
            </tr>
          ) : (
            documents.map(doc => (
              <tr key={doc.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedDocuments.includes(doc.id)}
                    onChange={() => toggleDocumentSelection(doc.id)}
                  />
                </td>
                <td>{doc.type}</td>
                <td>{doc.validUntil}</td>
                <td>{doc.documentNumber}</td>
                <td>{doc.comment}</td>
                <td>
                  <SquareButton
                    bgColor={TwBackgroundColors.slate50}
                    icon={{
                      type: IconType.SolidButton,
                      icon: IconList.editPencil,
                      className: TwTextColors.slate400,
                    }}
                    onClick={() => handleEditDocument(doc)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {(showDocumentPopup || editDocument) && (
        <Popup
          onClose={() => {
            setShowDocumentPopup(false);
            setEditDocument(null);
          }}
          header={editDocument ? "Redaguoti dokumentą" : "Pridėti dokumentą"}
          size={PopupSize.w500}
        >
          <DocumentValidityPopup
            driverId={element.id}
            document={editDocument || undefined}
            onClose={() => {
              setShowDocumentPopup(false);
              setEditDocument(null);
            }}
            onSave={handleDocumentSave}
          />
        </Popup>
      )}
    </div>
  ), [documents, selectedDocuments, showDocumentPopup, editDocument]);


  const EmploymentTab = useMemo(() => (
    <Row>
      <Col md={6}>
        <Form>
          <Form.Group>
            <Form.Label>Priėmimo data</Form.Label>
            <DateField value={hireDate} setValue={setHireDate} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Darbo sutarties sudarymo data</Form.Label>
            <DateField value={contractSigningDate} setValue={setContractSigningDate} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Pareigos</Form.Label>
            <Form.Control
              as="select"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            >
              {POSITIONS.map((pos) => (
                <option key={pos.id} value={pos.id}>
                  {pos.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label>Banko sąskaita 1</Form.Label>
            <Form.Control
              value={bankAccountDisplay}
              onChange={(e) => {
                const value = e.target.value.toUpperCase().replace(/\s/g, '');
                setBankAccount(value);
                setBankAccountDisplay(formatIBAN(value));

                if (value) {
                  if (!validateIBAN(value)) {
                    setBankAccountError('Neteisingas banko sąskaitos numeris');
                  } else {
                    setBankAccountError('');
                  }
                } else {
                  setBankAccountError('');
                  setBankAccountDisplay('');
                }
              }}
              isInvalid={!!bankAccountError}
              placeholder="LT12 3456 7890 1234 5678"
            />
            {bankAccountError && (
              <Form.Control.Feedback type="invalid">
                {bankAccountError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label>Sodros NR.</Form.Label>
            <Form.Control value={socialSecurityNumber} onChange={(e) => setSocialSecurityNumber(e.target.value)} />
          </Form.Group>
        </Form>
      </Col>

      <Col md={6}>
        <Form>
          <Form.Group>
            <Form.Label>Atleidimo data</Form.Label>
            <DateField value={terminationDate} setValue={setTerminationDate} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Darbo sutarties NR.</Form.Label>
            <Form.Control value={employmentContractNumber} onChange={(e) => setEmploymentContractNumber(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Pareigų suteikimo data</Form.Label>
            <DateField value={positionGrantDate} setValue={setPositionGrantDate} />
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label>Banko sąskaita 2</Form.Label>
            <Form.Control
              value={bankAccount2Display}
              onChange={(e) => {
                const value = e.target.value.toUpperCase().replace(/\s/g, '');
                setBankAccount2(value);
                setBankAccount2Display(formatIBAN(value));

                if (value) {
                  if (!validateIBAN(value)) {
                    setBankAccount2Error('Neteisingas banko sąskaitos numeris');
                  } else {
                    setBankAccount2Error('');
                  }
                } else {
                  setBankAccount2Error('');
                  setBankAccount2Display('');
                }
              }}
              isInvalid={!!bankAccount2Error}
              placeholder="LT12 3456 7890 1234 5678"
            />
            {bankAccount2Error && (
              <Form.Control.Feedback type="invalid">
                {bankAccount2Error}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form>
      </Col>
    </Row>
  ), [hireDate, terminationDate, contractSigningDate, employmentContractNumber, position, positionGrantDate, socialSecurityNumber, bankAccount, bankAccount2, bankAccountError]);


  const AnalyticsTab = useMemo(() => (
    <Form>
    </Form>
  ), []);

  const items = useMemo(() => [
    { content: GeneralTab, tab: { children: t("Bendra") } },
    { content: EmploymentTab, tab: { children: t("Įdarbinimas") } },
    { content: ContactsTab, tab: { children: t("Kontaktai") } },
    { content: DocumentValidityTab, tab: { children: t("Dokumentų galiojimas") } },
    { content: AnalyticsTab, tab: { children: t("Analitika") } },
  ], [GeneralTab, ContactsTab, DocumentValidityTab, EmploymentTab, AnalyticsTab, t]);

  return (
    <Row>
      <Col md={9}>
        <UI.Card.WhiteCard style={{ width: '100%' }}>
          <UI.MVC.MvcViewTitle
            onBack={() => history.goBack()}
            title={`${firstName} ${lastName}`.trim() || "Driver"}
          />
          <TabContainer items={items} />
          <Card.Footer>
            <Row>
              <Col></Col>
              <Col className={"text-right"}>
                <Button className="mb-2" type={"button"} variant={"primary"} onClick={handleSave}>
                  Išsaugoti
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </UI.Card.WhiteCard>
      </Col>
      <Col md={3}>
        <Card>
          <Card.Body>
            <Form.Group className="mb-3">
              <Form.Label>Komentaras</Form.Label>
              <Form.Control
                as="textarea"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                style={{ minHeight: '80px' }}
              />
            </Form.Group>
          </Card.Body>
          <Card.Header>Komentarų istorija</Card.Header>
          <Card.Body>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>DATA</th>
                  <th>PASTABOS</th>
                </tr>
              </thead>
              <tbody>
                {[...commentHistory]
                  .reverse()
                  .map((el: DriverHistory) => (
                    <tr key={`hist-${el.id}`}>
                      <td>{moment(el.date).format('YYYY-MM-DD HH:mm')}</td>
                      <td>{el.change}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}