import React from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../../service/ApiRest";
import {UI} from "@newageerp/nae-react-ui";

interface Props {
    id: number
}

const moduleName = 'trip';

export default function TripDeleteButton(props: Props) {
    const history = useHistory();
    const {id} = props;

    const doDelete = () => {
        // if (!window.confirm('Ar tikrai norite ištrinti kelionės lapą?')) return false;
        ApiRest.deleteElement(moduleName, id).then(res => {
            history.push('/' + moduleName + 's');
        });
    }

    return (
        <UI.PopoverConfirm.PopoverConfirm onClick={doDelete}>
            <UI.Buttons.Button
                bgColor={UI.Buttons.ButtonBgColor.red}
                size={UI.Buttons.ButtonSize.sm}
            >
                Ištrinti
            </UI.Buttons.Button>
        </UI.PopoverConfirm.PopoverConfirm>
    )
}
