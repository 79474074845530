import React, {Fragment} from "react";
import {Card, Table} from "react-bootstrap";
import {NaePaging} from "nae-react-core-styles";
import {UI} from "@newageerp/nae-react-ui"

interface Props<T> {
    pageSize?: number,
    activePage: number,
    setActivePage: (p: number) => void,
    dataToRender: T[],
    head: React.ReactChild,
    renderItem: (item: T) => React.ReactChild,
    afterItems?: any
}

export default function TableWithPaging<T>(props: Props<T>) {
    const {head, renderItem, dataToRender, activePage, setActivePage, pageSize = 20} = props;

    const pages = Math.ceil(dataToRender.length / pageSize);
    const offsetStart = (activePage - 1) * pageSize;
    const offsetEnd = offsetStart + pageSize;

    return (
        <Fragment>
            <Card.Body>
                <Table striped hover className={"mt-2"} id={"table-to-export"}>
                    <thead>
                    {head}
                    </thead>
                    <tbody>
                    {dataToRender.slice(offsetStart, offsetEnd).map(renderItem)}
                    {!!props.afterItems && props.afterItems}
                    </tbody>
                </Table>
            </Card.Body>
            <UI.Table.Paging pages={pages} activePage={activePage} setActivePage={setActivePage}/>
        </Fragment>
    );
}
