import { functions, UI } from '@newageerp/nae-react-ui';
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { SelectorClientNae,SelectorCountryNae,SelectorDriverTripNae,SelectorImportedFileNae,SelectorInvoiceNae,SelectorNoteNae,SelectorTripNae,SelectorTruckNae,SelectorTruckFuelTankNae,SelectorUserNae, } from "../Models/ormSelectors"
import { NaeSSchemaMap } from '../../config/NaeSSchema';
import { useDataCache } from './DataCacheProvider';

import { IClientFieldsNae } from './useClientHookNae';
import { ICountryFieldsNae } from './useCountryHookNae';
import { IDriverTripFieldsNae } from './useDriverTripHookNae';
import { IImportedFileFieldsNae } from './useImportedFileHookNae';
import { IInvoiceFieldsNae } from './useInvoiceHookNae';
import { INoteFieldsNae } from './useNoteHookNae';
import { ITripFieldsNae } from './useTripHookNae';
import { ITruckFieldsNae } from './useTruckHookNae';
import { ITruckFuelTankFieldsNae } from './useTruckFuelTankHookNae';
import { IUserFieldsNae } from './useUserHookNae';

import { useRecoilValue } from 'recoil';
import { OpenApi } from '@newageerp/nae-react-auth-wrapper';

export default function DataCacheSocketComponent() {
    const userState: any = useRecoilValue(OpenApi.naeUserState);

    const { addToQueue } = useDataCache();
    const [socketItem, setSocketItem] = useState();

            const ClientDataNae = useSelector(state => SelectorClientNae(state));
            const CountryDataNae = useSelector(state => SelectorCountryNae(state));
            const DriverTripDataNae = useSelector(state => SelectorDriverTripNae(state));
            const ImportedFileDataNae = useSelector(state => SelectorImportedFileNae(state));
            const InvoiceDataNae = useSelector(state => SelectorInvoiceNae(state));
            const NoteDataNae = useSelector(state => SelectorNoteNae(state));
            const TripDataNae = useSelector(state => SelectorTripNae(state));
            const TruckDataNae = useSelector(state => SelectorTruckNae(state));
            const TruckFuelTankDataNae = useSelector(state => SelectorTruckFuelTankNae(state));
            const UserDataNae = useSelector(state => SelectorUserNae(state));
    
    const onSocketDoRequest = (data: any) => {
        let fields = ['id'];
        let dataToCheck = [];
        let schema = functions.schemas.getSchemaByClassName(data.schema);

                if (data.schema === NaeSSchemaMap.Client.className) {
            dataToCheck = ClientDataNae;
            fields = IClientFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.Country.className) {
            dataToCheck = CountryDataNae;
            fields = ICountryFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.DriverTrip.className) {
            dataToCheck = DriverTripDataNae;
            fields = IDriverTripFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.ImportedFile.className) {
            dataToCheck = ImportedFileDataNae;
            fields = IImportedFileFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.Invoice.className) {
            dataToCheck = InvoiceDataNae;
            fields = IInvoiceFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.Note.className) {
            dataToCheck = NoteDataNae;
            fields = INoteFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.Trip.className) {
            dataToCheck = TripDataNae;
            fields = ITripFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.Truck.className) {
            dataToCheck = TruckDataNae;
            fields = ITruckFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.TruckFuelTank.className) {
            dataToCheck = TruckFuelTankDataNae;
            fields = ITruckFuelTankFieldsNae;
        }
                if (data.schema === NaeSSchemaMap.User.className) {
            dataToCheck = UserDataNae;
            fields = IUserFieldsNae;
        }
        
        dataToCheck = dataToCheck.map((el: any) => el.id);

        if (dataToCheck.indexOf(data.id) >= 0) {
            const item = {
                elementId: data.id,
                schema: schema,
                fields: fields,
            }
            addToQueue(item);
        }
    }

    const onForceSocketDoRequest = (data: any) => {
                if (data.schema === NaeSSchemaMap.Note.className) {
            const item = {
                elementId: data.id,
                schema: NaeSSchemaMap.Note.schema,
                fields: INoteFieldsNae,
            }
            addToQueue(item);
        }
            }

    useEffect(() => {
        if (socketItem) {
            onSocketDoRequest(socketItem);
        }
    }, [socketItem]);

    useEffect(() => {
        UI.Socket.Service.addCallback(
            "data-update-all",
            "data-update-all",
            setSocketItem
        );
        return () => {
            UI.Socket.Service.removeCallback(
                "data-update-all",
                "data-update-all"
            );
        };
    }, []);

    useEffect(() => {
        UI.Socket.Service.subscribe(`notes-${userState.id}`);
        UI.Socket.Service.subscribe(`notes-${userState.permissionGroup}`);

        UI.Socket.Service.addCallback(
            `notes-create`,
            `notes-create`,
            onForceSocketDoRequest
        );
        return () => {
            UI.Socket.Service.unsubscribe(`notes-${userState.id}`);
            UI.Socket.Service.unsubscribe(`notes-${userState.permissionGroup}`);

            UI.Socket.Service.removeCallback(
                `notes-create`,
                `notes-create`,
            );
        };
    }, [userState]);
    return (
        <Fragment />
    )
}
