import React, { Fragment } from "react";
import { UI } from "@newageerp/nae-react-ui"
import { Table, Th, Td } from '@newageerp/ui.table.base.table'

interface Props<T> {
    pageSize?: number,
    activePage: number,
    setActivePage: (p: number) => void,
    dataToRender: T[],
    head: React.ReactChild,
    renderItem: (item: T) => React.ReactChild,
    beforeItems?: React.ReactNode;
    afterItems?: any
}

export default function TableWithPagingV2<T>(props: Props<T>) {
    const { head, renderItem, dataToRender, activePage, setActivePage, pageSize = 20 } = props;

    const pages = Math.ceil(dataToRender.length / pageSize);
    const offsetStart = (activePage - 1) * pageSize;
    const offsetEnd = offsetStart + pageSize;

    return (
        <Fragment>

            <Table className={"mt-2"} id={"table-to-export"}
                thead={<thead>
                    {head}
                </thead>}
                tbody={<tbody>
                    {!!props.beforeItems && props.beforeItems}
                    {dataToRender.slice(offsetStart, offsetEnd).map(renderItem)}
                    {!!props.afterItems && props.afterItems}
                </tbody>}
            />



            <UI.Table.Paging pages={pages} activePage={activePage} setActivePage={setActivePage} />
        </Fragment>
    );
}
