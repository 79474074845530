import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import TableWithPaging from "../../TableWithPaging";
import { NaeTable } from "nae-react-core-styles";
import { Trip } from "./SalesTypes";
import { UI } from "@newageerp/nae-react-ui";
import { Col, Form, Row } from "react-bootstrap";
import { Driver, Truck } from "../Trucks/TruckTypes";
import DateField from "../../Forms/fields/DateField";
import moment from "moment";
// @ts-ignore
import { Multiselect } from 'multiselect-react-dropdown';
import { MultiSelectOption } from "../Reports/ReportsPage";

const moduleName = 'trip';

export default function TripListPageReports() {
    const history = useHistory();

    const [searchElement, setSearchElement] = useState<any>({});
    const appendSearch = (key: string, val: string) => {
        setSearchElement({ ...searchElement, [key]: val })
    }
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedTrucks, setSelectedTrucks] = useState<MultiSelectOption[]>([]);

    const getListData = () => {
        ApiRest.getElements('driver').then(res => {
            if (res) {
                setDrivers(res);
            }
        });
        ApiRest.getElements('truck').then(res => {
            if (res) {
                setTrucks(res.filter((t: Truck) => !!t.locktrackerNumber || t.name.indexOf('MAGNUM') >= 0));
            }
        });
    }
    useEffect(getListData, []);

    const truckOptions = useMemo(() => {
        return [
            { id: -1, name: 'Nepriskirtas' },
            ...trucks.map((item) => {
                return { id: item.id, name: item.name };
            })
        ]
    }, [trucks]);

    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = () => setShowFilter(!showFilter);

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [trips, setTrips] = useState<Trip[]>([])
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [dataToRender, setDataToRender] = useState<Trip[]>([]);

    const [filter, setFilter] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setTrips(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = trips;
            if (search) {
                _data = _data.filter((item: Trip) => {
                    return item.number.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.reference.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.truckName.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.trailer.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.comment.toLowerCase().indexOf(search.toLowerCase()) > -1
                });
            }

            if (selectedTrucks && selectedTrucks.length > 0) {
                const selectedTrucksIds = selectedTrucks.map(i => parseInt(i.id, 10));
                _data = _data.filter((item: Trip) => {
                    return selectedTrucksIds.indexOf(item.truck) >= 0;
                });
            }

            if (searchElement) {
                if (searchElement.number) {
                    _data = _data.filter((item: Trip) => {
                        return item.number.toLowerCase().indexOf(searchElement.number.toLowerCase()) > -1
                    });
                }
                if (searchElement.reference) {
                    _data = _data.filter((item: Trip) => {
                        return item.reference.toLowerCase().indexOf(searchElement.reference.toLowerCase()) > -1
                    });
                }
                if (searchElement.trailer) {
                    _data = _data.filter((item: Trip) => {
                        return item.trailer.toLowerCase().indexOf(searchElement.trailer.toLowerCase()) > -1
                    });
                }
                if (searchElement.driver && searchElement.driver > 0) {
                    _data = _data.filter((item: Trip) => {
                        return item.truckDriver == searchElement.driver || item.truckDriver2 == searchElement.driver;
                    });
                }

                if (searchElement.dateFromFrom) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateFrom) >= moment(searchElement.dateFromFrom);
                    });
                }
                if (searchElement.dateFromTo) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) <= moment(searchElement.dateFromTo);
                    });
                }


                if (searchElement.dateToFrom) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) >= moment(searchElement.dateToFrom);
                    });
                }
                if (searchElement.dateToTo) {
                    _data = _data.filter((item: Trip) => {
                        return moment(item.dateTo) <= moment(searchElement.dateToTo);
                    });
                }

            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, trips, filter, readyToLoad, searchElement]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter', 'searchElement']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                } else if (p === 'searchElement') {
                    setSearchElement(v ? v : {});
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: Trip) => {
        history.replace('/trips-reports', { search, activePage, filter, searchElement });
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    let autoRouteKmFlTotal = 0;
    let loctrackerKmFlTotal = 0;

    let autoRouteKmFlTotal2 = 0;
    let loctrackerKmFlTotal2 = 0;

    let invoicesKmFlTotal = 0;

    let otherCostsTotal = 0;
    let invoiceOtherCostsTotal = 0;
    let roadCostsTotal = 0;
    let invoiceRoadCostsTotal = 0;

    dataToRender.forEach((item: Trip) => {
        autoRouteKmFlTotal += item.autoRouteKm;
        loctrackerKmFlTotal += item.locktrackerKm;

        autoRouteKmFlTotal2 += item.autoRouteKm2;
        loctrackerKmFlTotal2 += item.locktrackerKm2;

        invoicesKmFlTotal += item.invoiceDrivenKm;

        otherCostsTotal += item.otherCost;
        invoiceOtherCostsTotal += item.invoiceOtherCosts;
        roadCostsTotal += item.roadCost;
        invoiceRoadCostsTotal += item.invoiceRoadCosts;
    })


    const autoRouteDrivenDiffTotal = Math.round((invoicesKmFlTotal > 0 && autoRouteKmFlTotal2 > 0 ? (invoicesKmFlTotal - autoRouteKmFlTotal2) / invoicesKmFlTotal : -1) * 100 * 100) / 100;
    const autoRouteLockTrackerDiffTotal = Math.round((invoicesKmFlTotal > 0 && loctrackerKmFlTotal2 > 0 ? (invoicesKmFlTotal - loctrackerKmFlTotal2) / invoicesKmFlTotal : 1) * 100 * 100) / 100;

    return (
        <div>

            <UI.Card.WhiteCard>
                <div className={"flex items-center"}>
                    <UI.Typography.H5 className={'flex-grow'}>Kelionių lapai</UI.Typography.H5>
                    <UI.Buttons.Button icon={"fal fa-fw fa-plus"} onClick={goToNew}>Naujas</UI.Buttons.Button>
                </div>

                <div className={"flex gap-2"}>
                    <UI.Form.Input placeholder={"Paieška"} className={"w-full"} value={search}
                        onChange={(e) => setSearch(e.target.value)} />

                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.nsecondary}
                        onClick={toggleFilter}>Filtras</UI.Buttons.Button>
                </div>

                {showFilter && <div className={'bg-blue-50 rounded-md px-4'}>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Numeris</Form.Label>
                                <Form.Control value={searchElement.number ? searchElement.number : ''}
                                    onChange={e => appendSearch('number', e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Reference</Form.Label>
                                <Form.Control value={searchElement.reference ? searchElement.reference : ''}
                                    onChange={e => appendSearch('reference', e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Vilkikas</Form.Label>
                                <Multiselect options={truckOptions}
                                    displayValue="name"
                                    placeholder={"Pasirinkti..."}
                                    id={"trucks"}
                                    onSelect={(selectedList: any) => setSelectedTrucks(selectedList)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Priekaba</Form.Label>
                                <Form.Control value={searchElement.trailer ? searchElement.trailer : ''}
                                    onChange={e => appendSearch('trailer', e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Vairuotojas</Form.Label>
                                <Form.Control
                                    as="select"
                                    className="mr-sm-2"
                                    value={searchElement.driver ? searchElement.driver : ''}
                                    onChange={(e) => appendSearch('driver', e.target.value)}
                                >
                                    <option value="0">Pasirinkite...</option>
                                    {drivers.map((driver: Driver) => <option key={'opt-g' + driver.id}
                                        value={driver.id}>{driver.fullName}</option>)}

                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pradžios data nuo</Form.Label>
                                <DateField value={searchElement.dateFromFrom ? searchElement.dateFromFrom : ''}
                                    setValue={(val) => appendSearch('dateFromFrom', val)} />

                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pradžios data iki</Form.Label>
                                <DateField value={searchElement.dateFromTo ? searchElement.dateFromTo : ''}
                                    setValue={(val) => appendSearch('dateFromTo', val)} />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pabaigos data nuo</Form.Label>
                                <DateField value={searchElement.dateToFrom ? searchElement.dateToFrom : ''}
                                    setValue={(val) => appendSearch('dateToFrom', val)} />

                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Pabaigos data iki</Form.Label>
                                <DateField value={searchElement.dateToTo ? searchElement.dateToTo : ''}
                                    setValue={(val) => appendSearch('dateToTo', val)} />
                            </Form.Group>
                        </Col>
                    </Row>

                </div>}

                <TableWithPaging
                    pageSize={500}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    dataToRender={dataToRender}
                    head={
                        <tr className="sticky top-0 left-0 bg-white">
                            <NaeTable.th id={true}>ID</NaeTable.th>
                            <NaeTable.th>Numeris</NaeTable.th>
                            <NaeTable.th>Reference</NaeTable.th>
                            <NaeTable.th>Vilkikas</NaeTable.th>
                            <NaeTable.th>Priekaba</NaeTable.th>
                            <NaeTable.th>Vairuotojas</NaeTable.th>
                            <NaeTable.th number={true}>AutoRoute, KM</NaeTable.th>
                            <NaeTable.th number={true}>LocTracker, KM</NaeTable.th>
                            <NaeTable.th> </NaeTable.th>
                            <NaeTable.th number={true}>Sąskaita, KM</NaeTable.th>
                            <NaeTable.th> </NaeTable.th>

                            <NaeTable.th number={true}>Keliai (Planuota)</NaeTable.th>
                            <NaeTable.th number={true}>Keliai (Sąskaita)</NaeTable.th>
                            <NaeTable.th> </NaeTable.th>

                            <NaeTable.th number={true}>Kitos išlaidos (Planuota)</NaeTable.th>
                            <NaeTable.th number={true}>Kitos išlaidos (Sąskaita)</NaeTable.th>
                            <NaeTable.th> </NaeTable.th>

                        </tr>}
                    renderItem={
                        (item: Trip) => {
                            const drivenKm = item.invoiceDrivenKm;
                            const odoDiff = item.locktrackerKm2;

                            const autoRouteKmFl = item.autoRouteKm2;
                            const autoRouteDrivenDiff = Math.round((drivenKm > 0 && autoRouteKmFl > 0 ? (drivenKm - autoRouteKmFl) / drivenKm : -1) * 100 * 100) / 100;
                            const autoRouteLockTrackerDiff = Math.round((drivenKm > 0 && odoDiff > 0 ? (drivenKm - odoDiff) / drivenKm : 1) * 100 * 100) / 100;


                            const invRoadCosts = item.invoiceRoadCosts;
                            const invOtherCosts = item.invoiceOtherCosts;

                            const roadCost = item.roadCost;
                            const otherCost = item.otherCost;


                            return (
                                <tr key={"list-row-" + item.id}>
                                    <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                    <NaeTable.td onClick={() => goTo(item)} link={true}>{item.number}</NaeTable.td>
                                    <NaeTable.td>{item.reference}</NaeTable.td>
                                    <NaeTable.td>{item.truckName}</NaeTable.td>
                                    <NaeTable.td>{item.trailer}</NaeTable.td>
                                    <NaeTable.td>
                                        {!!item.truckDriver && item.truckDriverName}
                                        {!!item.truckDriver2 && <Fragment> / {item.truckDriver2Name}</Fragment>}
                                    </NaeTable.td>

                                    <NaeTable.td number={true}>
                                        {item.autoRouteKm.toFixed(0)}
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        {item.locktrackerKm.toFixed(0)}
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        <span
                                            className={`${autoRouteLockTrackerDiff < 0 ? 'text-red-500' : 'text-green-500'}`}>{autoRouteLockTrackerDiff}%</span>
                                    </NaeTable.td>

                                    <NaeTable.td number={true}>
                                        {item.invoiceDrivenKm.toFixed(0)}
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        <span
                                            className={`${autoRouteDrivenDiff < 0 ? 'text-red-500' : 'text-green-500'}`}>{autoRouteDrivenDiff}%</span>
                                    </NaeTable.td>

                                    <NaeTable.td number={true}>
                                        {item.otherCost.toFixed(2)}
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        {item.invoiceOtherCosts.toFixed(2)}
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        <span
                                            className={`${invRoadCosts < roadCost ? 'text-red-500' : 'text-green-500'}`}>
                                            {(invRoadCosts - roadCost).toFixed(2)}
                                        </span>
                                    </NaeTable.td>

                                    <NaeTable.td number={true}>
                                        {item.roadCost.toFixed(2)}
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        {item.invoiceRoadCosts.toFixed(2)}
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        <span
                                            className={`${invOtherCosts < otherCost ? 'text-red-500' : 'text-green-500'}`}>
                                            {(invOtherCosts - otherCost).toFixed(2)}
                                        </span>
                                    </NaeTable.td>

                                </tr>
                            )
                        }
                    }
                />

                <div className={"space-y-2"}>
                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>
                            AutoRoute, KM
                        </div>
                        <div className={"text-right w-32"}>
                            {autoRouteKmFlTotal.toFixed(0)}
                        </div>
                    </div>
                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>
                            Loctracker, KM
                        </div>
                        <div className={"text-right w-32"}>
                            {loctrackerKmFlTotal.toFixed(0)}
                        </div>
                    </div>
                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>

                        </div>
                        <div className={"text-right w-32"}>
                            <span className={`${autoRouteLockTrackerDiffTotal < 0 ? 'text-red-500' : 'text-green-500'}`}>{autoRouteLockTrackerDiffTotal}%</span>
                        </div>
                    </div>



                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>
                            Sąskaita, KM
                        </div>
                        <div className={"text-right w-32"}>
                            {invoicesKmFlTotal.toFixed(0)}
                        </div>
                    </div>

                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>

                        </div>
                        <div className={"text-right w-32"}>
                            <span className={`${autoRouteDrivenDiffTotal < 0 ? 'text-red-500' : 'text-green-500'}`}>{autoRouteDrivenDiffTotal}%</span>
                        </div>
                    </div>

                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>

                        </div>
                        <div className={"text-right w-72"}>
                            <hr />
                        </div>
                    </div>

                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>
                            Keliai (Planuota)
                        </div>
                        <div className={"text-right w-32"}>
                            {roadCostsTotal.toFixed(2)}
                        </div>
                    </div>
                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>
                            Keliai (Sąskaita)
                        </div>
                        <div className={"text-right w-32"}>
                            {invoiceRoadCostsTotal.toFixed(0)}
                        </div>
                    </div>

                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>

                        </div>
                        <div className={"text-right w-32"}>
                            <span
                                className={`${invoiceRoadCostsTotal < roadCostsTotal ? 'text-red-500' : 'text-green-500'}`}>
                                {(invoiceRoadCostsTotal - roadCostsTotal).toFixed(2)}
                            </span>
                        </div>
                    </div>


                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>

                        </div>
                        <div className={"text-right w-72"}>
                            <hr />
                        </div>
                    </div>

                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>
                            Kitos išlaidos (Planuota)
                        </div>
                        <div className={"text-right w-32"}>
                            {otherCostsTotal.toFixed(0)}
                        </div>
                    </div>
                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>
                            Kitos išlaidos (Sąskaita)
                        </div>
                        <div className={"text-right w-32"}>
                            {invoiceOtherCostsTotal.toFixed(0)}
                        </div>
                    </div>
                    <div className={'flex'}>
                        <div className={"flex-grow text-right"}>

                        </div>
                        <div className={"text-right w-32"}>
                            <span
                                className={`${invoiceOtherCostsTotal < otherCostsTotal ? 'text-red-500' : 'text-green-500'}`}>
                                {(invoiceOtherCostsTotal - otherCostsTotal).toFixed(2)}
                            </span>
                        </div>
                    </div>


                </div>

            </UI.Card.WhiteCard>

        </div>
    );
}
