import { ORM } from "redux-orm";
import ClientModel from "./ClientModel"
import CountryModel from "./CountryModel"
import DriverTripModel from "./DriverTripModel"
import ImportedFileModel from "./ImportedFileModel"
import InvoiceModel from "./InvoiceModel"
import NoteModel from "./NoteModel"
import QueueModel from "./QueueModel"
import TripModel from "./TripModel"
import TruckModel from "./TruckModel"
import TruckFuelTankModel from "./TruckFuelTankModel"
import UserModel from "./UserModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    ClientModel,
    CountryModel,
    DriverTripModel,
    ImportedFileModel,
    InvoiceModel,
    NoteModel,
    QueueModel,
    TripModel,
    TruckModel,
    TruckFuelTankModel,
    UserModel,
);

export default orm;