import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import TableWithPaging from "../../TableWithPaging";
import { NaeTable } from "nae-react-core-styles";
import { Trip } from "./SalesTypes";
import { UI } from "@newageerp/nae-react-ui";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Driver, Truck } from "../Trucks/TruckTypes";
import DateField from "../../Forms/fields/DateField";
import moment from "moment";
// @ts-ignore
import { Multiselect } from 'multiselect-react-dropdown';
import { MultiSelectOption } from "../Reports/ReportsPage";
import { Service } from "../Services/ServiceTypes";
import { Table, Th, Td } from '@newageerp/ui.table.base.table'
import { TwTextAlignment } from "@newageerp/ui.styles.tailwind";

const moduleName = 'trip';

const uri = 'data:application/vnd.ms-excel;base64,';

const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
    'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
    'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
    'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
    '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
    'xml><![endif]--></head><body>{table}</body></html>';


const calcDiffPercent = (_v1: number, _v2: number) => {
    const v1 = Math.round(_v1);
    const v2 = Math.round(_v2);

    return Math.round((v1 > 0 && v2 > 0 ? (v1 - v2) / v1 : -1) * 100 * 100) / 100;
}
const calcDiffValue = (v1: number, v2: number) => {
    return Math.round(v1 - v2).toFixed(0);
}

export default function TripRoutesFuelKmReports() {
    const history = useHistory();

    const [fuelReports, setFuelReports] = useState<any>({});

    // FUEL
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const [state, setState] = useState('');
    const [stats, setStats] = useState<any>([]);
    const [cols, setCols] = useState([]);

    const [services, setServices] = useState<Service[]>([]);
    const [selectedServicesFvs, setSelectedServicesFvs] = useState<MultiSelectOption[]>([]);

    const serviceFvsOptions = useMemo(() => {
        return [
            { id: -1, name: 'Nepriskirtas' },
            ...services.map((item) => {
                return item.fvsCode;
            }).filter((value, index, self) => {
                return self.indexOf(value) === index;
            }).map(s => ({ id: s, name: s }))
        ]
    }, [services]);

    const getOptions = () => {
        const options = {
            dateFrom,
            dateTo,
            servicesFvsCodes: selectedServicesFvs.map(_i => _i.id),
            trucks: selectedTrucks.map(_i => _i.id),
            type: 'P',

        }
        return options;
    }

    const getStats = () => {
        setState('loading');
        ApiRest.getReport(
            'invoice-line',
            {
                options: getOptions(),
                reportValues: ['quantity', 'total'],
                reportGroupCol: 'vehicle',
                reportGroupRow: 'country',
            }
        ).then(res => {
            setStats(res.data);
            setCols(res.cols.sort());


            ApiRest.getTruckReports(res.cols.sort(), dateFrom, dateTo).then(res => {
                setFuelReports(res);
                setState('done');
            });
        });
    }


    const base64 = (s: string) => {
        return window.btoa(unescape(encodeURIComponent(s)));
    }

    const doFormat = (s: string) => {
        const context = {
            worksheet: 'Worksheet',
            // @ts-ignore
            table: window.document.getElementById("table-to-export").outerHTML,
        };

        // @ts-ignore
        return s.replace(/{(\w+)}/g, (_m, p) => context[p]);
    }

    const doDownload = () => {
        const element = window.document.createElement('a');
        element.href = uri + base64(doFormat(template));
        element.download = 'ataskaita.xls';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const [searchElement, setSearchElement] = useState<any>({});
    const appendSearch = (key: string, val: string) => {
        setSearchElement({ ...searchElement, [key]: val })
    }
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedTrucks, setSelectedTrucks] = useState<MultiSelectOption[]>([]);

    const getListData = () => {
        ApiRest.getElements('driver').then(res => {
            if (res) {
                setDrivers(res);
            }
        });
        ApiRest.getElements('truck').then(res => {
            if (res) {
                setTrucks(res.filter((t: Truck) => !!t.locktrackerNumber || t.name.indexOf('MAGNUM') >= 0));
            }
        });
    }
    useEffect(getListData, []);

    const truckOptions = useMemo(() => {
        return [
            { id: -1, name: 'Nepriskirtas' },
            ...trucks.map((item) => {
                return { id: item.id, name: item.name };
            })
        ]
    }, [trucks]);

    const [showFilter, setShowFilter] = useState(true);
    const toggleFilter = () => setShowFilter(!showFilter);

    const [readyToLoad, setReadyToLoad] = useState(false);

    // const [trips, setTrips] = useState<Trip[]>([])
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [dataToRender, setDataToRender] = useState<Trip[]>([]);

    const [filter, setFilter] = useState('');

    const getData = () => {
        // ApiRest.getElements(moduleName).then(res => {
        //     setTrips(res);
        // })

        ApiRest.getElements('service').then(res => {
            setServices(res);

            const selectedServices = res.filter((s: Service) => {
                return s.fvsCode === 'KURAS';
            }).map((s: Service) => s.fvsCode).filter((value: string, index: number, self: string[]) => {
                return self.indexOf(value) === index;
            }).map((s: string) => (
                {
                    id: s,
                    name: s
                }
            ));
            setSelectedServicesFvs(selectedServices);
        });
    }

    useEffect(getData, []);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter', 'searchElement']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                } else if (p === 'searchElement') {
                    setSearchElement(v ? v : {});
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: Trip) => {
        history.replace('/trips-reports-fuel-km', { search, activePage, filter, searchElement });
        history.push('/' + moduleName + '/' + item.id);
    }

    let autoRouteKmFlTotal = 0;
    let loctrackerKmFlTotal = 0;
    let invoicesKmFlTotal = 0;

    let otherCostsTotal = 0;
    let invoiceOtherCostsTotal = 0;
    let roadCostsTotal = 0;
    let invoiceRoadCostsTotal = 0;

    dataToRender.forEach((item: Trip) => {
        autoRouteKmFlTotal += item.autoRouteKm;
        loctrackerKmFlTotal += item.locktrackerKm;
        invoicesKmFlTotal += item.invoiceDrivenKm;

        otherCostsTotal += item.otherCost;
        invoiceOtherCostsTotal += item.invoiceOtherCosts;
        roadCostsTotal += item.roadCost;
        invoiceRoadCostsTotal += item.invoiceRoadCosts;
    })

    return (
        <div>

            <UI.Card.WhiteCard>
                <div className={"flex items-center"}>
                    <UI.Typography.H5 className={'flex-grow'}>Kuro sanaudų ataskaita</UI.Typography.H5>
                </div>

                <div className={"flex gap-2"}>
                    <UI.Form.Input placeholder={"Paieška"} className={"w-full"} value={search}
                        onChange={(e) => setSearch(e.target.value)} />

                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.nsecondary}
                        onClick={toggleFilter}>Filtras</UI.Buttons.Button>

                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.green} brightness={700}
                        onClick={doDownload}>Xlsx</UI.Buttons.Button>
                </div>

                {showFilter && <div className={'rounded-md'}>

                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Tranzakcijos data nuo</Form.Label>
                                <DateField value={dateFrom}
                                    setValue={val => setDateFrom(val)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Tranzakcijos data iki</Form.Label>
                                <DateField value={dateTo}
                                    setValue={val => setDateTo(val)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Vilkikas</Form.Label>
                                <Multiselect options={truckOptions}
                                    displayValue="name"
                                    placeholder={"Pasirinkti..."}
                                    id={"trucks"}
                                    onSelect={(selectedList: any) => setSelectedTrucks(selectedList)} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>FVS kodas</Form.Label>
                                <Multiselect options={serviceFvsOptions}
                                    displayValue="name"
                                    placeholder={"Pasirinkti..."}
                                    id={"servicesFvs"}
                                    selectedValues={selectedServicesFvs}
                                    onSelect={(selectedList: any) => setSelectedServicesFvs(selectedList)} />
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.green} className={'mb-2'}
                                onClick={getStats}>Filtruoti</UI.Buttons.Button>
                        </Col>
                    </Row>
                </div>}

                {!!state &&

                    <Fragment>
                        {state === 'loading' ?

                            <Spinner animation="border" variant="info" />

                            :
                            <Fragment>
                                {/*<Card.Header>*/}
                                {/*    <Row>*/}
                                {/*        <Col>*/}
                                {/*            Rezultatas: {recordsCount}*/}
                                {/*        </Col>*/}
                                {/*        <Col sm={2} textAlignment={TwTextAlignment.right}>*/}
                                {/*            <Button variant={"outline-success"}*/}
                                {/*                    onClick={() => exportStats('', '')}>Ekportuoti</Button>*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*</Card.Header>*/}

                                {cols.map((col: string) => {
                                    const fuelReport = (col in fuelReports) ? fuelReports[col] : {};

                                    const canFuelUsed = 'canFuelUsed' in fuelReport ? fuelReport['canFuelUsed'] : 0;

                                    const canDistance = 'canDistance' in fuelReport ? fuelReport['canDistance'] / 1000 : 0;

                                    const canOdometerValueStart = 'canOdometerValueStart' in fuelReport ? fuelReport['canOdometerValueStart'] : 0;
                                    const canOdometerValueEnd = 'canOdometerValueEnd' in fuelReport ? fuelReport['canOdometerValueEnd'] : 0;

                                    const rows: string[] = Object.keys(stats).sort();
                                    let qTotal = 0;
                                    let tTotal = 0;

                                    const trips = dataToRender.filter((t: Trip) => t.truckName === col);
                                    let locktrackerTruckKm = 0;
                                    trips.forEach(t => {
                                        locktrackerTruckKm += t.locktrackerKm;
                                    });
                                    let kmTotal = locktrackerTruckKm;


                                    return (
                                        <Table
                                            id={"table-to-export"}
                                            thead={
                                                <thead>
                                                    <tr>
                                                        <Th className="border-r border-blue-300">{" "}</Th>
                                                        <Th textAlignment={TwTextAlignment.right}>Užpilta kuro</Th>
                                                        <Th textAlignment={TwTextAlignment.right}>Suma</Th>
                                                        <Th textAlignment={TwTextAlignment.right} className="border-r border-blue-300">Litro kaina</Th>

                                                        <Th textAlignment={TwTextAlignment.right}>Pradinis spidometras</Th>
                                                        <Th textAlignment={TwTextAlignment.right}>Galinis spidometras</Th>
                                                        <Th textAlignment={TwTextAlignment.right} className="border-r border-blue-300">Nuvažiuota KM</Th>

                                                        <Th textAlignment={TwTextAlignment.right} className="border-r border-blue-300">Panaudota kuro</Th>

                                                        <Th textAlignment={TwTextAlignment.right}>Kuro norma</Th>
                                                    </tr>
                                                </thead>
                                            }
                                            tbody={
                                                <tbody>
                                                    {rows.map((key) => {
                                                        const q = !!stats[key] && !!stats[key][col] ? stats[key][col].quantity : 0;
                                                        const t = !!stats[key] && !!stats[key][col] ? stats[key][col].total : 0;

                                                        qTotal += q;
                                                        tTotal += t;



                                                        let norm = 0;
                                                        if (locktrackerTruckKm > 0) {
                                                            norm = q / locktrackerTruckKm * 100;
                                                        }

                                                        if (q === 0 && t === 0) {
                                                            return <Fragment />
                                                        }

                                                        return (
                                                            <Fragment />
                                                        )
                                                    })}
                                                    <tr>
                                                        <Td className="font-medium border-r border-blue-300">{col}</Td>
                                                        <Td textAlignment={TwTextAlignment.right}
                                                            className={'w-1/6'}
                                                        >
                                                            {qTotal.toFixed(2)}
                                                        </Td>
                                                        <Td textAlignment={TwTextAlignment.right}
                                                            className={'w-1/6'}
                                                        >
                                                            {tTotal.toFixed(2)}
                                                        </Td>
                                                        <Td textAlignment={TwTextAlignment.right}
                                                            className={'w-1/6 border-r border-blue-300'}
                                                        >
                                                            {(tTotal / qTotal).toFixed(4)}
                                                        </Td>

                                                        <Td textAlignment={TwTextAlignment.right}
                                                            className={'w-1/6'}>
                                                            {canOdometerValueStart.toFixed(0)}
                                                        </Td>
                                                        <Td textAlignment={TwTextAlignment.right}
                                                            className={'w-1/6'}>
                                                            {canOdometerValueEnd.toFixed(0)}
                                                        </Td>
                                                        <Td textAlignment={TwTextAlignment.right}
                                                            className={'w-1/6 border-r border-blue-300'}>
                                                            {canDistance.toFixed(0)}
                                                        </Td>

                                                        <Td textAlignment={TwTextAlignment.right}
                                                            className={'w-1/6 border-r border-blue-300'}>
                                                            {canFuelUsed.toFixed(2)}
                                                        </Td>

                                                        <Td textAlignment={TwTextAlignment.right}
                                                            className={'w-1/6'}>
                                                            {canDistance > 0 ? (canFuelUsed / canDistance * 100).toFixed(2) : 0}
                                                        </Td>
                                                    </tr>
                                                </tbody>
                                            }
                                        />



                                    )
                                })}


                            </Fragment>
                        }
                    </Fragment>
                }


            </UI.Card.WhiteCard>

        </div>
    );
}
