import React, { Fragment } from 'react'
import { MenuFolder, MenuSpacer } from '@newageerp/ui.menu.base.menu'
import MenuItemTruckMain from "../items/MenuItemTruckMain" 
import MenuItemTruckGroupMain from "../items/MenuItemTruckGroupMain" 
import MenuItemTruckFuelTankMain from "../items/MenuItemTruckFuelTankMain" 


export default function MenuFolderVilkikai809() {
    return (
        <Fragment>
            <MenuFolder
                item={{
                    children: 'Vilkikai',
                    icon: 'truck'
                }}
                menuFolderId='MenuFolderVilkikai809'
            >

                <MenuItemTruckMain forceSkipIcon={true}/>
                <MenuItemTruckGroupMain forceSkipIcon={true}/>
                <MenuItemTruckFuelTankMain forceSkipIcon={true}/>
            </MenuFolder>
        </Fragment>
    )
}
