import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Truck, TruckGroup, TruckTrailerGroup} from "../Pages/Trucks/TruckTypes";
import ApiRest from "../../service/ApiRest";
import {Service, ServiceGroup} from "../Pages/Services/ServiceTypes";
import {useHistory} from 'react-router-dom'
import {Country, Station} from "../Pages/Reports/ReportTypes";
import BackBtn from "../BackBtn";

interface Props {
    element: Station,
    saveData: (name: string,
               code: string,
               fvsCode: string,
    ) => void,
}

export default function StationForm(props: Props) {
    const history = useHistory();

    const {element, saveData} = props;
    const [name, setName] = useState(element.name);
    const [code, setCode] = useState(element.code);
    const [country, setCountry] = useState(element.country);

    const goBack = () => {
        history.goBack();
    }

    return (
        <Card>
            <Card.Header>
                <BackBtn/>
                Aptarnavimo stotis
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Pavadinimas</Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Kodas</Form.Label>
                        <Form.Control value={code} onChange={e => setCode(e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Šalis</Form.Label>
                        <Form.Control value={country} onChange={e => setCountry(e.target.value)}/>
                    </Form.Group>

                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(
                                    name,
                                    code,
                                    country,
                                )}>Išsaugoti</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
