
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import { Hooks, functions, UI } from "@newageerp/nae-react-ui";
import { NaeSSchemaMap } from '../../config/NaeSSchema';
import moment from "moment";
import { SelectorClientNae, SelectorCountryNae, SelectorDriverTripNae, SelectorImportedFileNae, SelectorInvoiceNae, SelectorNoteNae, SelectorTripNae, SelectorTruckNae, SelectorTruckFuelTankNae, SelectorUserNae } from '../../Components/Models/ormSelectors';
import { useClientHookNae } from '../../Components/Hooks/useClientHookNae';
import { useCountryHookNae } from '../../Components/Hooks/useCountryHookNae';
import { useDriverTripHookNae } from '../../Components/Hooks/useDriverTripHookNae';
import { useImportedFileHookNae } from '../../Components/Hooks/useImportedFileHookNae';
import { useInvoiceHookNae } from '../../Components/Hooks/useInvoiceHookNae';
import { useNoteHookNae } from '../../Components/Hooks/useNoteHookNae';
import { useTripHookNae } from '../../Components/Hooks/useTripHookNae';
import { useTruckHookNae } from '../../Components/Hooks/useTruckHookNae';
import { useTruckFuelTankHookNae } from '../../Components/Hooks/useTruckFuelTankHookNae';
import { useUserHookNae } from '../../Components/Hooks/useUserHookNae';


export const getHookForSchema = (schema: string) => {
  let selector : any;
  if (schema === 'client') {
    return useClientHookNae;
  }
  if (schema === 'country') {
    return useCountryHookNae;
  }
  if (schema === 'driver-trip') {
    return useDriverTripHookNae;
  }
  if (schema === 'imported-file') {
    return useImportedFileHookNae;
  }
  if (schema === 'invoice') {
    return useInvoiceHookNae;
  }
  if (schema === 'note') {
    return useNoteHookNae;
  }
  if (schema === 'trip') {
    return useTripHookNae;
  }
  if (schema === 'truck') {
    return useTruckHookNae;
  }
  if (schema === 'truck-fuel-tank') {
    return useTruckFuelTankHookNae;
  }
  if (schema === 'user') {
    return useUserHookNae;
  }
  return selector;
}

export const getSelectorForSchema = (schema: string) => {
  let selector : any;
  if (schema === 'client') {
    return SelectorClientNae;
  }
  if (schema === 'country') {
    return SelectorCountryNae;
  }
  if (schema === 'driver-trip') {
    return SelectorDriverTripNae;
  }
  if (schema === 'imported-file') {
    return SelectorImportedFileNae;
  }
  if (schema === 'invoice') {
    return SelectorInvoiceNae;
  }
  if (schema === 'note') {
    return SelectorNoteNae;
  }
  if (schema === 'trip') {
    return SelectorTripNae;
  }
  if (schema === 'truck') {
    return SelectorTruckNae;
  }
  if (schema === 'truck-fuel-tank') {
    return SelectorTruckFuelTankNae;
  }
  if (schema === 'user') {
    return SelectorUserNae;
  }
  return selector;
}

export function ClientEmptyFieldNae(props: PropsLink) {
  const element = useClientHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CountryEmptyFieldNae(props: PropsLink) {
  const element = useCountryHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function DriverTripEmptyFieldNae(props: PropsLink) {
  const element = useDriverTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ImportedFileEmptyFieldNae(props: PropsLink) {
  const element = useImportedFileHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function InvoiceEmptyFieldNae(props: PropsLink) {
  const element = useInvoiceHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function NoteEmptyFieldNae(props: PropsLink) {
  const element = useNoteHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function TripEmptyFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function TripOtherCostFrigoFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.otherCostFrigo`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripOtherCostFrigoFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.otherCostFrigo}
      </Fragment>
  )
}

export function TripOtherCostFrigoCostFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.otherCostFrigoCost`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripOtherCostFrigoCostFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.otherCostFrigoCost}
      </Fragment>
  )
}

export function TripOtherCostFrigoDaysFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.otherCostFrigoDays`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripOtherCostFrigoDaysFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.otherCostFrigoDays}
      </Fragment>
  )
}

export function TripAutoRouteKmFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.autoRouteKm`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripAutoRouteKmFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.autoRouteKm}
      </Fragment>
  )
}

export function TripAutoRouteKmAllowedDiffFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.autoRouteKmAllowedDiff`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripAutoRouteKmAllowedDiffFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.autoRouteKmAllowedDiff}
      </Fragment>
  )
}

export function TripTripFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.trip`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripTripFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.trip}
      </Fragment>
  )
}

export function TripLoctrackerDrivenKmFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.loctrackerDrivenKm`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripLoctrackerDrivenKmFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.loctrackerDrivenKm}
      </Fragment>
  )
}

export function TripInvoiceDrivenKmFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.invoiceDrivenKm`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripInvoiceDrivenKmFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.invoiceDrivenKm}
      </Fragment>
  )
}

export function TripRoadCostFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.roadCost`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripRoadCostFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.roadCost}
      </Fragment>
  )
}

export function TripInvoiceRoadCostsFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.invoiceRoadCosts`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripInvoiceRoadCostsFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.invoiceRoadCosts}
      </Fragment>
  )
}

export function TripOtherCostDriverFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.otherCostDriver`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripOtherCostDriverFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.otherCostDriver}
      </Fragment>
  )
}

export function TripOtherCostAdrFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.otherCostAdr`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripOtherCostAdrFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.otherCostAdr}
      </Fragment>
  )
}

export function TripOtherCostLoadFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.otherCostLoad`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripOtherCostLoadFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.otherCostLoad}
      </Fragment>
  )
}

export function TripOtherCostDowntimeFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.otherCostDowntime`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripOtherCostDowntimeFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.otherCostDowntime}
      </Fragment>
  )
}

export function TripInvoiceOtherCostsFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.invoiceOtherCosts`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripInvoiceOtherCostsFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.invoiceOtherCosts}
      </Fragment>
  )
}

export function TripOtherCostFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.otherCost`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripOtherCostFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.otherCost}
      </Fragment>
  )
}

export function TripLoctrackerDrivenKmWithAllowedDiffFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.loctrackerDrivenKmWithAllowedDiff`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripLoctrackerDrivenKmWithAllowedDiffFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.loctrackerDrivenKmWithAllowedDiff}
      </Fragment>
  )
}

export function TripAutoRouteKmWithAllowedDiffFieldNae(props: PropsLink) {
  const element = useTripHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.Trip.schema}.autoRouteKmWithAllowedDiff`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.Trip.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function TripAutoRouteKmWithAllowedDiffFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useTripHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.autoRouteKmWithAllowedDiff}
      </Fragment>
  )
}

export function TruckEmptyFieldNae(props: PropsLink) {
  const element = useTruckHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function TruckFuelTankEmptyFieldNae(props: PropsLink) {
  const element = useTruckFuelTankHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function UserEmptyFieldNae(props: PropsLink) {
  const element = useUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function UserDisabledFieldNae(props: PropsLink) {
  const element = useUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.User.schema}.disabled`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.User.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function UserDisabledFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useUserHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.disabled}
      </Fragment>
  )
}

export function UserFullNameFieldNae(props: PropsLink) {
  const element = useUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.User.schema}.fullName`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.User.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function UserFullNameFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useUserHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.fullName}
      </Fragment>
  )
}

export function UserPositionFieldNae(props: PropsLink) {
  const element = useUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.User.schema}.position`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.User.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function UserPositionFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;

  const element = useUserHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.position}
      </Fragment>
  )
}

 export const getFieldNaeViewByPath = (path: string, id: number) => {

    if (path === 'trip.otherCostFrigo') {
        return <TripOtherCostFrigoFieldNae id={id}/>;
    }
    if (path === 'trip.otherCostFrigoCost') {
        return <TripOtherCostFrigoCostFieldNae id={id}/>;
    }
    if (path === 'trip.otherCostFrigoDays') {
        return <TripOtherCostFrigoDaysFieldNae id={id}/>;
    }
    if (path === 'trip.autoRouteKm') {
        return <TripAutoRouteKmFieldNae id={id}/>;
    }
    if (path === 'trip.autoRouteKmAllowedDiff') {
        return <TripAutoRouteKmAllowedDiffFieldNae id={id}/>;
    }
    if (path === 'trip.trip') {
        return <TripTripFieldNae id={id}/>;
    }
    if (path === 'trip.loctrackerDrivenKm') {
        return <TripLoctrackerDrivenKmFieldNae id={id}/>;
    }
    if (path === 'trip.invoiceDrivenKm') {
        return <TripInvoiceDrivenKmFieldNae id={id}/>;
    }
    if (path === 'trip.roadCost') {
        return <TripRoadCostFieldNae id={id}/>;
    }
    if (path === 'trip.invoiceRoadCosts') {
        return <TripInvoiceRoadCostsFieldNae id={id}/>;
    }
    if (path === 'trip.otherCostDriver') {
        return <TripOtherCostDriverFieldNae id={id}/>;
    }
    if (path === 'trip.otherCostAdr') {
        return <TripOtherCostAdrFieldNae id={id}/>;
    }
    if (path === 'trip.otherCostLoad') {
        return <TripOtherCostLoadFieldNae id={id}/>;
    }
    if (path === 'trip.otherCostDowntime') {
        return <TripOtherCostDowntimeFieldNae id={id}/>;
    }
    if (path === 'trip.invoiceOtherCosts') {
        return <TripInvoiceOtherCostsFieldNae id={id}/>;
    }
    if (path === 'trip.otherCost') {
        return <TripOtherCostFieldNae id={id}/>;
    }
    if (path === 'trip.loctrackerDrivenKmWithAllowedDiff') {
        return <TripLoctrackerDrivenKmWithAllowedDiffFieldNae id={id}/>;
    }
    if (path === 'trip.autoRouteKmWithAllowedDiff') {
        return <TripAutoRouteKmWithAllowedDiffFieldNae id={id}/>;
    }
    if (path === 'user.disabled') {
        return <UserDisabledFieldNae id={id}/>;
    }
    if (path === 'user.fullName') {
        return <UserFullNameFieldNae id={id}/>;
    }
    if (path === 'user.position') {
        return <UserPositionFieldNae id={id}/>;
    }
}
