export const COUNTRIES = [
  { code: '+355', country: 'AL', name: 'Albanija' },
  { code: '+376', country: 'AD', name: 'Andora' },
  { code: '+43', country: 'AT', name: 'Austrija' },
  { code: '+375', country: 'BY', name: 'Baltarusija' },
  { code: '+32', country: 'BE', name: 'Belgija' },
  { code: '+387', country: 'BA', name: 'Bosnija ir Hercegovina' },
  { code: '+359', country: 'BG', name: 'Bulgarija' },
  { code: '+382', country: 'ME', name: 'Juodkalnija' },
  { code: '+357', country: 'CY', name: 'Kipras' },
  { code: '+385', country: 'HR', name: 'Kroatija' },
  { code: '+45', country: 'DK', name: 'Danija' },
  { code: '+372', country: 'EE', name: 'Estija' },
  { code: '+30', country: 'GR', name: 'Graikija' },
  { code: '+995', country: 'GE', name: 'Gruzija' },
  { code: '+354', country: 'IS', name: 'Islandija' },
  { code: '+353', country: 'IE', name: 'Airija' },
  { code: '+39', country: 'IT', name: 'Italija' },
  { code: '+44', country: 'GB', name: 'Jungtinė Karalystė' },
  { code: '+383', country: 'XK', name: 'Kosovas' },
  { code: '+371', country: 'LV', name: 'Latvija' },
  { code: '+48', country: 'PL', name: 'Lenkija' },
  { code: '+423', country: 'LI', name: 'Lichtenšteinas' },
  { code: '+370', country: 'LT', name: 'Lietuva' },
  { code: '+352', country: 'LU', name: 'Liuksemburgas' },
  { code: '+356', country: 'MT', name: 'Malta' },
  { code: '+373', country: 'MD', name: 'Moldova' },
  { code: '+377', country: 'MC', name: 'Monakas' },
  { code: '+31', country: 'NL', name: 'Nyderlandai' },
  { code: '+47', country: 'NO', name: 'Norvegija' },
  { code: '+351', country: 'PT', name: 'Portugalija' },
  { code: '+33', country: 'FR', name: 'Prancūzija' },
  { code: '+40', country: 'RO', name: 'Rumunija' },
  { code: '+7', country: 'RU', name: 'Rusija' },
  { code: '+378', country: 'SM', name: 'San Marinas' },
  { code: '+381', country: 'RS', name: 'Serbija' },
  { code: '+421', country: 'SK', name: 'Slovakija' },
  { code: '+386', country: 'SI', name: 'Slovėnija' },
  { code: '+358', country: 'FI', name: 'Suomija' },
  { code: '+34', country: 'ES', name: 'Ispanija' },
  { code: '+46', country: 'SE', name: 'Švedija' },
  { code: '+41', country: 'CH', name: 'Šveicarija' },
  { code: '+389', country: 'MK', name: 'Šiaurės Makedonija' },
  { code: '+90', country: 'TR', name: 'Turkija' },
  { code: '+380', country: 'UA', name: 'Ukraina' },
  { code: '+379', country: 'VA', name: 'Vatikanas' },
  { code: '+36', country: 'HU', name: 'Vengrija' },
  { code: '+49', country: 'DE', name: 'Vokietija' },
  { code: '+420', country: 'CZ', name: 'Čekija' },
] as const;