import {useState} from 'react';
import ApiRest from "../service/ApiRest";

interface Resp {
    loading: boolean,
    data: any,
}

export default function useApiRestPost(action: string): [(data: any) => Promise<any>, Resp] {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>({data: []});

    const doRequest = (data: any) => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            ApiRest.makeRequestPost(action, data).then((res: any) => {
                setLoading(false);
                setData(res);
                resolve(res);
            }).catch(e => {
                setLoading(false);
                reject(e);
            })
        })

    }

    return [doRequest, {loading, data}];
}
