// @ts-nocheck
import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RecoilRoot } from 'recoil'
import { I18nextProvider } from 'react-i18next'
import i18n from '../../../config/lang/i18'

interface Props {
    children: any
}

export const RoutesWrapper = ({ children }: Props) => {
    return (
        <React.Fragment>
            <I18nextProvider i18n={i18n}>
                <RecoilRoot>
                    <React.Fragment>{children}</React.Fragment>
                </RecoilRoot>
            </I18nextProvider>

            <ToastContainer
                position={'bottom-right'}
                hideProgressBar
                newestOnTop={false}
            />
        </React.Fragment>
    )
}