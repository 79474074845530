import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import ApiRest from "../../../../service/ApiRest";

interface Props {
  id: number;
  moduleName: string;
}

export default function DeleteButton(props: Props) {
  const history = useHistory();
  const { id, moduleName } = props;

  const doDelete = () => {
    let confirmMsg = '';
    if (moduleName === 'fuel-card') {
      confirmMsg = 'Ar tikrai norite ištrinti? Kortelė su tranzakcijų istorija nebus ištrinta.';
    } else if (moduleName === 'tablet') {
      confirmMsg = 'Ar tikrai norite ištrinti? Planšetė su tranzakcijų istorija nebus ištrinta.';
    }

    if (!window.confirm(confirmMsg)) return false;

    // DELETE
    ApiRest.deleteElement(moduleName, id)
      .then(res => {
        history.push('/' + moduleName + 's');
      })
      .catch(err => {
        console.error('DELETE failed:', err);
      });
  };

  return (
    <ButtonGroup vertical={true}>
      <Button variant={"danger"} className={"mb-2"} onClick={doDelete}>Ištrinti</Button>
    </ButtonGroup>
  );
}
