import React, {Fragment} from 'react';
import { Wide, WideToolbar, WideRow, Compact, CompactToolbar, CompactRow, Label } from '@newageerp/ui.form.base.form-pack';
import { useTranslation } from 'react-i18next'
import { Input } from "@newageerp/ui.form.base.form-pack";
import { SelectAdvData } from "@newageerp/ui.form.base.form-pack";

interface Props {
    element: any;
    onChange: (key: string, value: any) => void,
    compact?: boolean,

    onSave: () => void,
    onCancel: () => void,
}

export default function TruckMainForm(props: Props) {
    const { element, onChange } = props;
    const { t } = useTranslation();

    if (props.compact) {
        return (
            <Compact>
                <CompactRow label={<Label>{t('Valstybinis numeris')}</Label>} control={<Input onChange={(e: any) => onChange('name', e.target.value)} value={element.name}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Pagaminimo metai')}</Label>} control={<Input onChange={(e: any) => onChange('manufactureYear', e.target.value)} value={element.manufactureYear}/>}/>
<CompactRow label={<Label>{t('Rida')}</Label>} control={<Input onChange={(e: any) => onChange('mileage', e.target.value)} value={element.mileage}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Kuro bakas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck-fuel-tank"
                        field="name"
                        selectedId={element.fuelTank?.id}
                        onSelectId={(e: any) => onChange('fuelTank', {id: e})}
                        parentElement={element}
                        parentSchema={"truck"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<CompactRow label={<Label>{t('Grupė')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema=""
                        field=""
                        selectedId={element.truckGroup?.id}
                        onSelectId={(e: any) => onChange('truckGroup', {id: e})}
                        parentElement={element}
                        parentSchema={"truck"}
                        sort={[]}
                        />}/>
<CompactRow label={<Label>{t('Priekabos tipas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema=""
                        field=""
                        selectedId={element.truckTrailerGroup?.id}
                        onSelectId={(e: any) => onChange('truckTrailerGroup', {id: e})}
                        parentElement={element}
                        parentSchema={"truck"}
                        sort={[]}
                        />}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Lizingas nuo')}</Label>} control={<Input onChange={(e: any) => onChange('leasingFrom', e.target.value)} value={element.leasingFrom}/>}/>
<CompactRow label={<Label>{t('Lizingas iki')}</Label>} control={<Input onChange={(e: any) => onChange('leasingTo', e.target.value)} value={element.leasingTo}/>}/>
                <CompactToolbar onSave={props.onSave} onCancel={props.onCancel} />
            </Compact>
        )
    }

    return (
        <Wide>
            <WideRow label={<Label>{t('Valstybinis numeris')}</Label>} control={<Input onChange={(e: any) => onChange('name', e.target.value)} value={element.name}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Pagaminimo metai')}</Label>} control={<Input onChange={(e: any) => onChange('manufactureYear', e.target.value)} value={element.manufactureYear}/>}/>
<WideRow label={<Label>{t('Rida')}</Label>} control={<Input onChange={(e: any) => onChange('mileage', e.target.value)} value={element.mileage}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Kuro bakas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck-fuel-tank"
                        field="name"
                        selectedId={element.fuelTank?.id}
                        onSelectId={(e: any) => onChange('fuelTank', {id: e})}
                        parentElement={element}
                        parentSchema={"truck"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<WideRow label={<Label>{t('Grupė')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema=""
                        field=""
                        selectedId={element.truckGroup?.id}
                        onSelectId={(e: any) => onChange('truckGroup', {id: e})}
                        parentElement={element}
                        parentSchema={"truck"}
                        sort={[]}
                        />}/>
<WideRow label={<Label>{t('Priekabos tipas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema=""
                        field=""
                        selectedId={element.truckTrailerGroup?.id}
                        onSelectId={(e: any) => onChange('truckTrailerGroup', {id: e})}
                        parentElement={element}
                        parentSchema={"truck"}
                        sort={[]}
                        />}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Lizingas nuo')}</Label>} control={<Input onChange={(e: any) => onChange('leasingFrom', e.target.value)} value={element.leasingFrom}/>}/>
<WideRow label={<Label>{t('Lizingas iki')}</Label>} control={<Input onChange={(e: any) => onChange('leasingTo', e.target.value)} value={element.leasingTo}/>}/>
            <WideToolbar onSave={props.onSave} onCancel={props.onCancel} />
        </Wide>
    )
}