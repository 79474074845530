import React, { useState, Fragment } from "react";
import { Table, Td, Th } from "@newageerp/ui.table.base.table";
import { useTranslation } from "react-i18next";
import { Icon, IconType } from '@newageerp/ui.icons.base.icon'
import { IconList } from '@newageerp/ui.icons.base.icon-list'
import { OpenApi } from "@newageerp/nae-react-auth-wrapper";
import { TwTextColors, TwBackgroundColors } from "@newageerp/ui.styles.tailwind";
import { Button } from "@newageerp/ui.buttons.base.button";
import { UI } from "@newageerp/nae-react-ui";

import { String } from "@newageerp/data.table.string";
import { Date } from "@newageerp/data.table.date";

export interface TableSelectedProps {
    onSelect: (selectedIds: number[]) => void,
    toolbarContent: any
}

interface Props {
    data: any,
    doReload: () => void,
    className?: string,

    navigate?: (schema: string, id: number | string, el: any) => void,

    selection?: TableSelectedProps
}

export default function TripMainTable(props: Props) {
    const { data, navigate } = props;
    const { t } = useTranslation();

    const [doRemove, doRemoveData] = OpenApi.useURequest('NAEURemoveMultiple');

    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    const dataIds = data.map((i: any) => i?.id);
    const intersection = dataIds.filter((value: number) => selectedIds.includes(value));
    const isCheckedAll = intersection.length === data.length;

    const toggleSelectAll = () => {
        let _selected: number[] = JSON.parse(JSON.stringify(selectedIds));
        if (isCheckedAll) {
            dataIds.forEach((id: number) => {
                if (_selected.indexOf(id) >= 0) {
                    _selected = _selected.filter(i => id !== i);
                } else {

                }
            })
        } else {
            dataIds.forEach((id: number) => {
                if (_selected.indexOf(id) >= 0) {
                } else {
                    _selected.push(id);
                }
            })
        }
        setSelectedIds(_selected);
    }

    const toggleSelect = (id: number) => {
        const _selected: number[] = JSON.parse(JSON.stringify(selectedIds));
        if (selectedIds.indexOf(id) >= 0) {
            setSelectedIds(_selected.filter(i => id !== i));
        } else {
            setSelectedIds([..._selected, id]);
        }
    }

    const doMultipleRemove = () => {
        doRemove({
            ids: selectedIds,
            schema: 'trip'
        }).then(() => {
            setSelectedIds([]);
            props.doReload();
        })
    }

    return (
    <Fragment>
        {selectedIds.length > 0 && <div className={`tw3-flex tw3-items-center tw3-gap-6 tw3-mb-2 ${TwTextColors.slate400}`}>
            <p>{t('Pažymėta')}: {selectedIds.length}</p>
            <Button confirmation={true} bgColor={TwBackgroundColors.white} onClick={doMultipleRemove}><Icon type={IconType.Solid} icon={IconList.trash} ping={doRemoveData.loading} /></Button>
        </div>}
        <Table
            className={props.className}
            thead={
                <thead>
                    <tr>
                        <Th textAlignment="tw3-text-left">{t("Numeris")}</Th>
<Th textAlignment="tw3-text-left">{t("Reference")}</Th>
<Th textAlignment="tw3-text-left">{t("Vadybininkas")}</Th>
<Th textAlignment="tw3-text-left">{t("Vilkikas")}</Th>
<Th textAlignment="tw3-text-left">{t("Priekaba")}</Th>
<Th textAlignment="tw3-text-left">{t("Vairuotojas")}</Th>
<Th textAlignment="tw3-text-left">{t("Vairuotojas 2")}</Th>
<Th textAlignment="tw3-text-left">{t("Data nuo")}</Th>
<Th textAlignment="tw3-text-left">{t("Data iki")}</Th>
<Th textAlignment="tw3-text-left">{t("Komentaras")}</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {data.map((item: any) => {
                        const itemNumber0 = item?.number;
const itemReference1 = item?.reference;
const itemUserFullName2 = item?.user?.fullName;
const itemUserId2Id = item?.user?.id;
const itemTruckName3 = item?.truck?.name;
const itemTruckId3Id = item?.truck?.id;
const itemTrailer4 = item?.trailer;
const itemTruckDriverFullName5 = item?.truckDriver?.fullName;
const itemTruckDriverId5Id = item?.truckDriver?.id;
const itemTruckDriver2FullName6 = item?.truckDriver2?.fullName;
const itemTruckDriver2Id6Id = item?.truckDriver2?.id;
const itemDateFrom7 = item?.dateFrom;
const itemDateTo8 = item?.dateTo;
const itemComment9 = item?.comment;
                        return (
                            <tr key={`row-${item?.id}`}>
                                <Td textAlignment="tw3-text-left" className="">
<UI.Buttons.SchemaMultiLink
    id={item.id}
    schema={'trip'}
    className={'text-left'}
    onClick={() => {
        if (navigate) {
            navigate('trip', item.id, item);
        }
    }}
    buttonsNl={false}
    onClickDef={'main'}
>
<String value={itemNumber0}/>
</UI.Buttons.SchemaMultiLink>
</Td>
<Td textAlignment="tw3-text-left" className=""><String value={itemReference1}/></Td>
<Td textAlignment="tw3-text-left" className=""><String value={itemUserFullName2}/></Td>
<Td textAlignment="tw3-text-left" className=""><String value={itemTruckName3}/></Td>
<Td textAlignment="tw3-text-left" className=""><String value={itemTrailer4}/></Td>
<Td textAlignment="tw3-text-left" className=""><String value={itemTruckDriverFullName5}/></Td>
<Td textAlignment="tw3-text-left" className=""><String value={itemTruckDriver2FullName6}/></Td>
<Td textAlignment="tw3-text-left" className="whitespace-nowrap"><Date value={itemDateFrom7}/></Td>
<Td textAlignment="tw3-text-left" className="whitespace-nowrap"><Date value={itemDateTo8}/></Td>
<Td textAlignment="tw3-text-left" className=""><String value={itemComment9}/></Td>
                            </tr>
                        )
                    })}
                </tbody>
            }
        />
    </Fragment>
    )
}