import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Button, Card, Col, Form, Row, Spinner, Table} from 'react-bootstrap';
import ApiRest from "../../../service/ApiRest";
import {Country} from "./ReportTypes";
import {FuelCard, Truck, TruckGroup, TruckTrailerGroup} from "../Trucks/TruckTypes";
import {NaeTable} from 'nae-react-core-styles';
// @ts-ignore
import {Multiselect} from 'multiselect-react-dropdown';
import {Service, ServiceGroup} from "../Services/ServiceTypes";
import DateField from "../../Forms/fields/DateField";

export interface MultiSelectOption {
    id: string,
    name: string
}

const reportValuesOptions: MultiSelectOption[] = [
    {id: 'quantity', name: "Kiekis"},
    {id: 'total', name: "Suma"},
    {id: 'vatTotal', name: "PVM Suma"},
    {id: 'totalWithVat', name: "Suma su PVM"},
    {id: 'average', name: 'Vid. kaina'}
];
const reportGroupsColsOptions: MultiSelectOption[] = [
    {id: 'client', name: "Klientas"},
    {id: 'country', name: "Šalis"},
    {id: 'service', name: "Paslauga"},
    {id: 'serviceFvsCode', name: "FVS kodas"},
    {id: 'serviceGroup', name: "Grupė"},
    {id: 'vehicle', name: "Transportas"},
    {id: 'vehicleGroup', name: "Parkas"},
    {id: 'vehicleTrailerGroup', name: "Priekabos tipas"},
];

interface Props {
    selectedCards?: MultiSelectOption[]
}

export default function ReportsPage(props: Props) {
    const [state, setState] = useState('');
    const [recordsCount, setRecordsCount] = useState(0);
    const [stats, setStats] = useState<any>([]);
    const [cols, setCols] = useState([]);

    const [reportValues, setReportValues] = useState(['quantity', 'total']);
    const [reportGroupCol, setReportGroupCol] = useState('serviceGroup');
    const [reportGroupRow, setReportGroupRow] = useState('country');


    // FILTERS
    const [invDateFrom, setInvDateFrom] = useState('');
    const [invDateTo, setInvDateTo] = useState('');
    const [invSerialNo, setInvSerialNo] = useState('');
    const [invClientCode, setInvClientCode] = useState('');

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [selectedCountries, setSelectedCountries] = useState<MultiSelectOption[]>([]);
    const [station, setStation] = useState('');
    const [selectedServices, setSelectedServices] = useState<MultiSelectOption[]>([]);
    const [selectedServicesFvs, setSelectedServicesFvs] = useState<MultiSelectOption[]>([]);
    const [serviceFvsCode, setServiceFvsCode] = useState('');
    const [selectedServiceGroups, setSelectedServiceGroups] = useState<MultiSelectOption[]>([]);

    const [selectedTrucks, setSelectedTrucks] = useState<MultiSelectOption[]>([]);
    const [selectedTruckGroups, setSelectedTruckGroups] = useState<MultiSelectOption[]>([]);
    const [selectedTruckTrailers, setSelectedTruckTrailers] = useState<MultiSelectOption[]>([]);
    const [selectedCards, setSelectedCards] = useState<MultiSelectOption[]>(props.selectedCards ? props.selectedCards : []);

    const [yearsFrom, setYearsFrom] = useState('');
    const [yearsTo, setYearsTo] = useState('');

    const [mileageFrom, setMileageFrom] = useState('');
    const [mileageTo, setMileageTo] = useState('');

    const [leasingFrom, setLeasingFrom] = useState('');
    const [leasingTo, setLeasingTo] = useState('');

    const [quantityFrom, setQuantityFrom] = useState('');
    const [quantityTo, setQuantityTo] = useState('');
    const [type, setType] = useState('P');


    // GET DATA
    const [countries, setCountries] = useState<Country[]>([]);
    const [truckGroups, setTruckGroups] = useState<TruckGroup[]>([]);
    const [truckTrailers, setTruckTrailers] = useState<TruckTrailerGroup[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);

    const [services, setServices] = useState<Service[]>([]);
    const [serviceGroups, setServiceGroups] = useState<ServiceGroup[]>([]);

    const [fuelCards, setFuelCards] = useState<FuelCard[]>([]);

    const toggleReportValues = (id: string) => {
        if (reportValues.indexOf(id) >= 0) {
            setReportValues([...reportValues.filter(i => i !== id)]);
        } else {
            setReportValues([...reportValues, id]);
        }
    }

    const getData = () => {
        ApiRest.getElements('country').then(res => {
            setCountries(res);
        });
        ApiRest.getElements('truck-group').then(res => {
            setTruckGroups(res);
        });
        ApiRest.getElements('truck-trailer').then(res => {
            setTruckTrailers(res);
        });
        ApiRest.getElements('truck').then(res => {
            setTrucks(res.filter((t: Truck) => !!t.locktrackerNumber || t.name.indexOf('MAGNUM') >= 0));
        });
        ApiRest.getElements('service').then(res => {
            setServices(res);
        });
        ApiRest.getElements('service-group').then(res => {
            setServiceGroups(res);
        });
        ApiRest.getElements('fuel-card').then(res => {
            setFuelCards(res.filter((t: FuelCard) => t.status === 10));

            if (window.location.hash) {
                const jsonHash = JSON.parse(decodeURIComponent(window.location.hash.substr(1)));
                if (jsonHash.card) {
                    setSelectedCards([jsonHash.card])
                }
            }
        });

    }

    const getOptions = () => {
        const options = {
            invDateFrom,
            invDateTo,
            invSerialNo,
            invClientCode,
            dateFrom,
            dateTo,
            countries: selectedCountries.map(_i => _i.id),
            station,
            services: selectedServices.map(_i => _i.id),
            servicesFvsCodes: selectedServicesFvs.map(_i => _i.id),
            // serviceFvsCode,
            serviceGroups: selectedServiceGroups.map(_i => _i.id),
            trucks: selectedTrucks.map(_i => _i.id),
            truckGroups: selectedTruckGroups.map(_i => _i.id),
            truckTrailers: selectedTruckTrailers.map(_i => _i.id),
            cards: selectedCards.map(_i => _i.id),

            yearsFrom,
            yearsTo,
            mileageFrom,
            mileageTo,
            leasingFrom,
            leasingTo,
            quantityFrom,
            quantityTo,

            type,

        }
        return options;
    }

    const exportStats = (rowFilter: any, colFilter: any) => {
        ApiRest.exportReport(
            'invoice-line',
            {
                options: getOptions(),
                reportValues,
                reportGroupCol,
                reportGroupRow,
                rowFilter,
                colFilter
            }
        );
    }

    const getStats = () => {
        setState('loading');

        ApiRest.getReport(
            'invoice-line',
            {
                options: getOptions(),
                reportValues,
                reportGroupCol,
                reportGroupRow
            }
        ).then(res => {
            setRecordsCount(res.count);
            setStats(res.data);
            setCols(res.cols.sort());
            setState('done');
        });
    }

    const countriesOptions = useMemo(() => {
        const c = countries.map((item) => {
            return item.fvsCode;
        }).sort();
        return [
            {id: -1, name: 'Nepriskirtas'},

            ...c.filter((value, index, self) => {
                return self.indexOf(value) === index;
            }).map((i) => {
                return {id: i, name: i};
            })
        ]
    }, [countries]);
    const truckGroupOptions = useMemo(() => {
        return [
            {id: -1, name: 'Nepriskirtas'},
            ...truckGroups.map((item) => {
                return {id: item.id, name: item.name};
            })
        ]
    }, [truckGroups]);
    const truckTrailerOptions = useMemo(() => {
        return [
            {id: -1, name: 'Nepriskirtas'},
            ...truckTrailers.map((item) => {
                return {id: item.id, name: item.name};
            })
        ]
    }, [truckTrailers]);
    const truckOptions = useMemo(() => {
        return [
            {id: -1, name: 'Nepriskirtas'},
            ...trucks.map((item) => {
                return {id: item.id, name: item.name};
            })
        ]
    }, [trucks]);

    const serviceOptions = useMemo(() => {
        return [
            {id: -1, name: 'Nepriskirtas'},
            ...services.map((item) => {
                return {id: item.id, name: item.name};
            })
        ]
    }, [services]);
    const serviceFvsOptions = useMemo(() => {
        return [
            {id: -1, name: 'Nepriskirtas'},
            ...services.map((item) => {
                return item.fvsCode;
            }).filter((value, index, self) => {
                return self.indexOf(value) === index;
            }).map(s => ({id: s, name: s}))
        ]
    }, [services]);
    const serviceGroupsOptions = useMemo(() => {
        return [
            {id: -1, name: 'Nepriskirtas'},
            ...serviceGroups.map((item) => {
                return {id: item.id, name: item.name};
            })
        ]
    }, [serviceGroups]);
    const fuelCardOptions = useMemo(() => {
        return [
            {id: -1, name: 'Nepriskirtas'},
            ...fuelCards.map((item) => {
                return {id: item.id, name: item.number};
            })
        ]
    }, [fuelCards]);

    useEffect(getData, []);

    const onColClick = (row: string, col: string) => {
        console.log({row, col})
    }

    return (
        <Fragment>
            <Card>
                <Card.Header>Filtras</Card.Header>
                <Card.Body>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-sm-6"}>
                            <Form.Label>Sąskatos data</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <DateField value={invDateFrom}
                                                   setValue={val => setInvDateFrom(val)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <DateField value={invDateTo}
                                                   setValue={val => setInvDateTo(val)}/>
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Numeris</Form.Label>
                            <Form.Control value={invSerialNo} onChange={e => setInvSerialNo(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Kliento kodas</Form.Label>
                            <Form.Control value={invClientCode} onChange={e => setInvClientCode(e.target.value)}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-sm-6"}>
                            <Form.Label>Tranzakcijos data</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <DateField value={dateFrom}
                                                   setValue={val => setDateFrom(val)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <DateField value={dateTo}
                                                   setValue={val => setDateTo(val)}/>
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Šalis</Form.Label>
                            <Multiselect options={countriesOptions}
                                         displayValue="name"
                                         placeholder={"Pasirinkti..."}
                                         id={"countries"}
                                         onSelect={(selectedList: any) => setSelectedCountries(selectedList)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Aptarnavimo stotis</Form.Label>
                            <Form.Control value={station} onChange={e => setStation(e.target.value)}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Tipas</Form.Label>
                            <Form.Control value={type} onChange={e => setType(e.target.value)} as={"select"}>
                                <option value={"P"}>Pirkimas</option>
                                <option value={"S"}>Pardavimas</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Paslauga</Form.Label>
                            <Multiselect options={serviceOptions}
                                         displayValue="name"
                                         placeholder={"Pasirinkti..."}
                                         id={"services"}
                                         onSelect={(selectedList: any) => setSelectedServices(selectedList)}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>FVS kodas</Form.Label>
                            <Multiselect options={serviceFvsOptions}
                                         displayValue="name"
                                         placeholder={"Pasirinkti..."}
                                         id={"servicesFvs"}
                                         onSelect={(selectedList: any) => setSelectedServicesFvs(selectedList)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Grupė</Form.Label>
                            <Multiselect options={serviceGroupsOptions}
                                         displayValue="name"
                                         placeholder={"Pasirinkti..."}
                                         id={"service_groups"}
                                         onSelect={(selectedList: any) => setSelectedServiceGroups(selectedList)}/>
                        </Form.Group>

                    </Form.Row>


                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Transportas</Form.Label>
                            <Multiselect options={truckOptions}
                                         displayValue="name"
                                         placeholder={"Pasirinkti..."}
                                         id={"trucks"}
                                         onSelect={(selectedList: any) => setSelectedTrucks(selectedList)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Parkas</Form.Label>
                            <Multiselect options={truckGroupOptions}
                                         displayValue="name"
                                         placeholder={"Pasirinkti..."}
                                         id={"truck_group"}
                                         onSelect={(selectedList: any) => setSelectedTruckGroups(selectedList)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Priekabos tipas</Form.Label>

                            <Multiselect options={truckTrailerOptions}
                                         displayValue="name"
                                         placeholder={"Pasirinkti..."}
                                         id={"truck_trailer"}
                                         onSelect={(selectedList: any) => setSelectedTruckTrailers(selectedList)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Kortelė</Form.Label>
                            <Multiselect options={fuelCardOptions}
                                         displayValue="name"
                                         placeholder={"Pasirinkti..."}
                                         id={"cards"}
                                         onSelect={(selectedList: any) => setSelectedCards(selectedList)}
                                         selectedValues={selectedCards}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Pagaminimo metai</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Control type={"number"} placeholder={"nuo"} value={yearsFrom}
                                                      onChange={e => setYearsFrom(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Control type={"number"} placeholder={"iki"} value={yearsTo}
                                                      onChange={e => setYearsTo(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={1}></Col>
                            </Row>

                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Rida</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Control type={"number"} placeholder={"nuo"} value={mileageFrom}
                                                      onChange={e => setMileageFrom(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Control type={"number"} placeholder={"iki"} value={mileageTo}
                                                      onChange={e => setMileageTo(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={1}></Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Lizingas</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Control type={"number"} placeholder={"nuo"} value={leasingFrom}
                                                      onChange={e => setLeasingFrom(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Control type={"number"} placeholder={"iki"} value={leasingTo}
                                                      onChange={e => setLeasingTo(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={1}></Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Kiekis</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Control type={"number"} placeholder={"nuo"} value={quantityFrom}
                                                      onChange={e => setQuantityFrom(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Control type={"number"} placeholder={"iki"} value={quantityTo}
                                                      onChange={e => setQuantityTo(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={1}></Col>
                            </Row>
                        </Form.Group>
                    </Form.Row>


                    <Form.Row>
                        <Form.Group as={Col} sm={3}>
                            <Form.Label>Eilutė</Form.Label>

                            <Form.Control as="select" value={reportGroupRow}
                                          onChange={e => setReportGroupRow(e.target.value)}>
                                <option value={""} key={"row-0"}>{" "}</option>
                                {reportGroupsColsOptions.map(i => <option key={'row-' + i.id}
                                                                          value={i.id}>{i.name}</option>)}
                            </Form.Control>

                        </Form.Group>
                        <Form.Group as={Col} sm={3}>
                            <Form.Label>Stulpelis</Form.Label>

                            <Form.Control as="select" value={reportGroupCol}
                                          onChange={e => setReportGroupCol(e.target.value)}>
                                <option key={"col-0"} value={""}>{" "}</option>
                                {reportGroupsColsOptions.map(i => <option key={'col-' + i.id}
                                                                          value={i.id}>{i.name}</option>)}
                            </Form.Control>

                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Reikšmės</Form.Label>
                            <Row>
                                <Col style={{height: 36}} className={"v-center"}>
                                    {reportValuesOptions.map(i => <Form.Check checked={reportValues.indexOf(i.id) >= 0}
                                                                              onChange={() => toggleReportValues(i.id)}
                                                                              inline
                                                                              label={i.name} type={"checkbox"}
                                                                              id={`i-v-${i.id}`}
                                                                              key={`i-v-${i.id}`}/>)}
                                </Col>
                            </Row>
                        </Form.Group>

                    </Form.Row>


                </Card.Body>
                <Card.Footer>
                    <Button variant={"primary"} onClick={getStats}>Filtruoti</Button>

                    <Button variant={"outline-light"}>Atšaukti</Button>
                </Card.Footer>
            </Card>

            {!!state &&
            <Card>

                {state === 'loading' ?
                    <Card.Body>
                        <Spinner animation="border" variant="info"/>
                    </Card.Body>
                    :
                    <Fragment>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Rezultatas: {recordsCount}
                                </Col>
                                <Col sm={2} className={"text-right"}>
                                    <Button variant={"outline-success"}
                                            onClick={() => exportStats('', '')}>Ekportuoti</Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {reportValues.map((type, rindex) => {
                                const rows: string[] = Object.keys(stats).sort();
                                let totals: any = {};
                                let grandTotal = 0;

                                const title = reportValuesOptions.filter((p) => p.id === type)[0].name;

                                return (
                                    <Fragment key={"report-value-" + rindex}>
                                        <h4>{title}</h4>
                                        <Table striped={true} className={"reports-table"}>
                                            <thead>
                                            <tr>
                                                <NaeTable.th>{""}</NaeTable.th>
                                                {cols.map((k, colIndex) => <NaeTable.th
                                                    key={"th-" + rindex + "-" + colIndex}
                                                    number={true}>{k}</NaeTable.th>)}
                                                {type !== 'average' &&
                                                <NaeTable.th>{""}</NaeTable.th>
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rows.map((key) => {
                                                const row = stats[key];
                                                let total = 0;
                                                return (
                                                    <tr>
                                                        <NaeTable.td link={true}>{key}</NaeTable.td>
                                                        {cols.map((k, colIndex) => {
                                                            let val = 0.00;
                                                            if (type === 'average') {
                                                                if (!!row[k] && !!row[k]['average_q'] && row[k]['average_q'] !== 0) {
                                                                    val = Math.round(row[k]['average_t'] / row[k]['average_q'] * 1000) / 1000;
                                                                }
                                                            } else if (!!row[k] && !!row[k][type]) {
                                                                val = Math.round(row[k][type] * 100) / 100;
                                                                total += val
                                                                if (!totals[k]) {
                                                                    totals[k] = 0;
                                                                }
                                                                totals[k] += val;
                                                                grandTotal += val;
                                                            }

                                                            return (<td className={"text-right"}
                                                                        onClick={() => exportStats(key, k)}
                                                                        key={"td-" + rindex + "-" + colIndex}
                                                                        title={key + ' / ' + k}>
                                                                {val !== 0.00 ? val : " "}
                                                            </td>)
                                                        })}
                                                        {type !== 'average' &&
                                                        <NaeTable.td
                                                            number={true}><strong>{Math.round(total * 100) / 100}</strong></NaeTable.td>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                            {type !== 'average' &&
                                            <tr>
                                                <NaeTable.td link={true}>{" "}</NaeTable.td>
                                                {cols.map((k, colIndex) => {
                                                    let val = 0.00;
                                                    if (!!totals[k]) {
                                                        val = Math.round(totals[k] * 100) / 100;
                                                    }

                                                    return (<NaeTable.td number={true}
                                                                         key={"td-" + rindex + "-" + colIndex}>
                                                        <strong>{val !== 0.00 ? val : " "}</strong>
                                                    </NaeTable.td>)
                                                })}
                                                <NaeTable.td link={true}
                                                             number={true}>{Math.round(grandTotal * 100) / 100}</NaeTable.td>
                                            </tr>
                                            }
                                            </tbody>
                                        </Table>
                                    </Fragment>
                                )
                            })}
                        </Card.Body>
                    </Fragment>
                }
            </Card>
            }
        </Fragment>
    )
}
