const IBAN_REGEX = {
    LT: /^LT\d{18}$/, // Lithuania: LT + 18 digits (20 total)
    LV: /^LV\d{19}$/, // Latvia: LV + 19 digits (21 total)
    EE: /^EE\d{18}$/, // Estonia: EE + 18 digits (20 total)
    PL: /^PL\d{26}$/, // Poland: PL + 26 digits (28 total)
    DE: /^DE\d{20}$/, // Germany: DE + 20 digits (22 total)
  };
  
  export const validateIBAN = (iban: string): boolean => {

    const cleanIban = iban.replace(/\s/g, '').toUpperCase();
    
    const countryCode = cleanIban.slice(0, 2);

    const regex = IBAN_REGEX[countryCode as keyof typeof IBAN_REGEX];
    
    if (!regex) {
      return false;
    }
    
    return regex.test(cleanIban);
  };

  export const formatIBAN = (iban: string): string => {
    const cleanIban = iban.replace(/\s/g, '').toUpperCase();
    return cleanIban.replace(/(.{4})/g, '$1 ').trim();
  };