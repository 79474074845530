import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import TruckRelTable from "../tables/TruckRelTable"
import { OpenApi } from '@newageerp/nae-react-auth-wrapper';
import { functions, UI } from '@newageerp/nae-react-ui';
import { PageContainer, PageContainerMarginTop } from '@newageerp/ui.paging.base.page-container'
import { SearchToolbar, SearchToolbarMarginBottom } from '@newageerp/ui.components.list.search-toolbar'
import { SortingItem } from '@newageerp/ui.components.list.sort-controller';
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { ContentWidgetProps } from "@newageerp/nae-react-ui/dist/UI/Widget/NEWidgets";
import { WhiteCardWithTitle } from '@newageerp/ui.cards.base.white-card-with-title'

interface Props {
    navigate?: (schema: string, id: number | string, el: any) => void,
    dataProcessing?: (data: any[]) => any[],
    relId?: number,
    hideSearchBar?: boolean,
    hidePaging?: boolean,
}

export function TruckRelTableDataSourceByTruckFuelTankWidget(props: ContentWidgetProps) {
    return (
        <WhiteCardWithTitle title={"Vilkikai"} compact={true}>
            <TruckRelTableDataSourceByTruckFuelTank
                relId={props.element.id}
                hideSearchBar={props.options.hideSearchBar}
                hidePaging={props.options.hidePaging}
                dataProcessing={props.options.dataProcessing}
                navigate={props.options.navigate}
            />
        </WhiteCardWithTitle>
    )
}

export default function TruckRelTableDataSourceByTruckFuelTank(props: Props) {
    const history = useHistory()

    const [socketId, setSocketId] = useState('')

    const otherTabs = null;

    const headerRef = useRef(null)
    const scrollToHeader = () => {
        if (headerRef && headerRef.current) {
            // @ts-ignore
            headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    const pageSize = 20;

    const sort = [{"key":"i.name","value":"ASC"}];

    const defaultState = {
        page: 1,
        sort: sort,
        quickSearch: '',
    }

    const isCreatable = false;

    const quickSearchFields : any = ["i.name","i.truckGroup.name","i.locktrackerNumber"];
    const predefinedFilter : any = null;
    const relFilter : any = ["i.fuelTank","=",props.relId,true];

    const [dataState, setDateState] = useState(defaultState);

    const setActivePage = (page: number) => {
        if (page !== dataState.page) {
            const _state = JSON.parse(JSON.stringify(dataState));
            _state.page = page;
            setDateState(_state);
        }
    }

    const setQuickSearch = (s: string) => {
        if (s !== dataState.quickSearch) {
            const _state = JSON.parse(JSON.stringify(dataState));
            _state.page = 1;
            _state.quickSearch = s;
            setDateState(_state);
        }
    }

    const setSort = (sort: SortingItem[]) => {
        if (sort !== dataState.sort) {
            const _state = JSON.parse(JSON.stringify(dataState));
            _state.page = 1;
            _state.sort = sort;
            setDateState(_state);
        }
    }

    const tab = functions.tabs.getTabFromSchemaAndType('truck', 'rel');
    const fieldsToReturn = functions.tabs.getTabFieldsToReturn(tab);

    const [getData, dataResult] = OpenApi.useUList('truck', fieldsToReturn);
    const { t } = useTranslation();

    const navigate = (schema: string, id: number | string, el: any) => {
        if (props.navigate) {
            props.navigate(schema, id, el);
        } else {
            history.push(`/u/${schema}/main/view/${id}`);
        }
    }

    const navigateToTab = (type: string) => {
        history.push(`/u/truck/${type}/list`)
    }

    const onCreate = () => {
        history.push('/u/truck/main/edit/new')
    }

    const loadData = () => {

        let _filter: any = [{ and: [] }]
        if (!!predefinedFilter) {
            _filter[0]['and'].push(predefinedFilter);
        }
        if (!!relFilter) {
            _filter[0]['and'].push(relFilter);
        }

        if (quickSearchFields && !!dataState.quickSearch) {
            let _or: any = quickSearchFields.map((s: any) => {
                if (typeof s === 'string') {
                    return [s, 'contains', dataState.quickSearch, true]
                }
                return [
                    s.key,
                    s.method ? s.method : 'contains',
                    dataState.quickSearch,
                    s.directSelect
                ]
            })
            _filter[0]['and'].push({ or: _or })
        }

        let filter = _filter[0]['and'].length > 0 ? _filter : []

        getData(
            filter,
            dataState.page,
            pageSize,
            dataState.sort.filter((s: SortingItem) => s.key !== ''),
        );
    }
    useEffect(loadData, [dataState]);


    const records = dataResult.data.records;
    const pages = Math.ceil(records / pageSize);

    const dataProcessing = (data: any[]) => {
        if (props.dataProcessing) {
            return props.dataProcessing(data);
        }
        return data;
    }

    const dataToRender = dataProcessing(dataResult.data.data);

    useEffect(() => {
        if (!!props.relId && props.relId > 0) {
            setSocketId(props.relId + '-' + uuidv4())
        }
    }, [props.relId])

    useEffect(() => {
        if (socketId) {
            UI.Socket.Service.subscribeToList(
                {
                    key: socketId,
                    data: {
                        "truck.fuelTank.id": props.relId
                    }
                },
                loadData
            )
        }

        return () => {
            if (socketId) {
                UI.Socket.Service.unSubscribeFromList({ key: socketId })
            }
        }
    }, [socketId])

    return (
        <div ref={headerRef}>
            {!props.hideSearchBar &&
                <SearchToolbar
                    onCreate={isCreatable?onCreate:undefined}
                    search={
                        {
                            searchValue: dataState.quickSearch,
                            onSearch: setQuickSearch
                        }
                    }
                    sort={
                        {
                            onSort: setSort,
                            sort: dataState.sort,
                            defaultSort: sort,
                            schema: 'truck'
                        }
                    }
                    marginBottom={SearchToolbarMarginBottom['40px']}
                    tabSwitch={
                        otherTabs?
                        {
                            options: otherTabs,
                            currentTab: 'rel',
                            onTabSwitch: navigateToTab
                        }:
                        undefined
                    }
                />
            }
            <div className="overflow-x-auto">
                <TruckRelTable
                        data={dataToRender}
                        doReload={loadData}
                        className={`${dataResult.loading?'tw3-animate-pulse':''}`}
                        navigate={navigate}
                />
            </div>
            {!props.hidePaging && pages > 1 &&
                <PageContainer
                            pages={pages}
                            activePage={dataState.page}
                            setActivePage={setActivePage}
                            marginTop={PageContainerMarginTop['20px']}
                            hidePageSelectionSelect={true}
                />
            }
        </div>
    )
}
