import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {Button, Card, Col, Container, Row, Table} from "react-bootstrap";
import {ServiceGroup} from "./ServiceTypes";
import {NaeTable} from "nae-react-core-styles";
import {UI} from "@newageerp/nae-react-ui";

const moduleName = 'service-group';

export default function ServicesGroupListPage() {
    const history = useHistory();

    const [serviceGroups, setServiceGroups] = useState<ServiceGroup[]>([])

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setServiceGroups(res);
        })
    }

    useEffect(getData, []);

    const goTo = (item: ServiceGroup) => {
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"card-table"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Grupė
                                </Col>
                                <Col className={"text-right"}>
                                    <UI.Buttons.Button icon={"fal fa-fw fa-plus"} onClick={goToNew}>
                                        Nauja
                                    </UI.Buttons.Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table striped hover className={"mt-2"}>
                                <thead>
                                <tr>
                                    <NaeTable.th id={true}>ID</NaeTable.th>
                                    <NaeTable.th>Pavadinimas</NaeTable.th>
                                </tr>
                                </thead>
                                <tbody>
                                {serviceGroups.map((item: ServiceGroup) => {
                                    return (
                                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                            <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                            <NaeTable.td link={true}>{item.name}</NaeTable.td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </Col>
            </Row>
        </Container>
    );
}
