import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { NaeLayout } from 'nae-react-core-styles';
import ApiRest from "../../../service/ApiRest";
import { toast } from "react-toastify";
import { SystemUser } from "./UserTypes";
import UserForm from "../../Forms/UserForm";
import { Popup } from "@newageerp/ui.popups.base.popup";
import { Card, Form, Table } from "react-bootstrap";
import { usePermissions } from "../../User/PermissionProvider";

const moduleName = 'user';

interface ParamTypes {
    id: string
}

const defElement: SystemUser = {
    id: 0,
    fullName: '',
    position: '',
    email: '',
    disabled: false,
}

interface Permission {
    id: number;
    permissionName: string;
    userFriendlyName: string;
    userFriendlyDescription: string;
}

export default function UsersPage() {
    const history = useHistory();
    const { hasPermission } = usePermissions();
    const [element, setElement] = useState(defElement);
    const { id } = useParams<ParamTypes>();
    const [showPopup, setShowPopup] = useState(false);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

    const saveData = async (
        fullName: string,
        position: string,
        email: string,
        password: string,
        disabled: boolean,
        selectedPermissions: number[]
    ) => {
        if (id === 'new') {
            const res = await ApiRest.addElement(
                moduleName,
                {
                    fullName,
                    position,
                    email,
                    password,
                    disabled,
                }
            );
            toast.success('Įrašas išsaugotas');
            setElement(res);
            history.replace('/' + moduleName + '/' + res.id);
        } else {
            await ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    fullName,
                    position,
                    email,
                    password,
                    disabled,
                }
            );
            toast.success('Įrašas išsaugotas');
            setElement({
                ...element,
                fullName,
                position,
                email,
                disabled,
            });
            if (hasPermission('CAN_MANAGE_USER_PERMISSIONS')) {
                await ApiRest.makeRequestPost(`permissions/user/${element.id}/`, { permissions: selectedPermissions });
                fetchUserPermissions(element.id);
            }
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
                fetchPermissions();
                fetchUserPermissions(res.id);
            }).catch(e => {
                console.error(e);
            });
        }
    }

    const fetchPermissions = async () => {
        const response = await ApiRest.getElements('permissions');
        setPermissions(response);
    };

    const fetchUserPermissions = async (userId: number) => {
        const response = await ApiRest.getElements(`permissions/user/${userId}`);
        setSelectedPermissions(response.map((p: Permission) => p.id));
    };

    const handlePermissionChange = (permissionId: number) => {
        setSelectedPermissions(prevSelected =>
            prevSelected.includes(permissionId)
                ? prevSelected.filter(id => id !== permissionId)
                : [...prevSelected, permissionId]
        );
    };

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <UserForm
                element={element}
                saveData={saveData}
                onAssignPermissions={() => setShowPopup(true)}
                selectedPermissions={selectedPermissions}
                handlePermissionChange={handlePermissionChange}
                permissions={permissions}
            />
        </Fragment>
        : <Fragment />
        ;

    const rightComponent = (
        <Fragment />
    );

    return (<Fragment>

        <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent} />

        {showPopup && (
            <Popup onClose={() => setShowPopup(false)} header="Teisių priskyrimas">
                <Card>
                    <Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Teisė</th>
                                    <th>Aprašymas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permissions.map(permission => (
                                    <tr key={permission.id} className="gap-2">
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                checked={selectedPermissions.includes(permission.id)}
                                                onChange={() => handlePermissionChange(permission.id)}
                                                className="mr-4"
                                            />
                                        </td>
                                        <td>{permission.userFriendlyName}</td>
                                        <td>{permission.userFriendlyDescription}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Popup>
        )}
    </Fragment>
    )
}
