class ApiUploadService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/';
    }

    authRequestOptions = (method: string = 'POST') => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: method,
            headers: {
                'Authorization': token
            },
        }
    };

    upload = (data: FormData) => {
        const url = this.baseUrl + 'upload/process';

        const requestOptions = {
            ...this.authRequestOptions('POST'),
            body: data
        };

        return fetch(url, requestOptions).then(res => res.json()).then(res => this._checkResponse(res));
    }


    _checkResponse = (res: any) => {
        return new Promise<any>((success, reject) => {
            if (!!res && res.success !== 0) {
                success(res);
            } else {
                reject(res.errors);
            }
        });
    }
}

const ApiUpload = new ApiUploadService();
export default ApiUpload;
