import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import ApiRest from "../../../service/ApiRest";
import {toast} from "react-toastify";
import CountryForm from "../../Forms/CountryForm";
import InvoiceLinesListCard from "../Invoices/InvoiceLinesListCard";

const moduleName = 'country';

interface ParamTypes {
    id: string
}

const defElement = {
    id: 0,
    name: '',
    code: '',
    fvsCode: '',
}


export default function CountriesPage() {
    const history = useHistory();

    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (name: string,
                      code: string,
                      fvsCode: string,
    ) => {
        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    name,
                    code,
                    fvsCode,
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
                history.push('/' + moduleName + '/' + res.id);
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    name,
                    code,
                    fvsCode,
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <CountryForm element={element} saveData={saveData}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Fragment>
        </Fragment>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

            {element.id > 0 &&
            <InvoiceLinesListCard options={{countryId: element.id}}/>
            }

        </Fragment>
    )
}
