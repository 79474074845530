import { INaeSchema } from "@newageerp/nae-react-ui/dist/interfaces";
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "Bookmark",
        "schema": "bookmark",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Client",
        "schema": "client",
        "title": "Klientas",
        "titlePlural": "Klientai",
        "required": [],
        "scopes": []
    },
    {
        "className": "Country",
        "schema": "country",
        "title": "\u0160alis",
        "titlePlural": "\u0160alys"
    },
    {
        "className": "Driver",
        "schema": "driver",
        "title": "Vairuotojas",
        "titlePlural": "Vairuotojai"
    },
    {
        "className": "DriverTrip",
        "schema": "driver-trip",
        "title": "Komandiruot\u0117",
        "titlePlural": "Komandiruot\u0117s",
        "required": [],
        "scopes": []
    },
    {
        "className": "FollowUp",
        "schema": "follow-up",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "FuelCard",
        "schema": "fuel-card",
        "title": "Kortel\u0117",
        "titlePlural": "Kortel\u0117s"
    },
    {
        "className": "FuelCardHistory",
        "schema": "fuel-card-history",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "ImportedFile",
        "schema": "imported-file",
        "title": "Importuotas failas",
        "titlePlural": "Importuoti failai"
    },
    {
        "className": "Invoice",
        "schema": "invoice",
        "title": "S\u0105skaita",
        "titlePlural": "S\u0105skaitos"
    },
    {
        "className": "InvoiceLine",
        "schema": "invoice-line",
        "title": "S\u0105skaitos eilut\u0117",
        "titlePlural": "S\u0105skaitos eilut\u0117s"
    },
    {
        "className": "LocTrackerTask",
        "schema": "loc-tracker-task",
        "title": "LocTracker u\u017eduotis",
        "titlePlural": "LocTracker u\u017eduotys"
    },
    {
        "className": "Note",
        "schema": "note",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Service",
        "schema": "service",
        "title": "Paslauga",
        "titlePlural": "Paslaugos"
    },
    {
        "className": "ServiceGroup",
        "schema": "service-group",
        "title": "Paslaug\u0173 grup\u0117",
        "titlePlural": "Paslaug\u0173 grup\u0117s"
    },
    {
        "className": "Station",
        "schema": "station",
        "title": "Aptarnavimo stotis",
        "titlePlural": "Aptarnavimo stotys"
    },
    {
        "className": "Task",
        "schema": "task",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "TaskCategory",
        "schema": "task-category",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Trip",
        "schema": "trip",
        "title": "Kelion\u0117s lapas",
        "titlePlural": "Kelion\u0117s lapai"
    },
    {
        "className": "Truck",
        "schema": "truck",
        "title": "Vilkikas",
        "titlePlural": "Vilkikai"
    },
    {
        "className": "TruckFuelTank",
        "schema": "truck-fuel-tank",
        "title": "Kuro bakas",
        "titlePlural": "Kuro bakai",
        "required": [],
        "scopes": []
    },
    {
        "className": "TruckGroup",
        "schema": "truck-group",
        "title": "Vilkik\u0173 grup\u0117",
        "titlePlural": "Vilkik\u0173 grup\u0117s"
    },
    {
        "className": "TruckTrailerGroup",
        "schema": "truck-trailer-group",
        "title": "Priekab\u0173 tipas",
        "titlePlural": "Priekab\u0173 tipai"
    },
    {
        "className": "User",
        "schema": "user",
        "title": "Vartotojas",
        "titlePlural": "Vartotojai"
    }
]
        export const NaeSSchemaMap = {
    "Driver": {
        "className": "Driver",
        "schema": "driver"
    },
    "Trip": {
        "className": "Trip",
        "schema": "trip"
    },
    "FuelCard": {
        "className": "FuelCard",
        "schema": "fuel-card"
    },
    "Invoice": {
        "className": "Invoice",
        "schema": "invoice"
    },
    "Truck": {
        "className": "Truck",
        "schema": "truck"
    },
    "TruckTrailerGroup": {
        "className": "TruckTrailerGroup",
        "schema": "truck-trailer-group"
    },
    "TruckGroup": {
        "className": "TruckGroup",
        "schema": "truck-group"
    },
    "Service": {
        "className": "Service",
        "schema": "service"
    },
    "Station": {
        "className": "Station",
        "schema": "station"
    },
    "InvoiceLine": {
        "className": "InvoiceLine",
        "schema": "invoice-line"
    },
    "FuelCardHistory": {
        "className": "FuelCardHistory",
        "schema": "fuel-card-history"
    },
    "ImportedFile": {
        "className": "ImportedFile",
        "schema": "imported-file"
    },
    "ServiceGroup": {
        "className": "ServiceGroup",
        "schema": "service-group"
    },
    "Country": {
        "className": "Country",
        "schema": "country"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "LocTrackerTask": {
        "className": "LocTrackerTask",
        "schema": "loc-tracker-task"
    },
    "Note": {
        "className": "Note",
        "schema": "note"
    },
    "Task": {
        "className": "Task",
        "schema": "task"
    },
    "TaskCategory": {
        "className": "TaskCategory",
        "schema": "task-category"
    },
    "Bookmark": {
        "className": "Bookmark",
        "schema": "bookmark"
    },
    "FollowUp": {
        "className": "FollowUp",
        "schema": "follow-up"
    },
    "TruckFuelTank": {
        "className": "TruckFuelTank",
        "schema": "truck-fuel-tank"
    },
    "Client": {
        "className": "Client",
        "schema": "client"
    },
    "DriverTrip": {
        "className": "DriverTrip",
        "schema": "driver-trip"
    }
}