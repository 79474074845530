import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FuelCard, Truck, TruckGroup } from "./TruckTypes";
import ApiRest from "../../../service/ApiRest";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import TableWithPaging from "../../TableWithPaging";
import { NaeTable } from "nae-react-core-styles";
import moment from "moment";
import DateField from "../../Forms/fields/DateField";
import { UI } from "@newageerp/nae-react-ui";
const moduleName = 'fuel-card';

export default function FuelCardsListPage() {
    const history = useHistory();

    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = () => setShowFilter(!showFilter);

    const [groups, setGroups] = useState([]);
    const [trucks, setTrucks] = useState([]);

    const [searchElement, setSearchElement] = useState<any>({});

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [fuelCards, setFuelCards] = useState<FuelCard[]>([])
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [dataToRender, setDataToRender] = useState<FuelCard[]>([]);

    const [filter, setFilter] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setFuelCards(res);
        })
    }
    useEffect(getData, []);

    const getListData = () => {
        ApiRest.getElements('truck-group').then(res => {
            if (res) {
                setGroups(res);
            }
        });
        ApiRest.getElements('truck').then(res => {
            if (res) {
                setTrucks(res.filter((t: Truck) => !!t.locktrackerNumber || t.name.indexOf('MAGNUM') >= 0));
            }
        });
    }
    useEffect(getListData, []);


    const filterData = () => {
        if (readyToLoad) {
            let _data = fuelCards;
            if (search) {
                _data = _data.filter((item: FuelCard) => {
                    return item.number.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.truckGroupName.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.truckName.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.supplier.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.pin.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.comment.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || (item.historyText ? item.historyText : "").toLowerCase().indexOf(search.toLowerCase()) > -1
                });
            }

            if (filter) {
                if (filter === 'no_group') {
                    _data = _data.filter((item: FuelCard) => {
                        return item.truckGroupName === ''
                    });
                }
                if (filter === 'active') {
                    _data = _data.filter((item: FuelCard) => {
                        return item.status === 10
                    });
                }
                if (filter === 'no_active') {
                    _data = _data.filter((item: FuelCard) => {
                        return item.status === 0
                    });
                }
            } else {
                // _data = _data.filter((item: FuelCard) => {
                //     return item.status === 10
                // });
            }

            if (searchElement) {
                if (searchElement.supplier) {
                    _data = _data.filter((item: FuelCard) => {
                        return item.supplier.toLowerCase().indexOf(searchElement.supplier.toLowerCase()) > -1
                    });
                }
                if (searchElement.number) {
                    _data = _data.filter((item: FuelCard) => {
                        return item.number.toLowerCase().indexOf(searchElement.number.toLowerCase()) > -1
                    });
                }
                if (searchElement.truck && searchElement.truck > 0) {
                    _data = _data.filter((item: FuelCard) => {
                        return item.truck == searchElement.truck;
                    });
                }
                if (searchElement.pin) {
                    _data = _data.filter((item: FuelCard) => {
                        return item.pin.toLowerCase().indexOf(searchElement.pin.toLowerCase()) > -1
                    });
                }
                if (searchElement.dateFrom) {
                    _data = _data.filter((item: FuelCard) => {
                        return moment(item.date) >= moment(searchElement.dateFrom);
                    });
                }
                if (searchElement.dateTo) {
                    _data = _data.filter((item: FuelCard) => {
                        return moment(item.date) <= moment(searchElement.dateTo);
                    });
                }
                if (searchElement.truckGroup) {
                    _data = _data.filter((item: FuelCard) => {
                        return item.truckGroup == searchElement.truckGroup;
                    });
                }
                if (searchElement.status) {
                    if (searchElement.status == 5) {
                        _data = _data.filter((item: FuelCard) => {
                            return item.status === 0;
                        });
                    }
                    if (searchElement.status == 10) {
                        _data = _data.filter((item: FuelCard) => {
                            return item.status === 10;
                        });
                    }
                } else {
                    _data = _data.filter((item: FuelCard) => {
                        return item.status === 10;
                    });
                }
                if (searchElement.comment) {
                    _data = _data.filter((item: FuelCard) => {
                        return item.comment.toLowerCase().indexOf(searchElement.comment.toLowerCase()) > -1 ||
                        (item.historyText ? item.historyText : "").toLowerCase().indexOf(searchElement.comment.toLowerCase()) > -1
                    });
                }
            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, fuelCards, filter, readyToLoad, searchElement]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter', 'searchElement']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                } else if (p === 'searchElement') {
                    setSearchElement(v ? v : {});
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: FuelCard) => {
        history.replace('/fuel-cards', { search, activePage, filter, searchElement });
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    const appendSearch = (key: string, val: string) => {
        setSearchElement({ ...searchElement, [key]: val })
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"card-table"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Kortelės
                                </Col>
                                <Col className={"text-right"}>
                                    <UI.Buttons.Button icon={"fal fa-fw fa-plus"} onClick={goToNew}>
                                        Nauja
                                    </UI.Buttons.Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Header className={"search"}>
                            <Row>
                                <Col className={"v-center"}>
                                    <input placeholder={"Paieška"} className={"search-input"} value={search}
                                        onChange={(e) => setSearch(e.target.value)} />
                                </Col>
                                <Col sm={"auto"}>
                                    {/*<select value={filter} onChange={e => setFilter(e.target.value)}>*/}
                                    {/*    <option value={""}>Filtras</option>*/}
                                    {/*    <option value={"no_group"}>Nepriskirta grupė</option>*/}
                                    {/*    <option value={"active"}>Aktyvios</option>*/}
                                    {/*    <option value={"no_active"}>Neaktyvios</option>*/}
                                    {/*</select>*/}
                                    <UI.Buttons.Button bgColor={UI.Buttons.ButtonBgColor.nsecondary} onClick={toggleFilter}>Filtras</UI.Buttons.Button>
                                </Col>
                            </Row>
                            {showFilter && <Fragment>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Tiekėjas</Form.Label>
                                            <Form.Control value={searchElement.supplier ? searchElement.supplier : ''}
                                                onChange={e => appendSearch('supplier', e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Kortelės nr.</Form.Label>
                                            <Form.Control value={searchElement.number ? searchElement.number : ''}
                                                onChange={e => appendSearch('number', e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Vilkikas</Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mr-sm-2"
                                                value={searchElement.truck ? searchElement.truck : ''}
                                                onChange={(e) => appendSearch('truck', e.target.value)}
                                            >
                                                <option value="0">Pasirinkite...</option>
                                                {trucks.map((truck: Truck) => <option key={'opt-g' + truck.id}
                                                    value={truck.id}>{truck.name}</option>)}

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Pin</Form.Label>
                                            <Form.Control value={searchElement.pin ? searchElement.pin : ''}
                                                onChange={e => appendSearch('pin', e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Galiojimo data nuo</Form.Label>
                                            <DateField value={searchElement.dateFrom ? searchElement.dateFrom : ''}
                                                setValue={(val) => appendSearch('dateFrom', val)} />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Galiojimo data iki</Form.Label>
                                            <DateField value={searchElement.dateTo ? searchElement.dateTo : ''}
                                                setValue={(val) => appendSearch('dateTo', val)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Grupė</Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mr-sm-2"
                                                value={searchElement.truckGroup ? searchElement.truckGroup : ''}
                                                onChange={(e) => appendSearch('truckGroup', e.target.value)}
                                            >
                                                <option value="0">Pasirinkite...</option>
                                                {groups.map((group: TruckGroup) => <option key={'opt-g' + group.id}
                                                    value={group.id}>{group.name}</option>)}

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Statusas</Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mr-sm-2"
                                                value={searchElement.status ? searchElement.status : ''}
                                                onChange={(e) => appendSearch('status', e.target.value)}
                                            >
                                                <option value="">Visos</option>
                                                <option value="5">Neaktyvios</option>
                                                <option value="10">Aktyvios</option>

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Komentaras</Form.Label>
                                            <Form.Control
                                                value={searchElement.comment ? searchElement.comment : ''}
                                                onChange={e => appendSearch('comment', e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Fragment>}

                        </Card.Header>
                        <TableWithPaging
                            activePage={activePage}
                            setActivePage={setActivePage}
                            dataToRender={dataToRender}
                            head={
                                <tr>
                                    <NaeTable.th id={true}>ID</NaeTable.th>
                                    <NaeTable.th>Tiekėjas</NaeTable.th>
                                    <NaeTable.th>Kortelės nr.</NaeTable.th>
                                    <NaeTable.th>Vilkikas</NaeTable.th>
                                    <NaeTable.th>Pin</NaeTable.th>
                                    <NaeTable.th>Galiojimo data</NaeTable.th>
                                    <NaeTable.th>Grupė</NaeTable.th>
                                    <NaeTable.th>Statusas</NaeTable.th>
                                </tr>}
                            renderItem={
                                (item: FuelCard) => {
                                    return (
                                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                            <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                            <NaeTable.td link={true}>{item.supplier}</NaeTable.td>
                                            <NaeTable.td link={true}>{item.number}</NaeTable.td>
                                            <NaeTable.td>{item.truckName}</NaeTable.td>

                                            <NaeTable.td>{item.pin}</NaeTable.td>
                                            <NaeTable.td>{item.date}</NaeTable.td>

                                            <NaeTable.td>{item.truckGroupName}</NaeTable.td>

                                            <NaeTable.td>{item.status === 10 ? "Aktyvios" : "Neaktyvios"}</NaeTable.td>
                                        </tr>
                                    )
                                }
                            }
                        />

                    </Card>


                </Col>
            </Row>
        </Container>
    );
}
