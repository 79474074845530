import { OpenApi } from "@newageerp/nae-react-auth-wrapper";
import { UI } from "@newageerp/nae-react-ui";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import DataCacheSocketComponent from "../../../Components/Hooks/DataCacheSocketComponent";
import MenuFolderDuomenysc60 from "../../menu/folders/MenuFolderDuomenysc60";
import MenuFolderImportavimasc97 from "../../menu/folders/MenuFolderImportavimasc97";
import MenuFolderNustatymaifad from "../../menu/folders/MenuFolderNustatymaifad";
import MenuFolderPardavimai171 from "../../menu/folders/MenuFolderPardavimai171";
import MenuFolderSskaitos79f from "../../menu/folders/MenuFolderSskaitos79f";
import { MenuProvider } from '@newageerp/ui.menu.base.menu-provider'
import MenuFolderVilkikai809 from "../../menu/folders/MenuFolderVilkikai809";
import MenuFolderKomandiruots6c8 from "../../menu/folders/MenuFolderKomandiruots6c8";

export default function LeftMenu() {
  const userState: any = useRecoilValue(OpenApi.naeUserState);
  const { t } = useTranslation();
  const history = useHistory();
  const goTo = (p: string) => {
    history.push(p);
  };

  return (
    <Fragment>
      {/* <div className={"mb-4"}>
        <div className={"flex gap-4 items-center px-2"}>

          <button onClick={() => goTo("/c/apps/bookmarks")} title={t("Sekami")}>
            <i className="fad fa-fw fa-bookmark text-nsecondary-100" />
          </button>

          <button
            onClick={() => goTo(`/c/apps/follow-up`)}
            title={t("Priminimai")}
          >
            <i className="fad fa-fw fa-bell text-nsecondary-100" />
          </button>

          <UI.Tasks.Toolbar userId={userState.id} />

          <UI.EventsHistory.Toolbar />
        </div>
      </div> */}
      <MenuProvider allowMultipleOpen={false} badgeValues={{}}>
        <MenuFolderImportavimasc97 />
        <MenuFolderPardavimai171 />
        <MenuFolderKomandiruots6c8 />
        <MenuFolderSskaitos79f />
        <MenuFolderVilkikai809 />
        <MenuFolderDuomenysc60 />
        <MenuFolderNustatymaifad />
      </MenuProvider>
      <DataCacheSocketComponent />
    </Fragment>
  );
}
