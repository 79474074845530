import {useState} from 'react';
import ApiRest from "../service/ApiRest";

interface Resp {
    loading: boolean,
    data: any,
}

export default function useApiRest(action: string): [() => Promise<any>, Resp] {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>({});

    const doRequest = () => {
        setLoading(true);
        return new Promise((resolve) => {
            ApiRest.makeRequest(action).then((res: any) => {
                setLoading(false);
                setData(res);
                resolve(res);
            })
        })

    }

    return [doRequest, {loading, data}];
}
