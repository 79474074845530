import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { FamilyContact } from "../Pages/Trucks/TruckTypes";
import { COUNTRIES } from '../Utils/countries';

interface Props {
  driverId: number;
  contact?: FamilyContact;
  onClose: () => void;
  onSave: (contact: FamilyContact) => void;
}

export default function FamilyContactPopup({ driverId, contact, onClose, onSave }: Props) {
  const [name, setName] = useState(contact?.name || '');
  const [surname, setSurname] = useState(contact?.surname || '');
  const [phonePrefix1, setPhonePrefix1] = useState('+370');
  const [phoneNumber1, setPhoneNumber1] = useState('');
  const [email1, setEmail1] = useState(contact?.email1 || '');
  const [comment, setComment] = useState(contact?.comment || '');
  const isEditMode = !!contact;

  useEffect(() => {
    if (contact && contact.telNumber1) {
      const prefix = COUNTRIES.find(p => contact.telNumber1.startsWith(p.code));
      if (prefix) {
        setPhonePrefix1(prefix.code);
        setPhoneNumber1(contact.telNumber1.slice(prefix.code.length));
      } else {
        setPhoneNumber1(contact.telNumber1);
      }
    }
  }, [contact]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 8) {
      setPhoneNumber1(value);
    }
  };

  const handleSave = () => {
    const contactData: FamilyContact = {
      id: contact?.id || Date.now(),
      name,
      surname,
      telNumber1: `${phonePrefix1}${phoneNumber1}`,
      email1,
      comment,
    };
    onSave(contactData);
  };

  return (
    <div className="px-4 pb-2">
      <Form.Group className="mb-3">
        <Form.Label>Vardas</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Pavardė</Form.Label>
        <Form.Control
          type="text"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Tel. numeris</Form.Label>
        <Row>
          <Col xs={4} style={{ paddingRight: '5px' }}>
            <Form.Control
              as="select"
              value={phonePrefix1}
              onChange={(e) => setPhonePrefix1(e.target.value)}
            >
              {COUNTRIES.map((prefix) => (
                <option key={prefix.code} value={prefix.code}>
                  {prefix.code} ({prefix.country})
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col xs={8} style={{ paddingLeft: '5px' }}>
            <Form.Control
              type="text"
              value={phoneNumber1}
              onChange={handlePhoneChange}
              placeholder="12345678"
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>El. paštas</Form.Label>
        <Form.Control
          type="email"
          value={email1}
          onChange={(e) => setEmail1(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Komentaras</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Form.Group>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!name || !surname || !phoneNumber1}
        >
          {isEditMode ? 'Atnaujinti' : 'Pridėti'}
        </Button>
        <Button
          variant="secondary"
          onClick={onClose}
        >
          Atšaukti
        </Button>
      </div>
    </div>
  );
}