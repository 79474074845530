import React, { Fragment, useState } from "react";
import { WhiteCard } from "@newageerp/ui.cards.base.white-card";
import { CardWidgetTitle } from "@newageerp/ui.cards.base.card-widget-title";
import { IconType } from "@newageerp/ui.icons.base.icon";
import { IconList } from "@newageerp/ui.icons.base.icon-list";
import {
  TwBackgroundColors,
  TwTextColors,
} from "@newageerp/ui.styles.tailwind";
import { SquareButton } from "@newageerp/ui.buttons.base.square-button";
import { useTranslation } from "react-i18next";
import { TripLine } from "../../Components/Pages/Sales/SalesTypes";
import { Table, Td, Th } from "@newageerp/ui.table.base.table";
import TripFormTripLine from "../../Components/Forms/TripFormTripLine";
import { Popup, PopupSize } from "@newageerp/ui.popups.base.popup";
import TripLocatorTasks from "../../Components/Pages/Sales/TripLocatorTasks";
import { useTripHookNae } from "../../Components/Hooks/useTripHookNae";
import { PropsId } from "../ModelsCacheData/types";

export default function TripsDirections(props: PropsId) {
  const element = useTripHookNae(props.id);

  const [showPopup, setShowPopup] = useState(false);

  const isReadonly = false;

  const { t } = useTranslation();

  const [tripLines, setTripLines] = useState<TripLine[]>([]);

  const addTripLine = (obj?: TripLine) => {
    const lastOdo =
      tripLines.length > 0 ? tripLines[tripLines.length - 1].odoFinish : "";
    setTripLines([
      ...tripLines,
      obj
        ? obj
        : {
            city: "",
            dateStart: "",
            dateFinish: "",
            odoStart: "",
            odoFinish: lastOdo,
            comment: "",
          },
    ]);
  };
  const removeTripLine = (index: number) => {
    setTripLines([...tripLines.filter((_l, i) => index !== i)]);
  };

  const upTripLine = (index: number) => {
    if (index > 0) {
      const indexZero = tripLines[index - 1];
      const indexEq = tripLines[index];
      const _tripLines = [...tripLines];
      _tripLines[index - 1] = indexEq;
      _tripLines[index] = indexZero;
      setTripLines(_tripLines);
    }
  };
  const downTripLine = (index: number) => {
    if (index + 1 < tripLines.length) {
      const indexZero = tripLines[index + 1];
      const indexEq = tripLines[index];
      const _tripLines = [...tripLines];
      _tripLines[index + 1] = indexEq;
      _tripLines[index] = indexZero;
      setTripLines(_tripLines);
    }
  };

  const setDataToTripLine = (index: number, key: string, value: string) => {
    setTripLines([
      ...tripLines.map((_l, i) => {
        if (index === i) {
          const _el = { ..._l, [key]: value };
          return _el;
        }
        return _l;
      }),
    ]);
  };

  if (!element) {
    return <Fragment />;
  }

  return (
    <div>
      <WhiteCard>
        <div className="flex items-center gap-2">
          <CardWidgetTitle className="flex-grow">
            {t("Maršrutas")}
          </CardWidgetTitle>
          <SquareButton
            bgColor={TwBackgroundColors.slate50}
            icon={{
              type: IconType.SolidButton,
              icon: IconList.plus,
              className: TwTextColors.slate400,
            }}
            onClick={() => addTripLine()}
          />
          <SquareButton
            bgColor={TwBackgroundColors.slate50}
            icon={{
              type: IconType.SolidButton,
              icon: IconList.locationPin,
              className: TwTextColors.slate400,
            }}
            onClick={() => setShowPopup(true)}
          />
        </div>

        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Miestas")}</Th>
                <Th>{t("Data")}</Th>
                <Th>{t("Odo")}</Th>
                <Th style={{ width: 100 }}></Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {tripLines.map((_l: TripLine, i: number) => {
                return (
                  <TripFormTripLine
                    downTripLine={downTripLine}
                    setDataToTripLine={setDataToTripLine}
                    line={_l}
                    index={i}
                    removeTripLine={removeTripLine}
                    upTripLine={upTripLine}
                    isReadonly={isReadonly}
                    tripLinesCount={tripLines.length}
                    key={"trip-line-" + i}
                  />
                );
              })}
            </tbody>
          }
        />
      </WhiteCard>

      {showPopup && (
        <Popup onClose={() => setShowPopup(false)} header={t("Užduotys")}>
            <TripLocatorTasks
              addTripLine={addTripLine}
              tripLines={tripLines}
              element={element}
              getData={() => {}}
            />
        </Popup>
      )}
    </div>
  );
}
