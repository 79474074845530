import React from "react";
import { UI } from "@newageerp/nae-react-ui";
import { Col, Form } from "react-bootstrap";
import { TripLine } from "../Pages/Sales/SalesTypes";
import { Td } from "@newageerp/ui.table.base.table";
import { Datepicker, Input, Textarea } from "@newageerp/ui.form.base.form-pack";
import { ActionTagIcon } from '@newageerp/sfs.locktracker.action-tag-icon'
// import {Menu} from '@newageerp/sfs-menu'

interface Props {
  line: TripLine;
  index: number;
  isReadonly: boolean;
  setDataToTripLine: (index: number, key: string, value: string) => void;

  removeTripLine: (index: number) => void;
  upTripLine: (index: number) => void;
  downTripLine: (index: number) => void;

  tripLinesCount: number;
}

export default function TripFormTripLine(props: Props) {
  const {
    line,
    index,
    isReadonly,
    setDataToTripLine,
    removeTripLine,
    upTripLine,
    downTripLine,
    tripLinesCount,
  } = props;
  return (
    <tr>
      <Td className={"text-sm"}>

        <ActionTagIcon type={line.tagName ? line.tagName : ""} className="mr-2 text-lg" />

        {!!line.taskId ? (
          line.city
        ) : (
          <Textarea
            value={line.city}
            disabled={isReadonly}
            rows={2}
            onChange={(e) => setDataToTripLine(index, "city", e.target.value)}
          />
        )}
      </Td>
      <Td className="space-y-1">
        <div className="flex gap-2">
          <Datepicker
            value={line.dateStart}
            onChange={(e) => setDataToTripLine(index, "dateStart", e)}
            className={"field-date-small"}
            hideIcon={true}
          />
          <Datepicker
          hideIcon={true}
            value={line.dateFinish}
            onChange={(e) => setDataToTripLine(index, "dateFinish", e)}
            className={"field-date-small"}
          />
        </div>
      </Td>

      <Td className="space-y-1">
        <div className="flex gap-2">
          <Input
            value={line.odoStart}
            disabled={isReadonly}
            onChange={(e) => setDataToTripLine(index, "odoStart", e.target.value)}
            className={"field-date-small"}
          />
          <Input
            value={line.odoFinish}
            disabled={isReadonly}
            onChange={(e) =>
              setDataToTripLine(index, "odoFinish", e.target.value)
            }
            className={"field-date-small"}
          />
        </div>
      </Td>
      <Td>
        {parseFloat(line.odoStart) > 0 && parseFloat(line.odoFinish) > 0 && (
          <p className={"text-right"}>
            {Math.round(
              parseFloat(line.odoFinish) - parseFloat(line.odoStart)
            ).toFixed(0)}
          </p>
        )}
        <div className={"grid grid-cols-2 gap-1"}>
          <UI.PopoverConfirm.PopoverConfirm
            customText={
              <Textarea
                value={line.comment}
                disabled={isReadonly}
                rows={2}
                onChange={(e) =>
                  setDataToTripLine(index, "comment", e.target.value)
                }
              />
            }
            onClick={() => { }}
            cancelText={"Uždaryti"}
            hideConfirm={true}
          >
            <button>
              <UI.Icons.FA
                type={"fad"}
                icon={"comment"}
                className={!!line.comment ? "text-green-500" : ""}
                fixedWidth={true}
              />
            </button>
          </UI.PopoverConfirm.PopoverConfirm>

          <UI.PopoverConfirm.PopoverConfirm
            onClick={() => removeTripLine(index)}
          >
            <button>
              <i className={"fad fa-fw fa-minus text-red-500"} />
            </button>
          </UI.PopoverConfirm.PopoverConfirm>

          <button onClick={() => downTripLine(index)}>
            <i
              className={`fad fa-fw fa-caret-down ${index + 1 < tripLinesCount
                  ? "text-nsecondary-500"
                  : "text-gray-300"
                } text-lg`}
            />
          </button>

          <button onClick={() => upTripLine(index)}>
            <i
              className={`fad fa-fw fa-caret-up ${index > 0 ? "text-nsecondary-500" : "text-gray-300"
                } text-lg`}
            />
          </button>

        </div>
      </Td>
    </tr>
  );
}
