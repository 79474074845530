import React, {Fragment, useState} from "react";
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap'
import ApiUpload from "../../../service/ApiUpload";
import UploadsReportListPage from "./UploadsReportListPage";
import {useHistory} from 'react-router-dom'
import {UI} from '@newageerp/nae-react-ui'

export default function UploadReportPage() {

    return (
        <UploadsReportListPage/>
    )
}
