import React from "react";
import {Card, Col, Form} from "react-bootstrap";
import {Invoice} from "../Pages/Invoices/InvoiceTypes";
import BackBtn from "../BackBtn";


interface Props {
    element: Invoice,
}

export default function InvoiceForm(props: Props) {
    const {element} = props;

    const currencyRate = (element.total / element.totalEur).toFixed(6);

    return (
        <Card>
            <Card.Header><BackBtn/>Sąskaita</Card.Header>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Data</Form.Label>
                            <Form.Text>{element.date}</Form.Text>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Numeris</Form.Label>
                            <Form.Text>{element.number}</Form.Text>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Kliento kodas</Form.Label>
                            <Form.Text>{element.client}</Form.Text>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Valiuta</Form.Label>
                            <Form.Text>{element.currency}</Form.Text>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Kursas</Form.Label>
                            <Form.Text>{currencyRate}</Form.Text>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Eilučių kiekis</Form.Label>
                            <Form.Text className={"text-right"}>{element.linesCount}</Form.Text>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Iš viso, EUR</Form.Label>
                            <Form.Text className={"text-right"}>{element.totalEur.toFixed(2)}</Form.Text>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>PVM, EUR</Form.Label>
                            <Form.Text className={"text-right"}>{element.totalVatEur.toFixed(2)}</Form.Text>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Iš viso su PVM, EUR</Form.Label>
                            <Form.Text className={"text-right"}>{element.totalWithVatEur.toFixed(2)}</Form.Text>
                        </Form.Group>
                    </Form.Row>

                    {element.currency !== 'EUR' &&
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Iš viso, {element.currency}</Form.Label>
                            <Form.Text className={"text-right"}>{element.total.toFixed(2)}</Form.Text>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>PVM, {element.currency}</Form.Label>
                            <Form.Text className={"text-right"}>{element.totalVat.toFixed(2)}</Form.Text>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Iš viso su PVM, {element.currency}</Form.Label>
                            <Form.Text className={"text-right"}>{element.totalWithVat.toFixed(2)}</Form.Text>
                        </Form.Group>
                    </Form.Row>
                    }
                </Form>
            </Card.Body>
        </Card>)
}
