import React from "react";
import {useHistory} from "react-router-dom";
import ApiRest from "../service/ApiRest";
import {Button} from "react-bootstrap";

interface Props {
    id: number,
    module: string,
    path: string
}

export default function CommonRemoveBtn(props: Props) {
    const history = useHistory();
    const {id, module, path} = props;

    const doDelete = () => {
        if (!window.confirm('Ar tikrai norite ištrinti?')) return false;

        ApiRest.deleteElement(props.module, id).then(res => {
            history.push(props.path);
        });
    }

    return (
        <Button variant={"danger"} onClick={doDelete}>Ištrinti</Button>
    )
}
