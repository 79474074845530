import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ServicesListCard from "./ServicesListCard";

export default function ServicesListPage() {

    return (
        <Container>
            <Row>
                <Col>
                    <ServicesListCard/>
                </Col>
            </Row>
        </Container>
    );
}
