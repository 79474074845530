import React, {Fragment} from 'react';
import { Wide, WideToolbar, WideRow, Compact, CompactToolbar, CompactRow, Label } from '@newageerp/ui.form.base.form-pack';
import { useTranslation } from 'react-i18next'
import { Input } from "@newageerp/ui.form.base.form-pack";
import { SelectAdvData } from "@newageerp/ui.form.base.form-pack";
import { Datepicker } from "@newageerp/ui.form.base.form-pack";

interface Props {
    element: any;
    onChange: (key: string, value: any) => void,
    compact?: boolean,

    onSave: () => void,
    onCancel: () => void,
}

export default function TripMainForm(props: Props) {
    const { element, onChange } = props;
    const { t } = useTranslation();

    if (props.compact) {
        return (
            <Compact>
                <CompactRow label={<Label>{t('Numeris')}</Label>} control={<Input onChange={(e: any) => onChange('number', e.target.value)} value={element.number}/>}/>
<CompactRow label={<Label>{t('Reference')}</Label>} control={<Input onChange={(e: any) => onChange('reference', e.target.value)} value={element.reference}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Vilkikas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck"
                        field="name"
                        selectedId={element.truck?.id}
                        onSelectId={(e: any) => onChange('truck', {id: e})}
                        parentElement={element}
                        parentSchema={"trip"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<CompactRow label={<Label>{t('Priekaba')}</Label>} control={<Input onChange={(e: any) => onChange('trailer', e.target.value)} value={element.trailer}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Vairuotojas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="driver"
                        field="fullName"
                        selectedId={element.truckDriver?.id}
                        onSelectId={(e: any) => onChange('truckDriver', {id: e})}
                        parentElement={element}
                        parentSchema={"trip"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<CompactRow label={<Label>{t('Vairuotojas 2')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="driver"
                        field="fullName"
                        selectedId={element.truckDriver2?.id}
                        onSelectId={(e: any) => onChange('truckDriver2', {id: e})}
                        parentElement={element}
                        parentSchema={"trip"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Data nuo')}</Label>} control={<Datepicker value={element.dateFrom} onChange={(e: any) => onChange('dateFrom', e)}  className={"field-date"}/>}/>
<CompactRow label={<Label>{t('Data iki')}</Label>} control={<Datepicker value={element.dateTo} onChange={(e: any) => onChange('dateTo', e)}  className={"field-date"}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Vadybininkas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="user"
                        field="fullName"
                        selectedId={element.user?.id}
                        onSelectId={(e: any) => onChange('user', {id: e})}
                        parentElement={element}
                        parentSchema={"trip"}
                        sort={[
    {
        "key": "i.fullName",
        "value": "ASC"
    }
]}
                        />}/>
                <CompactToolbar onSave={props.onSave} onCancel={props.onCancel} />
            </Compact>
        )
    }

    return (
        <Wide>
            <WideRow label={<Label>{t('Numeris')}</Label>} control={<Input onChange={(e: any) => onChange('number', e.target.value)} value={element.number}/>}/>
<WideRow label={<Label>{t('Reference')}</Label>} control={<Input onChange={(e: any) => onChange('reference', e.target.value)} value={element.reference}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Vilkikas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck"
                        field="name"
                        selectedId={element.truck?.id}
                        onSelectId={(e: any) => onChange('truck', {id: e})}
                        parentElement={element}
                        parentSchema={"trip"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<WideRow label={<Label>{t('Priekaba')}</Label>} control={<Input onChange={(e: any) => onChange('trailer', e.target.value)} value={element.trailer}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Vairuotojas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="driver"
                        field="fullName"
                        selectedId={element.truckDriver?.id}
                        onSelectId={(e: any) => onChange('truckDriver', {id: e})}
                        parentElement={element}
                        parentSchema={"trip"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<WideRow label={<Label>{t('Vairuotojas 2')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="driver"
                        field="fullName"
                        selectedId={element.truckDriver2?.id}
                        onSelectId={(e: any) => onChange('truckDriver2', {id: e})}
                        parentElement={element}
                        parentSchema={"trip"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Data nuo')}</Label>} control={<Datepicker value={element.dateFrom} onChange={(e: any) => onChange('dateFrom', e)}  className={"field-date"}/>}/>
<WideRow label={<Label>{t('Data iki')}</Label>} control={<Datepicker value={element.dateTo} onChange={(e: any) => onChange('dateTo', e)}  className={"field-date"}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Vadybininkas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="user"
                        field="fullName"
                        selectedId={element.user?.id}
                        onSelectId={(e: any) => onChange('user', {id: e})}
                        parentElement={element}
                        parentSchema={"trip"}
                        sort={[
    {
        "key": "i.fullName",
        "value": "ASC"
    }
]}
                        />}/>
            <WideToolbar onSave={props.onSave} onCancel={props.onCancel} />
        </Wide>
    )
}