import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    value?: string;
    setValue: (val: string) => void;
    readOnly?: boolean;
    disabled?: boolean;
    inPopup?: boolean;
}

export default function DateField(props: Props) {
    const [valDate, setValDate] = useState(props.value ? moment(props.value, 'YYYY-MM-DD').toDate() : undefined);

    useEffect(() => {
        setValDate(props.value ? moment(props.value, 'YYYY-MM-DD').toDate() : undefined)
    }, [props.value]);

    useEffect(() => {
        props.setValue(valDate ? moment(valDate).format("YYYY-MM-DD") : '')
    }, [valDate]);

    const handleChange = (date: Date) => {
        setValDate(date);
    };

    return (
        <div onClick={e => props.inPopup && e.stopPropagation()}>
            <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd"
                readOnly={props.readOnly}
                selected={valDate}
                onChange={handleChange}
                disabled={props.disabled}
                calendarStartDay={1}
                portalId={props.inPopup ? undefined : "root-portal"}
                popperProps={props.inPopup ? {
                    strategy: "fixed"
                } : undefined}
                popperClassName={props.inPopup ? "tw3-z-[9999]" : ""}
            />
        </div>
    );
}