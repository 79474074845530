import React, {useEffect, useState} from "react";
import {useHistory, useLocation } from "react-router-dom";

import ApiRest from "../../../service/ApiRest";
import { Card, Col, Container, Row, Spinner, Table, Form } from "react-bootstrap";
import {ImportedFile} from "./UploadTypes";
import {NaePaging, NaeTable} from 'nae-react-core-styles';
import DateField from "../../Forms/fields/DateField";


const moduleName = 'imported-file';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function UploadsReportListPage() {
    const history = useHistory();
    const query = useQuery();

    const [state, setState] = useState('');
    const [count, setCount] = useState(0);

    const [invoiceLines, setInvoiceLines] = useState<ImportedFile[]>([])
    const [categorySelected, setCategorySelected] = useState(query.get("category") || '');
    const [displaySelectedType, setDisplaySelectedType] = useState(query.get("type") || "");
    
    const [activePage, setActivePage] = useState(parseInt(query.get("page") || "1"));
    const [selectedType, setSelectedType] = useState(query.get("type") || "");
    const [dateFrom, setDateFrom] = useState(query.get("dateFrom") || "");
    const [dateTo, setDateTo] = useState(query.get("dateTo") || "");


    const typeOptions = [
        { label: "DKV", value: "SN", category: "purchases" },
        { label: "AS24", value: "AS24", category: "purchases" },
        { label: "EuroWag", value: "Eurowag", category: "purchases" },
        { label: "Neste", value: "Neste", category: "purchases" },
        { label: "E100", value: "E100", category: "purchases" },
        { label: "WEX EUROPE", value: "WEX", category: "purchases" },
        { label: "DKV TISPL PAS (FR)", value: "TISPL_PAS", category: "purchases" },
        { label: "DKV PAS (IT)", value: "DKV_PAS", category: "purchases" },
        { label: "FVS pirkimai", value: "FVS_PURCHASE", category: "purchases" },
        { label: "CRT", value: "CRT", category: "purchases" },
        { label: "CEPSA", value: "CEPSA", category: "purchases" },
        { label: "TFC", value: "TFC", category: "purchases" },
        { label: "DSV pardavimai", value: "DSV_SALES", category: "sales" },
        { label: "TRANSIMEKSA pardavimai", value: "TRANSIMEKSA_SALES", category: "sales" },
        { label: "ALPI pardavimai", value: "ALPI_SALES", category: "sales" },
        { label: "LKW Walter pardavimai", value: "WALTER_SALES", category: "sales" },
        { label: "WITT Trucking pardavimai", value: "WITT_SALES", category: "sales" },
        { label: "WITT Trucking pardavimai (naujas)", value: "WITT_SALES_2", category: "sales" },
        { label: "NTG LT pardavimai", value: "NTG_SALES", category: "sales" },
        { label: "NTG DK pardavimai", value: "NTG_DK_SALES", category: "sales" },
        { label: "HELLMAN pardavimai", value: "HELLMAN", category: "sales" },
        { label: "Axis pardavimai", value: "AXIS_SALES", category: "sales" },
        { label: "Continexus pardavimai", value: "CONTINEXUS_SALES", category: "sales"},
        { label: "Fedex pardavimai", value: "FEDEX", category: "sales" },
        { label: "Transekspedicija pardavimai", value: "TRANSEKSPEDICIJA_SALES", category: "sales" },
        { label: "Delamode pardavimai", value: "DELAMODE_SALES", category: "sales" },
      ];

      const getData = () => {
        setState('loading');
    
        let types = selectedType;
        if (categorySelected && selectedType.includes(',')) {
            types = selectedType;
        } else if (!categorySelected) {
            types = selectedType;
        }
    
        const extraData = {
            page: activePage,
            detailed: true,
            type: types,
            dateFrom,
            dateTo,
        };
    
        ApiRest.getElements(moduleName, extraData).then(res => {
            setInvoiceLines(res.data);
            setCount(res.count);
            setState('');
        }).catch(error => {
            console.error('Error fetching data:', error);
            setState('error');
        });
    };
    
    
    

    useEffect(getData, [activePage, selectedType, categorySelected, dateFrom, dateTo]);

    const goTo = (item: ImportedFile) => {
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    const pages = Math.ceil(count / 20);

    const updateQueryParams = () => {
        const params = new URLSearchParams();
        if (selectedType) params.set("type", selectedType);
        if (categorySelected) params.set("category", categorySelected);
        if (dateFrom) params.set("dateFrom", dateFrom);
        if (dateTo) params.set("dateTo", dateTo);
        params.set("page", activePage.toString());
      
        history.push({ search: params.toString() });
      };
      
      useEffect(() => {
        updateQueryParams();
      }, [activePage, selectedType, dateFrom, dateTo]);
      

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"card-table"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Importavimo peržiūra
                                </Col>
                                <Col className={"text-right"}>
                                    {/*<Button variant={"success"} onClick={goToNew}>Nauja</Button>*/}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Kategorija</Form.Label>
                                        <Form.Control
                                        as="select"
                                        value={categorySelected}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            const newCategorySelected = e.target.value;
                                            setCategorySelected(newCategorySelected);
                                            setActivePage(1);

                                            if (newCategorySelected !== '') {
                                            const typesInCategory = typeOptions
                                                .filter(option => option.category === newCategorySelected)
                                                .map(option => option.value)
                                                .join(', ');
                                            setSelectedType(typesInCategory);
                                            setDisplaySelectedType('');
                                            } else {
                                            setSelectedType('');
                                            setDisplaySelectedType('');
                                            }
                                        }}
                                        >
                                            <option value="">Visi</option>
                                            <option value="sales">Pardavimai</option>
                                            <option value="purchases">Pirkimai</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Tipas</Form.Label>
                                        <Form.Control
                                        as="select"
                                        value={displaySelectedType}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            const newSelectedType = e.target.value;
                                            setSelectedType(newSelectedType);
                                            setDisplaySelectedType(newSelectedType);
                                            setActivePage(1);
                                        }}
                                        >
                                            <option value="" disabled>Pasirinkite...</option>
                                            {typeOptions
                                                .filter(option => categorySelected === '' || option.category === categorySelected)
                                                .map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Data Nuo</Form.Label>
                                        <DateField value={dateFrom} setValue={(val) => setDateFrom(val)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Data Iki</Form.Label>
                                        <DateField value={dateTo} setValue={(val) => setDateTo(val)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Header>

                        <Card.Body>
                            {state === 'loading' ?
                                <Spinner animation="border" variant="info"/>
                                :
                                <Table striped hover className={"mt-2"}>
                                    <thead>
                                        <tr>
                                            <NaeTable.th id={true}>ID</NaeTable.th>
                                            <NaeTable.th>Data</NaeTable.th>
                                            <NaeTable.th>Tipas</NaeTable.th>
                                            <NaeTable.th>Sąskaitų kiekis</NaeTable.th>
                                            <NaeTable.th>Eilučių kiekis</NaeTable.th>
                                            {/* <NaeTable.th>Total Amount VAT EUR</NaeTable.th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoiceLines.map((item: ImportedFile) => (
                                            <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                                <NaeTable.td id={true} link={true}>{item.id}</NaeTable.td>
                                                <NaeTable.td link={true}>{item.createdAt}</NaeTable.td>
                                                <NaeTable.td>{item.type}</NaeTable.td>
                                                <NaeTable.td>{item.total_invoice_count}</NaeTable.td>
                                                <NaeTable.td>{item.total_lines_count}</NaeTable.td>
                                                {/* <NaeTable.td>{item.total_amount_vat_eur}</NaeTable.td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            }

                        </Card.Body>
                        <Card.Footer>
                            <div className={"paging"}>
                                <NaePaging.Prev onClick={() => {
                                    setActivePage(Math.max(activePage - 1, 1));
                                }}/>
                                <NaePaging.Group pages={pages} activePage={activePage} onClick={(p) => {
                                    setActivePage(p);
                                }}/>
                                <NaePaging.Next onClick={() => {
                                    setActivePage(Math.min(activePage + 1, pages));
                                }}/>
                            </div>
                        </Card.Footer>
                    </Card>


                </Col>
            </Row>
        </Container>
    );
}