import { ContentWidgetProps } from "@newageerp/nae-react-ui/dist/UI/Widget/NEWidgets";
import React, { Fragment } from "react";
import TripsDirections from "../../../../UserComponents/Trips/TripsDirections";
import TripsInvoiceLines from "../../../../UserComponents/Trips/TripsInvoiceLines";

export default function TripBottomWidget(props: ContentWidgetProps) {
  return (
    <Fragment>
      <TripsDirections id={props.element.id} />
      <TripsInvoiceLines id={props.element.id} />
    </Fragment>
  );
}
