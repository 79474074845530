import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import { toast } from "react-toastify";
import { Driver } from "./TruckTypes";
import DriverForm from "../../Forms/DriverForm";

const moduleName = 'employee';

interface ParamTypes {
  id: string
}

const defElement: Driver = {
  id: 0,
  comment: '',
  firstName: '',
  lastName: '',
  fullName: '',
  birthDate: '',
  hireDate: '',
  terminationDate: '',
  contractSigningDate: '',
  position: '',
  positionGrantDate: '',
  country: '',
  email: '',
  employmentContractNumber: '',
  socialSecurityNumber: '',
  personalCode: '',
  bankAccount: '',
  bankAccount2: '',
  // Collections
  history: [],
  phoneNumbers: [],
  addresses: [],
  familyContacts: [],
  documents: [],
}


export default function DriversPage() {
  const history = useHistory();
  const [element, setElement] = useState(defElement);
  const { id } = useParams<ParamTypes>();

  const saveData = (data: Partial<Driver>) => {
    if (id === 'new') {
      ApiRest.addElement(
        moduleName,
        data
      ).then(res => {
        toast.success('Įrašas išsaugotas');
        setElement(res);
        history.replace('/' + moduleName + '/' + res.id);
      })
    } else {

      const { history: newHistory, ...otherData } = data;
      
      ApiRest.updateElement(
        moduleName,
        element.id,
        {
          ...otherData,
          history: newHistory ? newHistory.map(h => ({
            date: h.date,
            change: h.change
          })) : []
        }
      ).then(res => {
        toast.success('Įrašas išsaugotas');
        setElement(res);
      });
    }
  }

  const getData = () => {
    if (id !== 'new') {
      ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
        setElement(res);
      }).catch(e => {
      });
    }
  }

  useEffect(getData, [id]);

  const form = (element.id > 0 || id === 'new') ?
    <Fragment>
      <DriverForm element={element} saveData={saveData}/>
    </Fragment>
    : <Fragment/>
  ;

  return (
    <Fragment>
      <div className={"mb-2"}>
        <div className={"flex gap-2"}>
          <div className={"flex-grow"}>
            {form}
          </div>
        </div>
      </div>
    </Fragment>
  )
}