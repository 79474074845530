import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {Card} from "react-bootstrap";
import ApiRest from "../../../service/ApiRest";
import UploadedForm from "../../Forms/UploadedForm";
import CommonRemoveBtn from "../../CommonRemoveBtn";
import InvoicesListCard from "../Invoices/InvoicesListCard";

const moduleName = 'imported-file';

interface ParamTypes {
    id: string
}

const defElement = {
    id: 0,
    createdAt: '',
    size: 0,
    type: '',
    status: '',
    linesWoCode: []
}


export default function UploadsPage() {
    const history = useHistory();

    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();


    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <UploadedForm element={element}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Card>
            <Card.Body>
                {/*<DeleteButton id={domain.id}/>*/}
                <CommonRemoveBtn path={"/upload"} module={moduleName} id={element.id}/>
                <p>
                    Bus ištrintos visos sąskaitos
                </p>
            </Card.Body>
        </Card>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

            {!!element.id && <InvoicesListCard options={{importedFileId: element.id}}/>}
        </Fragment>
    )
}
