import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {ServiceGroup} from "../Pages/Services/ServiceTypes";
import BackBtn from "../BackBtn";

interface Props {
    element: ServiceGroup,
    saveData: (name: string) => void,
}

export default function ServiceGroupForm(props: Props) {
    const {element, saveData} = props;
    const [name, setName] = useState(element.name);

    return (
        <Card>
            <Card.Header><BackBtn/>Paslaugų grupė</Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Pavadinimas</Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)}/>
                    </Form.Group>
                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(name)}>Išsaugoti</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
