import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Form, Row, Spinner, Table} from 'react-bootstrap';
import ApiRest from "../../../service/ApiRest";
import {Service} from "../Services/ServiceTypes";
import {NaeTable} from "nae-react-core-styles";
import DateField from "../../Forms/fields/DateField";

interface Result {
    total: number,
    totalFee: number,
    count: number,
    percent: number
}

export default function ReportsFeePage() {
    const [state, setState] = useState('');
    const [recordsCount, setRecordsCount] = useState(0);
    const [stats, setStats] = useState<any>({});

    // FILTERS

    const y = (new Date()).getFullYear();

    const [dateFrom, setDateFrom] = useState((y - 1) + '-01-01');
    const [dateTo, setDateTo] = useState((y + 1) + '-01-01');

    const [services, setServices] = useState<Service[]>([]);


    const getOptions = () => {
        const options = {
            dateFrom,
            dateTo,
        }
        return options;
    }

    const getStats = () => {
        setState('loading');

        ApiRest.getReport(
            'invoice-line-fee',
            {
                options: getOptions(),
            }
        ).then(res => {
            setRecordsCount(res.count);
            setStats(res.data);

            setState('done');
        });
    }

    const getData = () => {
        ApiRest.getElements('service').then(res => {
            setServices(res);
        });
    }
    useEffect(getData, []);

    const exportStats = (serviceId: any, date: any, country: any) => {
        ApiRest.exportReport(
            'invoice-line-fee',
            {
                options: getOptions(),
                serviceId,
                date,
                country
            }
        );
    }


    return (
        <Fragment>
            <Card>
                <Card.Header>Filtras</Card.Header>
                <Card.Body>

                    <Form.Row>
                        <Form.Group as={Col} className={"col-sm-6"}>
                            <Form.Label>Tranzakcijos data</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <DateField value={dateFrom}
                                                   setValue={val => setDateFrom(val)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <DateField value={dateTo}
                                                   setValue={val => setDateTo(val)}/>
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Form.Group>

                    </Form.Row>


                </Card.Body>
                <Card.Footer>
                    <Button variant={"primary"} onClick={getStats}>Filtruoti</Button>

                    <Button variant={"outline-light"}>Atšaukti</Button>
                </Card.Footer>
            </Card>

            {!!state &&
            <Card>

                {state === 'loading' ?
                    <Card.Body>
                        <Spinner animation="border" variant="info"/>
                    </Card.Body>
                    :
                    <Fragment>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Rezultatas: {recordsCount}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {Object.keys(stats).map(c => {
                                let dates: string[] = [];
                                Object.keys(stats[c]).map(s => {
                                    const _d = Object.keys(stats[c][s]);
                                    _d.map(d => {
                                        if (dates.indexOf(d) === -1) {
                                            dates.push(d);
                                        }
                                    })
                                })
                                dates = dates.sort();

                                return <Fragment>
                                    <h2>{c}</h2>
                                    <Table striped={true} className={"reports-table"}>
                                        <thead>
                                        <tr>
                                            <NaeTable.th>{""}</NaeTable.th>
                                            {dates.map(d => {
                                                return (<th className={"text-right"}>{d}</th>)
                                            })}
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <NaeTable.th>{""}</NaeTable.th>*/}
                                        {/*    {dates.map(d => {*/}
                                        {/*        return (*/}
                                        {/*            <Fragment>*/}
                                        {/*                <th>Suma</th>*/}
                                        {/*                <th>%</th>*/}
                                        {/*            </Fragment>)*/}
                                        {/*    })}*/}
                                        {/*</tr>*/}
                                        </thead>
                                        <tbody>
                                        {Object.keys(stats[c]).map(s => {
                                            const _f = services.filter((_s: Service) => _s.id === parseInt(s));
                                            const serviceName = _f.length > 0 ? _f[0].name : '---';
                                            const serviceId = _f.length > 0 ? _f[0].id : '---';
                                            const v = stats[c][s];

                                            return (
                                                <tr>
                                                    <NaeTable.td>
                                                        {serviceName}
                                                    </NaeTable.td>
                                                    {dates.map(d => {
                                                        if (v[d]) {
                                                            const r: Result = v[d];
                                                            return (<Fragment>
                                                                <td
                                                                    className={"text-right"}
                                                                    onClick={() => exportStats(serviceId, d, c)}
                                                                >
                                                                    {r.total.toFixed(2)}
                                                                    <br/>
                                                                    <span
                                                                        className={"text-secondary"}>{r.totalFee.toFixed(2)}</span>
                                                                    <br/>
                                                                    <strong>{r.percent.toFixed(2)} %</strong>
                                                                </td>

                                                            </Fragment>)
                                                        } else {
                                                            return (<Fragment>
                                                                <NaeTable.td>{" "}</NaeTable.td>

                                                            </Fragment>)
                                                        }
                                                    })}
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </Fragment>
                            })}
                        </Card.Body>
                    </Fragment>
                }
            </Card>
            }
        </Fragment>
    )
}
