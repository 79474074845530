import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {InvoiceLine} from "../Pages/Invoices/InvoiceTypes";
import {UI} from "@newageerp/nae-react-ui";
import {useHistory} from "react-router-dom";
import ApiRest from "../../service/ApiRest";
import {Service} from "../Pages/Services/ServiceTypes";


interface Props {
    element: InvoiceLine,
    saveData: (quantity: number,
               price: number,
               priceEur: number,
               total: number,
               totalEur: number,
               totalVat: number,
               totalVatEur: number,
               serviceId: number,) => void,
}

export default function InvoiceLineUpdateForm(props: Props) {
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    }

    const {element, saveData} = props;

    const [quantity, setQuantity] = useState(element.quantity.toString());
    const [price, setPrice] = useState(element.price.toString());
    const [priceEur, setPriceEur] = useState(element.priceEur.toString());

    const [total, setTotal] = useState(element.total.toString());
    const [totalEur, setTotalEur] = useState(element.totalEur.toString());

    const [totalVat, setTotalVat] = useState(element.totalVat.toString());
    const [totalVatEur, setTotalVatEur] = useState(element.totalVatEur.toString());

    const [serviceId, setServiceId] = useState(element.serviceId.toString());

    const [services, setServices] = useState([]);
    const getListData = () => {
        ApiRest.getElements('service').then(res => {
            if (res) {
                setServices(res);
            }
        });
    }
    useEffect(getListData, []);

    const updateQuantity = (_q: string) => {
        const q = parseFloat(_q.replace(',', '.'));
        setQuantity(_q);
        setTotal((q * parseFloat(price)).toFixed(2));
        setTotalEur((q * parseFloat(priceEur)).toFixed(2));
    }

    const updatePrice = (_p: string) => {
        const p = parseFloat(_p.replace(',', '.'));
        setPrice(_p);
        setPriceEur(_p);

        setTotal((p * parseFloat(quantity)).toFixed(2));
        setTotalEur((p * parseFloat(quantity)).toFixed(2));
    }


    return (
        <UI.Card.WhiteCard>
            <UI.MVC.MvcViewTitle
                onBack={goBack}
                title={"Sąskaitos eilutė"}
            />

            <Form>
                {element.invoiceType === 'S' ?
                    <Fragment>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Kiekis</Form.Label>
                                <Form.Control value={quantity} onChange={e => updateQuantity(e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col}>

                            </Form.Group>
                            <Form.Group as={Col}>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Kaina</Form.Label>
                                <Form.Control value={price} onChange={e => updatePrice(e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col}>

                            </Form.Group>

                            <Form.Group as={Col}>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Suma</Form.Label>
                                <Form.Control value={total} onChange={e => setTotal(e.target.value)} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col}>

                            </Form.Group>

                            <Form.Group as={Col}>

                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm={6}>
                                <Form.Label>Paslauga</Form.Label>
                                <Form.Control
                                    as="select"
                                    className="mr-sm-2"
                                    value={serviceId}
                                    onChange={(e) => setServiceId(e.target.value)}
                                >
                                    <option value="0">Pasirinkite...</option>
                                    {services.map((service: Service) =>
                                        <option key={'opt-g' + service.id}
                                                value={service.id}>{service.name}</option>)}

                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Fragment>
                    :

                    <Fragment>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Kiekis</Form.Label>
                                <Form.Control value={quantity} onChange={e => setQuantity(e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col}>

                            </Form.Group>
                            <Form.Group as={Col}>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Kaina</Form.Label>
                                <Form.Control value={price} onChange={e => setPrice(e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Kaina, EUR</Form.Label>
                                <Form.Control value={priceEur} onChange={e => setPriceEur(e.target.value)}/>
                            </Form.Group>

                            <Form.Group as={Col}>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Suma</Form.Label>
                                <Form.Control value={total} onChange={e => setTotal(e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Suma, EUR</Form.Label>
                                <Form.Control value={totalEur} onChange={e => setTotalEur(e.target.value)}/>
                            </Form.Group>

                            <Form.Group as={Col}>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>PVM</Form.Label>
                                <Form.Control value={totalVat} onChange={e => setTotalVat(e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>PVM, EUR</Form.Label>
                                <Form.Control value={totalVatEur} onChange={e => setTotalVatEur(e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col}>

                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm={6}>
                                <Form.Label>Paslauga</Form.Label>
                                <Form.Control
                                    as="select"
                                    className="mr-sm-2"
                                    value={serviceId}
                                    onChange={(e) => setServiceId(e.target.value)}
                                >
                                    <option value="0">Pasirinkite...</option>
                                    {services.map((service: Service) =>
                                        <option key={'opt-g' + service.id}
                                                value={service.id}>{service.name}</option>)}

                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Fragment>
                }


            </Form>

            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(
                                    parseFloat(quantity),
                                    parseFloat(price),
                                    parseFloat(priceEur),
                                    parseFloat(total),
                                    parseFloat(totalEur),
                                    parseFloat(totalVat),
                                    parseFloat(totalVatEur),
                                    parseInt(serviceId, 10),
                                )}>Išsaugoti</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </UI.Card.WhiteCard>)
}
