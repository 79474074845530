import React, {Fragment} from 'react';
import { Wide, WideToolbar, WideRow, Compact, CompactToolbar, CompactRow, Label } from '@newageerp/ui.form.base.form-pack';
import { useTranslation } from 'react-i18next'
import { Datepicker } from "@newageerp/ui.form.base.form-pack";
import { SelectAdvData } from "@newageerp/ui.form.base.form-pack";
import { InputFloat } from "@newageerp/ui.form.base.form-pack";

interface Props {
    element: any;
    onChange: (key: string, value: any) => void,
    compact?: boolean,

    onSave: () => void,
    onCancel: () => void,
}

export default function DriverTripMainForm(props: Props) {
    const { element, onChange } = props;
    const { t } = useTranslation();

    if (props.compact) {
        return (
            <Compact>
                <CompactRow label={<Label>{t('Pradžios data')}</Label>} control={<Datepicker value={element.dateStart} onChange={(e: any) => onChange('dateStart', e)}  className={"field-date"}/>}/>
<CompactRow label={<Label>{t('Pabaigos data')}</Label>} control={<Datepicker value={element.dateFinish} onChange={(e: any) => onChange('dateFinish', e)}  className={"field-date"}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Klientas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="client"
                        field="name"
                        selectedId={element.client?.id}
                        onSelectId={(e: any) => onChange('client', {id: e})}
                        parentElement={element}
                        parentSchema={"driver-trip"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('Vilkikas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck"
                        field="name"
                        selectedId={element.truck?.id}
                        onSelectId={(e: any) => onChange('truck', {id: e})}
                        parentElement={element}
                        parentSchema={"driver-trip"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<CompactRow label={<Label>{t('Vairuotojas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="driver"
                        field="fullName"
                        selectedId={element.driver?.id}
                        onSelectId={(e: any) => onChange('driver', {id: e})}
                        parentElement={element}
                        parentSchema={"driver-trip"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('K bakas pradžioje (cm)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankLeftCmStart', e)} value={element.tankLeftCmStart} className={"field-float"}/>}/>
<CompactRow label={<Label>{t('D bakas pradžioje (cm)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankRightCmStart', e)} value={element.tankRightCmStart} className={"field-float"}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('K bakas pabaigoje (cm)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankLeftCmFinish', e)} value={element.tankLeftCmFinish} className={"field-float"}/>}/>
<CompactRow label={<Label>{t('D bakas pabaigoje (cm)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankRightCmFinish', e)} value={element.tankRightCmFinish} className={"field-float"}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('REF kelionės pradžioje (l)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('refStart', e)} value={element.refStart} className={"field-float"}/>}/>
<CompactRow label={<Label>{t('REF kelionės gale (l)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('refFinish', e)} value={element.refFinish} className={"field-float"}/>}/>
                <CompactToolbar onSave={props.onSave} onCancel={props.onCancel} />
            </Compact>
        )
    }

    return (
        <Wide>
            <WideRow label={<Label>{t('Pradžios data')}</Label>} control={<Datepicker value={element.dateStart} onChange={(e: any) => onChange('dateStart', e)}  className={"field-date"}/>}/>
<WideRow label={<Label>{t('Pabaigos data')}</Label>} control={<Datepicker value={element.dateFinish} onChange={(e: any) => onChange('dateFinish', e)}  className={"field-date"}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Klientas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="client"
                        field="name"
                        selectedId={element.client?.id}
                        onSelectId={(e: any) => onChange('client', {id: e})}
                        parentElement={element}
                        parentSchema={"driver-trip"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('Vilkikas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="truck"
                        field="name"
                        selectedId={element.truck?.id}
                        onSelectId={(e: any) => onChange('truck', {id: e})}
                        parentElement={element}
                        parentSchema={"driver-trip"}
                        sort={[
    {
        "key": "i.name",
        "value": "ASC"
    }
]}
                        />}/>
<WideRow label={<Label>{t('Vairuotojas')}</Label>} control={<SelectAdvData 
                        withIcon={true}
                        schema="driver"
                        field="fullName"
                        selectedId={element.driver?.id}
                        onSelectId={(e: any) => onChange('driver', {id: e})}
                        parentElement={element}
                        parentSchema={"driver-trip"}
                        sort={[
    {
        "key": "i.id",
        "value": "DESC"
    }
]}
                        />}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('K bakas pradžioje (cm)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankLeftCmStart', e)} value={element.tankLeftCmStart} className={"field-float"}/>}/>
<WideRow label={<Label>{t('D bakas pradžioje (cm)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankRightCmStart', e)} value={element.tankRightCmStart} className={"field-float"}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('K bakas pabaigoje (cm)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankLeftCmFinish', e)} value={element.tankLeftCmFinish} className={"field-float"}/>}/>
<WideRow label={<Label>{t('D bakas pabaigoje (cm)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankRightCmFinish', e)} value={element.tankRightCmFinish} className={"field-float"}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('REF kelionės pradžioje (l)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('refStart', e)} value={element.refStart} className={"field-float"}/>}/>
<WideRow label={<Label>{t('REF kelionės gale (l)')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('refFinish', e)} value={element.refFinish} className={"field-float"}/>}/>
            <WideToolbar onSave={props.onSave} onCancel={props.onCancel} />
        </Wide>
    )
}