import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { SystemUser } from "../Pages/Common/UserTypes";
import BackBtn from "../BackBtn";
import { usePermissions } from "../../Components/User/PermissionProvider";

interface Props {
    element: SystemUser;
    saveData: (
        fullName: string,
        position: string,
        email: string,
        password: string,
        disabled: boolean,
        selectedPermissions: number[]
    ) => void;
    onAssignPermissions: () => void;
    selectedPermissions: number[];
    handlePermissionChange: (permissionId: number) => void;
    permissions: { id: number; userFriendlyName: string }[];
}

export default function UserForm(props: Props) {
    const history = useHistory();
    const { hasPermission } = usePermissions();

    const { element, saveData, onAssignPermissions, selectedPermissions, handlePermissionChange, permissions } = props;
    const [fullName, setFullName] = useState(element.fullName);
    const [position, setPosition] = useState(element.position);
    const [email, setEmail] = useState(element.email);
    const [disabled, setDisabled] = useState(element.disabled);
    const [password, setPassword] = useState('');

    const goBack = () => {
        history.goBack();
    }

    const handleSave = () => {
        const permissionIds = hasPermission('CAN_MANAGE_USER_PERMISSIONS') ? selectedPermissions : [];
        saveData(fullName, position, email, password, disabled, permissionIds);
    }

    return (
        <Card>
            <Card.Header>
                <BackBtn />
                Vartotojas
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Vardas, pavardė</Form.Label>
                        <Form.Control value={fullName} onChange={e => setFullName(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Pareigos</Form.Label>
                        <Form.Control value={position} onChange={e => setPosition(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>El. paštas</Form.Label>
                        <Form.Control value={email} onChange={e => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Slaptažodis</Form.Label>
                        <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Blokuoti prieigą</Form.Label>
                        <Form.Check checked={disabled} onChange={e => setDisabled(!disabled)} />
                    </Form.Group>
                </Form>
                {hasPermission('CAN_MANAGE_USER_PERMISSIONS') && (
                    <Button variant="info" onClick={onAssignPermissions}>Priskirti teises</Button>
                )}
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>
                    </Col>
                    <Col className="text-right">
                        <Button type="button" variant="primary" onClick={handleSave}>Išsaugoti</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
}
