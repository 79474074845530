class ApiInvoiceService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/invoice-public';
    }

    authRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    };

    exportInvoice = (invoiceId: number) => {
        window.open(this.baseUrl + '/export/' + invoiceId);
    }

    exportMultiple = (options: any) => {
        window.open(this.baseUrl + '/exportMultiple?extraData=' + encodeURIComponent(JSON.stringify({options})));
    }

}

const ApiInvoice = new ApiInvoiceService();
export default ApiInvoice;
