import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import { Button, Card, Col, Row } from "react-bootstrap";
import TableWithPaging from "../../TableWithPaging";
import { NaeTable } from "nae-react-core-styles";
import { Trip } from "./SalesTypes";
import { UI } from "@newageerp/nae-react-ui";

const moduleName = 'trip';

export default function TripListPage() {
    const history = useHistory();

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [trips, setTrips] = useState<Trip[]>([])
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [dataToRender, setDataToRender] = useState<Trip[]>([]);

    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setTrips(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = JSON.parse(JSON.stringify(trips));
            if (search) {
                _data = _data.filter((item: Trip) => {
                    return item.number.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.reference.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.truckName.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.trailer.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.comment.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.creator.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.truckDriverName.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || item.truckDriver2Name.toLowerCase().indexOf(search.toLowerCase()) > -1
                        || (item.client && item.client.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
                });
            }

            if (filter) {
                if (filter === 'active') {
                    _data = _data.filter((item: Trip) => {
                        return item.status === 0
                    });
                }
                if (filter === 'active_3') {
                    _data = _data.filter((item: Trip) => {
                        return item.status === 3
                    });
                }
                if (filter === 'active_5') {
                    _data = _data.filter((item: Trip) => {
                        return item.status === 5
                    });
                }
                if (filter === 'no_active') {
                    _data = _data.filter((item: Trip) => {
                        return item.status === 10
                    });
                }
            }

            if (sort) {
                if (sort === 'id_asc') {
                    _data = _data.sort((a: Trip, b: Trip) => {
                        return a.id > b.id ? 1 : -1;
                    })
                }
                if (sort === 'id_desc') {
                    _data = _data.sort((a: Trip, b: Trip) => {
                        return a.id > b.id ? -1 : 1;
                    })
                }

                if (sort === 'number_asc') {
                    _data = _data.sort((a: Trip, b: Trip) => {
                        return a.number > b.number ? 1 : -1;
                    })
                }
                if (sort === 'number_desc') {
                    _data = _data.sort((a: Trip, b: Trip) => {
                        return a.number > b.number ? -1 : 1;
                    })
                }

                if (sort === 'reference_asc') {
                    _data = _data.sort((a: Trip, b: Trip) => {
                        return a.reference > b.reference ? 1 : -1;
                    })
                }
                if (sort === 'reference_desc') {
                    _data = _data.sort((a: Trip, b: Trip) => {
                        return a.reference > b.reference ? -1 : 1;
                    })
                }
                if (sort === 'client_asc') {
                    _data = _data.sort((a: Trip, b: Trip) => {
                        const nameA = a.client ? a.client.name : '';
                        const nameB = b.client ? b.client.name : '';
                        return nameA.localeCompare(nameB);
                    })
                }
                if (sort === 'client_desc') {
                    _data = _data.sort((a: Trip, b: Trip) => {
                        const nameA = a.client ? a.client.name : '';
                        const nameB = b.client ? b.client.name : '';
                        return nameB.localeCompare(nameA);
                    })
                }
            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, trips, filter, readyToLoad, sort]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter', 'sort']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                } else if (p === 'sort') {
                    setSort(v);
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300);
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: Trip) => {
        history.replace('/trips', { search, activePage, filter, sort });
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (
        <div>

            <UI.Card.WhiteCard>
                <div className={"flex items-center"}>
                    <UI.Typography.H5 className={'flex-grow'}>Kelionių lapai</UI.Typography.H5>
                    <UI.Buttons.Button icon={"fal fa-fw fa-plus"} onClick={goToNew}>Naujas</UI.Buttons.Button>
                </div>

                <div className={"flex gap-2"}>
                    <UI.Form.Input placeholder={"Paieška"} className={"w-full"} value={search}
                        onChange={(e) => setSearch(e.target.value)} />

                    <select value={filter} onChange={e => setFilter(e.target.value)}>
                        <option value={""}>Filtras</option>
                        <option value={"active"}>Aktyvus</option>
                        <option value={"active_3"}>Grąžintas tikrinimui</option>
                        <option value={"active_5"}>Užbaigtas vadybininko</option>
                        <option value={"no_active"}>Užbaigtas</option>
                    </select>

                    <select value={sort} onChange={e => setSort(e.target.value)}>
                        <option value={""}>Rušiavimas</option>
                        <option value={"id_asc"}>ID 0-99</option>
                        <option value={"id_desc"}>ID 99-0</option>
                        <option value={"number_asc"}>Numeris A-Z</option>
                        <option value={"number_desc"}>Numeris Z-A</option>
                        <option value={"reference_asc"}>Reference A-Z</option>
                        <option value={"reference_desc"}>Reference Z-A</option>
                        <option value={"client_asc"}>Klientas A-Z</option>
                        <option value={"client_desc"}>Klientas Z-A</option>
                    </select>
                </div>

                <TableWithPaging
                    activePage={activePage}
                    setActivePage={setActivePage}
                    dataToRender={dataToRender}
                    head={
                        <tr>
                            <NaeTable.th id={true}>ID</NaeTable.th>
                            <NaeTable.th>Numeris</NaeTable.th>
                            <NaeTable.th>Reference</NaeTable.th>
                            <NaeTable.th>Klientas</NaeTable.th>
                            <NaeTable.th>Vadybininkas</NaeTable.th>
                            <NaeTable.th>Vilkikas</NaeTable.th>
                            <NaeTable.th>Priekaba</NaeTable.th>
                            <NaeTable.th>Vairuotojas</NaeTable.th>
                            <NaeTable.th>Data nuo</NaeTable.th>
                            <NaeTable.th>Data iki</NaeTable.th>
                            <NaeTable.th>Komentaras</NaeTable.th>
                            <NaeTable.th>Statusas</NaeTable.th>
                            <NaeTable.th number={true}>Atstumas</NaeTable.th>
                            <NaeTable.th number={true}>Išlaidos</NaeTable.th>
                        </tr>}
                    renderItem={
                        (item: Trip) => {
                            const drivenKm = item.invoiceDrivenKm;
                            const odoDiff = item.locktrackerKm2;

                            const autoRouteKmFl = item.autoRouteKm2;
                            const autoRouteDrivenDiff = Math.round((drivenKm > 0 && autoRouteKmFl > 0 ? (drivenKm - autoRouteKmFl) / drivenKm : -1) * 100 * 100) / 100;
                            const autoRouteLockTrackerDiff = Math.round((drivenKm > 0 && odoDiff > 0 ? (drivenKm - odoDiff) / drivenKm : 1) * 100 * 100) / 100;

                            const invRoadCosts = item.invoiceRoadCosts;
                            const invOtherCosts = item.invoiceOtherCosts;

                            const roadCost = item.roadCost;
                            const otherCost = item.otherCost;
                            return (
                                <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                    <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                    <NaeTable.td link={true}>{item.number}</NaeTable.td>
                                    <NaeTable.td>{item.reference}</NaeTable.td>
                                    <NaeTable.td>{item.client ? item.client.name : ''}</NaeTable.td>
                                    <NaeTable.td>{item.creator}</NaeTable.td>
                                    <NaeTable.td>{item.truckName}</NaeTable.td>
                                    <NaeTable.td>{item.trailer}</NaeTable.td>
                                    <NaeTable.td>
                                        {item.driver}
                                        <br />
                                        {!!item.truckDriver && item.truckDriverName}
                                        {!!item.truckDriver2 && <Fragment> / {item.truckDriver2Name}</Fragment>}
                                    </NaeTable.td>

                                    <NaeTable.td>{item.dateFrom}</NaeTable.td>
                                    <NaeTable.td>{item.dateTo}</NaeTable.td>

                                    <NaeTable.td>{item.comment}</NaeTable.td>

                                    <NaeTable.td>
                                        {item.status === 0 && <UI.Badges.Badge brightness={200} size={"sm"}>Aktyvus</UI.Badges.Badge>}
                                        {item.status === 3 && <UI.Badges.Badge bgColor={UI.Badges.BadgeBgColor.red} size={"sm"}>Grąžintas tikrinimui</UI.Badges.Badge>}
                                        {item.status === 5 && <UI.Badges.Badge bgColor={UI.Badges.BadgeBgColor.indigo} size={"sm"}>Užbaigtas vadybininko</UI.Badges.Badge>}
                                        {item.status === 10 && <UI.Badges.Badge bgColor={UI.Badges.BadgeBgColor.green} size={"sm"}>Užbaigtas</UI.Badges.Badge>}
                                    </NaeTable.td>

                                    <NaeTable.td number={true}>
                                        <span title={"AUTOROUTE"}>{item.autoRouteKm}</span> <br />
                                        <span title={"LocTracker"} className={'text-right'}>
                                            {(drivenKm - odoDiff).toFixed(0)} <span
                                                className={`${autoRouteLockTrackerDiff < 0 ? 'text-red-500' : 'text-green-500'}`}>({autoRouteLockTrackerDiff}%)</span>
                                        </span><br />
                                        <span title={"Sąskaita"} className={'text-right'}>
                                            {(drivenKm - autoRouteKmFl).toFixed(0)} <span
                                                className={`${autoRouteDrivenDiff < 0 ? 'text-red-500' : 'text-green-500'}`}>({autoRouteDrivenDiff}%)</span>
                                        </span>
                                    </NaeTable.td>
                                    <NaeTable.td number={true}>
                                        <span title={"Keliai (Sąskaita)"}>{(invRoadCosts - roadCost).toFixed(2)}</span> <br />
                                        <span title={"Kitos išlaidos (Sąskaita)"}>
                                            {(invOtherCosts - otherCost).toFixed(2)}
                                        </span>
                                    </NaeTable.td>

                                </tr>
                            )
                        }
                    }
                />

            </UI.Card.WhiteCard>

        </div>
    );
}
