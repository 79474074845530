import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import { WhiteCard } from "@newageerp/ui.cards.base.white-card";
import { UI } from "@newageerp/nae-react-ui";
import { Col, Form, Row } from "react-bootstrap";
import DateField from "../../Forms/fields/DateField";
import moment from "moment";
import { useRecoilState } from "recoil";
import { UserSpaceWrapperToolbarState } from "../../../_generated/layout/toolbar/UserSpaceWrapperToolbar";
import { Table, Th, Td } from '@newageerp/ui.table.base.table';
import TableWithPagingV2 from "../../TableWithPagingV2";
import { TwTextAlignment } from "@newageerp/ui.styles.tailwind";
// @ts-ignore
import { Multiselect } from 'multiselect-react-dropdown';
import { Driver, DriverHistory, DocumentValidity } from "./TruckTypes";

const EXCEL_URI = 'data:application/vnd.ms-excel;base64,';
const EXCEL_TEMPLATE = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>{table}</body></html>';

interface SearchElement {
    hireDateFrom?: string;
    hireDateTo?: string;
    documentsValidFrom?: string;
    documentsValidTo?: string;
    terminationDateFrom?: string;
    terminationDateTo?: string;
    employmentContractNumber?: string;
    position?: string;
    phoneNumber?: string;
    email?: string;
    id?: string;
    documentType?: string;
    documentValidityDate?: string;
    employmentStatus?: string;
}

interface CountryOption {
    id: string;
    name: string;
}

interface PositionOption {
    id: string;
    name: string;
}

interface DocumentTypeOption {
    id: string;
    name: string;
}

interface EmploymentStatusOption {
    id: string;
    name: string;
}

export default function DriverReportsPage() {
    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState);
    const history = useHistory();

    const base64 = (s: string) => window.btoa(unescape(encodeURIComponent(s)));
    const doFormat = (s: string) => {
        const context: Record<string, string | undefined> = {
            worksheet: 'Worksheet',
            table: window.document.getElementById("table-to-export")?.outerHTML,
        };
        return s.replace(/{(\w+)}/g, (_m: string, p: string) => context[p] || '');
    };

    const doDownload = () => {
        const element = window.document.createElement('a');
        element.href = EXCEL_URI + base64(doFormat(EXCEL_TEMPLATE));
        element.download = 'drivers-report.xls';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const [searchElement, setSearchElement] = useState<SearchElement>({});
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<CountryOption[]>([]);
    const [selectedPositions, setSelectedPositions] = useState<PositionOption[]>([]);
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<DocumentTypeOption[]>([]);
    const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState<EmploymentStatusOption[]>([
        { id: 'active', name: 'Aktyvus' }
    ]);
    const [showFilter, setShowFilter] = useState(false);
    const [readyToLoad, setReadyToLoad] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [dataToRender, setDataToRender] = useState<Driver[]>([]);

    const appendSearch = (key: keyof SearchElement, val: string) => {
        setSearchElement(prev => ({ ...prev, [key]: val }));
    };

    const getListData = () => {
        ApiRest.getElements('driver').then(res => {
            if (res) {
                setDrivers(res);
            }
        });
    };

    useEffect(getListData, []);

    const countryOptions = useMemo(() => {
        const countries = drivers
            .map(d => d.country)
            .filter((country): country is string => Boolean(country));
        return Array.from(new Set(countries)).map(country => ({ id: country, name: country }));
    }, [drivers]);

    const positionOptions = useMemo(() => {
        const positions = drivers
            .map(d => d.position)
            .filter((position): position is string => Boolean(position));
        return Array.from(new Set(positions)).map(position => ({ id: position, name: position }));
    }, [drivers]);

    const documentTypeOptions = useMemo(() => {
        const types = drivers
            .flatMap(d => d.documents?.map(doc => doc.type) || [])
            .filter(Boolean);
        return Array.from(new Set(types)).map(type => ({ id: type, name: type }));
    }, [drivers]);

    const employmentStatusOptions = [
        { id: "all", value: "all", name: "Visi" },
        { id: "active", value: "active", name: "Aktyvus" },
        { id: "inactive", value: "inactive", name: "Neaktyvus" }
    ];

    const toggleFilter = () => setShowFilter(!showFilter);

    const filterData = () => {
        if (readyToLoad) {
            let _data = drivers;

            // Text search across multiple fields
            if (search) {
                const searchLower = search.toLowerCase();
                _data = _data.filter(item =>
                    item.firstName.toLowerCase().includes(searchLower) ||
                    item.lastName.toLowerCase().includes(searchLower) ||
                    (item.personalCode?.toLowerCase().includes(searchLower)) ||
                    item.history?.some(hist =>
                        hist.change.toLowerCase().includes(searchLower)
                    )
                );
            }

            // ID filter
            if (searchElement.id) {
                _data = _data.filter(item =>
                    item.id.toString().includes(searchElement.id || '')
                );
            }

            // Country filter
            if (selectedCountries.length > 0) {
                const selectedCountryNames = selectedCountries.map(c => c.name);
                _data = _data.filter(item => selectedCountryNames.includes(item.country));
            }

            // Position filter
            if (selectedPositions.length > 0) {
                const selectedPositionNames = selectedPositions.map(p => p.name);
                _data = _data.filter(item => selectedPositionNames.includes(item.position));
            }

            // Document type filter
            if (selectedDocumentTypes.length > 0) {
                _data = _data.filter(driver =>
                    driver.documents?.some(doc =>
                        selectedDocumentTypes.some(selected => selected.id === doc.type)
                    )
                );
            }

            // Document validity date filter
            if (searchElement.documentValidityDate) {
                _data = _data.filter(driver =>
                    driver.documents?.some(doc =>
                        moment(doc.validUntil).isSameOrBefore(moment(searchElement.documentValidityDate))
                    )
                );
            }

            // Date ranges
            if (searchElement.hireDateFrom) {
                _data = _data.filter(item => moment(item.hireDate) >= moment(searchElement.hireDateFrom));
            }
            if (searchElement.hireDateTo) {
                _data = _data.filter(item => moment(item.hireDate) <= moment(searchElement.hireDateTo));
            }

            if (searchElement.terminationDateFrom) {
                _data = _data.filter(item => item.terminationDate && moment(item.terminationDate) >= moment(searchElement.terminationDateFrom));
            }
            if (searchElement.terminationDateTo) {
                _data = _data.filter(item => item.terminationDate && moment(item.terminationDate) <= moment(searchElement.terminationDateTo));
            }

            if (searchElement.employmentStatus && searchElement.employmentStatus !== 'all') {
                _data = _data.filter(driver => {
                    const isActive = !driver.terminationDate;
                    return searchElement.employmentStatus === 'active' ? isActive : !isActive;
                });
            }

            // Text field filters
            if (searchElement.employmentContractNumber) {
                _data = _data.filter(item =>
                    item.employmentContractNumber?.toLowerCase().includes(searchElement.employmentContractNumber?.toLowerCase() || '')
                );
            }

            if (searchElement.phoneNumber) {
                _data = _data.filter(item =>
                    item.phoneNumbers?.some(phone =>
                        phone.number.toLowerCase().includes(searchElement.phoneNumber?.toLowerCase() || '')
                    )
                );
            }

            if (searchElement.email) {
                _data = _data.filter(item =>
                    item.email?.toLowerCase().includes(searchElement.email?.toLowerCase() || '')
                );
            }

            setDataToRender(_data);
        }
    };

    useEffect(filterData, [
        search,
        activePage,
        drivers,
        readyToLoad,
        searchElement,
        selectedCountries,
        selectedPositions,
        selectedDocumentTypes,
        selectedEmploymentStatus
    ]);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, readyToLoad]);

    useEffect(() => {
        setToolbarTitle('Vairuotojų ataskaita');
        setReadyToLoad(true);
    }, [setToolbarTitle]);

    const goTo = (item: Driver) => {
        history.push('/driver/' + item.id);
    };

    const renderHistoryBadge = (driver: Driver) => {
        const matchingHistory = driver.history?.filter(hist =>
            hist.change.toLowerCase().includes(search.toLowerCase())
        );

        if (search && matchingHistory && matchingHistory.length > 0) {
            return (
                <div className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                    {matchingHistory.length} history matches
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <WhiteCard compact={true}>
                <div className="flex gap-2 px-4">
                    <UI.Form.Input
                        placeholder="Paieska..."
                        className="w-full"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <UI.Buttons.Button
                        bgColor={UI.Buttons.ButtonBgColor.nsecondary}
                        onClick={toggleFilter}
                    >
                        Filtras
                    </UI.Buttons.Button>
                    <UI.Buttons.Button
                        bgColor={UI.Buttons.ButtonBgColor.green}
                        brightness={700}
                        onClick={doDownload}
                    >
                        Xlsx
                    </UI.Buttons.Button>
                </div>

                {showFilter && (
                    <div className="bg-blue-50 rounded-md px-4 mt-4">
                        <Row className="mb-3">
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>ID</Form.Label>
                                    <Form.Control
                                        value={searchElement.id || ''}
                                        onChange={e => appendSearch('id', e.target.value)}
                                        // placeholder="Search by ID..."
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Pilietybė</Form.Label>
                                    <Multiselect
                                        options={countryOptions}
                                        displayValue="name"
                                        placeholder="Pasirinkti..."
                                        onSelect={(selectedList: CountryOption[]) => setSelectedCountries(selectedList)}
                                        onRemove={(selectedList: CountryOption[]) => setSelectedCountries(selectedList)}
                                        selectedValues={selectedCountries}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Dokumento tipas</Form.Label>
                                    <Multiselect
                                        options={documentTypeOptions}
                                        displayValue="name"
                                        placeholder="Pasirinkti..."
                                        onSelect={(selectedList: DocumentTypeOption[]) => setSelectedDocumentTypes(selectedList)}
                                        onRemove={(selectedList: DocumentTypeOption[]) => setSelectedDocumentTypes(selectedList)}
                                        selectedValues={selectedDocumentTypes}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Įdarbinimo statusas</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={searchElement.employmentStatus || 'active'}
                                        onChange={(e) => appendSearch('employmentStatus', e.target.value)}
                                    >
                                        {employmentStatusOptions.map((option) => (
                                            <option key={`status-${option.id}`} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row className="mb-3">
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Įdarbinimo data nuo</Form.Label>
                                    <DateField
                                        value={searchElement.hireDateFrom || ''}
                                        setValue={(val) => appendSearch('hireDateFrom', val)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Įdarbinimo data iki</Form.Label>
                                    <DateField
                                        value={searchElement.hireDateTo || ''}
                                        setValue={(val) => appendSearch('hireDateTo', val)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Atleidimo data nuo</Form.Label>
                                    <DateField
                                        value={searchElement.terminationDateFrom || ''}
                                        setValue={(val) => appendSearch('terminationDateFrom', val)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Atleidimo data iki</Form.Label>
                                    <DateField
                                        value={searchElement.terminationDateTo || ''}
                                        setValue={(val) => appendSearch('terminationDateTo', val)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Dokumento galiojimo data iki</Form.Label>
                                    <DateField
                                        value={searchElement.documentValidityDate || ''}
                                        setValue={(val) => appendSearch('documentValidityDate', val)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Sutarties nr.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={searchElement.employmentContractNumber || ''}
                                        onChange={(e) => appendSearch('employmentContractNumber', e.target.value)}
                                        placeholder="Ieškoti pagal sutarties numerį..."
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Pareigos</Form.Label>
                                    <Multiselect
                                        options={positionOptions}
                                        displayValue="name"
                                        placeholder="Pasirinkti..."
                                        onSelect={(selectedList: PositionOption[]) => setSelectedPositions(selectedList)}
                                        onRemove={(selectedList: PositionOption[]) => setSelectedPositions(selectedList)}
                                        selectedValues={selectedPositions}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Tel. numeris</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={searchElement.phoneNumber || ''}
                                        onChange={(e) => appendSearch('phoneNumber', e.target.value)}
                                        placeholder="Ieškoti pagal tel. numerį..."
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                )}

                <div className="mt-4">
                    <TableWithPagingV2
                        pageSize={500}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        dataToRender={dataToRender}
                        head={
                            <tr>
                                <Th>ID</Th>
                                <Th>Vardas Pavardė</Th>
                                <Th>Pilietybė</Th>
                                <Th>Asmens kodas</Th>
                                <Th>Įdarbinimo data</Th>
                                <Th>Atleidimo data</Th>
                                <Th>Sutarties nr.</Th>
                                <Th>Pareigos</Th>
                                <Th>Tel. numeris</Th>
                                <Th>El. paštas</Th>
                            </tr>
                        }
                        renderItem={(item: Driver) => (
                            <tr key={`list-row-${item.id}`} className="hover:bg-gray-50">
                                <Td>{item.id}</Td>
                                <Td>
                                    <div className="flex items-center">
                                        <button
                                            onClick={() => goTo(item)}
                                            className="text-left text-primary hover:underline"
                                        >
                                            {item.firstName} {item.lastName}
                                        </button>
                                        {renderHistoryBadge(item)}
                                    </div>
                                </Td>
                                <Td>{item.country}</Td>
                                <Td>{item.personalCode}</Td>
                                <Td>{item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : ''}</Td>
                                <Td>{item.terminationDate ? moment(item.terminationDate).format('YYYY-MM-DD') : ''}</Td>
                                <Td>{item.employmentContractNumber}</Td>
                                <Td>{item.position}</Td>
                                <Td>
                                    {item.phoneNumbers && item.phoneNumbers.length > 0
                                        ? item.phoneNumbers[0].number
                                        : ''}
                                </Td>
                                <Td>{item.email}</Td>
                            </tr>
                        )}
                    />
                </div>

                <div className="mt-4 px-4 text-sm text-gray-600">
                    Showing {dataToRender.length} of {drivers.length} drivers
                </div>
            </WhiteCard>
        </div>
    );
}