import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {Button, Card} from "react-bootstrap";
import ApiRest from "../../../service/ApiRest";
import {toast} from "react-toastify";
import InvoiceLinesListCard from "../Invoices/InvoiceLinesListCard";
import {Tablet, TabletHistory} from "./TruckTypes";
import ReportsPage from "../Reports/ReportsPage";
import DeleteButton from "./Components/DeleteButton";
import TabletForm from "../../Forms/TabletForm";

interface ParamTypes {
    id: string
}

// Initial default state for tablet
const defElement: Tablet = {
    id: 0,
    number: '',
    model: '',
    truck: 0,
    truckName: '',
    date: '',
    status: 0,
    history: [],
    comment: '',
};

const moduleName = 'tablet';

export default function TabletsPage() {
    const history = useHistory();
    const [key, setKey] = useState<string | null>('list');
    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (number: string,
                      model: string,
                      truck: number,
                      date: string,
                      status: number,
                      comment: string,
    ) => {
        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    number,
                    model,
                    truck,
                    date,
                    status,
                    comment
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
                history.push('/' + moduleName + '/' + res.id);
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    number,
                    model,
                    truck,
                    date,
                    status,
                    comment
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10), {addHistory: true}).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <TabletForm element={element} saveData={saveData}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Fragment>
            {id !== 'new' && <DeleteButton id={element.id} moduleName="tablet"/>}


            <Card>
                <Card.Header>
                    Pakeitimų istorija
                </Card.Header>
                <Card.Body>
                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Komentaras</th>
                        </tr>
                        </thead>
                        <tbody>
                        {element.history.map((el: TabletHistory) => {
                            return (
                                <tr key={"hist-" + el.id}>
                                    <td>
                                        {el.date}
                                    </td>
                                    <td>
                                        {el.change}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </Card.Body>
            </Card>
        </Fragment>
    );

    return (
        <Fragment>
            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>
        </Fragment>
    )
}
