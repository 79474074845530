import { Compact, CompactRow, Wide, WideRow, Label } from '@newageerp/ui.form.base.form-pack'
import { useTranslation } from 'react-i18next'
import React, {Fragment} from 'react'
    import { Float } from "@newageerp/data.table.float";

interface Props {
    element: any
}

export default function TripRoadsViewForm(props: Props) {
    const { t } = useTranslation();
    const { element } = props;

    return (
        <Wide>
                            <WideRow label={<Label>{t('Keliai')}</Label>} control={<Float value={element.roadCost}/>}/>
                            <WideRow label={<Label>{t('Sąskaita, keliai, EUR')}</Label>} control={<Float value={element.invoiceRoadCosts}/>}/>
                    </Wide>
    )
}
