import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Station} from "../Reports/ReportTypes";
import {NaeTable} from "nae-react-core-styles";
import TableWithPaging from "../../TableWithPaging";

const moduleName = 'station';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function StationsListPage() {
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();

    const [stations, setStations] = useState<Station[]>([])
    const [dataToRender, setDataToRender] = useState<Station[]>([]);
    const [activePage, setActivePage] = useState(parseInt(query.get("page") || "1"));
    const [search, setSearch] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setStations(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        let _data = stations;
        if (search) {
            _data = _data.filter((item: Station) => {
                return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.code.toLowerCase().indexOf(search.toLowerCase()) > -1
                    || item.country.toLowerCase().indexOf(search.toLowerCase()) > -1
            });
        }

        setDataToRender(_data);
    }

    useEffect(filterData, [search, activePage, stations]);

    const goTo = (item: Station) => {
        history.push('/' + moduleName + '/' + item.id);
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set("page", activePage.toString());
        history.replace({ pathname: location.pathname, search: params.toString() });
    }, [activePage, history, location.search, location.pathname]);

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"card-table"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Aptarnavimo stotys
                                </Col>
                                <Col className={"text-right"}>
                                    {/*<Button variant={"success"} onClick={goToNew}>Naujas</Button>*/}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Header className={"search"}>
                            <Row>
                                <Col className={"v-center"}>
                                    <input placeholder={"Paieška"} className={"search-input"} value={search}
                                           onChange={(e) => setSearch(e.target.value)}/>
                                </Col>
                            </Row>
                        </Card.Header>
                        <TableWithPaging
                            activePage={activePage}
                            setActivePage={setActivePage}
                            dataToRender={dataToRender}
                            head={
                                <tr>
                                    <NaeTable.th id={true}>ID</NaeTable.th>
                                    <NaeTable.th>Pavadinimas</NaeTable.th>
                                    <NaeTable.th>Kodas</NaeTable.th>
                                    <NaeTable.th>Šalis</NaeTable.th>
                                </tr>
                            }
                            renderItem={(item: Station) => {
                                return (
                                    <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                        <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                        <NaeTable.td link={true}>{item.name}</NaeTable.td>
                                        <NaeTable.td>{item.code}</NaeTable.td>
                                        <NaeTable.td>{item.country}</NaeTable.td>
                                    </tr>
                                )
                            }}
                        />
                    </Card>


                </Col>
            </Row>
        </Container>
    );
}
