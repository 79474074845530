import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Country } from "../Reports/ReportTypes";
import { NaeTable } from "nae-react-core-styles";
import TableWithPaging from "../../TableWithPaging";
import CountryMainTableDataSource from "../../../_generated/tabs/tables-data-source/CountryMainTableDataSource";

const moduleName = 'country';

export default function CountriesListPage() {
    return <CountryMainTableDataSource />
}

// export default function CountriesListPage() {
//     const history = useHistory();

//     const [filter, setFilter] = useState('');
//     const [countries, setCountries] = useState<Country[]>([])
//     const [dataToRender, setDataToRender] = useState<Country[]>([]);
//     const [activePage, setActivePage] = useState(1);
//     const [search, setSearch] = useState('');

//     const getData = () => {
//         ApiRest.getElements(moduleName).then(res => {
//             setCountries(res);
//         })
//     }

//     useEffect(getData, []);

//     const filterData = () => {
//         let _data = countries;
//         if (search) {
//             _data = _data.filter((item: Country) => {
//                 return item.fvsCode.toLowerCase().indexOf(search.toLowerCase()) > -1
//                     || item.code.toLowerCase().indexOf(search.toLowerCase()) > -1
//             });
//         }
//         if (filter === 'without_fvs') {
//             _data = _data.filter((item: Country) => {
//                 return !item.fvsCode
//             });
//         }

//         setDataToRender(_data);
//     }

//     useEffect(filterData, [search, activePage, countries, filter]);

//     const goTo = (item: Country) => {
//         history.push('/' + moduleName + '/' + item.id);
//     }

//     const goToNew = () => {
//         history.push('/' + moduleName + '/new');
//     }

//     return (
//         <Container>
//             <Row>
//                 <Col>
//                     <Card className={"card-table"}>
//                         <Card.Header>
//                             <Row>
//                                 <Col className={"v-center"}>
//                                     Šalys
//                                 </Col>
//                                 <Col className={"text-right"}>
//                                     {/*<Button variant={"success"} onClick={goToNew}>Naujas</Button>*/}
//                                 </Col>
//                             </Row>
//                         </Card.Header>
//                         <Card.Header className={"search"}>
//                             <Row>
//                                 <Col className={"v-center"}>
//                                     <input placeholder={"Paieška"} className={"search-input"} value={search}
//                                            onChange={(e) => setSearch(e.target.value)}/>
//                                 </Col>
//                                 <Col sm={2}>
//                                     <select value={filter} onChange={(e) => setFilter(e.target.value)}>
//                                         <option value={""}>Filtras</option>
//                                         <option value={"without_fvs"}>Be FVS kodo</option>
//                                     </select>
//                                 </Col>

//                             </Row>
//                         </Card.Header>
//                         <TableWithPaging
//                             activePage={activePage}
//                             setActivePage={setActivePage}
//                             dataToRender={dataToRender}
//                             head={
//                                 <tr>
//                                     <NaeTable.th id={true}>ID</NaeTable.th>
//                                     <NaeTable.th>Pavadinimas</NaeTable.th>
//                                     <NaeTable.th>Kodas</NaeTable.th>
//                                     <NaeTable.th>FVS kodas</NaeTable.th>
//                                 </tr>
//                             }
//                             renderItem={(item: Country) => {
//                                 return (
//                                     <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
//                                         <NaeTable.td id={true}>{item.id}</NaeTable.td>
//                                         <NaeTable.td link={true}>{item.name}</NaeTable.td>
//                                         <NaeTable.td>{item.code}</NaeTable.td>
//                                         <NaeTable.td>{item.fvsCode}</NaeTable.td>
//                                     </tr>
//                                 )
//                             }}
//                         />
//                     </Card>


//                 </Col>
//             </Row>
//         </Container>
//     );
// }
