import React, {useEffect, useState} from "react";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {NaePaging, NaeTable} from "nae-react-core-styles";
import {InvoiceLine} from "./InvoiceTypes";
import {useHistory, useLocation} from "react-router-dom";
import ApiRest from "../../../service/ApiRest";

const moduleName = 'invoice-line';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface Props {
    options?: any,
    title?: string,
}

export default function InvoiceLinesListCard(props: Props) {
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();

    const [state, setState] = useState('');
    const [activePage, setActivePage] = useState(parseInt(query.get("page") || "1"));
    const [count, setCount] = useState(0);

    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([])

    const getData = () => {
        setState('loading');
        ApiRest.getElements(moduleName, {page: activePage, options: props.options}).then(res => {
            setInvoiceLines(res.data);
            setCount(res.count);
            setState('');
        })
    }

    useEffect(getData, [activePage, props.options]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set("page", activePage.toString());
        history.replace({ pathname: location.pathname, search: params.toString() });
    }, [activePage, history, location.search, location.pathname]);

    const goTo = (item: InvoiceLine) => {
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToInvoice = (item: InvoiceLine) => {
        if (item.invoiceId > 0) {
            history.push('/invoice/' + item.invoiceId);
        }
    }
    const goToTruck = (item: InvoiceLine) => {
        if (item.truckId > 0) {
            history.push('/truck/' + item.truckId);
        }
    }
    const goToService = (item: InvoiceLine) => {
        if (item.serviceId > 0) {
            history.push('/service/' + item.serviceId);
        }
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    const pages = Math.ceil(count / 20);

    return (
        <Card className={"card-table"}>
            <Card.Header>
                <Row>
                    <Col className={"v-center"}>
                        {props.title ? props.title : "Sąskaitų eilutės"}
                    </Col>
                    <Col className={"text-right"}>
                        {/*<Button variant={"success"} onClick={goToNew}>Nauja</Button>*/}
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                {state === 'loading' ?
                    <Spinner animation="border" variant="info"/>
                    :
                    <Table striped hover className={"mt-2"}>
                        <thead>
                        <tr>
                            <NaeTable.th id={true}>ID</NaeTable.th>
                            <NaeTable.th>Tranzakcija</NaeTable.th>

                            <NaeTable.th>Sąskaita</NaeTable.th>
                            <NaeTable.th>Data</NaeTable.th>

                            <NaeTable.th>Vilkikas</NaeTable.th>
                            <NaeTable.th>Paslauga</NaeTable.th>
                            <NaeTable.th>Grupė</NaeTable.th>
                            <NaeTable.th>Šalis</NaeTable.th>
                            <NaeTable.th number={true}>Kiekis</NaeTable.th>
                            <NaeTable.th number={true}>Suma</NaeTable.th>
                            <NaeTable.th number={true}>PVM</NaeTable.th>
                            <NaeTable.th number={true}>Suma su PVM</NaeTable.th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoiceLines.map((item: InvoiceLine) => {
                            return (
                                <tr key={"list-row-" + item.id}>
                                    <NaeTable.td onClick={() => goTo(item)} link={true}
                                                 id={true}>{item.id}</NaeTable.td>
                                    <NaeTable.td onClick={() => goTo(item)} link={true}>{item.dateTime}</NaeTable.td>


                                    <NaeTable.td onClick={() => goToInvoice(item)}>{item.invoice}</NaeTable.td>
                                    <NaeTable.td nw={true}>{item.invoiceDate}</NaeTable.td>

                                    <NaeTable.td onClick={() => goToTruck(item)}>{item.truckName}</NaeTable.td>
                                    <NaeTable.td onClick={() => goToService(item)}>{item.serviceName}</NaeTable.td>
                                    <NaeTable.td>{item.serviceGroupName}</NaeTable.td>
                                    <NaeTable.td>{item.country}</NaeTable.td>
                                    <NaeTable.td number={true}>{item.quantity.toFixed(2)}</NaeTable.td>
                                    <NaeTable.td number={true}>{item.totalEur.toFixed(2)}</NaeTable.td>
                                    <NaeTable.td number={true}>{item.totalVatEur.toFixed(2)}</NaeTable.td>
                                    <NaeTable.td number={true}>{item.totalWithVatEur.toFixed(2)}</NaeTable.td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                }

            </Card.Body>
            <Card.Footer>
                <div className={"paging"}>
                    <NaePaging.Prev onClick={() => {
                        setActivePage(Math.max(activePage - 1, 1));
                    }}/>
                    <NaePaging.Group pages={pages} activePage={activePage} onClick={(p) => {
                        setActivePage(p);
                    }}/>
                    <NaePaging.Next onClick={() => {
                        setActivePage(Math.min(activePage + 1, pages));
                    }}/>
                </div>
            </Card.Footer>
        </Card>
    )
}
