import { UI } from '@newageerp/nae-react-ui';
import { INaeWidget } from '@newageerp/nae-react-ui/dist/interfaces';
import TripBottomWidget from '../../../_generated/_custom/widgets/trip/TripBottomWidget';
import TripRightTopWidget from '../../../_generated/_custom/widgets/trip/TripRightTopWidget';
import { NaeSSchemaMap } from '../../NaeSSchema';

export const TripWidgets: INaeWidget[] = [
    {
        schema: NaeSSchemaMap.Trip.schema,
        type: UI.Widget.WidgetType.viewRightTop,
        sort: 0,
        options: {},
        comp: TripRightTopWidget,
    },
    {
        schema: NaeSSchemaMap.Trip.schema,
        type: UI.Widget.WidgetType.viewBottom,
        sort: 0,
        options: {},
        comp: TripBottomWidget,
    },
];
