import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {Card} from "react-bootstrap";
import ApiRest from "../../../service/ApiRest";
import TruckTrailerForm from "../../Forms/TruckTrailerForm";
import {toast} from "react-toastify";
import TrucksListCard from "./TrucksListCard";
import CommonRemoveBtn from "../../CommonRemoveBtn";

const moduleName = 'truck-trailer';

interface ParamTypes {
    id: string
}

const defElement = {
    id: 0,
    name: '',
}

export default function TrucksTrailerPage() {
    const history = useHistory();

    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (name: string) => {
        if (id === 'new') {
            ApiRest.addElement(moduleName, {name}).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
                history.push('/' + moduleName + '/' + res.id);
            })
        } else {
            ApiRest.updateElement(moduleName, element.id, {name}).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <TruckTrailerForm element={element} saveData={saveData}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        element.id > 0 ?
            <Card>
                <Card.Body>
                    <CommonRemoveBtn id={element.id} module={moduleName} path={"/truck-trailers"}/>
                </Card.Body>
            </Card>
            : <Fragment/>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

            {element.id > 0 &&
            <TrucksListCard trailerGroupId={element.id}/>
            }
        </Fragment>
    )
}
