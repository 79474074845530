import React, { ReactNode, Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'

interface Props {
    render: (
        id: string,
        onSave: (element: any, isNew: boolean, schema: string) => void,
        onCancel: () => void,
    ) => ReactNode
}

interface ParamTypes {
    id: string
}

export default function DefaultEditRouteWrapper(props: Props) {
    const history = useHistory()
    const routeParams = useParams<ParamTypes>()

    const onSave = (element: any, isNew: boolean, schema: string) => {
        if (isNew) {
            history.replace(`/u/${schema}/main/view/${element.id}`)
        } else {
            history.goBack()
        }
    }
    const onCancel = () => {
        history.goBack();
    }

    return (
        <div className="mx-auto" style={ { maxWidth: 800 } }>{props.render(routeParams.id, onSave, onCancel)}</div>
    )
}
