import React, {useEffect, useState, Fragment} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Tablet, Truck} from "../Pages/Trucks/TruckTypes";
import ApiRest from "../../service/ApiRest";
import BackBtn from "../BackBtn";

interface Props {
    element: Tablet,
    saveData: (number: string,
               model: string,
               truck: number,
               date: string,
               status: number,
               comment: string,
    ) => void,
}

export default function TabletForm(props: Props) {
    const {element, saveData} = props;
    const [number, setNumber] = useState(element.number);
    const [model, setModel] = useState(element.model);
    const [truck, setTruck] = useState(element.truck.toString());
    const [date, setDate] = useState(element.date);
    const [status, setStatus] = useState(element.status);
    const [comment, setComment] = useState(element.comment);

    const [trucks, setTrucks] = useState([]);

    const getListData = () => {
        ApiRest.getElements('truck').then(res => {
            if (res) {
                setTrucks(res.filter((t: Truck) => !!t.locktrackerNumber));
            }
        });
    }

    useEffect(getListData, []);

    return (
        <Fragment>
            <Card>
                <Card.Header><BackBtn/>Planšetė</Card.Header>
                <Card.Body>
                    <Form>

                        <Form.Group>
                            <Form.Label>Numeris</Form.Label>
                            <Form.Control value={number} onChange={e => setNumber(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Modelis</Form.Label>
                            <Form.Control value={model} onChange={e => setModel(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Vilkikas</Form.Label>
                            <Form.Control
                                as="select"
                                className="mr-sm-2"
                                value={truck}
                                onChange={(e) => setTruck(e.target.value)}>
                                <option value="0">Pasirinkite...</option>
                                {trucks.map((truck: Truck) => <option key={'opt-g' + truck.id}
                                                                      value={truck.id}>{truck.name}</option>)}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Pirkimo Data</Form.Label>
                            <Form.Control value={date} onChange={e => setDate(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Statusas</Form.Label>
                            <Form.Control
                                as="select"
                                className="mr-sm-2"
                                value={status}
                                onChange={(e) => setStatus(parseInt(e.target.value, 10))}
                            >
                                <option value="0">Neaktyvi</option>
                                <option value="10">Aktyvi</option>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Komentaras</Form.Label>
                            <Form.Control as={"textarea"} value={comment} onChange={e => setComment(e.target.value)}/>
                        </Form.Group>

                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col>

                        </Col>
                        <Col className={"text-right"}>
                            <Button type={"button"} variant={"primary"}
                                    onClick={() => saveData(
                                        number,
                                        model,
                                        parseInt(truck, 10),
                                        date,
                                        status,
                                        comment
                                    )}>Išsaugoti</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Fragment>)
}
