import React, {Fragment} from "react";
import {Card, Col, Form, Table} from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import {ImportedFile} from "../Pages/Upload/UploadTypes";
import BackBtn from "../BackBtn";
import {NaeTable} from "nae-react-core-styles";
import {Service} from "../Pages/Services/ServiceTypes";

interface Props {
    element: ImportedFile,

}

export default function UploadedForm(props: Props) {
    const history = useHistory();

    const {element} = props;


    const goBack = () => {
        history.goBack();
    }

    const openService = (serviceId: number) => {
        window.open('/service/' + serviceId)
    }

    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <BackBtn/>
                    Importuotas failas
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Įkelta</Form.Label>
                                <Form.Text>{element.createdAt}</Form.Text>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Dydis</Form.Label>
                                <Form.Text>{element.size}</Form.Text>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Tipas</Form.Label>
                                <Form.Text>{element.type}</Form.Text>
                            </Form.Group>

                        </Form.Row>

                    </Form>
                </Card.Body>

            </Card>
            {element.linesWoCode.length > 0 && <Fragment>
                <Card>
                    <Card.Header>
                        Paslaugos be FVS kodo
                    </Card.Header>
                    <Card.Body>
                        <Table striped hover className={"mt-2"}>
                            <thead>
                            <tr>
                                <NaeTable.th id={true}>ID</NaeTable.th>
                                <NaeTable.th>Paslauga</NaeTable.th>

                            </tr>
                            </thead>
                            <tbody>
                            {element.linesWoCode.map((item: Service) => {
                                return (
                                    <tr key={"list-row-" + item.id} onClick={() => openService(item.id)}>
                                        <NaeTable.td id={true} link={true}>{item.id}</NaeTable.td>
                                        <NaeTable.td link={true}>{item.name}</NaeTable.td>

                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Fragment>}
        </Fragment>)
}
