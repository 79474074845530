import { OpenApi } from "@newageerp/nae-react-auth-wrapper";
import { CardWidgetTitle } from "@newageerp/ui.cards.base.card-widget-title";
import { WhiteCard } from "@newageerp/ui.cards.base.white-card";
import {
  Input,
  SelectAdvId,
  WideToolbar,
} from "@newageerp/ui.form.base.form-pack";
import {
  TwTextAlignment,
  TwBackgroundColors,
} from "@newageerp/ui.styles.tailwind";
import { Table, Td, Th } from "@newageerp/ui.table.base.table";
import { SquareButton } from "@newageerp/ui.buttons.base.square-button";
import classNames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import ApiRest from "../../service/ApiRest";

interface Props {
  id: number;
  onClose: () => void;
}

interface IElement {
  id: number;
  price: number;
  total: number;
  quantity: number;
  service: {
    id: number;
    name: string;
  };
  fuelCard: {
    id: number;
    number: string;
  };
  tripReference: string;
}

interface IService {
  id: number;
  name: string;
}

interface IFuelCard {
  id: number;
  number: string;
}

export default function TripFormEdit(props: Props) {
  const [oldService, setOldService] = useState("");
  const [oldReference, setOldReference] = useState("");

  const [oldQuantity, setOldQuantity] = useState("0");
  const [oldPrice, setOldPrice] = useState("0");

  const [saveData, saveDataData] = OpenApi.useUSave("invoice-line");
  const doSave = () => {
    if (element) {
      const total = parseFloat(oldPrice) * parseFloat(oldQuantity);
      saveData(
        {
          price: parseFloat(oldPrice),
          priceEur: parseFloat(oldPrice),
          quantity: parseFloat(oldQuantity),
          total: total,
          totalEur: total,
          service: {
            id: oldService,
          },
          tripReference: oldReference,
        },
        element.id
      ).then(() => {
        window.location.reload();
      });
    }
  };

  const [getData, elementData] = OpenApi.useUList<IElement>("invoice-line", [
    "id",
    "price",
    "total",
    "quantity",
    "service.id",
    "fuelCard.id",
    "service.name",
    "fuelCard.number",
    "invoice.id",
    "importedFile.id",
    "date",
    "time",
    "dateTime",
    "invoiceDate",
    "currency",
    "truck.id",
    "station.id",
    "tripReference",
  ]);
  const [element, setElement] = useState<IElement | undefined>(undefined);

  const [getServices, servicesData] = OpenApi.useUList<IService>("service", [
    "id",
    "name",
  ]);
  const [getFuelCards, cardsData] = OpenApi.useUList<IFuelCard>("fuel-card", [
    "id",
    "number",
  ]);

  const loadData = () => {
    getData(
      [
        {
          and: [["i.id", "=", props.id, true]],
        },
      ],
      1,
      1,
      [{ value: "ASC", key: "i.id" }]
    ).then((res: any) => {
      setElement(res.data.data[0]);
      setOldQuantity(res.data.data[0].quantity);
      setOldPrice(res.data.data[0].price);
      setOldReference(res.data.data[0].tripReference);
      setOldService(res.data.data[0].service.id);
    });
    getServices([], 1, 10000, [{ value: "ASC", key: "i.name" }]);
    getFuelCards([], 1, 10000, [{ value: "ASC", key: "i.number" }]);
  };
  useEffect(loadData, []);

  if (!element) {
    return <Fragment />;
  }

  const totalOld = parseFloat(oldQuantity) * parseFloat(oldPrice);

  return (
    <Fragment>
      <Table
        thead={
          <thead>
            <tr>
              <Th>Paslauga</Th>
              <Th>Refence</Th>
              <Th>Kiekis</Th>
              <Th>Kaina</Th>
              <Th>Suma</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            <tr>
              <Td>
                <SelectAdvId
                  selectedId={oldService}
                  onSelectId={(e: any) => setOldService(e)}
                  options={[
                    { value: "", label: "" },
                    ...servicesData.data.data.map((service: IService) => ({
                      value: service.id.toString(),
                      label: service.name,
                    })),
                  ]}
                />
              </Td>
              <Td>
                <Input
                  value={oldReference}
                  onChange={(e) => setOldReference(e.target.value)}
                />
              </Td>
              <Td>
                <Input
                  value={oldQuantity}
                  onChange={(e) => setOldQuantity(e.target.value)}
                />
              </Td>
              <Td>
                <Input
                  value={oldPrice}
                  onChange={(e) => setOldPrice(e.target.value)}
                />
              </Td>
              <Td textAlignment={TwTextAlignment.right}>
                {totalOld.toFixed(2)}
              </Td>
            </tr>
            <tr
              className={classNames({
                [TwBackgroundColors.red200]:
                  totalOld.toFixed(2) !== element.total.toFixed(2),
              })}
            >
              <Td>Suma po redagavimo</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td textAlignment={TwTextAlignment.right}>
                {totalOld.toFixed(2)}
              </Td>
            </tr>
            <tr>
              <Td>Suma prieš redagavimą</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td textAlignment={TwTextAlignment.right}>
                {element.total.toFixed(2)}
              </Td>
            </tr>
          </tbody>
        }
      />
      <WideToolbar
        onSave={
          totalOld.toFixed(2) === element.total.toFixed(2) ? doSave : undefined
        }
        onCancel={props.onClose}
      />
    </Fragment>
  );
}
