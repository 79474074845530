import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {Button, Card} from "react-bootstrap";
import ApiRest from "../../../service/ApiRest";
import FuelCardForm from "../../Forms/FuelCardForm";
import {toast} from "react-toastify";
import InvoiceLinesListCard from "../Invoices/InvoiceLinesListCard";
import {FuelCard, FuelCardHistory} from "./TruckTypes";
import ReportsPage from "../Reports/ReportsPage";
import DeleteButton from "./Components/DeleteButton";

interface ParamTypes {
    id: string
}

const defElement: FuelCard = {
    id: 0,
    number: '',

    truckGroup: 0,
    truckGroupName: '',

    truck: 0,
    truckName: '',

    supplier: '',
    pin: '',
    date: '',
    status: 0,
    history: [],

    comment: '',
}


const moduleName = 'fuel-card';

export default function FuelCardsPage() {
    const history = useHistory();

    const [key, setKey] = useState<string | null>('list');


    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (number: string,
                      truckGroup: number,
                      truck: number,
                      supplier: string,
                      pin: string,
                      date: string,
                      status: number,
                      comment: string,
    ) => {
        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    number,
                    truckGroup,
                    truck,
                    supplier,
                    pin,
                    date,
                    status,
                    comment
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
                history.push('/' + moduleName + '/' + res.id);
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    number,
                    truckGroup,
                    truck,
                    supplier,
                    pin,
                    date,
                    status,
                    comment
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10), {addHistory: true}).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <FuelCardForm element={element} saveData={saveData}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Fragment>
            {id !== 'new' && <DeleteButton id={element.id} moduleName="fuel-card"/>}


            <Card>
                <Card.Header>
                    Pakeitimų istorija
                </Card.Header>
                <Card.Body>
                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Komentaras</th>
                        </tr>
                        </thead>
                        <tbody>
                        {element.history.map((el: FuelCardHistory) => {
                            return (
                                <tr key={"hist-" + el.id}>
                                    <td>
                                        {el.date}
                                    </td>
                                    <td>
                                        {el.change}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </Card.Body>
            </Card>
        </Fragment>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

            {element.id > 0 &&
            <Fragment>
                <div className={"mb-2"}>
                    <Button onClick={() => setKey('list')} variant={key === 'list' ? 'primary' : 'light'}
                            className={"mr-2"}>Lentelė</Button>
                    <Button onClick={() => setKey('reports')}
                            variant={key === 'reports' ? 'primary' : 'light'}>Ataskaita</Button>
                </div>

                <div>
                    {key === 'list' ? <InvoiceLinesListCard options={{fuelCardId: element.id}}/> :
                        <ReportsPage selectedCards={[{id: element.id.toString(), name: element.number}]}/>}
                </div>
            </Fragment>
            }
        </Fragment>
    )
}
