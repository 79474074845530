import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {Button, Card, Col, Container, Row, Table} from "react-bootstrap";
import {Truck, TruckGroup, TruckTrailerGroup} from "./TruckTypes";
import { NaeTable } from "nae-react-core-styles";
import {UI} from "@newageerp/nae-react-ui";

const moduleName = 'truck-trailer';

export default function TrucksTrailerListPage() {
    const history = useHistory();

    const [truckTrailers, setTruckTrailers] = useState<TruckTrailerGroup[]>([])

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setTruckTrailers(res);
        })
    }

    useEffect(getData, []);

    const goTo = (item: TruckGroup) => {
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"card-table"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Priekabų tipai
                                </Col>
                                <Col className={"text-right"}>
                                    <UI.Buttons.Button icon={"fal fa-fw fa-plus"} onClick={goToNew}>
                                        Naujas
                                    </UI.Buttons.Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table striped hover className={"mt-2"}>
                                <thead>
                                <tr>
                                    <NaeTable.th id={true}>ID</NaeTable.th>
                                    <NaeTable.th>Pavadinimas</NaeTable.th>
                                </tr>
                                </thead>
                                <tbody>
                                {truckTrailers.map((item: TruckTrailerGroup) => {
                                    return (
                                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                            <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                            <NaeTable.td link={true}>{item.name}</NaeTable.td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </Col>
            </Row>
        </Container>
    );
}
