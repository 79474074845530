import React, {Fragment} from 'react';
import { Wide, WideToolbar, WideRow, Compact, CompactToolbar, CompactRow, Label } from '@newageerp/ui.form.base.form-pack';
import { useTranslation } from 'react-i18next'
import { Input } from "@newageerp/ui.form.base.form-pack";
import { InputFloat } from "@newageerp/ui.form.base.form-pack";

interface Props {
    element: any;
    onChange: (key: string, value: any) => void,
    compact?: boolean,

    onSave: () => void,
    onCancel: () => void,
}

export default function TruckFuelTankMainForm(props: Props) {
    const { element, onChange } = props;
    const { t } = useTranslation();

    if (props.compact) {
        return (
            <Compact>
                <CompactRow label={<Label>{t('Pavadinimas')}</Label>} control={<Input onChange={(e: any) => onChange('name', e.target.value)} value={element.name}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('K bakas litrai')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankLeftCapacity', e)} value={element.tankLeftCapacity} className={"field-float"}/>}/>
<CompactRow label={<Label>{t('K bakas 1 cm / l')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankLeftCml', e)} value={element.tankLeftCml} className={"field-float"}/>}/>
<div className="h-6"></div>
<CompactRow label={<Label>{t('R bakas litrai')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankRightCapacity', e)} value={element.tankRightCapacity} className={"field-float"}/>}/>
<CompactRow label={<Label>{t('R bakas l / cm')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankRightCml', e)} value={element.tankRightCml} className={"field-float"}/>}/>
                <CompactToolbar onSave={props.onSave} onCancel={props.onCancel} />
            </Compact>
        )
    }

    return (
        <Wide>
            <WideRow label={<Label>{t('Pavadinimas')}</Label>} control={<Input onChange={(e: any) => onChange('name', e.target.value)} value={element.name}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('K bakas litrai')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankLeftCapacity', e)} value={element.tankLeftCapacity} className={"field-float"}/>}/>
<WideRow label={<Label>{t('K bakas 1 cm / l')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankLeftCml', e)} value={element.tankLeftCml} className={"field-float"}/>}/>
<div className="h-6"></div>
<WideRow label={<Label>{t('R bakas litrai')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankRightCapacity', e)} value={element.tankRightCapacity} className={"field-float"}/>}/>
<WideRow label={<Label>{t('R bakas l / cm')}</Label>} control={<InputFloat onChangeFloat={(e: any) => onChange('tankRightCml', e)} value={element.tankRightCml} className={"field-float"}/>}/>
            <WideToolbar onSave={props.onSave} onCancel={props.onCancel} />
        </Wide>
    )
}