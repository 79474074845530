import React, { Fragment } from 'react'
import { MenuFolder, MenuSpacer } from '@newageerp/ui.menu.base.menu'
import MenuItemInvoices from "../items/MenuItemInvoices" 
import MenuItemInvoiceLines from "../items/MenuItemInvoiceLines" 
import MenuItemReports from "../items/MenuItemReports" 
import MenuItemReportsFee from "../items/MenuItemReportsFee" 


export default function MenuFolderSskaitos79f() {
    return (
        <Fragment>
            <MenuFolder
                item={{
                    children: 'Sąskaitos',
                    icon: 'file-invoice'
                }}
                menuFolderId='MenuFolderSskaitos79f'
            >

                <MenuItemInvoices forceSkipIcon={true}/>
                <MenuItemInvoiceLines forceSkipIcon={true}/>
                <MenuItemReports forceSkipIcon={true}/>
                <MenuItemReportsFee forceSkipIcon={true}/>
            </MenuFolder>
        </Fragment>
    )
}
