import { ContentWidgetProps } from '@newageerp/nae-react-ui/dist/UI/Widget/NEWidgets'
import React, { Fragment } from 'react'
import TripFrigoViewFormWidget from '../../../viewforms/widgets/TripFrigoViewFormWidget'
import TripKmsViewFormWidget from '../../../viewforms/widgets/TripKmsViewFormWidget'
import TripOtherCostsViewFormWidget from '../../../viewforms/widgets/TripOtherCostsViewFormWidget'
import TripRoadsViewFormWidget from '../../../viewforms/widgets/TripRoadsViewFormWidget'
import TripKmTable from './Components/TripKmTable'

export default function TripRightTopWidget(props: ContentWidgetProps) {
  return (
    <Fragment>
      <TripKmsViewFormWidget id={props.element.id} contentAfterForm={<TripKmTable id={props.element.id} />} />
      <TripRoadsViewFormWidget id={props.element.id} />
      {/* <TripFrigoViewFormWidget id={props.element.id} />
      <TripOtherCostsViewFormWidget id={props.element.id} /> */}
    </Fragment>
  )
}
