import { createSelector } from "redux-orm";
import orm from "./orm";
export const SelectorCountryNae = createSelector(orm.CountryModel);
export const SelectorImportedFileNae = createSelector(orm.ImportedFileModel);
export const SelectorInvoiceNae = createSelector(orm.InvoiceModel);
export const SelectorTruckNae = createSelector(orm.TruckModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorUserNae = createSelector(orm.UserModel);
export const SelectorNoteNae = createSelector(orm.NoteModel);
export const SelectorTripNae = createSelector(orm.TripModel);
export const SelectorTruckFuelTankNae = createSelector(orm.TruckFuelTankModel);
export const SelectorClientNae = createSelector(orm.ClientModel);
export const SelectorDriverTripNae = createSelector(orm.DriverTripModel);
