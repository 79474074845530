import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import ApiRest from "../../../service/ApiRest";
import {Button, ButtonGroup, Card, Col, Row} from "react-bootstrap";
import {NaeLayout} from "nae-react-core-styles";
import InvoiceForm from "../../Forms/InvoiceForm";
import InvoiceLines from "./Components/InvoiceLines";
import InvoiceReport from "./Components/InvoiceReport";
import InvoiceUpdateForm from "../../Forms/InvoiceUpdateForm";
import DeleteButton from "./Components/DeleteButton";
import {Invoice} from "./InvoiceTypes";

const moduleName = 'invoice';

interface ParamTypes {
    id: string
}

const defElement: Invoice = {
    id: 0,
    number: '',
    date: '',
    client: '',

    currency: '',
    linesCount: 0,

    total: 0,
    totalVat: 0,
    totalWithVat: 0,

    totalEur: 0,
    totalVatEur: 0,
    totalWithVatEur: 0,

    type: '',

    references: '',
}


export default function InvoicePage() {
    const history = useHistory();

    const [editMode, setEditMode] = useState(false);
    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (date: string, number: string, currencyRate: number) => {
        if (id === 'new') {
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    date,
                    serialNo: number,
                    currencyRate
                }).then(res => {
                setElement(res);
                setEditMode(false);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            {editMode ? <InvoiceUpdateForm element={element} saveData={saveData}/> :
                <InvoiceForm element={element}/>}

        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Card>
            <Card.Body>
                <ButtonGroup vertical>

                    {/*<DeleteButton id={domain.id}/>*/}

                    {!editMode &&
                        <Button variant={"outline-success"} onClick={() => setEditMode(true)}>Redaguoti</Button>
                    }
                    {editMode &&
                        <Button variant={"outline-light"} onClick={() => setEditMode(false)}>Atšaukti</Button>
                    }

                    <DeleteButton id={element.id}/>
                </ButtonGroup>
            </Card.Body>
        </Card>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

            {!!element && element.type === 'P' &&
                <Row>
                    <Col>
                        <InvoiceReport element={element}/>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <InvoiceLines element={element}/>
                </Col>
            </Row>

        </Fragment>
    )
}
