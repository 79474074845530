
// @ts-nocheck
import axios from "axios";
export const NaePaths = [
    {
        "id": "pingtest",
        "method": "get",
        "path": "\/app\/tmp\/ping",
        "parameters": []
    },
    {
        "id": "NAEauthDoLogin",
        "method": "post",
        "path": "\/app\/nae-core\/auth\/login",
        "parameters": []
    },
    {
        "id": "NAEauthGetProfile",
        "method": "post",
        "path": "\/app\/nae-core\/auth\/get",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderList",
        "method": "post",
        "path": "\/app\/nae-core\/config-builder\/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-builder\/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-builder\/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDbRunSqlSelect",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/runSqlSelect",
        "parameters": []
    },
    {
        "id": "NaeConfigDbRunSqlQuery",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/runSqlQuery",
        "parameters": []
    },
    {
        "id": "NaeConfigDbVariablesSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/variablesSave",
        "parameters": []
    },
    {
        "id": "NaeConfigDbVariablesRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/variablesRemove",
        "parameters": []
    },
    {
        "id": "NaeConfigDbVariablesList",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/variables",
        "parameters": []
    },
    {
        "id": "NaeConfigDbUserPermissionsSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/userPermissionsSave",
        "parameters": []
    },
    {
        "id": "NaeConfigDbUserPermissionsRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/userPermissionsRemove",
        "parameters": []
    },
    {
        "id": "NaeConfigDbUserPermissionsList",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/user-permissions",
        "parameters": []
    },
    {
        "id": "NaeConfigDbPdfsSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/pdfSave",
        "parameters": []
    },
    {
        "id": "NaeConfigDbPdfsRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/pdfRemove",
        "parameters": []
    },
    {
        "id": "NaeConfigDbPdfsList",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/pdfs",
        "parameters": []
    },
    {
        "id": "NaeConfigDbStatusesSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/statusSave",
        "parameters": []
    },
    {
        "id": "NaeConfigDbStatusesRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/statusRemove",
        "parameters": []
    },
    {
        "id": "NaeConfigDbStatusesList",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/statuses",
        "parameters": []
    },
    {
        "id": "NaeConfigDbEntitiesSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/entitySave",
        "parameters": []
    },
    {
        "id": "NaeConfigDbEntitiesList",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/entities",
        "parameters": []
    },
    {
        "id": "NaeConfigDbPropertiesSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/propertySave",
        "parameters": []
    },
    {
        "id": "NaeConfigDbPropertiesRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/propertyRemove",
        "parameters": []
    },
    {
        "id": "NaeConfigDbPropertiesList",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/properties",
        "parameters": []
    },
    {
        "id": "NaeConfigDbEnumsList",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/enums",
        "parameters": []
    },
    {
        "id": "NaeConfigDbEnumsRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/enumRemove",
        "parameters": []
    },
    {
        "id": "NaeConfigDbEnumsSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-db\/enumSave",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsList",
        "method": "post",
        "path": "\/app\/nae-core\/config-defaults\/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-defaults\/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-defaults\/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditList",
        "method": "post",
        "path": "\/app\/nae-core\/config-edit\/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-edit\/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-edit\/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabList",
        "method": "post",
        "path": "\/app\/nae-core\/config-tabs\/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-tabs\/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-tabs\/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewList",
        "method": "post",
        "path": "\/app\/nae-core\/config-view\/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-view\/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-view\/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetList",
        "method": "post",
        "path": "\/app\/nae-core\/config-widgets\/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetSave",
        "method": "post",
        "path": "\/app\/nae-core\/config-widgets\/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetRemove",
        "method": "post",
        "path": "\/app\/nae-core\/config-widgets\/removeConfig",
        "parameters": []
    },
    {
        "id": "NAEUExport",
        "method": "post",
        "path": "\/app\/nae-core\/export\/doExport",
        "parameters": []
    },
    {
        "id": "NAEfileRemove",
        "method": "post",
        "path": "\/app\/nae-core\/files\/remove",
        "parameters": []
    },
    {
        "id": "NAEfilesList",
        "method": "post",
        "path": "\/app\/nae-core\/files\/list",
        "parameters": []
    },
    {
        "id": "NAEfilesMultipleList",
        "method": "post",
        "path": "\/app\/nae-core\/files\/multipleList",
        "parameters": []
    },
    {
        "id": "NAEUImport",
        "method": "post",
        "path": "\/app\/nae-core\/import\/mainImport",
        "parameters": []
    },
    {
        "id": "NAEmailsGetData",
        "method": "post",
        "path": "\/app\/nae-core\/mails\/getData",
        "parameters": []
    },
    {
        "id": "NAEmailsSend",
        "method": "post",
        "path": "\/app\/nae-core\/mails\/send",
        "parameters": []
    },
    {
        "id": "NAENotesReadAll",
        "method": "post",
        "path": "\/app\/nae-core\/plugins\/notes\/readAllMessages",
        "parameters": []
    },
    {
        "id": "NAEPing",
        "method": "get",
        "path": "\/app\/nae-core\/u\/ping",
        "parameters": []
    },
    {
        "id": "NAEUMultipleList",
        "method": "post",
        "path": "\/app\/nae-core\/u\/getMultiple",
        "parameters": []
    },
    {
        "id": "NAEUList",
        "method": "post",
        "path": "\/app\/nae-core\/u\/get\/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEUSave",
        "method": "post",
        "path": "\/app\/nae-core\/u\/save\/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEUSaveMultiple",
        "method": "post",
        "path": "\/app\/nae-core\/u\/saveMultiple",
        "parameters": []
    },
    {
        "id": "NAEURemove",
        "method": "post",
        "path": "\/app\/nae-core\/u\/remove\/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEURemoveMultiple",
        "method": "post",
        "path": "\/app\/nae-core\/u\/removeMultiple",
        "parameters": []
    },
    {
        "id": "NAEXlsxToArray",
        "method": "post",
        "path": "\/app\/nae-core\/xlsx\/toArray",
        "parameters": []
    }
]

export const NaeApiFunctions = {
    'pingtest': () => {
        const url = '/app/tmp/ping';
        
        
                    return axios.get(url, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEauthDoLogin': (data: any) => {
        const url = '/app/nae-core/auth/login';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEauthGetProfile': (data: any) => {
        const url = '/app/nae-core/auth/get';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigBuilderList': (data: any) => {
        const url = '/app/nae-core/config-builder/listConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigBuilderSave': (data: any) => {
        const url = '/app/nae-core/config-builder/saveConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigBuilderRemove': (data: any) => {
        const url = '/app/nae-core/config-builder/removeConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbRunSqlSelect': (data: any) => {
        const url = '/app/nae-core/config-db/runSqlSelect';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbRunSqlQuery': (data: any) => {
        const url = '/app/nae-core/config-db/runSqlQuery';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbVariablesSave': (data: any) => {
        const url = '/app/nae-core/config-db/variablesSave';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbVariablesRemove': (data: any) => {
        const url = '/app/nae-core/config-db/variablesRemove';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbVariablesList': (data: any) => {
        const url = '/app/nae-core/config-db/variables';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbUserPermissionsSave': (data: any) => {
        const url = '/app/nae-core/config-db/userPermissionsSave';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbUserPermissionsRemove': (data: any) => {
        const url = '/app/nae-core/config-db/userPermissionsRemove';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbUserPermissionsList': (data: any) => {
        const url = '/app/nae-core/config-db/user-permissions';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbPdfsSave': (data: any) => {
        const url = '/app/nae-core/config-db/pdfSave';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbPdfsRemove': (data: any) => {
        const url = '/app/nae-core/config-db/pdfRemove';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbPdfsList': (data: any) => {
        const url = '/app/nae-core/config-db/pdfs';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbStatusesSave': (data: any) => {
        const url = '/app/nae-core/config-db/statusSave';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbStatusesRemove': (data: any) => {
        const url = '/app/nae-core/config-db/statusRemove';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbStatusesList': (data: any) => {
        const url = '/app/nae-core/config-db/statuses';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbEntitiesSave': (data: any) => {
        const url = '/app/nae-core/config-db/entitySave';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbEntitiesList': (data: any) => {
        const url = '/app/nae-core/config-db/entities';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbPropertiesSave': (data: any) => {
        const url = '/app/nae-core/config-db/propertySave';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbPropertiesRemove': (data: any) => {
        const url = '/app/nae-core/config-db/propertyRemove';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbPropertiesList': (data: any) => {
        const url = '/app/nae-core/config-db/properties';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbEnumsList': (data: any) => {
        const url = '/app/nae-core/config-db/enums';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbEnumsRemove': (data: any) => {
        const url = '/app/nae-core/config-db/enumRemove';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDbEnumsSave': (data: any) => {
        const url = '/app/nae-core/config-db/enumSave';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDefaultsList': (data: any) => {
        const url = '/app/nae-core/config-defaults/listConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDefaultsSave': (data: any) => {
        const url = '/app/nae-core/config-defaults/saveConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigDefaultsRemove': (data: any) => {
        const url = '/app/nae-core/config-defaults/removeConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigEditList': (data: any) => {
        const url = '/app/nae-core/config-edit/listConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigEditSave': (data: any) => {
        const url = '/app/nae-core/config-edit/saveConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigEditRemove': (data: any) => {
        const url = '/app/nae-core/config-edit/removeConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigTabList': (data: any) => {
        const url = '/app/nae-core/config-tabs/listConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigTabSave': (data: any) => {
        const url = '/app/nae-core/config-tabs/saveConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigTabRemove': (data: any) => {
        const url = '/app/nae-core/config-tabs/removeConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigViewList': (data: any) => {
        const url = '/app/nae-core/config-view/listConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigViewSave': (data: any) => {
        const url = '/app/nae-core/config-view/saveConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigViewRemove': (data: any) => {
        const url = '/app/nae-core/config-view/removeConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigWidgetList': (data: any) => {
        const url = '/app/nae-core/config-widgets/listConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigWidgetSave': (data: any) => {
        const url = '/app/nae-core/config-widgets/saveConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NaeConfigWidgetRemove': (data: any) => {
        const url = '/app/nae-core/config-widgets/removeConfig';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEUExport': (data: any) => {
        const url = '/app/nae-core/export/doExport';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEfileRemove': (data: any) => {
        const url = '/app/nae-core/files/remove';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEfilesList': (data: any) => {
        const url = '/app/nae-core/files/list';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEfilesMultipleList': (data: any) => {
        const url = '/app/nae-core/files/multipleList';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEUImport': (data: any) => {
        const url = '/app/nae-core/import/mainImport';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEmailsGetData': (data: any) => {
        const url = '/app/nae-core/mails/getData';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEmailsSend': (data: any) => {
        const url = '/app/nae-core/mails/send';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAENotesReadAll': (data: any) => {
        const url = '/app/nae-core/plugins/notes/readAllMessages';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEPing': () => {
        const url = '/app/nae-core/u/ping';
        
        
                    return axios.get(url, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEUMultipleList': (data: any) => {
        const url = '/app/nae-core/u/getMultiple';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEUList': (data: any,schema: string) => {
        const url = '/app/nae-core/u/get/{schema}'.replace('{schema}', schema);
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEUSave': (data: any,schema: string) => {
        const url = '/app/nae-core/u/save/{schema}'.replace('{schema}', schema);
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEUSaveMultiple': (data: any) => {
        const url = '/app/nae-core/u/saveMultiple';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEURemove': (data: any,schema: string) => {
        const url = '/app/nae-core/u/remove/{schema}'.replace('{schema}', schema);
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEURemoveMultiple': (data: any) => {
        const url = '/app/nae-core/u/removeMultiple';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

    'NAEXlsxToArray': (data: any) => {
        const url = '/app/nae-core/xlsx/toArray';
        
        
                    return axios.post(url, data, {headers: {Authorization: window.localStorage.getItem("token"),"Content-Type": "application/json",},});
                    
    },

        }